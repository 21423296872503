import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'

import { EmptyList } from '@praticabr/ppsa'
import $ from 'jquery'
import { FilterData } from 'models/FilterData'
import { Transfer } from 'models/Transfer'
import HasError from 'module/technicalServices/components/Shared/Messages/HasError'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import React, { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TransfersListItem from '../../TransfersListItem/Web'
import './styles.scss'

const SCROLL_TOP = 0

interface Params {
	onItemClick: (transfer: Transfer) => void
}

const TransfersListWeb: React.FC<Params> = ({ onItemClick }) => {
	const { transferList, isLoading, itemFilter, hasError, onFilterChange } =
		useContext(TransfersContextElement)

	const [warrantOrder, setWarrantOrder] = useState(
		itemFilter?.warrantOrder || 'desc'
	)

	$('.mobile-scroll').scrollTop(SCROLL_TOP)
	$('.tranfer-list-items').scrollTop(SCROLL_TOP)

	const onOrderChange = (order: 'desc' | 'asc') => {
		const newFilter = itemFilter ? { ...itemFilter } : ({} as FilterData)

		newFilter.warrantOrder = order

		onFilterChange(newFilter)
		setWarrantOrder(order)
	}

	const arrowOrderContent = () => {
		return warrantOrder === 'desc' ? (
			<MdArrowUpward onClick={() => onOrderChange('asc')} />
		) : (
			<MdArrowDownward onClick={() => onOrderChange('desc')} />
		)
	}

	const listContent = () => {
		return (
			<>
				{transferList?.map((transfer: Transfer, index: number) => {
					return (
						<TransfersListItem {...{ transfer, onItemClick }} key={index} />
					)
				})}
			</>
		)
	}

	return (
		<section className="transfer-list-content">
			<div className="transfer-list-header">
				<div className="list-header date-solicitation">
					<span>Data Solic.</span>
				</div>
				<div className="list-header serial">
					<span>N/S</span>
				</div>
				<div className="list-header origin">
					<span>Canal</span>
				</div>
				<div className="list-header client-name">
					<span>Cliente Atual</span>
				</div>
				<div className="list-header equip-name">
					<span>Equipamento</span>
				</div>
				<div className="list-header date-transfer">
					<span>Data Transf.</span>
				</div>
				<div className="list-header status">
					<span>Status</span>
				</div>
				<div className="list-header user">
					<span>Atendente</span>
				</div>
				<div className="list-header options"></div>
			</div>

			<div className="transfer-list-items">
				{!isLoading ? listContent() : <Skeleton count={10} height={28.9} />}
			</div>
			{!transferList?.length && !isLoading && (
				<EmptyList text="Sem transferências por aqui" />
			)}
			<HasError show={!!hasError && !transferList?.length && !isLoading} />
		</section>
	)
}

export default TransfersListWeb
