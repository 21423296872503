import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
// import Button from 'module/orderManagement/components/Button';
import { Button, Modal } from '@praticabr/ppsa'
import Filter from 'assets/image/Filter.svg'
import financialBlockIcon from 'assets/image/financial-block.svg'
import financialNotBlockIcon from 'assets/image/financial-not-block.svg'
import React, { useContext, useState } from 'react'
import { history } from 'routes/history'
import Search from 'shared/components/Search'
import ClientsFilter from '../ClientsFilter'
import './styles.scss'

const ClientsActions = () => {
	const { onSearchClient } = useContext(ClientContextElement)
	const [clientsFilterShowModal, setClientsFilterShowModal] =
		useState<boolean>(false)

	return (
		<>
			<div className="clients-actions">
				<div className="clients-search-and-filter">
					<Search
						variant="light"
						placeholder="Procurar Cliente"
						onChangeValues={onSearchClient}
						removeSpecialCharacters={true}
					/>
					<img
						src={Filter}
						alt="filter icon"
						onClick={() => setClientsFilterShowModal(true)}
					/>
				</div>
				<div className="clients-bt-new-order">
					<Button
						size="lg"
						title="NOVO CLIENTE"
						variant="confirmation-solid"
						onClick={() => history.push('/clients/new-client')}
					/>
				</div>
				{clientsFilterShowModal && (
					<Modal.root>
						<Modal.header
							title="FILTROS"
							onClose={() => setClientsFilterShowModal(false)}
						/>
						<Modal.body>
							<Modal.body.main>
								<ClientsFilter
									onClose={() => setClientsFilterShowModal(false)}
								/>
							</Modal.body.main>
						</Modal.body>
					</Modal.root>
				)}
			</div>
			<div className="clients-legends">
				<span>
					<img src={financialBlockIcon} alt="financial block icon" /> Com
					bloqueio financeiro
				</span>
				<span>
					<img src={financialNotBlockIcon} alt="financial not block icon" /> Sem
					bloqueio financeiro
				</span>
			</div>
		</>
	)
}
export default ClientsActions
