import { Button, Input, Select } from '@praticabr/ppsa'
import helpIcon from 'assets/image/helpIcon.svg'
import { Order } from 'module/orderManagement/domain/models/order'
import { PaymentFields } from 'module/orderManagement/domain/usecases/add-order'
import { OrderBranchTypes } from 'module/orderManagement/utils/constants'
import React, { ReactNode, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import AddIcon from 'shared/components/SvgIcons/AddIcon'
import { store } from 'store'
import { OrderClientInstallmentsEditingMode } from './NewOrderClientInstallments/OrderClientInstallmentsEditingMode'
import './styles.scss'

type PaymentDetailsEditingModeProps = {
	order?: Order
	paymentForm: UseFormReturn<PaymentFields, any, any>
	installmentForm: any
	children: ReactNode
	handleSimulateClick: () => Promise<void>
	fieldArray: any
	branch: string | undefined
}

export const PaymentDetailsEditingMode = ({
	order,
	paymentForm,
	installmentForm,
	fieldArray,
	handleSimulateClick,
	branch,
	children
}: PaymentDetailsEditingModeProps) => {
	const [installmentsShowModal, setInstallmentsShowModal] =
		useState<boolean>(false)
	const { paymentFormList } = store.getState().baseLists

	const {
		setValue,
		clearErrors,
		register,
		formState: { errors },
		watch,
		getValues
	} = paymentForm

	useEffect(() => {
		setValue('branch', order?.branch || branch || '')
		setValue('payment_code', order?.payment_code || '')
	}, [order])

	useEffect(() => {
		const elementPayment = document.querySelector(
			'.new-payment-section'
		) as HTMLElement
		!order &&
			elementPayment &&
			elementPayment.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}, [])

	const MainContent = () => (
		<div className="new-order-payment-contain-edit-mode">
			<div className="new-order-payment-contain-information">
				<label>Filial</label>
				<Select
					id="branch"
					options={OrderBranchTypes}
					selectedOption={[getValues('branch')]}
					onSelectOptions={(option) => {
						setValue('branch', option[0])
						clearErrors('branch')
					}}
					innerRef={{
						...register('branch', {
							required: {
								value: true,
								message: 'O campo forma de filial é obrigatório!'
							}
						})
					}}
					errors={errors.branch}
				/>
			</div>
			<div className="new-order-payment-contain-information">
				<label>Forma de pagamento</label>
				<div className="order-payment-form-container">
					<Select
						id="payment_code"
						options={paymentFormList}
						selectedOption={[getValues('payment_code')]}
						onSelectOptions={(option) => {
							setValue('payment_code', option[0])
							clearErrors('payment_code')
						}}
						innerRef={{
							...register('payment_code', {
								required: {
									value: true,
									message: 'O campo forma de pagamento é obrigatório!'
								}
							})
						}}
						errors={errors.payment_code}
					/>
					{getValues('payment_code') === '999' && <></>}
				</div>
			</div>
			<div className="new-order-payment-contain-information">
				<label>Aut. cartão</label>
				<div className="order-payment-form-container">
					<Input
						maxLength={12}
						variant="light"
						id="aut-cartao"
						value={order?.credit_card_authorization}
						errors={errors?.credit_card_authorization}
						{...register('credit_card_authorization', {
							required: false,
							maxLength: {
								value: 12,
								message: 'O campo aut. cartão aceita no máximo 12 caracteres'
							}
						})}
					/>
				</div>
			</div>
			<div className="new-order-payment-contain-information">
				<div className="new-order-payment-contain-action">
					<div
						className="new-order-installments"
						onClick={() => {
							if (
								order?.total_with_taxes &&
								getValues('payment_code') === '999'
							) {
								setInstallmentsShowModal(true)
							}
						}}
					>
						<AddIcon
							width={''}
							height={''}
							fill={
								order?.total_with_taxes && getValues('payment_code') === '999'
									? '#004992'
									: '#7c7c7c'
							}
						/>
						<label htmlFor="addItemIcon">
							{installmentForm.getValues
								? 'Visualizar parcelas'
								: 'Adicionar parcelas'}
						</label>
						<img
							src={helpIcon}
							alt="observation icon"
							title='A forma de pagamento deve ser parcelada. Clique em "calcular" para adicionar parcelas.'
						/>
					</div>
					<Button
						variant="confirmation-solid"
						title="CALCULAR"
						size="lg"
						onClick={handleSimulateClick}
					/>
				</div>
			</div>
		</div>
	)

	return (
		<>
			<MainContent />
			<div className="new-order-payment-contain">{children}</div>
			{installmentsShowModal && order && (
				<OrderClientInstallmentsEditingMode
					onClose={() => setInstallmentsShowModal(false)}
					order={order}
					installmentForm={installmentForm}
					fieldArray={fieldArray}
				/>
			)}
		</>
	)
}
