import { toast } from 'react-toastify'
import { all, put, takeLatest } from 'redux-saga/effects'
import { api, login, updateUserToken, userInfo } from '../../../services'
import { signInFailure, signInSuccess } from './actions'

export function* signIn({ payload }) {
	const { email, password, hasError } = payload
	try {
		const response = yield login(email, password)
		const { id, userId } = response.data

		api.interceptors.request.use(function (config) {
			config.headers.Authorization = `${id}`
			return config
		})

		const responseUserInfo = yield userInfo(userId)
		const {
			name,
			cel,
			surname,
			register,
			department,
			is_active,
			tutorials,
			lastVersion
		} = responseUserInfo.data

		if (is_active === '1') {
			yield put(
				signInSuccess(
					id,
					userId,
					name,
					email,
					cel,
					surname,
					register,
					department,
					tutorials,
					lastVersion
				)
			)
			location.replace('/home')
		} else {
			toast.error('Este usuário esta inativo. Entre em contato com o RH.')
		}
	} catch (error) {
		if (
			(error?.response?.data?.error?.statusCode === 403 &&
				error?.response?.data?.error?.message === 'AccountLocked') ||
			error?.response?.data?.error?.message?.split(' ')[1] === '0'
		) {
			toast.error(
				'Acesso bloqueado! Siga as instruções de recuperação de conta.'
			)
			hasError()
		} else if (error?.response?.data?.error?.message?.split(' ')[1] === '2') {
			toast.error(
				'Senha incorreta! Duas tentativas restantes antes de ter o acesso à conta bloqueado.'
			)
		} else if (error?.response?.data?.error?.message?.split(' ')[1] === '1') {
			toast.error(
				'Senha incorreta! Uma tentativa restante antes de ter o acesso à conta bloqueado.'
			)
		} else {
			toast.error(
				'Não foi possível fazer o login no sistema. Preencha corretamente seus dados'
			)
		}
		yield put(signInFailure())
	}
}

export function setToken({ payload }) {
	if (!payload) return

	const { token } = payload.auth

	if (token) {
		api.interceptors.request.use(function (config) {
			config.headers.Authorization = `${token}`
			return config
		})
	}
}

export function signOut({ payload }) {
	if (!payload) return
	updateUserToken(null, payload.userId)
	location.replace('/')
	localStorage.clear()
}

export default all([
	takeLatest('persist/REHYDRATE', setToken),
	takeLatest('@auth/SIGN_IN_REQUEST', signIn),
	takeLatest('@auth/SIGN_OUT', signOut)
])
