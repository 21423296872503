import { Input, LoadingProgress, Select, Tooltip } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import {
	FinancialReleaseTypes,
	handleFinancialReleaseTypes
} from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { LuAlertOctagon, LuAlertTriangle } from 'react-icons/lu'
import Skeleton from 'react-loading-skeleton'
import { store } from 'store'
import { ViewType } from 'utils/Constants'
import './styles.scss'

const TabTitles = () => {
	const {
		sc5010,
		sc5IsLoading,
		libFinIsLoading,
		onUpdateC5Libfin,
		orderPrpIsLoading
	} = useContext(OrderDetailsContextElement)
	const { sa1010, sa1010IsLoading } = useContext(ClientDetailsContextElement)
	const [availableFinancialReleaseTypes, setAvailableFinancialReleaseTypes] =
		useState(FinancialReleaseTypes)
	const [c5Libfin, setC5Libfin] = useState('')
	const [blockArray, setBlockArray] = useState<string[] | number[]>([])
	const currentMenu = location.pathname.split('/')[1]
	const { roles } = store.getState().user
	const hasC5LibFinRole =
		!!roles.includes('digitalEndeavourManager') ||
		!!roles.includes('orderManager')

	const { viewType } = useContext(DeviceContextElement)

	useEffect(() => {
		setC5Libfin(handleFinancialReleaseTypes(sc5010?.c5Libfin))
	}, [sc5IsLoading])

	useEffect(() => {
		!roles.includes('orderManager') &&
			setAvailableFinancialReleaseTypes(
				availableFinancialReleaseTypes.filter(
					(e) => e.value !== '1' && e.value !== '2'
				)
			)
	}, [roles])

	const handleUpdateC5Libfin = (c5Libfin: string) => {
		onUpdateC5Libfin({
			c5Libfin,
			protheusOrder: `${sc5010?.c5Filial}${sc5010?.c5Num}`
		})
	}

	useEffect(() => {
		const blockArray: any[] = []
		if (sc5010?.c5Msblql === '1') {
			blockArray.push('Pedido Bloqueado')
		}
		if (sa1010?.a1Msblql === '1') {
			blockArray.push('Cliente Bloq. Geral')
		}
		if (sa1010?.a1Xblfin === 'S') {
			blockArray.push('Cliente Bloq. Financeiro')
		}
		setBlockArray(blockArray)
	}, [sc5010, sa1010])

	const handleBlockOrder = () => {
		const renderBlockMessage = (message: string, icon: React.ReactNode) => (
			<div className="status-block-order">
				{icon}
				{message}
			</div>
		)

		const renderMobileIcon = (icon: React.ReactNode) => <>{icon}</>

		if (viewType !== ViewType.MOBILE) {
			if (sc5010?.c5Msblql === '1') {
				return renderBlockMessage(
					'Pedido Bloqueado',
					<LuAlertOctagon size={20} color="var(--error)" />
				)
			} else if (sa1010?.a1Msblql === '1') {
				return renderBlockMessage(
					'Cliente Bloq. Geral',
					<LuAlertOctagon size={20} color="var(--error)" />
				)
			} else if (sa1010?.a1Xblfin === 'S') {
				return renderBlockMessage(
					'Cliente Bloq. Financeiro',
					<LuAlertTriangle size={20} color="var(--error)" />
				)
			}
		} else {
			if (sc5010?.c5Msblql === '1') {
				return renderMobileIcon(
					<LuAlertOctagon size={30} color="var(--high-pure)" />
				)
			} else if (sa1010?.a1Msblql === '1') {
				return renderMobileIcon(
					<LuAlertOctagon size={30} color="var(--high-pure)" />
				)
			} else if (sa1010?.a1Xblfin === 'S') {
				return renderMobileIcon(
					<LuAlertTriangle size={30} color="var(--high-pure)" />
				)
			}
		}

		return null
	}

	return (
		<>
			<div className="order-titles">
				{currentMenu === 'orders' ||
					(currentMenu === 'order' && (
						<>
							<div className="order-titles-div">
								<span className="title-client">
									{sc5IsLoading ? (
										<Skeleton width={700} height={18} />
									) : (
										sc5010?.sa1010?.a1Nome
									)}
								</span>
								<span className="title-financial-release">
									{!!handleBlockOrder() && (
										<Tooltip
											mobile={viewType === ViewType.MOBILE}
											info={blockArray}
										>
											<div className="title-block-info">
												{handleBlockOrder()}
											</div>
										</Tooltip>
									)}
									<div className="title-financial-release">
										{hasC5LibFinRole ? (
											sc5IsLoading || orderPrpIsLoading ? (
												<Skeleton />
											) : !!sc5010?.c5Nota ||
											  (sc5010?.c5Liberok === 'E' && !sc5010?.c5Blq) ? (
												<Input
													disabled={true}
													variant="light"
													id="financial-release"
													name="financial-release"
													value={handleFinancialReleaseTypes(
														sc5010?.c5Libfin || ''
													)}
												/>
											) : (
												<Select
													id="financial_release"
													selectedOption={[sc5010?.c5Libfin || '']}
													options={FinancialReleaseTypes}
													onSelectOptions={(option) =>
														handleUpdateC5Libfin(option[0])
													}
												/>
											)
										) : sc5IsLoading || orderPrpIsLoading ? (
											<Skeleton />
										) : (
											<Input
												disabled={true}
												variant="light"
												id="financial-release"
												name="financial-release"
												value={handleFinancialReleaseTypes(
													sc5010?.c5Libfin || ''
												)}
											/>
										)}
									</div>
								</span>
							</div>
							<span className="title-order">
								{sc5IsLoading ? (
									<Skeleton width={200} height={18} />
								) : (
									`${sc5010?.c5Filial}${sc5010?.c5Num}`
								)}
							</span>
						</>
					))}
				{currentMenu === 'clients' ||
					(currentMenu === 'client' && (
						<>
							<div className="client-titles-div">
								<span className="title-client">
									{sa1010IsLoading ? (
										<Skeleton width={700} height={18} />
									) : (
										sa1010?.a1Nome
									)}
								</span>
								<span className="title-financial-release">
									{!!handleBlockOrder() && (
										<Tooltip
											mobile={viewType === ViewType.MOBILE}
											info={blockArray}
										>
											<div className="title-block-info">
												{handleBlockOrder()}
											</div>
										</Tooltip>
									)}
								</span>
							</div>
							<span className="subtitle-client">
								{sa1010IsLoading ? (
									<Skeleton width={200} height={18} />
								) : (
									sa1010?.a1Cod
								)}
							</span>
						</>
					))}
			</div>
			{libFinIsLoading && (
				<LoadingProgress text="Atualizando liberação financeira..." />
			)}
		</>
	)
}
export default TabTitles
