import markIcon from 'assets/image/ic-location.svg'
import React, { useEffect, useState } from 'react'

import emailIcon from 'assets/image/mail.svg'
import phoneIcon from 'assets/image/phone.svg'

import { Button } from '@praticabr/ppsa'
import { List } from 'module/installAndSearch/components/List'
import { ModalChangeAddress } from 'module/installAndSearch/components/Modals/ModalChangeAddress'
import { ModalOpenOs } from 'module/installAndSearch/components/Modals/ModalOpenOs'
import { ModalSuccessOpenOs } from 'module/installAndSearch/components/Modals/ModalSuccessOpenOs'
import { allowedCloseClients } from 'module/installAndSearch/utils/functions'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { history } from 'routes/history'
import { api } from 'services'
import { Loader } from 'shared/components/Loader'
import './styles.scss'

export const Response = () => {
	const location = useLocation()
	const { client, product, techList } = location?.state as {
		client: any
		product: any
		techList: any[]
	}

	// UseStates
	const [showOrderServiceButton, setShowOrderServiceButton] =
		useState<boolean>(true)
	const [changeAddress, setChangeAddress] = useState(false)
	const [createOs, setCreateOs] = useState(false)
	const [techniciansList, setTechniciansList] = useState<any[]>([])
	const [warranty, setWarranty] = useState(false)
	const [finished, setFinished] = useState(false)
	const [externalEquip, setExternalEquip] = useState(false)
	const [showResponseModal, setShowResponseModal] = useState(false)
	const [responseData, setResponseData] = useState<any>()

	useEffect(() => {
		if (techList && techList?.length > 0) {
			setTechniciansList(techList)
			setFinished(true)
		}
	}, [techList])

	useEffect(() => {
		if (!client) {
			history.push(`/service`)
		}
	}, [client, history])

	useEffect(() => {
		let isWarranty = false
		if (product && client) {
			const date = product.date_end_warranty
			const year = date.substring(0, 4)
			const month = date.substring(4, 6)
			const day = date.substring(6, 8)
			const formattedDate = new Date(year, month - 1, day)
			const currentDate = new Date()
			isWarranty = formattedDate >= currentDate
			setWarranty(isWarranty)
			if (!isWarranty) {
				setShowOrderServiceButton(isCloseLocation())
			}
			setExternalEquip(client.state_sigla === 'EX')
		} else {
			history.push(`/service`)
		}
	}, [product, history])

	/**
	 * Method that open the modal of the change address
	 */
	const onOpenModal = () => {
		setChangeAddress(true)
	}

	const onSubmitLoadTechnicals = ({
		zip_code,
		city,
		state_sigla,
		address,
		neighborhood,
		number
	}: any) => {
		setChangeAddress(false)
		const aux = client
		aux.zip_code = zip_code
		aux.city = city
		aux.state_sigla = state_sigla
		aux.address = address
		aux.neighborhood =
			neighborhood || neighborhood !== 'undefined' ? neighborhood : null
		aux.number = number
	}

	const onCloseOsModal = () => {
		setCreateOs(false)
	}

	const saveOrderService = async (
		nameContact: string,
		phone: string,
		type: string,
		obs: string,
		email: string,
		addressDefault: boolean
	) => {
		const parameters = {
			equipment: {
				serial_number: product.serial_number,
				name: product.description,
				code_prp: product.product_code,
				date_end_warranty: product.date_end_warranty,
				model: product.model
			},
			service: {
				created_by: 'BUSCA_APP',
				originOS: 'BUSCA_APP',
				client_code: client.code,
				service_order: '',
				contato: nameContact,
				phone: phone,
				email: email,
				equipment_id: '',
				type: 'ASSISTENCIA',
				obs: obs,
				address: client.street,
				neighborhood: client.neighborhood,
				city: client.city,
				state_sigla: client.state_sigla,
				zip_code: client.zip_code,
				addressDefault: addressDefault
			},
			client: {
				cod_protheus: client.code,
				razao_social: client.razao_social,
				street: client.street,
				neighborhood: client.neighborhood,
				city: client.city,
				zip_code: client.zip_code,
				state: client.state_sigla,
				nome_fantasia: client.name,
				cpfCnpj: client.cpf_cnpj,
				keyAccount: client.key_account,
				data_criacao: Date.now()
			},
			msg: obs,
			step_type: 'create_os',
			origin: 'BUSCA'
		}

		const { data } = await api.post('serviceOrders/register', parameters)
		if (data) {
			onCloseOsModal()
			setResponseData(data)
			setShowResponseModal(true)
		} else {
			toast.info('Atenção! Não foi possível criar a Ordem de Serviço.')
		}
	}

	const handleCloseNewOsResponseModal = () => {
		setShowResponseModal(false)
		history.push(`/service`)
	}

	async function onSubmitNewOs({
		name,
		phone,
		type,
		obs,
		email,
		addressDefault
	}: any) {
		saveOrderService(name, phone, type, obs, email, addressDefault)
	}

	const callPraticaOnCLick = async () => {
		const params = {
			serialNumber: product.serial_number,
			technicianCode: 'PRATICA'
		}
		await api.put(`PrpSearchLogs/log`, params)
	}

	/**
	 * Method that open the modal to create a new OS
	 */
	const onOpenModalOpenServiceOrder = () => {
		setCreateOs(true)
		setChangeAddress(false)
	}

	/**
	 * Method to close the modal that change address.
	 */
	const onReturn = () => {
		setChangeAddress(false)
	}

	const isCloseLocation = () => {
		return (
			(client &&
				client.city
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '') === 'sao paulo') ||
			(client && allowedCloseClients(client))
		)
	}

	const listTechsContent = () => {
		return finished ? (
			<List
				techniciansList={techniciansList}
				serialNumber={product.serial_number}
			/>
		) : (
			<Loader />
		)
	}

	const serviceOrderContent = () => {
		return !showOrderServiceButton ? (
			contactExternalContent()
		) : (
			<div className="service-order-container">
				<p>
					<strong>Agende direto com a Prática!</strong>{' '}
				</p>
				<div className="service-order-options-container">
					<div className="service-order-email">
						<img src={emailIcon} />
						<a href="mailto:ajuda@praticabr.com">
							<strong>ajuda@praticabr.com</strong>
						</a>
					</div>
					{warranty ? (
						<>
							<div className="service-order-phone show-m">
								<img src={phoneIcon} />
								<a href="tel:3534491200" onClick={callPraticaOnCLick}>
									{' '}
									Ligue agora{' '}
								</a>
							</div>
							<div className="service-order-phone hide-m">
								<img src={phoneIcon} />
								<p>
									<strong>(35) 3449 1200</strong>
								</p>
							</div>
						</>
					) : (
						<>
							<div className="service-order-phone show-m">
								<img src={phoneIcon} />
								<a href="tel:3534491212" onClick={callPraticaOnCLick}>
									{' '}
									Ligue agora{' '}
								</a>
							</div>
							<div className="service-order-phone hide-m">
								<img src={phoneIcon} />
								<p>
									<strong>(35) 3449 1212</strong>
								</p>
							</div>
						</>
					)}
					<div className="service-order-actions">
						<Button
							size="lg"
							style={{
								width: '190px',
								borderRadius: '1px',
								textTransform: 'uppercase',
								fontWeight: '600'
							}}
							onClick={onOpenModalOpenServiceOrder}
							variant="confirmation-solid"
							title="Abra um chamado online"
						/>
					</div>
				</div>
			</div>
		)
	}

	const contactExternalContent = () => {
		return !externalEquip ? (
			<></>
		) : (
			<div className="service-order-container external">
				<p>
					<strong>Agende direto com a Prática!</strong>
				</p>
				<div className="service-order-options-container">
					<div className="service-order-email">
						<img src={emailIcon} />
						<a href="mailto:comex@praticabr.com">
							<strong>ajuda@praticabr.com</strong>
						</a>
					</div>
					<div className="service-order-phone show-m">
						<img src={phoneIcon} />
						<a href="tel:+553534491235" onClick={callPraticaOnCLick}>
							{' '}
							Ligue agora{' '}
						</a>
					</div>
					<div className="service-order-phone hide-m">
						<img src={phoneIcon} />
						<p>
							<strong>(35) 3449 1235</strong>
						</p>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="response-container">
			{product && (
				<div className="msg msg-container">
					<div className="main-body-message">
						<div className="container-address">
							<div className="container-f">
								<img src={markIcon} alt="" className="item-f" />
								<span className="item-f text-align-l">
									{client && client.street}, {client && client.neighborhood}
									<br />
									{client && client.city} - {client && client.state_sigla}
								</span>
							</div>
						</div>
					</div>
					{serviceOrderContent()}
				</div>
			)}

			{!!techniciansList?.length && !warranty && listTechsContent()}

			{showResponseModal && (
				<ModalSuccessOpenOs
					osNumber={responseData?.serviceOrderCode}
					onClick={handleCloseNewOsResponseModal}
				/>
			)}

			{changeAddress && (
				<ModalChangeAddress
					onSubmit={onSubmitLoadTechnicals}
					onReturn={onReturn}
				/>
			)}

			{createOs && (
				<ModalOpenOs
					onSubmit={onSubmitNewOs}
					onReturn={onCloseOsModal}
					onChangeAddress={onOpenModal}
					productName={product && product.description}
					numSerie={product && product.serial_number}
					address={client}
				/>
			)}
		</div>
	)
}
