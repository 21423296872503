import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderHistoryContextElement } from 'module/orderManagement/context/OrderHistoryContext/OrderHistoryContext'
import IOrderHistoryListItem from 'module/orderManagement/models/IOrderHistoryListItem'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './styles.scss'

import billedIcon from 'assets/image/billed.svg'
import billed_partiallyIcon from 'assets/image/billed_partially.svg'
import to_billingIcon from 'assets/image/to_billing.svg'
import {
	handleFinancialReleaseTypes,
	handleOrderCategoryType
} from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import LoadingList from 'shared/components/LoadingList'

const OrderHistoryListBodyMobile = () => {
	const { orderHistoryList, isLoading, totalPages } = useContext(
		OrderHistoryContextElement
	)
	const { sa1010IsLoading } = useContext(ClientDetailsContextElement)

	const handleStatus = (billed: boolean, not_billed: boolean) => {
		if (billed && !not_billed) {
			return (
				<>
					<img src={billedIcon} style={{ height: '26px', width: '16px' }} />
				</>
			)
		}

		if (billed && not_billed) {
			return (
				<>
					<img
						src={billed_partiallyIcon}
						style={{ height: '26px', width: '16px' }}
					/>
				</>
			)
		}

		if (!billed && not_billed) {
			return (
				<>
					<img src={to_billingIcon} style={{ height: '26px', width: '16px' }} />
				</>
			)
		}
	}

	const listContent = () => {
		return totalPages ? (
			<>
				{orderHistoryList.map((order: IOrderHistoryListItem, index: number) => {
					return (
						<NavLink
							key={index}
							target="_blank"
							id={order.order_code}
							className="order-history-list-mobile"
							to={`/order/${order.branch}/${order.order_code}/details`}
						>
							<div className="order-history-list-item order-history-list-item-first-line">
								<div>
									<span className="info-title">Pedido:</span>
									<span className="info-value">{order.order_code}</span>
								</div>
								<div>
									<span className="info-title">Filial:</span>
									<span className="info-value">{order.branch}</span>
								</div>
							</div>

							<div className="order-history-list-item order-history-list-item-first-line">
								<div>
									<span className="info-title">Emissão:</span>
									<span className="info-value">
										{order.issue_date
											? moment(order.issue_date).format('DD/MM/YY')
											: '-'}
									</span>
								</div>
								<div>
									<span className="info-title">Status:</span>
									<span className="info-value">
										{handleStatus(order.billed, order.not_billed)}
									</span>
								</div>
							</div>
							<div className="order-history-list-item order-history-list-item-second-line">
								<div>
									<span className="info-title">Dt. entrega:</span>
									<span className="info-value">
										{order.delivery_date
											? moment(order.delivery_date).format('DD/MM/YY')
											: '-'}
									</span>
								</div>
								<div>
									<span className="info-title">Ult. Faturamento:</span>
									<span className="info-value">
										{order.last_billing
											? moment(order.last_billing).format('DD/MM/YY')
											: ''}
									</span>
								</div>
							</div>
							<div className="order-history-list-item order-history-list-item-default">
								<div>
									<span className="info-title">Categoria:</span>
									<span className="info-value">
										{handleOrderCategoryType(order.order_category)}
									</span>
								</div>
							</div>
							<div className="order-history-list-item order-history-list-item-default">
								<div>
									<span className="info-title">Status financeiro:</span>
									<span className="info-value">
										{handleFinancialReleaseTypes(order.financial_status)}
									</span>
								</div>
							</div>
							<div className="order-history-list-item order-history-list-item-default">
								<div>
									<span className="info-title">Vendedor:</span>
									<span className="info-value">{order.salesperson}</span>
								</div>
							</div>
						</NavLink>
					)
				})}
			</>
		) : (
			<NavLink className="orders-list" to={`/orders`} id="emptyList" key={0}>
				<ul className="orders-list-item orders-list-empty-list">
					Nenhum pedido encontrado
				</ul>
			</NavLink>
		)
	}

	return (
		<>
			{sa1010IsLoading || isLoading ? (
				<LoadingList show={isLoading} />
			) : (
				listContent()
			)}
		</>
	)
}

export default OrderHistoryListBodyMobile
