import { EmptyList, GenericTable, LoadingProgress } from '@praticabr/ppsa'
import { Items, Order } from 'module/orderManagement/domain/models/order'
import { getInvoicePdf } from 'module/orderManagement/services'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import './styles.scss'

type InvoiceListProps = {
	code: string
	number: string
	serie: string
	delivery_date: string
}

type Props = {
	order: Order
}

export const OrderInvoices = ({ order }: Props) => {
	const [invoices, setInvoices] = useState<Items[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		if (order?.items?.length === 0) return

		const newInvoices: Items[] = []
		order?.items.forEach((item) => {
			if (
				item.invoice.number !== '' &&
				!newInvoices?.some((i) => i?.invoice?.number === item?.invoice?.number)
			) {
				newInvoices.push(item)
			}
		})
		setInvoices((prevInvoices) => [...prevInvoices, ...newInvoices])
	}, [])

	const handleDownloadInvoice = async (invoice: any) => {
		if (!invoice?.number || !invoice?.serie) {
			toast.error('Nota fiscal não encontrada.')
			return
		}
		try {
			setLoading(true)
			const response = await getInvoicePdf(
				invoice.number,
				invoice.serie,
				order.branch
			)
			const pdfUrl = URL.createObjectURL(
				new Blob([response.data], { type: 'application/pdf' })
			)
			window.open(pdfUrl, '_blank')
		} catch (error) {
			toast.error(
				'Erro ao buscar nota fiscal, atualize a pagina e tente novamente.'
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="order-invoices-container">
			<div>
				{invoices?.length ? (
					<GenericTable
						data={[
							...invoices.map((item, index) => {
								return {
									id: `${index}-invoice-${item?.code}*${item?.invoice?.number}*${item?.invoice?.serie}`,
									date:
										item?.invoice?.date !== '    -  -  T10:08:33-03:00'
											? moment(item?.invoice?.date).format('DD/MM/YYYY')
											: '-',
									number: item?.invoice?.number,
									serie: item?.invoice?.serie
								}
							})
						]}
						columns={[
							{
								key: 'date',
								gridArea: 'data1',
								header: 'Data',
								isSortable: false,
								width: '30%'
							},
							{
								key: 'serie',
								gridArea: 'title',
								header: 'Série',
								isSortable: false,
								width: '30%'
							},
							{
								key: 'number',
								gridArea: 'title',
								header: 'Nota fiscal',
								isSortable: false,
								width: '40%'
							}
						]}
						onRowClicked={(item) => handleDownloadInvoice(item)}
					/>
				) : (
					<EmptyList text="Nenhuma nota fiscal por aqui..." />
				)}
			</div>
			{loading && <LoadingProgress text="Carregando..." />}
		</div>
	)
}
