import { IconButton, TextField } from '@material-ui/core'
import {
	Button,
	Input,
	LoadingProgress,
	Modal,
	Paginator
} from '@praticabr/ppsa'
import Client from 'models/Client'
import Equipment from 'models/Equipment'
import Location from 'models/Locations'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { MdSearch } from 'react-icons/md'
import {
	clientPreventive,
	createClient,
	createLocation,
	createPreventive,
	getClientEquips,
	getClientLocations,
	searchClients
} from 'services'
import SuccessMsg from 'shared/SuccessMsg'
import { clearNumericField } from 'utils/Pipe'
import { IChangeAddressForm } from '../ChangeAddress'
import AdsForm from '../NewOrder/AddressForm'
import PreventiveClientsList from '../PreventiveClientsList'
import PreventiveEquipsList from '../PreventiveEquipsList'
import './styles.scss'

const CLIENT_STEP = 0
const LOCATION_STEP = 1
const EQUIPS_STEP = 2
const LIMIT = 20

const AddPreventive: React.FC = () => {
	const [clients, setClients] = React.useState<Client[]>([])
	const [clientSearch, setClientSearch] = React.useState<string>('')
	const [clientOnPrp, setClientOnPrp] = React.useState<Client>()
	const [clientSelected, setClientSelected] = React.useState<Client>()
	const [clientSelectedId, setClientSelectedId] = React.useState<number>(0)
	const [hasClientSelected, setHasClientSelected] =
		React.useState<boolean>(false)
	const [addLocation, setAddLocation] = React.useState<boolean>(false)
	const [locations, setLocations] = React.useState<Location[]>([])
	const [allLocations, setAllLocations] = React.useState<Location[]>([])
	const [equipments, setEquipments] = React.useState<Equipment[]>([])
	const [equipSearch, setEquipSearch] = React.useState<string>('')
	const [listEquipments, setListEquipments] = React.useState<Equipment[]>([])
	const [equipsSelected, setEquipsSelected] = React.useState<Equipment[]>([])
	const [hasEquipsSelected, setHasEquipsSelected] =
		React.useState<boolean>(false)
	const [endDate, setEndDate] = React.useState<Date>()
	const [loading, setLoading] = React.useState<boolean>(false)
	const [totalClientPages, setTotalClientPages] = React.useState<number>(0)
	const [pageClientNumber, setPageClientNumber] = React.useState<number>(1)
	const [totalLocationPages, setTotalLocationPages] = React.useState<number>(0)
	const [pageLocationNumber, setPageLocationNumber] = React.useState<number>(1)
	const [totalEquipPages, setTotalEquipPages] = React.useState<number>(0)
	const [pageEquipNumber, setPageEquipNumber] = React.useState<number>(1)
	const [step, setStep] = React.useState<number>(0)
	const [showSelected, setShowSelected] = React.useState<boolean>(false)
	const [hasSuccess, setHasSuccess] = React.useState<boolean>(false)
	const [showDialog, setShowDialog] = React.useState<boolean>(false)
	const [message, setMessage] = React.useState<string>('')

	const form = useForm<IChangeAddressForm>()
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = form

	/**
	 * @description Handles all changes on showDialog state.
	 */
	useEffect(() => {
		$('.message').toggle()
	}, [showDialog])

	/**
	 * @description Handles client selection.
	 */
	React.useEffect(() => {
		if (clientOnPrp) {
			verifyPreventiveForClient(clientOnPrp)
		}
	}, [clientOnPrp])

	/**
	 * @description Handles update value for search equipment field.
	 */
	React.useEffect(() => {
		if (clientOnPrp) {
			requestClientEquips()
		}
	}, [equipSearch])

	/**
	 * @description Handles all changes on showDialog state.
	 */
	React.useEffect(() => {
		createListEquipsToShow()
	}, [equipments])

	/**
	 * @description Handles all changes on showDialog state.
	 */
	React.useEffect(() => {
		const start = (pageEquipNumber - 1) * LIMIT
		const end = start + LIMIT
		const listToShow = equipments.slice(start, end)
		setListEquipments(listToShow)
	}, [pageEquipNumber])

	/**
	 * @description Handles all changes on showDialog state.
	 */
	React.useEffect(() => {
		if (showSelected) {
			setListEquipments(equipsSelected)
		} else {
			createListEquipsToShow()
			setPageEquipNumber(1)
		}
	}, [showSelected])

	/**
	 * @description Clear invalid values and send
	 * @param value
	 */
	const onSearchFieldChange = (value = '') => {
		const valueToSearch = value
			// eslint-disable-next-line no-useless-escape
			.replace(new RegExp(/\"/, 'g'), '')
			.replace(new RegExp(/\\/, 'g'), '')
			.replace(new RegExp(/#/, 'g'), '')
			.replace(new RegExp(/%/, 'g'), '')

		setClientSearch(valueToSearch)
	}

	/**
	 * @description clear all selections.
	 */
	const clearClient = () => {
		setClients([])
		setLocations([])
		setAllLocations([])
		setEquipments([])
		setListEquipments([])
		setEquipsSelected([])

		setHasClientSelected(false)
		setHasEquipsSelected(false)
		setClientSelectedId(0)
		setClientSelected(undefined)
		setClientOnPrp(undefined)
		setClientSearch('')
		setEquipSearch('')
		setEndDate(undefined)

		setTotalClientPages(0)
		setPageClientNumber(1)
		setTotalLocationPages(0)
		setPageLocationNumber(1)
		setTotalEquipPages(0)
		setPageEquipNumber(1)

		setStep(CLIENT_STEP)
		setAddLocation(false)
		setHasSuccess(false)
		setMessage('')
	}

	/**
	 * @description Search for clients
	 * @param page
	 */
	const requestClientSearch = async (page = 1) => {
		setLoading(true)
		const offset = (page - 1) * LIMIT
		await searchClients(clientSearch, LIMIT, offset)
			.then((res) => {
				const response = res.data.response
				setTotalClientPages(Math.ceil(response.count / LIMIT))
				setClients(response.clients)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
				setMessage('Houve um erro ao realizar buscar  por clientes')
				setHasSuccess(false)
				setShowDialog(true)
				setLoading(false)
			})
	}

	/**
	 * @description Request a client by value on search field.
	 */
	const onClientSearchClick = async () => {
		if (clientSearch) {
			setTotalClientPages(0)
			await requestClientSearch()
		}
	}

	/**
	 * @description Handle a client radio button selection
	 * @param value
	 */
	const onClientSelected = (value: number) => {
		const client = clients.find((c) => c.id === value)
		setClientSelected(client)
		setClientSelectedId(value)
		setHasClientSelected(true)
		setLocations([])
		setEquipments([])
		setListEquipments([])
		setHasEquipsSelected(false)
	}

	/**
	 * @description handle client pagination
	 */
	const handleClientPagination = async (value: number) => {
		setPageClientNumber(value)
		await requestClientSearch(value)
	}

	/**
	 * @description Handles Selection Client confirm
	 */
	const onClientConfirm = async () => {
		if (clientSelected) {
			setLoading(true)
			await requestClientInfo(clientSelected)
		} else {
			setLoading(false)
			setHasSuccess(false)
			setShowDialog(true)
			setMessage('Ocorreu um problema, tente novamente mais tarde.')
		}
	}

	/**
	 * @description Request for a preventive contract by id
	 */
	const verifyPreventiveForClient = async (client: Client) => {
		await clientPreventive(client.id)
			.then(async (res) => {
				if (res.data.response.hasPreventive) {
					setLoading(false)
					setHasSuccess(false)
					setShowDialog(true)
					setMessage('Já existe um contrato de preventiva para este cliente!')
				} else {
					await requestClientLocations(1, true)
					await requestAllClientLocations()
				}
			})
			.catch((error) => {
				console.log(error)
				setLoading(false)
				setHasSuccess(false)
				setShowDialog(true)
				setMessage('Ocorreu um problema, tente novamente mais tarde.')
			})
	}

	/**
	 * @description Request complete client's information.
	 */
	const requestClientInfo = async (client: Client) => {
		await createClient(client)
			.then(async (res) => {
				setClientOnPrp(res.data.clientData)
			})
			.catch((error) => {
				console.log(error)
				setLoading(false)
				setHasSuccess(false)
				setShowDialog(true)
				setMessage('Ocorreu um problema, tente novamente mais tarde.')
			})
	}

	/**
	 * @description Request all Clients locations
	 */
	const requestClientLocations = async (page = 1, firstLoad = false) => {
		if (clientOnPrp) {
			setStep(LOCATION_STEP)
			setLoading(true)
			const offset = (page - 1) * LIMIT
			await getClientLocations(clientOnPrp?.id, LIMIT, offset)
				.then(async (res) => {
					const response = res.data.response
					setLocations(response?.locations)
					setTotalLocationPages(Math.ceil(response?.count / LIMIT))
					if (!response?.locations?.length && firstLoad) {
						await createDefaultLocation()
					} else {
						setLoading(false)
					}
				})
				.catch((err) => {
					console.log(err)
					setMessage(
						'Houve um erro ao buscar as localizações do cliente selecionado.'
					)
					setHasSuccess(false)
					setShowDialog(true)
					setLoading(false)
				})
		}
	}

	/**
	 * @description Create a default location for clients that doest'n have one.
	 */
	const createDefaultLocation = async () => {
		const newLocation = {
			client_code: clientOnPrp?.id,
			name: 'Endereço Padrão',
			street: clientOnPrp?.street,
			neighborhood: clientOnPrp?.neighborhood,
			city: clientOnPrp?.city,
			state_sigla: clientOnPrp?.state,
			zip_code: clientOnPrp?.zip_code,
			original: 1
		}

		await createLocation(newLocation)
			.then(async (res) => {
				await requestClientLocations()
				await requestAllClientLocations()
			})
			.catch((err) => {
				console.log(err)
				setMessage('Ocorreu um problema, tente novamente mais tarde.')
				setHasSuccess(false)
				setShowDialog(true)
			})
	}

	/**
	 * @description Request all Clients locations
	 */
	const requestAllClientLocations = async () => {
		if (clientOnPrp) {
			await getClientLocations(clientOnPrp?.id)
				.then((res) => {
					const response = res.data.response
					setAllLocations(response.locations)
					setLoading(false)
				})
				.catch((err) => {
					console.log(err)
					setMessage('Ocorreu um problema, tente novamente mais tarde.')
					setHasSuccess(false)
					setShowDialog(true)
					setLoading(false)
				})
		}
	}

	/**
	 * @description Method that recover all data from Address Form and submit a new location
	 */
	const submitNewLocation = async (data: IChangeAddressForm) => {
		setLoading(true)

		const newLocation: any = data
		newLocation.client_code = clientOnPrp?.id
		newLocation.zip_code = clearNumericField(data.zip_code)
		newLocation.street = `${data.street}, ${data.number}`

		await createLocation(newLocation)
			.then(async (res) => {
				setLoading(false)
				setAddLocation(false)
				await requestClientLocations(1, true)
				await requestAllClientLocations()
				reset({
					name: '',
					zip_code: '',
					city: '',
					state_sigla: '',
					neighborhood: '',
					street: '',
					number: '',
					complement: ''
				})
			})
			.catch((err) => {
				console.log(err)
				setMessage('Houve um erro ao cadastrar um no endereço.')
				setLoading(false)
				setAddLocation(false)
				setHasSuccess(false)
				setShowDialog(true)
				requestClientLocations(1, true)
				requestAllClientLocations()
			})
	}

	/**
	 * @description Handles Locations pagination
	 */
	const handleLocationPagination = async (value: number) => {
		setPageLocationNumber(value)
		await requestClientLocations(value)
	}

	/**
	 * @description Request all equips on client base
	 */
	const requestClientEquips = async (page = 1) => {
		setLoading(true)
		if (clientOnPrp) {
			const offset = (page - 1) * LIMIT
			await getClientEquips(
				clientOnPrp.cod_protheus,
				LIMIT,
				offset,
				equipSearch
			)
				.then((res) => {
					const response = res.data.response
					setTotalEquipPages(Math.ceil(response.count / LIMIT))
					setEquipments(response.products)
					setLoading(false)
				})
				.catch((err) => {
					console.log(err)
					setMessage(
						'Houve um erro ao buscar a lista de equipamentos do cliente.'
					)
					setHasSuccess(false)
					setShowDialog(true)
					setLoading(false)
				})
		} else {
			setMessage('Houve um erro ao buscar a lista de equipamentos do cliente.')
			setHasSuccess(false)
			setShowDialog(true)
			setLoading(false)
		}
	}

	const createListEquipsToShow = () => {
		if (equipments?.length) {
			equipments.forEach((e, index) => {
				const selectedEquip = equipsSelected.find(
					(selected) => e.id === selected?.id
				)
				if (selectedEquip) {
					equipments[index] = selectedEquip
				}
			})

			const offset = (pageEquipNumber - 1) * LIMIT
			const listToShow = equipments.slice(offset, offset + LIMIT)
			setListEquipments(listToShow)
		} else {
			setListEquipments([])
		}
	}

	/**
	 * @description Method that handles the equips paginations
	 * @param value page selected
	 */
	const handleEquipPagination = async (value: number) => {
		setPageEquipNumber(value)
	}

	/**
	 * @description handles a equipment selecion on list
	 * @param equipment
	 */
	const handleEquipsChecked = (equipment: Equipment) => {
		const currentIndex = equipsSelected.indexOf(equipment)
		const newChecked = [...equipsSelected]

		if (currentIndex === -1) {
			newChecked.push(equipment)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setEquipsSelected(newChecked)

		if (newChecked?.length && endDate) {
			setHasEquipsSelected(true)
		} else {
			setHasEquipsSelected(false)
		}

		equipment.preventive = equipment.preventive ? 0 : 1
		updateEquipsArray(listEquipments, equipment, setListEquipments)
	}

	/**
	 * @description Update all equips Array on location change
	 * @param equipment
	 */
	const onEquipChanges = (equipment: Equipment) => {
		updateEquipsArray(listEquipments, equipment, setListEquipments)
		updateEquipsArray(equipsSelected, equipment, setEquipsSelected)
	}

	/**
	 * @description Update a content of only item on a Array
	 * @param array Array with a item to change
	 * @param equip Item with new values
	 * @param setState Array State
	 */
	const updateEquipsArray = (
		array: Equipment[],
		equip: Equipment,
		setState: React.Dispatch<React.SetStateAction<Equipment[]>>
	) => {
		const currentIndex = array.findIndex((e) => e.id === equip.id)
		const newList = [...array]
		newList[currentIndex] = equip
		setState(newList)
	}

	/**
	 * @description Search for a equipment that includes a value inputed on search field
	 * @param value
	 */
	const onEquipSearch = async (value: string) => {
		setEquipSearch(value)
	}

	/**
	 * @description Change equips item view.
	 */
	const showSelectedItems = () => {
		setLoading(true)
		setTimeout(() => {
			setShowSelected(!showSelected)
			setLoading(false)
		}, 500)
	}

	/**
	 * @description On Preventive Date Changes
	 * @param value
	 */
	const onDateChange = (value: string) => {
		setEndDate(moment(value).toDate())

		if (equipsSelected?.length) {
			setHasEquipsSelected(true)
		}
	}

	/**
	 * @description Submit all data
	 */
	const submitPreventive = async () => {
		const originalLocation = allLocations.find((l) => l.original === 1)
		if (clientOnPrp && equipsSelected?.length && endDate && originalLocation) {
			setLoading(true)
			const equipsOnPreventive = [...equipsSelected]
			const otherEquipsToInsert = equipments.filter((e) => e?.preventive === 0)
			if (otherEquipsToInsert?.length) {
				equipsOnPreventive?.push(...otherEquipsToInsert)
			}

			equipsOnPreventive.forEach((e) => {
				if (!e.location_id) {
					e.location_id = originalLocation?.id as number
				}
			})

			const preventive = {
				clientCode: clientOnPrp?.cod_protheus,
				equipsOnPreventive,
				endDate
			}

			await createPreventive(preventive)
				.then(() => {
					setMessage('Contrato de preventiva cadastrado com sucesso!')
					setHasSuccess(true)
					setShowDialog(true)
					setLoading(false)
				})
				.catch((err) => {
					console.log(err)
					setMessage(err.data.message)
					setHasSuccess(false)
					setShowDialog(true)
					setLoading(false)
				})

			setLoading(false)
		} else {
			// Show message
			setMessage('Verifique as informações do contrato!')
			setHasSuccess(false)
			setShowDialog(true)
		}
	}

	/**
	 * @description Client HTML content
	 */
	const clientContent = () => {
		return (
			<div style={{ position: 'relative', height: '471px' }}>
				<div className="search-area">
					<TextField
						id="search"
						className="search-field"
						placeholder="Pesquisar"
						value={clientSearch}
						disabled={loading}
						onChange={(e) => {
							onSearchFieldChange(e.target.value)
						}}
						onKeyPress={(e) => {
							const value = $('.search-field').find('input').val()?.toString()
							if (e.key == 'Enter' && value) {
								onSearchFieldChange(value)
								onClientSearchClick()
							}
						}}
					/>
					<IconButton
						color="primary"
						aria-label="upload picture"
						component="span"
						disabled={loading}
						onClick={() => {
							onClientSearchClick()
						}}
						className="icon-button"
					>
						<MdSearch />
					</IconButton>
				</div>
				<div className="modal-list">
					{loading ? (
						<div className="loading-list">
							<LoadingProgress text="Carregando..." />
						</div>
					) : (
						<div>
							<PreventiveClientsList
								clients={clients}
								clientSelectedId={clientSelectedId}
								searchValue={clientSearch}
								onChange={(value) => {
									onClientSelected(value)
								}}
							></PreventiveClientsList>
							{totalClientPages > 1 && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										paddingTop: '20px'
									}}
								>
									<Paginator
										onPageChange={(e) => handleClientPagination(e)}
										currentPage={pageClientNumber}
										totalPages={totalClientPages}
									/>
								</div>
							)}
						</div>
					)}
				</div>
				<div className="modal-footer">
					<Button
						style={{ width: '108px' }}
						variant="confirmation-solid"
						size="lg"
						title="Confirmar"
						disabled={!hasClientSelected}
						onClick={() => {
							onClientConfirm()
						}}
					/>
				</div>
			</div>
		)
	}

	/**
	 * @description Location Not Found HTML content
	 */
	const addNewLocation = () => {
		return (
			<div className="locations-not-found">
				{!addLocation && (
					<div
						className="add-location-link"
						onClick={() => {
							setAddLocation(!addLocation)
						}}
					>
						Adicionar localização
					</div>
				)}
				{addLocation ? (
					<div className="add-location">
						<div className="name-location">
							<header>Nome</header>
							<Input
								id="name"
								variant="light"
								placeholder="Nome"
								{...register('name', {
									required: {
										value: true,
										message: 'Campo nome é obrigatório!'
									}
								})}
								errors={errors.name}
							/>
						</div>
						<AdsForm form={form} />
						<div className="add-location-footer">
							<Button
								style={{ width: '108px' }}
								variant="confirmation-solid"
								size="lg"
								title="Salvar"
								onClick={handleSubmit(submitNewLocation)}
							/>
							<Button
								style={{ width: '108px' }}
								variant="cancellation"
								size="lg"
								title="Cancelar"
								color="secondary"
								onClick={() => {
									setAddLocation(false)
									reset({
										name: '',
										zip_code: '',
										city: '',
										state_sigla: '',
										neighborhood: '',
										street: '',
										number: '',
										complement: ''
									})
								}}
							>
								Cancelar
							</Button>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		)
	}

	/**
	 * @description Locations List HTML content
	 */
	const locationsList = () => {
		return (
			<div className="locations-content">
				<div className="table location">
					<div className="table-header ">
						<div className="location-table-header-item-name">Nome</div>
						<div className="location-table-header-item-zip">CEP</div>
						<div className="location-table-header-item-street">Rua</div>
						<div className="location-table-header-item-neigh">Bairro</div>
						<div className="location-table-header-item-city">Cidade</div>
						<div className="location-table-header-item-state">UF</div>
					</div>
					<div className="table-content">
						{locations.map((location: Location) => {
							return (
								<div className="table-item" key={location.id}>
									<div className="location-table-item-name">
										{location.name}
									</div>
									<div className="location-table-item-zip">
										{location.zip_code}
									</div>
									<div className="location-table-item-street">
										{location.street}
									</div>
									<div className="location-table-item-neigh">
										{location.neighborhood}
									</div>
									<div className="location-table-item-city">
										{location.city}
									</div>
									<div className="location-table-item-state">
										{location.state_sigla}
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="location-pagination">
					{totalLocationPages >= 1 && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								paddingTop: '20px'
							}}
						>
							<Paginator
								onPageChange={(e) => handleLocationPagination(e)}
								currentPage={pageLocationNumber}
								totalPages={totalLocationPages}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}

	/**
	 * @description Client's locations HTML content
	 */
	const locationContent = () => {
		return (
			<div>
				<div className="modal-list locations">
					{loading ? (
						<div className="loading-list">
							<LoadingProgress text="Carregando..." />
						</div>
					) : (
						<div>
							{addNewLocation()}
							{locations?.length && !addLocation ? locationsList() : <></>}
						</div>
					)}
				</div>
			</div>
		)
	}

	/**
	 * @description Equips List HTML content
	 */
	const equipsList = () => {
		return (
			<PreventiveEquipsList
				equips={listEquipments}
				equipsSelected={equipsSelected}
				locations={allLocations}
				onSelectItem={(equip: Equipment) => {
					handleEquipsChecked(equip)
				}}
				onLocationChanges={(equip) => {
					onEquipChanges(equip)
				}}
			/>
		)
	}

	/**
	 * @description Client's equips HTML content
	 */
	const equipsContent = () => {
		return (
			<div style={{ position: 'relative', height: '471px' }}>
				<div className="search-area equips">
					<TextField
						id="search"
						className="search-field"
						placeholder="Pesquisar"
						value={equipSearch}
						onChange={(e) => {
							onEquipSearch(e.target.value)
						}}
						onKeyPress={(e) => {
							const value = $('.search-field').find('input').val()?.toString()
							if (e.key == 'Enter' && value) {
								onEquipSearch(value)
							}
						}}
					/>
					<div className="equips-selected" onClick={() => showSelectedItems()}>
						{!showSelected
							? `Mostrar equipamentos selecionados`
							: `Mostrar todos os equipamentos`}
					</div>
				</div>
				<div
					className={`modal-list equips ${
						showSelected ? `selected-items` : ``
					}`}
				>
					{loading ? (
						<div className="loading-list">
							<LoadingProgress text="Carregando..." />
						</div>
					) : (
						<div>
							{listEquipments?.length ? (
								equipsList()
							) : (
								<div className="empty-list">
									{showSelected
										? `Nenhum equipamento foi selecionado!`
										: `Nenhum equipamento foi encontrado!`}
								</div>
							)}
						</div>
					)}
				</div>
				<div className="location-pagination">
					{totalEquipPages > 1 && !showSelected && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								paddingTop: '20px'
							}}
						>
							<Paginator
								onPageChange={(e) => handleEquipPagination(e)}
								currentPage={pageEquipNumber}
								totalPages={totalEquipPages}
							/>
						</div>
					)}
				</div>
				<div className="date-area">
					<span>Término de contrato em:</span>
					<TextField
						id="date"
						type="date"
						defaultValue={new Date(Date.now())}
						InputLabelProps={{
							shrink: true
						}}
						onChange={(e) => onDateChange(e.target.value)}
					/>
				</div>
				<div className="modal-footer">
					<Button
						style={{ width: '108px' }}
						variant="confirmation-solid"
						size="lg"
						title="Confirmar"
						disabled={!hasEquipsSelected}
						onClick={() => {
							submitPreventive()
						}}
					/>
				</div>
			</div>
		)
	}

	/**
	 * @description Method that handles all steps change.
	 */
	const changeTab = async (step: number) => {
		switch (step) {
			case CLIENT_STEP:
				setStep(step)
				clearClient()
				break
			case LOCATION_STEP:
				if (hasClientSelected) {
					setStep(step)
				}
				break
			case EQUIPS_STEP:
				if (hasClientSelected) {
					setStep(step)
					await requestClientEquips(1)
				}
				break
		}
	}

	/**
	 * @description Method to close this modal.
	 */
	const close = () => {
		clearClient()
		$('.createPreventive').toggle()
	}

	/**
	 * @description Reset operation by Message Dialog action
	 */
	const resetOperation = () => {
		if (hasSuccess) {
			close()
		} else {
			clearClient()
		}

		setShowDialog(false)
		setHasSuccess(false)
	}

	return (
		<Modal.root>
			<Modal.header title="Novo Contrato" onClose={close} />
			<Modal.body>
				<Modal.body.main>
					<div className="new-preventive-contract">
						<div>
							<div
								className={
									`tab-item ` + (step === CLIENT_STEP ? `selected` : '')
								}
							>
								<a
									onClick={() => {
										changeTab(CLIENT_STEP)
									}}
								>
									Cliente
								</a>
							</div>
							<div
								className={
									`tab-item ` + (step === LOCATION_STEP ? `selected` : '')
								}
							>
								<a
									onClick={() => {
										changeTab(LOCATION_STEP)
									}}
								>
									Localizações
								</a>
							</div>
							<div
								className={
									`tab-item ` + (step === EQUIPS_STEP ? `selected` : '')
								}
							>
								<a
									onClick={() => {
										changeTab(EQUIPS_STEP)
									}}
								>
									Equipamentos
								</a>
							</div>
						</div>

						{step === CLIENT_STEP
							? clientContent()
							: step === LOCATION_STEP
							? locationContent()
							: equipsContent()}

						<div className="modal message" style={{ display: 'block' }}>
							<SuccessMsg
								result={true}
								msg={message}
								resetStatus={() => {
									resetOperation()
								}}
							/>
						</div>
					</div>
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}

export default AddPreventive
