import { Order } from 'module/orderManagement/domain/models/order'
import {
	formatCurrency,
	handleOperationType,
	handleStorageType
} from 'module/orderManagement/utils/constants'
import React, { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { MdVisibility } from 'react-icons/md'
import { ItensDetailsListAccessories } from '../../ItensDetailsListAccessories'
import './styles.scss'

type OrderItemsContentViewingModeProps = {
	order: Order
}

const resolveTablePrice = (item: any, table: any) =>
	typeof item?.table_price === 'object'
		? item?.table_price?.filter((y: any) => y.table === table)[0]?.value || 0
		: item?.table_price

export const OrderItemsContentViewingMobile = ({
	order
}: OrderItemsContentViewingModeProps) => {
	const [accessoriesListShowModal, setAccessoriesListShowModal] =
		useState<boolean>(false)
	const [accessoriesItem, setAccessoriesItem] = useState<any>()

	const accessoriesList = () => (
		<>
			{accessoriesListShowModal && (
				<ItensDetailsListAccessories.Root
					onClose={() => setAccessoriesListShowModal(false)}
				>
					<ItensDetailsListAccessories.ViewingMode
						itemSelect={accessoriesItem}
					/>
				</ItensDetailsListAccessories.Root>
			)}
		</>
	)

	return (
		<div className="items-information-contain">
			<div className="items-table">
				<div className="items-body-container">
					{order?.items?.map((item, index) => {
						const tablePrice = resolveTablePrice(item, order?.price_table)
						return (
							<div key={`${index} - ${item?.code}`} className="container-item">
								<details
									id={`items-list-details-${index}`}
									className="items-list-details"
								>
									<summary id={item?.code} className={`items-list`}>
										<ul className="items-list-order-item items-list-order-product">
											<IoIosArrowDown
												className="arrow-icon"
												color="#767676"
												size={24}
											/>
											<div className="container-product viewing">
												<span>{`${item?.code} - ${item?.description}`}</span>
											</div>
										</ul>
										<ul className="items-list-order-item items-list-order-select">
											<div className="container-accessories">
												<header>Acessórios</header>
												{order?.items[index]?.accessories?.length ? (
													<div className="select-accessories">
														<span>Sim</span>
														<span className={`accessories-icon-${index}`}>
															{
																<MdVisibility
																	size={20}
																	color="var(--primary-pure)"
																	onClick={() => {
																		setAccessoriesListShowModal(true)
																		setAccessoriesItem({
																			code: item?.code,
																			description: item?.description,
																			accessories:
																				order?.items[index]?.accessories
																		})
																	}}
																/>
															}
														</span>
													</div>
												) : (
													<span>Não</span>
												)}
											</div>
											<div className="container-operation-type">
												<ul className="items-list-order-item items-list-order-operation-type">
													<header>Tipo OP</header>
													<span>
														{handleOperationType(order?.items[index]?.option)}
													</span>
												</ul>
											</div>
											<div className="container-storage-type">
												<ul className="items-list-order-item items-list-order-storage">
													<header>Armazém</header>
													<span>
														{handleStorageType(order?.items[index]?.storage)}
													</span>
												</ul>
											</div>
										</ul>
										<ul className="items-list-order-item items-list-order-amount">
											<header>Quantidade</header>
											<span>
												{order?.items[index]?.amount
													? order?.items[index]?.amount
													: '-'}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-unit-price">
											<header>Valor unitário (R$)</header>
											<span>
												{formatCurrency(order?.items[index]?.unit_price)}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-table-price">
											<header>Valor tabela (R$)</header>
											<span>{formatCurrency(tablePrice)}</span>
										</ul>
										<ul className="items-list-order-item items-list-order-total-net-price">
											<header>Total merc. (R$)</header>
											<span>
												{formatCurrency(order?.items[index]?.price_total)}
											</span>
										</ul>
									</summary>
									<div className={`items-list`}>
										<ul className="items-list-order-item items-list-order-serial-number">
											<header>NS</header>
											<span>
												{order?.items[index]?.serial_number
													? order?.items[index]?.serial_number
													: '-'}
											</span>
										</ul>

										<ul className="items-list-order-item items-list-order-ipi">
											<header>IPI</header>
											<span>
												{formatCurrency(order?.items[index]?.taxes?.ipi.value)}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-difal">
											<header>DIFAL</header>
											<span>
												{formatCurrency(
													order?.items[index]?.taxes?.difal.value
												)}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-st">
											<header>ST</header>
											<span>
												{order && order?.items[index]?.taxes
													? formatCurrency(order?.items[index]?.taxes.st.value)
													: '-'}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-suframa">
											<header>Suframa (R$)</header>
											<span>
												{formatCurrency(order?.items[index]?.suframa_value)}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-invoice-order-item">
											<header>Nota fiscal</header>
											<span>
												{item?.invoice.number && item?.invoice.serie
													? `${item?.invoice.number} - ${item?.invoice.serie}`
													: '-'}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-purchase-order-item">
											<header>Item do pedido de compra</header>
											<span>
												{order?.items[index]?.purchase_order_item
													? order?.items[index]?.purchase_order_item
													: '-'}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-purchase-order-number">
											<header>Nº pedido compra</header>
											<span>
												{order?.items[index]?.purchase_order_number
													? order?.items[index]?.purchase_order_number
													: '-'}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-embedded-freight">
											<header>Frete Embutido</header>
											<span>
												{formatCurrency(order?.items[index]?.embedded_freight)}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-delivery-date">
											<header>Entrega</header>
											<span>
												{order?.items[index]?.delivery_date
													? order?.items[index]?.delivery_date
													: '-'}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-delivery-date">
											<header>Dt. Reprogramada</header>
											<span>
												{order?.items[index]?.rescheduled_delivery_date
													? order?.items[index]?.rescheduled_delivery_date
													: '-'}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-installation-value">
											<header>Valor Instalação</header>
											<span>
												{formatCurrency(
													order?.items[index]?.installation_value
												)}
											</span>
										</ul>
										<ul className="items-list-order-item items-list-order-discount">
											<header>Total desconto</header>
											<span>
												{formatCurrency(order?.items[index]?.discounts.total)}
											</span>
										</ul>
									</div>
								</details>
							</div>
						)
					})}
				</div>
			</div>
			{accessoriesListShowModal && accessoriesList()}
		</div>
	)
}
