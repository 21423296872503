import LoginInput from 'models/LoginInput'
import { AuthContext } from 'module/entryPoint/context/AuthContext/AuthContext'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { history } from 'routes/history'
import { signInRequest } from 'store/modules/auth/actions'
import { RouteList } from 'utils/Constants'
import FormValidator from '../../FormFields/FormValidator'
import PasswordField from '../../PasswordField'
import './styles.scss'

const LoginForm: React.FC = () => {
	const dispatch = useDispatch()
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty }
	} = useForm<LoginInput>()

	const { setEmail } = useContext(AuthContext)

	const onSubmit = (data: LoginInput) => {
		setEmail(data.email)
		dispatch(
			signInRequest(data.email, data.password, () =>
				history.push('/recoverAccount')
			)
		)
	}

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div>
					<div className="form-item-content">
						<label>Login</label>
						<input
							{...register('email', {
								required: true,
								minLength: 8,
								maxLength: 50
							})}
							placeholder="Informe seu e-mail"
							maxLength={50}
						/>
						<FormValidator
							{...{
								formName: 'email',
								validatorName: 'Login',
								errors,
								isDirty
							}}
						/>
					</div>
					<div className="form-item-content">
						<label>Senha</label>
						<PasswordField
							register={register('password', { required: true, maxLength: 20 })}
							placeholder="Informe sua senha"
						/>
						<FormValidator
							{...{
								formName: 'password',
								validatorName: 'Senha',
								errors,
								isDirty
							}}
						/>
					</div>
				</div>
				<div className="login-form-options">
					<NavLink to={RouteList.RESET_PASS}>Esqueci minha senha</NavLink>
				</div>
				<div className="login-form-actions">
					<button className="login-submit" type="submit">
						ENTRAR
					</button>
				</div>
			</form>
		)
	}

	return <div className="login-form">{formContent()}</div>
}

export default LoginForm
