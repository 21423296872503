import {
	Divider,
	GenericTable,
	LoadingProgress,
	Section
} from '@praticabr/ppsa'
import TabTitles from 'module/productManagement/components/TabTitles'
import { UploadSingleFile } from 'module/productManagement/components/UploadSingleFile'
import React, { useContext, useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { RouteComponentProps } from 'react-router-dom'

import { ProductFamilyDetailsContext } from 'module/productManagement/context/ProductFamilyDetailsContext'
import './styles.scss'

type ProductDetailsProps = RouteComponentProps & {
	match: {
		params: {
			codeProtheus: string
		}
	}
}

export const ProductFamilyDetails = ({ match }: ProductDetailsProps) => {
	const { isLoading, szr010, sb1010, productFamilyDetails, processUpload } =
		useContext(ProductFamilyDetailsContext)

	const [volumeList, setVolumeList] = useState<any[]>([])

	useEffect(() => {
		transformList()
	}, [szr010])

	const transformList = () => {
		if (!szr010) return
		const result: any[] = []

		for (let i = 1; i <= szr010?.zrVolumes; i++) {
			const alturKey = i >= 10 ? `zrAltuv${i}` : `zrAlturv${i}`
			const largKey = `zrLargv${i}`
			const comprKey = i >= 10 ? `zrCompv${i}` : `zrComprv${i}`
			const pesoKey = `zrPesov${i}`

			const items = {
				volume: i,
				zrAlturv: szr010[alturKey] ?? null,
				zrLargv: szr010[largKey] ?? null,
				zrComprv: szr010[comprKey] ?? null,
				zrPesov: szr010[pesoKey] ?? null
			}

			result.push(items)
		}
		setVolumeList(result)
	}

	return (
		<>
			<div className="product-family-details">
				<main className="product-family-details-main">
					<div className="header">
						<TabTitles
							title={szr010?.zrFamilia}
							subtitle={szr010?.zrCodigo}
							isLoading={isLoading}
						/>
					</div>
					<div className="body">
						<Section.root>
							<Section.title>Informações do produto</Section.title>
							<Section.container>
								<div className="body-container-family">
									<div className="product-family-details-items">
										<header>Linha</header>
										<span title={szr010?.zrLinha}>{szr010?.zrLinha}</span>
									</div>
									<div className="product-family-details-items">
										<header>Ativo</header>
										<span>{szr010?.zrAtivo ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-family-details-items">
										<header>Site Vendas?</header>
										<span>{szr010?.zrVsite ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-family-details-items">
										<header>Volumes</header>
										<span>{szr010?.zrVolumes || '-'}</span>
									</div>
									<div className="product-family-details-items">
										<header>Desc. Acess</header>
										<span>{szr010?.zrDesace || '-'}</span>
									</div>
									<div className="product-family-details-items descriptive">
										<header>Descritivo</header>
										<Markdown>{szr010?.zrDescrit || '-'}</Markdown>
									</div>

									<Divider title={`Volumes`} />
									<div className="product-family-details-volumes">
										{volumeList.map((item: any) => (
											<div
												className="product-family-details-volumes-item"
												key={item.volume}
											>
												<div className="product-family-details-volumes-items">
													<header>Volume:</header>
													<span>{item?.volume || '-'}</span>
												</div>
												<div className="product-family-details-volumes-items">
													<header>Altura:</header>
													<span>{item?.zrAlturv || '-'}</span>
												</div>
												<div className="product-family-details-volumes-items">
													<header>Largura:</header>
													<span>{item?.zrLargv || '-'}</span>
												</div>
												<div className="product-family-details-volumes-items">
													<header>Comprim.:</header>
													<span>{item?.zrComprv || '-'}</span>
												</div>
												<div className="product-family-details-volumes-items">
													<header>Peso Br.:</header>
													<span>{item?.zrPesov || '-'}</span>
												</div>
											</div>
										))}
									</div>
									<Divider title="Anexos do produto" />
									<div className="attachments">
										<div className="picture">
											<label>
												<span>Imagem</span>
											</label>
											<UploadSingleFile
												product={productFamilyDetails}
												id="picture"
												{...{ processUpload }}
											/>
										</div>
										<div className="docs">
											<label>
												<span>Documentação</span>
											</label>
											<UploadSingleFile
												product={productFamilyDetails}
												id="documentation"
												{...{ processUpload }}
											/>
										</div>
										<div className="technical">
											<label>
												<span>Ficha técnica</span>
											</label>
											<UploadSingleFile
												product={productFamilyDetails}
												id="spec-sheet"
												{...{ processUpload }}
											/>
										</div>
									</div>
								</div>
							</Section.container>
						</Section.root>
						<Section.root>
							<Section.title>Produtos relacionados</Section.title>
							<Section.container>
								<div className="body-container-list-products">
									<GenericTable
										data={
											sb1010.map((sb: any) => ({
												...sb,
												b1Ativo: sb.b1Ativo ? 'Sim' : 'Não'
											})) || []
										}
										columns={[
											{
												key: 'b1Cod',
												header: 'Código',
												width: '15%'
											},
											{
												key: 'b1Desc',
												header: 'Nome',
												width: '40%'
											},
											{ key: 'b1Modelo', header: 'Modelo', width: '30%' },
											{
												key: 'b1Ativo',
												header: 'Ativo?',
												width: '15%'
											}
										]}
									/>
								</div>
							</Section.container>
						</Section.root>
					</div>
				</main>
			</div>
			{isLoading && <LoadingProgress />}
		</>
	)
}
