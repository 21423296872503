import { zodResolver } from '@hookform/resolvers/zod'
import Sa1010Overview from 'models/Sa1010Overview'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { ClientEditingModeComposition } from 'module/orderManagement/components/ClientEditingModeComposition'
import ClientMenuTabs from 'module/orderManagement/components/ClientMenuTabs'
import { ClientViewModeComposition } from 'module/orderManagement/components/ClientViewModeComposition'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaPencilAlt } from 'react-icons/fa'

import { Button, LoadingProgress } from '@praticabr/ppsa'
import { RouteComponentProps } from 'react-router-dom'
import { ViewType } from 'utils/Constants'
import {
	normalizeCGC,
	normalizeCepNumber,
	normalizeTelNumberWithoutDDD
} from 'utils/Pipe'
import { updateClientSchema } from '../../schemas/update-client-schema'
import './styles.scss'

type Props = RouteComponentProps & {
	match: {
		params: {
			clientCode: string
			clientStoreCode: string
		}
	}
}

export default function ClientDetails({ match }: Props) {
	const { viewType } = useContext(DeviceContextElement)
	const { sameBillingAddresses, sa1010, sa1010IsLoading, onUpdateClient } =
		useContext(ClientDetailsContextElement)
	const [clientEditInfo, setClientEditInfo] = useState<Sa1010Overview | null>(
		null
	)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const { clientCode, clientStoreCode } = match.params
	const methods = useForm<updateClientSchema>({
		resolver: zodResolver(updateClientSchema)
	})

	useEffect(() => {
		if (sa1010 && sa1010.a1Cod === clientCode) {
			setClientEditInfo(sa1010)
		}
	}, [sa1010])

	useEffect(() => {
		if (clientEditInfo) {
			methods.reset({
				a1Loja: clientEditInfo.a1Loja,
				a1Tecnico: clientEditInfo.a1Tecnico,
				a1Nome: clientEditInfo.a1Nome,
				a1Nreduz: clientEditInfo.a1Nreduz,
				a1Pessoa: clientEditInfo.a1Pessoa,
				a1Cgc: normalizeCGC(clientEditInfo.a1Cgc),
				a1Inscr: clientEditInfo.a1Inscr,
				a1Valie: clientEditInfo.a1Valie,
				a1Regiao: clientEditInfo.a1Regiao,
				a1Ytipocl: clientEditInfo.a1Ytipocl,
				a1Tipo: clientEditInfo.a1Tipo,
				a1Canal: clientEditInfo.a1Canal,
				a1Classif: clientEditInfo.a1Classif,
				a1Grppra: clientEditInfo.a1Grppra,
				a1Suframa: clientEditInfo.a1Suframa,
				a1Repres: clientEditInfo.a1Repres,
				a1Ddd: clientEditInfo.a1Ddd,
				a1Tel: normalizeTelNumberWithoutDDD(clientEditInfo.a1Tel),
				a1Email: clientEditInfo.a1Email,
				a1Contato: clientEditInfo.a1Contato,
				a1Telex: clientEditInfo.a1Telex,
				a1End: clientEditInfo.a1End,
				a1CodMun: clientEditInfo.a1CodMun,
				a1Bairro: clientEditInfo.a1Bairro,
				a1Complem: clientEditInfo.a1Complem,
				a1Est: clientEditInfo.a1Est,
				a1Cep: normalizeCepNumber(clientEditInfo.a1Cep),
				a1Naturez: clientEditInfo.a1Naturez,
				a1Conta: clientEditInfo.a1Conta,
				a1Ccusto: clientEditInfo.a1Ccusto,
				a1Contrib: clientEditInfo.a1Contrib,
				a1Endcob: clientEditInfo.a1Endcob,
				a1Xblfin: clientEditInfo.a1Xblfin,
				a1Pais: clientEditInfo.a1Pais,
				a1Munc: clientEditInfo.a1Munc,
				a1Bairroc: clientEditInfo.a1Bairroc,
				a1Estc: clientEditInfo.a1Estc,
				a1Cepc: normalizeCepNumber(clientEditInfo.a1Cepc)
			})
		}
	}, [clientEditInfo])

	useEffect(() => {
		if (clientEditInfo) {
			if (sameBillingAddresses) {
				methods.setValue('a1Endcob', clientEditInfo.a1End)
				methods.setValue('a1Munc', clientEditInfo.a1CodMun)
				methods.setValue('a1Bairroc', clientEditInfo.a1Bairro)
				methods.setValue('a1Estc', clientEditInfo.a1Est)
				methods.setValue('a1Cepc', normalizeCepNumber(clientEditInfo?.a1Cep))
			} else {
				methods.setValue('a1Endcob', clientEditInfo.a1Endcob)
				methods.setValue('a1Munc', clientEditInfo.a1Munc)
				methods.setValue('a1Bairroc', clientEditInfo.a1Bairroc)
				methods.setValue('a1Estc', clientEditInfo.a1Estc)
				methods.setValue('a1Cepc', normalizeCepNumber(clientEditInfo?.a1Cepc))
			}
		}
	}, [sameBillingAddresses, clientEditInfo])

	const onSubmit = (data: updateClientSchema) => {
		if (sa1010) {
			setClientEditInfo({
				...sa1010,
				...data
			})
			onUpdateClient({ a1Cod: clientCode, a1Loja: clientStoreCode }, data, () =>
				setIsEditing(false)
			)
		}
	}

	const EditingModeContent = () => {
		return (
			<FormProvider {...methods}>
				<form
					autoComplete="off"
					autoCapitalize="off"
					onSubmit={methods.handleSubmit(onSubmit)}
				>
					<ClientEditingModeComposition />
					<div
						style={{
							marginTop: '20px',
							width: '100%',
							display: 'grid',
							gridTemplateColumns: '128px 128px',
							justifyContent: 'center',
							gap: '20px'
						}}
					>
						<Button
							size="lg"
							title="CONFIRMAR"
							variant="confirmation-solid"
							disabled={sa1010IsLoading}
							type="submit"
						/>
						<Button
							variant="cancellation"
							title="CANCELAR"
							size="lg"
							disabled={sa1010IsLoading}
							onClick={() => setIsEditing(false)}
						/>
					</div>
				</form>
			</FormProvider>
		)
	}

	return (
		<>
			<ClientMenuTabs path={match.path} />
			<TabTitles />
			<div className="client-details-tab">
				<div className="section-container-action-edit">
					<Button
						size="lg"
						title={
							<span className="edit-button">
								{viewType === ViewType.MOBILE && <FaPencilAlt size={28} />}
								{!(viewType === ViewType.MOBILE) && 'Editar'}
							</span>
						}
						variant="confirmation-solid"
						onClick={() => setIsEditing(true)}
						disabled={sa1010IsLoading || isEditing}
					/>
				</div>
				{isEditing && !sa1010IsLoading ? (
					<EditingModeContent />
				) : (
					<ClientViewModeComposition />
				)}
			</div>
			{sa1010IsLoading && (
				<LoadingProgress text="Carregando dados do cliente..." />
			)}
		</>
	)
}
