import { ListType } from '@praticabr/ppsa'
import { store } from 'store'
import OrderItemModel from '../models/OrderItemModel'

export function handlePaymentFormType(paymentForm?: string) {
	const { paymentFormList } = store.getState().baseLists
	if (paymentForm && paymentFormList?.length) {
		const paymentFormType = paymentFormList.filter(
			(PaymentForm: { value: string }) => PaymentForm.value === paymentForm
		)
		if (paymentFormType) {
			return paymentFormType[0]?.label || '-'
		} else return '-'
	}
}

export function handleOperationType(operation?: string) {
	if (!operation) return '-'
	const { operationTypes } = store.getState().baseLists
	if (operation && operationTypes?.length) {
		const operationType = operationTypes.filter(
			(OperationType: { value: string }) => OperationType.value === operation
		)
		if (operationType) {
			return operationType[0]?.label.trim() ?? '-'
		} else return '-'
	}
}

export function handleStorageType(storage?: string) {
	const { storageList } = store.getState().baseLists
	if (storage && storageList?.length) {
		const storageType = storageList.filter(
			(StorageType: { value: string }) => StorageType.value === storage
		)
		if (storageType) {
			return storageType[0]?.label || '-'
		} else return '-'
	}
}

export function handlePriceTableType(priceTable?: string) {
	const { priceTableList } = store.getState().baseLists
	if (!priceTable || !priceTableList?.length) {
		return '-'
	}
	const priceTableType = priceTableList.find(
		(priceTableType: any) => priceTableType.value === priceTable
	)
	if (priceTableType) {
		return priceTableType?.label || '-'
	}
	return '-'
}

export function handleCarrierType(carrierCode?: string) {
	const { carrierList } = store.getState().baseLists
	if (carrierCode && carrierList?.length) {
		const carrierType = carrierList.filter(
			(carrierType: { value: string }) => carrierType.value === carrierCode
		)
		if (carrierType && carrierType?.length) {
			return carrierType[0]?.label || '-'
		} else return carrierCode
	}
}

export function handleSalespersonType(salespersonCode?: string) {
	const { salespersonList } = store.getState().baseLists
	if (salespersonCode && salespersonList?.length) {
		const salespersonType = salespersonList.filter(
			(salespersonType: { value: string }) =>
				salespersonType.value === salespersonCode
		)
		if (salespersonType && salespersonType?.length) {
			return salespersonType[0]?.label || '-'
		} else return salespersonCode
	}
}

export function handleNature(natureCode?: string) {
	const { natureList } = store.getState().baseLists
	if (natureCode && natureList?.length) {
		const natureType = natureList.filter(
			(natureType: { value: string }) => natureType.value === natureCode
		)
		if (natureType && natureType?.length) {
			return natureType[0]?.label || '-'
		} else return natureCode
	}
}

export function handleLedgerAccountType(ledgerAccountCode?: string) {
	const { ledgerAccountList } = store.getState().baseLists
	if (ledgerAccountCode && ledgerAccountList?.length) {
		const ledgerAccountType = ledgerAccountList.filter(
			(ledgerAccountType: { value: string }) =>
				ledgerAccountType.value === ledgerAccountCode
		)
		if (ledgerAccountType && ledgerAccountType?.length) {
			return ledgerAccountType[0]?.label || '-'
		} else return ledgerAccountCode
	}
}

export function handleCostCenterType(costCenterCode?: string) {
	const { costCenterList } = store.getState().baseLists
	if (costCenterCode && costCenterList?.length) {
		const costCenterType = costCenterList.filter(
			(costCenterType: { value: string }) =>
				costCenterType.value === costCenterCode
		)
		if (costCenterType && costCenterType?.length) {
			return costCenterType[0]?.label || '-'
		} else return costCenterCode
	}
}

export function handleOrderCategoryType(order_category?: string) {
	if (!order_category) return '-'
	const categoryType = OrderCategoryTypes.filter(
		(OrderCategory) => OrderCategory.value === order_category
	)
	return categoryType[0]?.label || '-'
}

export const OrderCategoryTypes = [
	{ value: '1', label: '1 - Assistência vendas' },
	{ value: '2', label: '2 - Acessórios' },
	{ value: '3', label: '3 - Fornos' },
	{ value: '4', label: '4 - Outros' },
	{ value: '5', label: '5 - Assistência garantia' },
	{ value: '6', label: '6 - Klimaquip' },
	{ value: '7', label: '7 - Technipan' },
	{ value: '8', label: '8 - Consignado' },
	{ value: '9', label: '9 - Locação' },
	{ value: 'A', label: 'A - Prestação de serviço - Preventiva' },
	{ value: 'B', label: 'B - Prestação de serviço - Fora de garantia' },
	{ value: 'C', label: 'C - Prestação de serviço - Assistência Técnica' },
	{ value: 'D', label: 'D - Vendas de peças - Preventiva' },
	{ value: 'E', label: 'E - Vendas de peças - Fora de garantia' }
]

export const OrderStatusTypes = [
	{ value: '1', label: 'Novo pedido' },
	{ value: '2', label: 'Em acompanhamento' },
	{ value: '3', label: 'Fazer follow-up' },
	{ value: '4', label: 'Liberado' },
	{ value: '5', label: 'Faturado parcialmente' },
	{ value: '6', label: 'Faturado' }
]

export function handleOrderStatusTypes(order_status: string) {
	if (!order_status) return '-'
	const orderStatus = OrderStatusTypes.filter(
		(OrderStatus) => OrderStatus.value === order_status
	)
	return orderStatus[0]?.label || '-'
}

export function handleOrderType(order_type?: string) {
	if (!order_type) return '-'
	const orderType = OrderTypes.filter(
		(OrderType) => OrderType.value === order_type
	)
	return orderType[0]?.label || '-'
}

export const OrderTypes = [
	{ value: 'I', label: 'Mercado Interno' },
	{ value: 'E', label: 'Mercado Externo' }
]

export function handleOrderBranchTypes(branch?: string) {
	if (!branch) return '-'
	const branchType = OrderBranchTypes.filter(
		(BranchType) => BranchType.value === branch
	)
	return branchType[0]?.label || '-'
}

export const OrderBranchTypes = [
	{ value: '01', label: 'Prática Matriz' },
	{ value: '02', label: 'Prática São Paulo' },
	{ value: '05', label: 'Prática Rio de Janeiro' },
	{ value: '06', label: 'Prática Recife' }
]

export function handleFreightTypes(type_of_freight?: string) {
	if (!type_of_freight) return '-'
	const typeOfFreight = FreightTypes.filter(
		(FreightType) => FreightType.value === type_of_freight
	)
	return typeOfFreight[0]?.label || '-'
}

export const FreightTypes = [
	{ value: 'C', label: 'CIF' },
	{ value: 'F', label: 'FOB' },
	{ value: 'T', label: 'Por conta terceiros' },
	{ value: 'R', label: 'Por conta remetente' },
	{ value: 'D', label: 'Por conta destinatário' },
	{ value: 'S', label: 'Sem frete' }
]

export function handleFinancialReleaseTypes(financial_release?: string) {
	if (!financial_release) return '-'
	const financialReleaseType = FinancialReleaseTypes.filter(
		(FinancialReleaseType) => FinancialReleaseType.value === financial_release
	)
	return financialReleaseType[0]?.label || '-'
}

export const FinancialReleaseTypes = [
	{ value: '1', label: 'Liberado' },
	{ value: '2', label: 'Liberado com pendência' },
	{ value: '3', label: 'Bloqueado produção' },
	{ value: '4', label: 'LIMBO' },
	{ value: '5', label: 'Financiamento longo prazo' }
]

export function handleFairOrderType(fairOrder?: string) {
	if (!fairOrder) return '-'
	const fairOrderType = FairOrderTypes.filter(
		(FairOrderType) => FairOrderType.value === fairOrder
	)
	return fairOrderType[0]?.label || '-'
}

export const FairOrderTypes = [
	{ value: 'NF', label: 'Não' },
	{ value: '1', label: 'Super Rio Expofood' },
	{ value: '2', label: 'Apas' },
	{ value: '3', label: 'Fennopan' },
	{ value: '4', label: 'Fispal NE' },
	{ value: '5', label: 'Fispal SP' },
	{ value: '6', label: 'Equipotel NE' },
	{ value: '7', label: 'Equipotel SP' },
	{ value: '8', label: 'Acaps Panshow' },
	{ value: '9', label: 'Superminas' },
	{ value: '10', label: 'Fipan' },
	{ value: '11', label: 'Outra' },
	{ value: '12', label: 'Sirha' },
	{ value: '13', label: 'Shimura' },
	{ value: '14', label: 'Mara Cakes' },
	{ value: '15', label: 'Concessionária' },
	{ value: '16', label: 'Consultor FCSI' },
	{ value: '17', label: 'Consultor Independente' },
	{ value: '18', label: 'IGA' },
	{ value: '19', label: 'France Panificação' }
]

export function handleCommentaryTypes(fairOrder?: string) {
	if (!fairOrder) return '-'
	const fairOrderType = CommentaryTypes.filter(
		(CommentaryTypes) => CommentaryTypes.value === fairOrder
	)
	return fairOrderType[0]?.label || '-'
}

export const CommentaryTypes = [
	{ value: 'internalComment', label: 'Comentário interno' },
	{ value: 'publicComment', label: 'Comentário publico' },
	{ value: 'expedition', label: 'Expedição' },
	{ value: 'pcp', label: 'PCP' }
]

export function handleProtheusCommentaryTypes(typeOrder?: string) {
	if (!typeOrder) return '-'
	const fairOrderType = ProtheusCommentaryTypes.filter(
		(ProtheusCommentaryTypes) => ProtheusCommentaryTypes.value === typeOrder
	)
	return fairOrderType[0]?.label || '-'
}

export const ProtheusCommentaryTypes = [
	{ value: 'c5Follow', label: 'internalComment' },
	{ value: 'c5Obsmemo', label: 'publicComment' },
	{ value: 'c5Obsexp2', label: 'expedition' },
	{ value: 'c5Obspcp', label: 'pcp' }
]

export function handleShipmentReleaseType(release_shipment?: string) {
	if (!release_shipment) return '-'
	const shipmentReleaseType = ShipmentReleaseTypes.filter(
		(ShipmentReleaseType) => ShipmentReleaseType.value === release_shipment
	)
	return shipmentReleaseType[0]?.label || '-'
}

export const ShipmentReleaseTypes = [
	{ value: '1', label: 'Sim' },
	{ value: '2', label: 'Não' }
]

export function handlePartialDeliveryReleaseTypes(partial_delivery?: string) {
	if (!partial_delivery) return '-'
	const partialDeliveryReleaseType = PartialDeliveryReleaseTypes.filter(
		(PartialDeliveryReleaseType) =>
			PartialDeliveryReleaseType.value === partial_delivery
	)
	return partialDeliveryReleaseType[0]?.label || '-'
}

export const PartialDeliveryReleaseTypes = [
	{ value: 'S', label: 'Sim' },
	{ value: 'N', label: 'Não' }
]

export function handleValidatedStateRegistrationType(
	validated_state_registration?: string
) {
	if (!validated_state_registration) return '-'
	const validatedStateRegistrationType = ValidatedStateRegistrationTypes.filter(
		(ValidatedStateRegistrationType) =>
			ValidatedStateRegistrationType.value === validated_state_registration
	)
	return validatedStateRegistrationType[0]?.label || '-'
}

export const ValidatedStateRegistrationTypes = [
	{ value: 'S', label: 'Sim' },
	{ value: 'N', label: 'Não' }
]
export function handlePersonType(person_type?: string) {
	if (!person_type) return '-'
	const personType = PersonType.filter(
		(PersonType) => PersonType.value === person_type
	)
	return personType[0].label
}

export const PersonType = [
	{ value: 'F', label: 'Física' },
	{ value: 'J', label: 'Jurídica' }
]
export function handleIndicatedClassificationType(classification?: string) {
	if (!classification) return '-'
	const indicatedClassificationType = IndicatedClassificationType.filter(
		(IndicatedClassificationType) =>
			IndicatedClassificationType.value === classification
	)
	return indicatedClassificationType[0].label
}

export const IndicatedClassificationType = [
	{ value: '1', label: 'REV 10' },
	{ value: '2', label: 'REV 90' },
	{ value: '3', label: 'EPP' },
	{ value: '4', label: 'CONCESSIONARIAS' },
	{ value: '5', label: 'MONTAD. COZINHAS' }
]

export function handleRegionType(region_code?: string) {
	const { regionList } = store.getState().baseLists
	if (region_code && regionList?.length) {
		const regionType = regionList.filter(
			(RegionType: { value: string }) => RegionType.value === region_code
		)
		if (regionType) {
			return regionType[0].label
		} else return '-'
	}
}

export function handleClientType(type_client?: string) {
	if (!type_client) return '-'
	const clientType = ClientTypes.filter(
		(ClientType) => ClientType.value === type_client
	)
	return clientType[0]?.label || '-'
}

export const ClientTypes = [
	{ value: 'F', label: 'Consumidor final' },
	{ value: 'L', label: 'Produtor rural' },
	{ value: 'R', label: 'Revendedor' },
	{ value: 'S', label: 'Solidário' },
	{ value: 'X', label: 'Exportação' }
]

export function handleClientChannelType(client_channel?: string) {
	if (!client_channel) return '-'
	const clientChannelType = ClientChannelTypes.filter(
		(ClientChannelType) => ClientChannelType.value === client_channel
	)
	return clientChannelType[0]?.label || '-'
}

export const ClientChannelTypes = [
	{ value: '1', label: 'Concessionária' },
	{ value: '2', label: 'Consumidor final direto' },
	{ value: '3', label: 'Consumidor final representante' },
	{ value: '4', label: 'Exportação' },
	{ value: '5', label: 'Key Account' },
	{ value: '6', label: 'Montador de cozinhas' },
	{ value: '7', label: 'Revenda' }
]

export function handleClientSegmentType(segment?: string) {
	if (!segment) return '-'
	const clientSegmentType = ClientSegmentTypes.filter(
		(ClientSegmentType) => ClientSegmentType.value === segment
	)
	return clientSegmentType[0]?.label
		? clientSegmentType[0]?.label
		: 'Segmento desconhecido'
}

export const ClientSegmentTypes = [
	{ value: '01', label: 'Restaurantes comerciais / Self-Service' },
	{ value: '02', label: 'Panificação / Confeitaria' },
	{ value: '03', label: 'Supermercado' },
	{ value: '04', label: 'Buffets' },
	{ value: '05', label: 'Alta gastronomia / Restaurante a la carte' },
	{ value: '06', label: 'Fast food' },
	{ value: '07', label: 'Hotelaria' },
	{ value: '08', label: 'Hospital' },
	{ value: '09', label: 'Pizzaria' },
	{ value: '10', label: 'Concessionária de alimentos / Catering' },
	{ value: '11', label: 'Loja de conveniência' },
	{ value: '12', label: 'Indústria de congelados' },
	{ value: '13', label: 'Contas públicas' },
	{ value: '14', label: 'Logistas (revendedores)' },
	{ value: '15', label: 'Integradores / Montadores de cozinha' },
	{ value: '16', label: 'Concessionárias Prática' },
	{ value: '17', label: 'Assistente técnico' },
	{ value: '18', label: 'Lanchonete' },
	{ value: '19', label: 'Cliente final EX' },
	{ value: '20', label: 'Distribuidor EX' }
]

export function handleTaxpayerType(taxpayer?: string) {
	if (!taxpayer) return '-'
	const taxpayerType = TaxpayerTypes.filter(
		(TaxpayerType) => TaxpayerType.value === taxpayer
	)
	return taxpayerType[0]?.label || '-'
}

export const TaxpayerTypes = [
	{ value: '1', label: 'Sim' },
	{ value: '2', label: 'Não' }
]

export function handleAccessoriesType(accessories?: string) {
	if (!accessories) return '-'
	const accessoriesType = AccessoriesTypes.filter(
		(AccessoriesType) => AccessoriesType.value === accessories
	)
	return accessoriesType[0]?.label || '-'
}

export const AccessoriesTypes = [
	{ value: '1', label: 'Sim' },
	{ value: '2', label: 'Não' }
]

export function handlePendencyTypes(pendency_type?: string) {
	if (!pendency_type) return '-'
	const pendencyType = PendencyTypes.filter(
		(PendencyType) => PendencyType.value === pendency_type
	)
	return pendencyType[0]?.label || '-'
}

export const PendencyTypes = [
	{ value: '1', label: 'Comercial' },
	{ value: '2', label: 'Financeiro' },
	{ value: '3', label: 'Administração de Pedidos' },
	{ value: '4', label: 'Expedição' }
]

export function handlePendencyOriginType(pendency_origin?: string) {
	if (!pendency_origin) return '-'
	const pendencyOriginType = PendencyOriginTypes.filter(
		(PendencyOriginType) => PendencyOriginType.value === pendency_origin
	)
	return pendencyOriginType[0]?.label || '-'
}

export const PendencyOriginTypes = [
	{ value: '1', label: 'Interna' },
	{ value: '2', label: 'Externa' },
	{ value: '3', label: '-' }
]

export function handleResponsibleSolutionType(responsible_solution?: string) {
	if (!responsible_solution) return '-'
	const responsibleSolutionType = ResponsibleSolutionTypes.filter(
		(ResponsibleSolutionType) =>
			ResponsibleSolutionType.value === responsible_solution
	)
	return responsibleSolutionType[0]?.label || '-'
}

export const ResponsibleSolutionTypes = [
	{ value: '1', label: 'Financeiro' },
	{ value: '2', label: 'Comercial' },
	{ value: '3', label: 'Administração de Pedidos' },
	{ value: '4', label: 'Representante' },
	{ value: '5', label: 'Cliente' },
	{ value: '6', label: 'Compras' },
	{ value: '7', label: 'PCP' }
]

export function handlePendencyStatusType(status?: string) {
	if (!status) return '-'
	const pendencyStatusType = PendencyStatusTypes.filter(
		(PendencyStatusType) => PendencyStatusType.value === status
	)
	return pendencyStatusType[0]?.label || '-'
}

export const PendencyStatusTypes = [
	{ value: '1', label: 'Planejada' },
	{ value: '2', label: 'Pendente' },
	{ value: '3', label: 'Encerrada' }
]

export function handleFinancialStatusType(status?: string) {
	if (!status) return '-'
	const financialStatusType = FinancialStatusTypes.filter(
		(FinancialStatusType) => FinancialStatusType.value === status
	)
	return financialStatusType[0]?.label || '-'
}

export const FinancialStatusTypes = [
	{ value: 'N', label: 'Não' },
	{ value: 'S', label: 'Sim' }
]

export function handleGroupClientType(customer_group?: string) {
	if (!customer_group) return '-'
	const groupClientType = GroupClientTypes.filter(
		(GroupClientType) => GroupClientType.value === customer_group
	)
	return groupClientType[0]?.label || '-'
}

export const GroupClientTypes = [
	{ value: '00', label: 'SUSEP' },
	{ value: '01', label: 'Pão de Açúcar' },
	{ value: '02', label: 'Carrefour' },
	{ value: '03', label: 'Walmart' },
	{ value: '04', label: 'Super Gimenes' },
	{ value: '05', label: 'Bom Preço' },
	{ value: '06', label: 'Peralta' },
	{ value: '07', label: 'Divino Fogao' },
	{ value: '08', label: 'Ford - Daimlerchrysler' },
	{ value: '09', label: 'Unissul' },
	{ value: '10', label: 'ABIP' },
	{ value: '11', label: 'Mega Matte' },
	{ value: '12', label: 'Pao De Batata' },
	{ value: '13', label: 'Gran Sapore' },
	{ value: '14', label: 'GRSA' },
	{ value: '15', label: 'La Pasta' },
	{ value: '16', label: 'Seletti' },
	{ value: '18', label: 'Casa do Pão de Queijo' },
	{ value: '19', label: 'Primo Cozinha' },
	{ value: '20', label: 'Cosif' },
	{ value: '21', label: 'Rei Do Pretzel' },
	{ value: '22', label: 'Frans Café' },
	{ value: '23', label: 'Vanilla E Bon Grille' },
	{ value: '24', label: 'Cafeteria 404 - Grupo Kaffe' },
	{ value: '25', label: 'Vovó Mocinha' },
	{ value: '26', label: 'Habibs' },
	{ value: '27', label: 'GJP' },
	{ value: '28', label: 'IMC' },
	{ value: '29', label: 'Rei Do Mate' },
	{ value: '31', label: 'Forno De Minas' },
	{ value: '32', label: 'Puras / Sodexo' },
	{ value: '33', label: 'Porto Bay Hotels & Resorts' },
	{ value: '34', label: 'Diversos Refeições Coletivas' },
	{ value: '35', label: 'Expresso do Pão de Queijo' },
	{ value: '36', label: 'Trendfoods' },
	{ value: '37', label: 'BBLC' },
	{ value: '38', label: 'Desfrutti' },
	{ value: '39', label: 'Diversos Hotelaria' },
	{ value: '40', label: 'Diversos Fast Food' },
	{ value: '41', label: 'Makro' },
	{ value: '42', label: 'BHG' },
	{ value: '43', label: 'Br Franchising' },
	{ value: '44', label: 'Smzto Mixirica Donnas' },
	{ value: '46', label: 'Francap, Cafe Pilão, Cafe Do Ponto' },
	{ value: '47', label: 'Trigo-Spoleto / Dominos' },
	{ value: '49', label: 'Atlântica Hotéis' },
	{ value: '50', label: 'Yum! Pizza Hut E Kfc' },
	{ value: '51', label: 'Sbarro' },
	{ value: '52', label: 'Tempero Manero' },
	{ value: '53', label: 'Camarão & Cia.' },
	{ value: '54', label: 'Xazz' },
	{ value: '55', label: 'Cencosud' },
	{ value: '56', label: 'Supermercado Sul' },
	{ value: '57', label: 'Supermercado Rio / Espirito Santo' },
	{ value: '58', label: 'Supermercado Norte / Nordeste' },
	{ value: '59', label: 'Supermercado Minas' },
	{ value: '60', label: 'Supermercado Sao Paulo Interior' },
	{ value: '61', label: 'Supermercado Sao Paulo Capital' },
	{ value: '62', label: 'Supermercado Centro Oeste' },
	{ value: '63', label: 'Raizen' },
	{ value: '64', label: 'Subway' },
	{ value: '65', label: 'DIA' },
	{ value: '66', label: 'Formadores de opinião' },
	{ value: '67', label: 'Exportacao' },
	{ value: '68', label: 'Rede Coop' },
	{ value: '69', label: 'Sendas' },
	{ value: '70', label: 'Roldão' },
	{ value: '99', label: 'Outros' }
]

export enum OrderPendencyStatusTypes {
	PENDENCY = '1,2',
	NOT_PENDENCY = '3'
}

export enum OrderPendencyStatusLabels {
	PENDENCY = 'Com pendência',
	NOT_PENDENCY = 'Sem pendência'
}

export enum ClientBlockFinancialStatusTypes {
	BLOCK = 'S',
	NOT_BLOCK = 'N'
}

export enum ClientBlockFinancialStatusLabels {
	BLOCK = 'Com bloqueio',
	NOT_BLOCK = 'Sem bloqueio'
}

export enum ExecAutoErrors {
	INSCRICAO_ESTADUAL = `Inscrição estadual do cliente não validada`,
	PEDIDO_FATURADO = `Este pedido não pode ser alterado, pois não há itens disponíveis para faturamento`,
	CONDICAO_PAGAMENTO_BLOQ = `Este registro está bloqueado para uso. SE4-Condições de Pagamento`,
	SOMA_TOTAL_PARCELAS = `A soma das parcelas não batem com o total do pedido`
}

export const FederalStatesList = [
	{ value: 'AC', label: 'Acre' },
	{ value: 'AL', label: 'Alagoas' },
	{ value: 'AP', label: 'Amapá' },
	{ value: 'AM', label: 'Amazonas' },
	{ value: 'BA', label: 'Bahia' },
	{ value: 'CE', label: 'Ceará' },
	{ value: 'DF', label: 'Distrito Federal' },
	{ value: 'ES', label: 'Espírito Santo' },
	{ value: 'GO', label: 'Goiás' },
	{ value: 'MA', label: 'Maranhão' },
	{ value: 'MT', label: 'Mato Grosso' },
	{ value: 'MS', label: 'Mato Grosso do Sul' },
	{ value: 'MG', label: 'Minas Gerais' },
	{ value: 'PA', label: 'Pará' },
	{ value: 'PB', label: 'Paraíba' },
	{ value: 'PR', label: 'Paraná' },
	{ value: 'PE', label: 'Pernambuco' },
	{ value: 'PI', label: 'Piauí' },
	{ value: 'RJ', label: 'Rio de Janeiro' },
	{ value: 'RN', label: 'Rio Grande do Norte' },
	{ value: 'RS', label: 'Rio Grande do Sul' },
	{ value: 'RO', label: 'Rondônia' },
	{ value: 'RR', label: 'Roraima' },
	{ value: 'SC', label: 'Santa Catarina' },
	{ value: 'SP', label: 'São Paulo' },
	{ value: 'SE', label: 'Sergipe' },
	{ value: 'TO', label: 'Tocantins' }
]

export const InstallmentsList = [
	{ value: '1', label: '1x' },
	{ value: '2', label: '2x' },
	{ value: '3', label: '3x' },
	{ value: '4', label: '4x' },
	{ value: '5', label: '5x' },
	{ value: '6', label: '6x' },
	{ value: '7', label: '7x' },
	{ value: '8', label: '8x' },
	{ value: '9', label: '9x' },
	{ value: '10', label: '10x' },
	{ value: '11', label: '11x' },
	{ value: '12', label: '12x' },
	{ value: '13', label: '13x' },
	{ value: '14', label: '14x' },
	{ value: '15', label: '15x' }
]

export const FederalStatesUF = (): string[] =>
	FederalStatesList.map((state) => state.value)

export const totalPriceAndDiscount = (
	orderItens: OrderItemModel[] | undefined
) => {
	let sumTotalPrice = 0
	let sumTotalDiscount = 0
	if (!orderItens) {
		return {
			price: formatCurrency(sumTotalPrice),
			discount: formatCurrency(sumTotalDiscount)
		}
	}

	orderItens?.forEach((item) => {
		if (Number(item.total_net_price) > 0) {
			return (sumTotalPrice += Number(item.total_net_price))
		}
	})

	orderItens?.forEach((item) => {
		if (Number(item.table_price) > 0) {
			return (sumTotalDiscount +=
				Number(item.table_price) * Number(item.amount))
		}
	})

	sumTotalDiscount =
		sumTotalDiscount - sumTotalPrice > 0 ? sumTotalDiscount - sumTotalPrice : 0

	return {
		price: formatCurrency(sumTotalPrice),
		discount: formatCurrency(sumTotalDiscount)
	}
}

export const formatCurrency = (value: number) => {
	if (!value) return 0
	return value.toLocaleString('pt-br', {
		minimumFractionDigits: 2,
		style: 'currency',
		currency: 'BRL'
	})
}

export const paymentFormCheckboxOptions = {
	'': [],
	santander: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'Acessório padrão' },
		{ value: 'ag', label: 'Acessório extra' },
		{ value: 'ah', label: 'Transportadora/Redespacho' },
		{ value: 'ai', label: 'Pedido de Prestação' },
		{ value: 'aj', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'ak', label: 'Entrega Parcial' },
		{ value: 'al', label: 'Libera expedição' },
		{ value: 'am', label: 'Data de Entrega' },
		{ value: 'an', label: 'Desconto' },
		{ value: 'ao', label: 'Condição de Pagamento' },
		{ value: 'ap', label: 'Tipo de Operação' },
		{ value: 'aq', label: 'Valor Total' },
		{ value: 'ar', label: 'Conhecimento do pedido' },
		{ value: 'as', label: 'Manutenção de pendência' }
	],
	cdcProger: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'SERASA' },
		{ value: 'ag', label: 'Autorização de Financiamento' },
		{ value: 'ah', label: 'Número pedido VEF' },
		{ value: 'ai', label: 'Número pedido REF' },
		{ value: 'aj', label: 'Contrato financiamento' },
		{ value: 'ak', label: 'Acessório padrão' },
		{ value: 'al', label: 'Acessório extra' },
		{ value: 'am', label: 'Pedido de prestação' },
		{ value: 'an', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'ao', label: 'Entrega Parcial' },
		{ value: 'ap', label: 'Libera expedição' },
		{ value: 'aq', label: 'Data de Entrega' },
		{ value: 'ar', label: 'Desconto' },
		{ value: 'as', label: 'Condição de Pagamento' },
		{ value: 'at', label: 'Tipo de Operação' },
		{ value: 'au', label: 'Valor Total' },
		{ value: 'av', label: 'Conhecimento do pedido' },
		{ value: 'aw', label: 'Manutenção de pendência' }
	],
	finame: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'SERASA' },
		{ value: 'ag', label: 'Autorização de Financiamento' },
		{ value: 'ah', label: 'Contrato financiamento' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Alteração nomenclatura' },
		{ value: 'al', label: 'Pedido de prestação' },
		{ value: 'am', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'an', label: 'Entrega Parcial' },
		{ value: 'ao', label: 'Libera expedição' },
		{ value: 'ap', label: 'Data de Entrega' },
		{ value: 'aq', label: 'Desconto' },
		{ value: 'ar', label: 'Condição de Pagamento' },
		{ value: 'as', label: 'Tipo de Operação' },
		{ value: 'at', label: 'Valor Total' },
		{ value: 'au', label: 'Conhecimento do pedido' },
		{ value: 'av', label: 'Manutenção de pendência' }
	],
	leasing: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'Autorização de Financiamento' },
		{ value: 'ag', label: 'Número pedido do banco' },
		{ value: 'ah', label: 'Número pedido SIMPLES REMESSA' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Pedido de prestação' },
		{ value: 'al', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'am', label: 'Entrega Parcial' },
		{ value: 'an', label: 'Libera expedição' },
		{ value: 'ao', label: 'Data de Entrega' },
		{ value: 'ap', label: 'Desconto' },
		{ value: 'aq', label: 'Condição de Pagamento' },
		{ value: 'ar', label: 'Tipo de Operação' },
		{ value: 'as', label: 'Valor Total' },
		{ value: 'at', label: 'Conhecimento do pedido' },
		{ value: 'au', label: 'Manutenção de pendência' }
	],
	bnbFne: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'TOKEN' },
		{ value: 'ag', label: 'Acessório padrão' },
		{ value: 'ah', label: 'Acessório extra' },
		{ value: 'ai', label: 'Pedido de prestação' },
		{ value: 'aj', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'ak', label: 'Entrega Parcial' },
		{ value: 'al', label: 'Libera expedição' },
		{ value: 'am', label: 'Data de Entrega' },
		{ value: 'an', label: 'Desconto' },
		{ value: 'ao', label: 'Condição de Pagamento' },
		{ value: 'ap', label: 'Tipo de Operação' },
		{ value: 'aq', label: 'Valor Total' },
		{ value: 'ar', label: 'Conhecimento do pedido' },
		{ value: 'as', label: 'Manutenção de pendência' }
	],
	fco: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'Autorização de Financiamento' },
		{ value: 'ag', label: 'Número pedido VEF' },
		{ value: 'ah', label: 'Número pedido REF' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Pedido de prestação' },
		{ value: 'al', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'am', label: 'Entrega Parcial' },
		{ value: 'an', label: 'Libera expedição' },
		{ value: 'ao', label: 'Data de Entrega' },
		{ value: 'ap', label: 'Desconto' },
		{ value: 'aq', label: 'Condição de Pagamento' },
		{ value: 'ar', label: 'Tipo de Operação' },
		{ value: 'as', label: 'Valor Total' },
		{ value: 'at', label: 'Conhecimento do pedido' },
		{ value: 'au', label: 'Manutenção de pendência' }
	],
	outros: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'Autorização de Financiamento' },
		{ value: 'ag', label: 'Número pedido VEF' },
		{ value: 'ah', label: 'Número pedido REF' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Pedido de prestação' },
		{ value: 'al', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'am', label: 'Entrega Parcial' },
		{ value: 'an', label: 'Libera expedição' },
		{ value: 'ao', label: 'Data de Entrega' },
		{ value: 'ap', label: 'Desconto' },
		{ value: 'aq', label: 'Condição de Pagamento' },
		{ value: 'ar', label: 'Tipo de Operação' },
		{ value: 'as', label: 'Valor Total' },
		{ value: 'at', label: 'Conhecimento do pedido' },
		{ value: 'au', label: 'Manutenção de pendência' }
	],
	grenke: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Mensagem para NF' },
		{ value: 'af', label: 'Autorização GRENKE' },
		{ value: 'ag', label: 'Número pedido Venda a ordem' },
		{ value: 'ah', label: 'Número pedido Remessa a ordem' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Pedido de prestação' },
		{ value: 'al', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'am', label: 'Entrega Parcial' },
		{ value: 'an', label: 'Libera expedição' },
		{ value: 'ao', label: 'Data de Entrega' },
		{ value: 'ap', label: 'Desconto' },
		{ value: 'aq', label: 'Condição de Pagamento' },
		{ value: 'ar', label: 'Tipo de Operação' },
		{ value: 'as', label: 'Valor Total' },
		{ value: 'at', label: 'Conhecimento do pedido' },
		{ value: 'au', label: 'Manutenção de pendência' }
	],
	parcelado: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'SERASA' },
		{ value: 'af', label: 'Autorização Financeiro' },
		{ value: 'ag', label: 'Ordem de compra' },
		{ value: 'ah', label: 'Contrato Reserva de Domínio' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Transportadora / Redespacho' },
		{ value: 'al', label: 'Pedido de prestação' },
		{ value: 'am', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'an', label: 'Entrega Parcial' },
		{ value: 'ao', label: 'Libera expedição' },
		{ value: 'ap', label: 'Data de Entrega' },
		{ value: 'aq', label: 'Desconto' },
		{ value: 'ar', label: 'Condição de Pagamento' },
		{ value: 'as', label: 'Tipo de Operação' },
		{ value: 'at', label: 'Valor Total' },
		{ value: 'au', label: 'Conhecimento do pedido' },
		{ value: 'av', label: 'Manutenção de pendência' }
	],
	cartaoCredito: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'QSA' },
		{ value: 'af', label: 'Autorização Financeiro' },
		{ value: 'ag', label: 'Documentos cartão de crédito' },
		{ value: 'ah', label: 'Declaração assinada' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Transportadora / Redespacho' },
		{ value: 'al', label: 'Pedido de prestação' },
		{ value: 'am', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'an', label: 'Entrega Parcial' },
		{ value: 'ao', label: 'Libera expedição' },
		{ value: 'ap', label: 'Data de Entrega' },
		{ value: 'aq', label: 'Desconto' },
		{ value: 'ar', label: 'Condição de Pagamento' },
		{ value: 'as', label: 'Tipo de Operação' },
		{ value: 'at', label: 'Valor Total' },
		{ value: 'au', label: 'Conhecimento do pedido' },
		{ value: 'av', label: 'Manutenção de pendência' }
	],
	cartaoBndes: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Dados Cartão BNDES' },
		{ value: 'af', label: 'Autorização Financeiro' },
		{ value: 'ag', label: 'Pedido VEF' },
		{ value: 'ah', label: 'Pedido REF' },
		{ value: 'ai', label: 'Acessório padrão' },
		{ value: 'aj', label: 'Acessório extra' },
		{ value: 'ak', label: 'Transportadora / Redespacho' },
		{ value: 'al', label: 'Pedido de prestação' },
		{ value: 'am', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'an', label: 'Entrega Parcial' },
		{ value: 'ao', label: 'Libera expedição' },
		{ value: 'ap', label: 'Data de Entrega' },
		{ value: 'aq', label: 'Desconto' },
		{ value: 'ar', label: 'Condição de Pagamento' },
		{ value: 'as', label: 'Tipo de Operação' },
		{ value: 'at', label: 'Valor Total' },
		{ value: 'au', label: 'Conhecimento do pedido' },
		{ value: 'av', label: 'Manutenção de pendência' }
	],
	revenda: [
		{ value: 'aa', label: 'Itens a conferir' },
		{ value: 'ab', label: 'Cadastro do cliente' },
		{ value: 'ac', label: 'SUFRAMA' },
		{ value: 'ad', label: 'DIFAL' },
		{ value: 'ae', label: 'Autorização Financeiro' },
		{ value: 'af', label: 'Acessório padrão' },
		{ value: 'ag', label: 'Acessório extra' },
		{ value: 'ah', label: 'Transportadora / Redespacho' },
		{ value: 'ai', label: 'Pedido de prestação' },
		{ value: 'aj', label: 'Frete Embutido / Frete Comercial' },
		{ value: 'ak', label: 'Entrega Parcial' },
		{ value: 'al', label: 'Libera expedição' },
		{ value: 'am', label: 'Data de Entrega' },
		{ value: 'an', label: 'Desconto' },
		{ value: 'ao', label: 'Condição de Pagamento' },
		{ value: 'ap', label: 'Tipo de Operação' },
		{ value: 'aq', label: 'Valor Total' },
		{ value: 'ar', label: 'Conhecimento do pedido' },
		{ value: 'as', label: 'Conhecimento do cliente' },
		{ value: 'at', label: 'Manutenção de pendência' }
	]
}

export const checklistPaymentFormOptions: ListType[] = [
	{
		label: 'Santander',
		value: 'santander'
	},
	{
		label: 'CDC - PROGER',
		value: 'cdcProger'
	},
	{
		label: 'FINAME',
		value: 'finame'
	},
	{
		label: 'LEASING',
		value: 'leasing'
	},
	{
		label: 'BNB/FNE',
		value: 'bnbFne'
	},
	{
		label: 'FCO',
		value: 'fco'
	},
	{
		label: 'Outros Financiamentos',
		value: 'outros'
	},
	{
		label: 'GRENKE',
		value: 'grenke'
	},
	{
		label: 'Parcelado pela empresa',
		value: 'parcelado'
	},
	{
		label: 'Cartão de Crédito',
		value: 'cartaoCredito'
	},
	{
		label: 'Cartão BNDES',
		value: 'cartaoBndes'
	},
	{
		label: 'Revenda',
		value: 'revenda'
	}
]

export function handleLogEntity(entity?: string) {
	if (!entity) return '-'
	const LogEntity = LogEntities.filter(
		(LogEntity) => LogEntity.value === entity
	)
	return LogEntity[0]?.label || '-'
}

export const LogEntities = [
	{ value: 'order', label: 'Pedido' },
	{ value: 'client', label: 'Cliente' }
]

export const countriesList = [
	{ value: '013', label: 'AFEGANISTAO' },
	{ value: '756', label: 'AFRICA DO SUL' },
	{ value: '017', label: 'ALBANIA, REPUBLICA DA' },
	{ value: '023', label: 'ALEMANHA' },
	{ value: '037', label: 'ANDORRA' },
	{ value: '040', label: 'ANGOLA' },
	{ value: '041', label: 'ANGUILLA' },
	{ value: '043', label: 'ANTIGUA E BARBUDA' },
	{ value: '047', label: 'ANTILHAS HOLANDESAS' },
	{ value: '053', label: 'ARABIA SAUDITA' },
	{ value: '059', label: 'ARGELIA' },
	{ value: '063', label: 'ARGENTINA' },
	{ value: '065', label: 'ARUBA' },
	{ value: '069', label: 'AUSTRALIA' },
	{ value: '072', label: 'AUSTRIA' },
	{ value: '077', label: 'BAHAMAS, ILHAS' },
	{ value: '081', label: 'BANGLADESH' },
	{ value: '083', label: 'BARBADOS' },
	{ value: '080', label: 'BAHREIN' },
	{ value: '088', label: 'BELIZE' },
	{ value: '229', label: 'BENIN' },
	{ value: '090', label: 'BERMUDAS' },
	{ value: '093', label: 'MIANMAR (BIRMANIA)' },
	{ value: '097', label: 'BOLIVIA' },
	{ value: '101', label: 'BOTSUANA' },
	{ value: '105', label: 'BRASIL' },
	{ value: '108', label: 'BRUNEI' },
	{ value: '111', label: 'BULGARIA, REPUBLICA DA' },
	{ value: '310', label: 'BURKINA FASSO' },
	{ value: '115', label: 'BURUNDI' },
	{ value: '119', label: 'BUTAO' },
	{ value: '127', label: 'CABO VERDE, REPUBLICA DE' },
	{ value: '145', label: 'CAMAROES' },
	{ value: '141', label: 'CAMBOJA' },
	{ value: '149', label: 'CANADA' },
	{ value: '154', label: 'CATAR' },
	{ value: '137', label: 'CAYMAN, ILHAS' },
	{ value: '788', label: 'CHADE' },
	{ value: '158', label: 'CHILE' },
	{ value: '160', label: 'CHINA, REPUBLICA POPULAR' },
	{ value: '163', label: 'CHIPRE' },
	{ value: '741', label: 'CINGAPURA' },
	{ value: '165', label: 'COCOS(KEELING),ILHAS' },
	{ value: '169', label: 'COLOMBIA' },
	{ value: '173', label: 'COMORES, ILHAS' },
	{ value: '177', label: 'CONGO,REPUBLICA DO' },
	{ value: '183', label: 'COOK, ILHAS' },
	{ value: '190', label: 'COREIA, REPUBLICA DA' },
	{ value: '187', label: 'COREIA, REP.POP.DEMOCRATI' },
	{ value: '193', label: 'COSTA DO MARFIM' },
	{ value: '196', label: 'COSTA RICA' },
	{ value: '198', label: 'COVEITE, KUWEIT' },
	{ value: '199', label: 'CUBA' },
	{ value: '232', label: 'DINAMARCA' },
	{ value: '783', label: 'DJIBUTI' },
	{ value: '235', label: 'DOMINICA,ILHA' },
	{ value: '240', label: 'EGITO' },
	{ value: '687', label: 'EL SALVADOR' },
	{ value: '244', label: 'EMIRADOS ARABES UNIDOS' },
	{ value: '239', label: 'EQUADOR' },
	{ value: '245', label: 'ESPANHA' },
	{ value: '249', label: 'ESTADOS UNIDOS' },
	{ value: '253', label: 'ETIOPIA' },
	{ value: '255', label: 'FALKLAND (ILHAS MALVINAS)' },
	{ value: '259', label: 'FEROE, ILHAS' },
	{ value: '870', label: 'FIJI' },
	{ value: '267', label: 'FILIPINAS' },
	{ value: '271', label: 'FINLANDIA' },
	{ value: '161', label: 'FORMOSA (TAIWAN)' },
	{ value: '275', label: 'FRANCA' },
	{ value: '106', label: 'FRETADO P/ O BRASIL' },
	{ value: '281', label: 'GABAO' },
	{ value: '285', label: 'GAMBIA' },
	{ value: '289', label: 'GANA' },
	{ value: '293', label: 'GIBRALTAR' },
	{ value: '297', label: 'GRANADA' },
	{ value: '301', label: 'GRECIA' },
	{ value: '305', label: 'GROENLANDIA' },
	{ value: '309', label: 'GUADALUPE' },
	{ value: '313', label: 'GUAM' },
	{ value: '317', label: 'GUATEMALA' },
	{ value: '337', label: 'GUIANA' },
	{ value: '325', label: 'GUIANA FRANCESA' },
	{ value: '329', label: 'GUINE' },
	{ value: '334', label: 'GUINE-BISSAU' },
	{ value: '331', label: 'GUINE-EQUATORIAL' },
	{ value: '341', label: 'HAITI' },
	{ value: '345', label: 'HONDURAS' },
	{ value: '351', label: 'HONG KONG' },
	{ value: '355', label: 'HUNGRIA, REPUBLICA DA' },
	{ value: '357', label: 'IEMEN' },
	{ value: '358', label: 'IEMEN DEMOCRATICO' },
	{ value: '361', label: 'INDIA' },
	{ value: '365', label: 'INDONESIA' },
	{ value: '372', label: 'IRA, REPUBLICA ISLAMICA D' },
	{ value: '369', label: 'IRAQUE' },
	{ value: '375', label: 'IRLANDA' },
	{ value: '379', label: 'ISLANDIA' },
	{ value: '383', label: 'ISRAEL' },
	{ value: '386', label: 'ITALIA' },
	{ value: '388', label: 'IUGOSLAVIA, REP.FED.DA' },
	{ value: '391', label: 'JAMAICA' },
	{ value: '399', label: 'JAPAO' },
	{ value: '403', label: 'JORDANIA' },
	{ value: '411', label: 'KIRIBATI' },
	{ value: '420', label: 'LAOS, REP.POP.DEMOCR.DO' },
	{ value: '426', label: 'LESOTO' },
	{ value: '431', label: 'LIBANO' },
	{ value: '434', label: 'LIBERIA' },
	{ value: '438', label: 'LIBIA' },
	{ value: '440', label: 'LIECHTENSTEIN' },
	{ value: '445', label: 'LUXEMBURGO' },
	{ value: '447', label: 'MACAU' },
	{ value: '450', label: 'MADAGASCAR' },
	{ value: '455', label: 'MALASIA' },
	{ value: '458', label: 'MALAVI' },
	{ value: '461', label: 'MALDIVAS' },
	{ value: '464', label: 'MALI' },
	{ value: '467', label: 'MALTA' },
	{ value: '472', label: 'MARIANAS DO NORTE' },
	{ value: '474', label: 'MARROCOS' },
	{ value: '476', label: 'MARSHALL,ILHAS' },
	{ value: '477', label: 'MARTINICA' },
	{ value: '485', label: 'MAURICIO' },
	{ value: '488', label: 'MAURITANIA' },
	{ value: '493', label: 'MEXICO' },
	{ value: '499', label: 'MICRONESIA' },
	{ value: '505', label: 'MOCAMBIQUE' },
	{ value: '495', label: 'MONACO' },
	{ value: '497', label: 'MONGOLIA' },
	{ value: '501', label: 'MONTSERRAT,ILHAS' },
	{ value: '507', label: 'NAMIBIA' },
	{ value: '508', label: 'NAURU' },
	{ value: '511', label: 'CHRISTMAS,ILHA (NAVIDAD)' },
	{ value: '517', label: 'NEPAL' },
	{ value: '521', label: 'NICARAGUA' },
	{ value: '525', label: 'NIGER' },
	{ value: '528', label: 'NIGERIA' },
	{ value: '531', label: 'NIUE,ILHA' },
	{ value: '535', label: 'NORFOLK,ILHA' },
	{ value: '538', label: 'NORUEGA' },
	{ value: '542', label: 'NOVA CALEDONIA' },
	{ value: '548', label: 'NOVA ZELANDIA' },
	{ value: '556', label: 'OMA' },
	{ value: '566', label: 'PACIFICO,ILHAS DO (POSSES' },
	{ value: '573', label: 'PAISES BAIXOS (HOLANDA)' },
	{ value: '575', label: 'PALAU' },
	{ value: '580', label: 'PANAMA' },
	{ value: '583', label: 'PAPUA NOVA GUINE' },
	{ value: '586', label: 'PARAGUAI' },
	{ value: '589', label: 'PERU' },
	{ value: '593', label: 'PITCAIRN,ILHA' },
	{ value: '599', label: 'POLINESIA FRANCESA' },
	{ value: '603', label: 'POLONIA, REPUBLICA DA' },
	{ value: '611', label: 'PORTO RICO' },
	{ value: '607', label: 'PORTUGAL' },
	{ value: '623', label: 'QUENIA' },
	{ value: '628', label: 'REINO UNIDO' },
	{ value: '640', label: 'REPUBLICA CENTRO-AFRICANA' },
	{ value: '647', label: 'REPUBLICA DOMINICANA' },
	{ value: '660', label: 'REUNIAO, ILHA' },
	{ value: '670', label: 'ROMENIA' },
	{ value: '675', label: 'RUANDA' },
	{ value: '685', label: 'SAARA OCIDENTAL' },
	{ value: '677', label: 'SALOMAO, ILHAS' },
	{ value: '690', label: 'SAMOA' },
	{ value: '691', label: 'SAMOA AMERICANA' },
	{ value: '697', label: 'SAN MARINO' },
	{ value: '710', label: 'SANTA HELENA' },
	{ value: '715', label: 'SANTA LUCIA' },
	{ value: '695', label: 'SAO CRISTOVAO E NEVES,ILH' },
	{ value: '700', label: 'SAO PEDRO E MIQUELON' },
	{ value: '720', label: 'SAO TOME E PRINCIPE, ILHA' },
	{ value: '705', label: 'SAO VICENTE E GRANADINAS' },
	{ value: '728', label: 'SENEGAL' },
	{ value: '735', label: 'SERRA LEOA' },
	{ value: '731', label: 'SEYCHELLES' },
	{ value: '744', label: 'SIRIA, REPUBLICA ARABE DA' },
	{ value: '748', label: 'SOMALIA' },
	{ value: '750', label: 'SRI LANKA' },
	{ value: '754', label: 'SUAZILANDIA' },
	{ value: '759', label: 'SUDAO' },
	{ value: '764', label: 'SUECIA' },
	{ value: '767', label: 'SUICA' },
	{ value: '770', label: 'SURINAME' },
	{ value: '776', label: 'TAILANDIA' },
	{ value: '790', label: 'TCHECOSLOVAQUIA' },
	{ value: '782', label: 'TERRITORIO BRIT.OC.INDICO' },
	{ value: '795', label: 'TIMOR LESTE' },
	{ value: '800', label: 'TOGO' },
	{ value: '810', label: 'TONGA' },
	{ value: '805', label: 'TOQUELAU,ILHAS' },
	{ value: '815', label: 'TRINIDAD E TOBAGO' },
	{ value: '820', label: 'TUNISIA' },
	{ value: '823', label: 'TURCAS E CAICOS,ILHAS' },
	{ value: '827', label: 'TURQUIA' },
	{ value: '828', label: 'TUVALU' },
	{ value: '833', label: 'UGANDA' },
	{ value: '840', label: 'URSS' },
	{ value: '845', label: 'URUGUAI' },
	{ value: '551', label: 'VANUATU' },
	{ value: '848', label: 'VATICANO, EST.DA CIDADE D' },
	{ value: '850', label: 'VENEZUELA' },
	{ value: '858', label: 'VIETNA' },
	{ value: '863', label: 'VIRGENS,ILHAS (BRITANICAS' },
	{ value: '866', label: 'VIRGENS,ILHAS (E.U.A.)' },
	{ value: '875', label: 'WALLIS E FUTUNA, ILHAS' },
	{ value: '888', label: 'CONGO, REPUBLICA DEMOCRAT' },
	{ value: '890', label: 'ZAMBIA' },
	{ value: '665', label: 'ZIMBABUE' },
	{ value: '997', label: 'ORGANIZACOES INTERNACIONA' },
	{ value: '999', label: 'DIVERSOS' },
	{ value: '087', label: 'BELGICA' },
	{ value: '247', label: 'ESLOVACA, REPUBLICA' },
	{ value: '449', label: 'MACEDONIA, ANT.REP.IUGOSL' },
	{ value: '545', label: 'PAPUA NOVA GUINE' },
	{ value: '576', label: 'PAQUISTAO' },
	{ value: '195', label: 'CROACIA (REPUBLICA DA)' },
	{ value: '246', label: 'ESLOVENIA, REPUBLICA DA' },
	{ value: '251', label: 'ESTONIA, REPUBLICA DA' },
	{ value: '427', label: 'LETONIA, REPUBLICA DA' },
	{ value: '442', label: 'LITUANIA, REPUBLICA DA' },
	{ value: '153', label: 'CAZAQUISTAO, REPUBLICA DO' },
	{ value: '847', label: 'UZBEQUISTAO, REPUBLICA DO' },
	{ value: '423', label: 'LEBUAN,ILHAS' },
	{ value: '895', label: 'ZONA DO CANAL DO PANAMA' },
	{ value: '064', label: 'ARMENIA, REPUBLICA DA' },
	{ value: '073', label: 'AZERBAIJAO, REPUBLICA DO ' },
	{ value: '291', label: 'GEORGIA, REPUBLICA DA' },
	{ value: '396', label: 'JOHNSTON, ILHAS' },
	{ value: '625', label: 'QUIRGUIZ, REPUBLICA' },
	{ value: '676', label: 'RUSSIA, FEDERACAO DA' },
	{ value: '772', label: 'TADJIQUISTAO, REPUBLICA D' },
	{ value: '990', label: 'PROVISAO DE NAVIOS E AERO' },
	{ value: '490', label: 'MIDWAY, ILHAS' },
	{ value: '085', label: 'BELARUS, REPUBLICA DA' },
	{ value: '098', label: 'BOSNIA-HERZEGOVINA (REPUB' },
	{ value: '494', label: 'MOLDAVIA, REPUBLICA DA' },
	{ value: '780', label: 'TANZANIA, REP.UNIDA DA' },
	{ value: '831', label: 'UCRANIA' },
	{ value: '791', label: 'TCHECA, REPUBLICA' },
	{ value: '452', label: 'ILHA DA MADEIRA' },
	{ value: '151', label: 'CANARIAS, ILHAS' },
	{ value: '031', label: 'BURKINA FASO' },
	{ value: '359', label: 'MAN, ILHA DE' },
	{ value: '678', label: 'SAINT KITTS E NEVIS' },
	{ value: '824', label: 'TURCOMENISTAO, REPUBLICA' },
	{ value: '994', label: 'A DESIGNAR' },
	{ value: '873', label: 'WAKE, ILHA' },
	{ value: '995', label: 'BANCOS CENTRAIS' },
	{ value: '150', label: 'JERSEY, ILHA DO CANAL' },
	{ value: '58', label: 'CURACAO' },
	{ value: 'EX', label: 'CALIFORNIA' }
]

export const getCountry = (value: string) => {
	return countriesList?.find((country) => country?.value === value)
}

export const blockOptions = [
	{ value: 'S', label: 'Sim' },
	{ value: 'N', label: 'Não' }
]
