import { RecoverAccountForm } from 'module/entryPoint/components/Account/RecoverAccountForm'
import PageTitle from 'module/entryPoint/components/PageTitle'
import PraticaImage from 'module/entryPoint/components/PraticaImage'
import SideBar from 'module/entryPoint/components/SideBar'
import React from 'react'
import './styles.scss'

const RecoverAccount: React.FC = () => {
	return (
		<>
			<PageTitle title="Prática Produtos" single />
			<section className="recover-account-main-content">
				<SideBar>
					<RecoverAccountForm />
				</SideBar>
				<PraticaImage />
			</section>
		</>
	)
}

export default RecoverAccount
