import { DiffEditor } from '@monaco-editor/react'
import { Modal } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import './styles.scss'
type CompareProps = {
	compare: any[]
	onClose: () => void
}

export const ModalCompare = ({ compare, onClose }: CompareProps) => {
	const { viewType } = useContext(DeviceContextElement)

	const formatJSON = (value: string) => {
		try {
			const res = JSON?.parse(value)
			return JSON?.stringify(res, null, 2)
		} catch {
			if (value === undefined) {
				return JSON?.stringify({}, null, 2)
			}
			const errorJson = {
				error: `${value}`
			}
			return JSON?.stringify(errorJson, null, 2)
		}
	}

	document.addEventListener('mousedown', (downEvent) => {
		const isMonacoSash = (downEvent.target as HTMLElement)?.classList.contains(
			'monaco-sash'
		)

		if (isMonacoSash) {
			const handleMouseMove = () => {
				const overflowGuard = document.querySelector(
					'.overflow-guard'
				) as HTMLElement
				const logCompareTitleOld = document.querySelector(
					'.log-compare-title-old'
				) as HTMLElement
				logCompareTitleOld.style.minWidth =
					overflowGuard.style.getPropertyValue('width')
			}

			document.addEventListener('mousemove', handleMouseMove)

			document.addEventListener('mouseup', () => {
				document.removeEventListener('mousemove', handleMouseMove)
			})
		}
	})

	return (
		<>
			{!!compare?.length && (
				<Modal.root>
					<Modal.header title={`Log de alteração`} onClose={() => onClose()} />
					<Modal.body>
						<Modal.body.main>
							<span>{`${
								compare[0]?.status
									? 'Alterado com sucesso as '
									: 'Erro ao alterar as '
							} ${new Date(
								compare[0]?.createdAt
							).toLocaleString()} por ${compare[0]?.user?.name} ${
								compare[0]?.user?.surname || ''
							}`}</span>
							{viewType > ViewType.MOBILE ? (
								<div className="log-compare">
									<span className="log-compare-title-old">
										<label>Dados anteriores a alteração</label>
									</span>
									<span className="log-compare-title-new">
										<label>Dados pós modificação</label>
									</span>
								</div>
							) : (
								<></>
							)}
							<DiffEditor
								height="70vh"
								width={viewType > ViewType.MOBILE ? '50vw' : '100%'}
								language="json"
								options={{
									readOnly: true
								}}
								modified={compare[0] && formatJSON(compare[0]?.payload)}
								original={compare[1] && formatJSON(compare[1]?.payload)}
							/>
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)}
		</>
	)
}
