import { LoadingProgress } from '@praticabr/ppsa'
import logo from 'assets/image/pratica-logo-grande-p-500.png'
import $ from 'jquery'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { RecoveryPass, getTwoFactor, updateUser } from 'services'
import { signInRequest } from 'store/modules/auth/actions'
import {
	Button,
	Container,
	Info,
	InfoName,
	Input,
	Title,
	TitleSenha
} from './styles'

const Register: React.FC = () => {
	const dispatch = useDispatch()

	const [exists, setExists] = useState<boolean>()
	const [nameArea, setNameArea] = useState<string>('')
	const [userId, setUserId] = useState<string>()
	const [loading, setLoading] = useState<boolean>()

	useEffect(() => {
		const department = getQueryVariable('custo_descri')
		let mountName = ''
		if (department) {
			const departmentArr = department?.split('%20')
			if (departmentArr?.length > 1) {
				for (let i = 0; i < departmentArr?.length; i++) {
					mountName = mountName + ' ' + departmentArr[i]
				}
				setNameArea(mountName)
			} else {
				setNameArea(departmentArr[0])
			}
		}

		const callOpenApi = () => {
			const auth_code = getQueryVariable('code_auth')
			getTwoFactor(auth_code)
				.then((response) => {
					if (response.data?.length === 0) {
						setExists(false)
					} else {
						setExists(true)
						setUserId(response.data[0].user_id)
					}
				})
				.catch((error) => {
					console.log(error)
				})
		}

		callOpenApi()
	}, [])

	function getQueryVariable(variable: string) {
		const query = window.location.search.substring(1)
		const vars = query?.split('&')

		for (let i = 0; i < vars?.length; i++) {
			const pair = vars[i]?.split('=')

			if (pair[0] == variable) {
				return decodeURI(pair[1])
			}
		}
		return false
	}

	function validateInfo() {
		if (!$('.workerName').val()) {
			alert('Campo nome é obrigatório!')
			$('.workerName').focus()
			return false
		}

		if (!$('.workerSurname').val()) {
			alert('Campo sobrenome é obrigatório!')
			$('.workerSurname').focus()
			return false
		}

		if (!$('.workerCel').val()) {
			alert('Campo celular é obrigatório!')
			$('.workerCel').focus()
			return false
		}

		if (!$('#password').val()) {
			alert('Campo senha é obrigatório!')
			$('#password').focus()
			return false
		}

		if (!$('#password1').val()) {
			alert('Campo confirmação de senha é obrigatório!')
			$('#password1').focus()
			return false
		}

		if (
			$('#password').val() == '' ||
			$('#password1').val() == '' ||
			$('#password').val() !== $('#password1').val()
		) {
			alert('As senhas não são iguais!')
			$(`#password`).find('input').focus()
			return false
		}

		return true
	}

	function handleSignIn() {
		if (validateInfo()) {
			setLoading(true)
			const email = getQueryVariable('email')
			const code_auth = getQueryVariable('code_auth')
			const userId = Number(getQueryVariable('id'))

			const data = {
				email: email,
				password: $('#password').val(),
				code_auth: code_auth
			}

			RecoveryPass(data)
				.then((res) => {
					const response = res.data.response
					const { userData } = response
					userData.name = $('.workerName').val()
					userData.surname = $('.workerSurname').val()
					userData.phone = $('.workerCel').val()

					updateUser(userData, userId)
						.then((resp) => {
							setLoading(false)
							dispatch(signInRequest(email, $('#password').val()))
						})
						.catch((error) => {
							setLoading(false)
							toast.error('Erro ao criar o novo usuário.')
						})
				})
				.catch((err) => {
					setLoading(false)
					toast.error('Erro ao atualizar a nova senha.')
				})
		}
	}

	const mainFormContent = () => {
		return (
			<>
				<img
					src={logo}
					style={{ width: '170px', marginBottom: '32px' }}
					alt="Prática"
				/>

				<Title>
					Bem vindo ao Admin Prática!<br></br> <br></br>
					Confira e ajuste seus dados para acessar a plataforma.
				</Title>

				<div
					style={{ display: 'flex', marginTop: '20px', marginBottom: '10px' }}
				>
					<InfoName>Matrícula:</InfoName>
					<Info>{getQueryVariable('matricula')}</Info>
				</div>

				<div
					style={{ display: 'flex', marginTop: '20px', marginBottom: '10px' }}
				>
					<InfoName>Área:</InfoName>
					<Info>{nameArea}</Info>
				</div>

				<div
					style={{
						display: 'flex',
						marginTop: '20px',
						marginBottom: '10px',
						alignItems: 'center'
					}}
				>
					<InfoName>E-mail:</InfoName>
					<Info>{getQueryVariable('email')}</Info>
				</div>

				<div
					style={{
						display: 'flex',
						marginTop: '20px',
						marginBottom: '10px',
						alignItems: 'center'
					}}
				>
					<InfoName>Nome:</InfoName>
					<Input
						className="workerName"
						defaultValue={`${getQueryVariable('name')
							.toString()
							.replace(/%20/g, ' ')}`}
					></Input>
				</div>

				<div
					style={{
						display: 'flex',
						marginTop: '20px',
						marginBottom: '10px',
						alignItems: 'center'
					}}
				>
					<InfoName>Sobrenome:</InfoName>
					<Input
						className="workerSurname"
						defaultValue={`${getQueryVariable('surname')
							.toString()
							.replace(/%20/g, ' ')}`}
					></Input>
				</div>

				<div
					style={{
						display: 'flex',
						marginTop: '20px',
						marginBottom: '10px',
						alignItems: 'center'
					}}
				>
					<InfoName>Celular:</InfoName>
					<Input
						className="workerCel"
						defaultValue={`${getQueryVariable('cel')
							.toString()
							.replace(/%20/g, ' ')}`}
					></Input>
				</div>

				<div
					style={{
						marginTop: '20px',
						marginBottom: '10px',
						alignItems: 'center'
					}}
				>
					<TitleSenha>Senha:</TitleSenha>
					<Input type="password" id="password" name="password" />
				</div>

				<div
					style={{
						marginTop: '20px',
						marginBottom: '10px',
						alignItems: 'center'
					}}
				>
					<TitleSenha>Confirmação de senha:</TitleSenha>
					<Input type="password" id="password1" name="password1" />
				</div>

				<Button className="submitBtn" onClick={handleSignIn}>
					Salvar
				</Button>

				{loadingContent()}
			</>
		)
	}

	const loadingContent = () => {
		return loading ? (
			<div className="modal loading">
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)'
					}}
				>
					<LoadingProgress text="Carregando" />
				</div>
			</div>
		) : (
			<></>
		)
	}

	const errorMessageContent = () => {
		return (
			<>
				<img
					src={logo}
					style={{ width: '170px', marginBottom: '32px' }}
					alt="Prática"
				/>

				<Title>
					Bem vindo ao Admin Prática!<br></br> <br></br>
					Este link para registro de usuário já foi utilizado. <br></br>
					Se você esqueceu sua senha, vá para a página de login e clique em
					recuperar senha. <br></br> <br></br> <br></br>
					<a
						style={{
							color: 'rgb(24, 81, 161)',
							border: '1px solid rgb(24, 81, 161)',
							borderRadius: '3px',
							padding: '5px'
						}}
						href="http://192.168.50.13:4141"
					>
						Fazer Login
					</a>
				</Title>
			</>
		)
	}

	const mainContent = () => {
		return exists && String(userId) === getQueryVariable('id')
			? mainFormContent()
			: errorMessageContent()
	}

	return <Container>{mainContent()}</Container>
}

export default Register
