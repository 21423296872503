import Filter from 'models/Filter'
import React, { useState } from 'react'
import MoreOptions from 'shared/components/MoreOptions'
import FilterOs from '../../FilterOs'

interface FilterListWebProps {
	filters: Filter[]
	handleChangesEdit: (worked: boolean) => void
	handleChangesRemove: (filter: Filter) => void
}

const FilterListMobile = ({
	filters,
	handleChangesEdit,
	handleChangesRemove
}: FilterListWebProps) => {
	const [hasEditingFilter, setHasEditingFilter] = useState<boolean>(false)
	const [filter, setFilter] = useState<Filter>()

	const listMap = (list: string[]) => {
		if (list?.length) {
			return (
				<div>
					{list.map((item, index) =>
						index !== list?.length - 1 ? item + ', ' : item + ' '
					)}
				</div>
			)
		} else {
			return '-'
		}
	}

	const handleEditFilter = (filter: Filter) => {
		setFilter(filter)
		setHasEditingFilter(true)
	}

	const filterListBodyContain = () => {
		return filters.map((filter, index) => {
			return (
				<div className="filter-view-contain-table-lines" key={index}>
					<div className="filter-view-contain-table-column column-filter-name">
						<span>Filtro:</span>
						<div>{filter.name}</div>
					</div>

					<div className="filter-view-contain-table-column column-filter-states">
						<span>Estados:</span>
						{listMap(filter.stateList)}
					</div>

					<div className="filter-view-contain-table-column column-filter-status">
						<span>Status:</span>
						{listMap(filter.statusList)}
					</div>

					<div className="filter-view-contain-table-column column-filter-type">
						<span>Tipo de OS:</span>
						{listMap(filter.typeOsList)}
					</div>

					<div className="filter-view-contain-table-column column-filter-cliente-group">
						<span>Grupo de clientes:</span>
						{listMap(filter.accountTypeList)}
					</div>

					<div className="filter-view-contain-table-column column-filter-more-options">
						<MoreOptions
							id={`${filter.id}`}
							options={[
								{
									item: 1,
									label: 'Editar',
									onClick: () => {
										handleEditFilter(filter)
									}
								},
								{
									item: 2,
									label: 'Excluir',
									onClick: () => handleChangesRemove(filter)
								}
							]}
						/>
					</div>
				</div>
			)
		})
	}

	return (
		<div className="filter-view-contain-table">
			{filterListBodyContain()}
			{hasEditingFilter && (
				<FilterOs
					handleChanges={handleChangesEdit}
					handleCancel={() => setHasEditingFilter(false)}
					filter={filter}
				/>
			)}
		</div>
	)
}

export default FilterListMobile
