import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'
import { RequisitionError } from 'models/RequisitionError'
import IOrderHistoryListItem from 'module/orderManagement/models/IOrderHistoryListItem'
import { getOrderHistoryByClient } from 'module/orderManagement/services'
import moment from 'moment-timezone'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { store } from 'store'
import { setOrderHistoryFilters } from 'store/modules/orderManagement/actions'
import {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'
import {
	OrderHistoryContextType,
	OrderHistoryContextValue
} from './OrderHistoryContextType'

export const OrderHistoryContextElement =
	createContext<OrderHistoryContextType>(OrderHistoryContextValue)
const defaultParams = defaultListParams(
	store.getState().orderManagement?.listOrderHistoryParams,
	false
)

const OrderHistoryProvider = (props: any) => {
	const [orderHistoryList, setOrderHistoryList] = useState<
		IOrderHistoryListItem[]
	>([])
	const [clientProtheusCode, setClientProtheusCode] = useState<string>('')
	const [isLoading, setLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<RequisitionError>()
	const listParams = useRef<ListParams>(defaultParams)

	const dispatch = useDispatch()

	useEffect(() => {
		requestHistoryOrders()
	}, [clientProtheusCode])

	const requestHistoryOrders = (newFilter?: FilterData) => {
		if (clientProtheusCode !== '') {
			setLoading(true)
			const { currentPage, pageLimit, searchValue } = { ...listParams.current }

			let copyNewFilter

			if (newFilter) {
				copyNewFilter = { ...newFilter }
				if (newFilter.initialDate) {
					copyNewFilter.initialDate = `${moment(newFilter.initialDate).format(
						'YYYYMMDD'
					)}`
				}
				if (newFilter.finalDate) {
					copyNewFilter.finalDate = `${moment(newFilter.finalDate).format(
						'YYYYMMDD'
					)}`
				}
			}

			const offset = (currentPage - 1) * pageLimit
			getOrderHistoryByClient(
				clientProtheusCode,
				pageLimit,
				offset,
				searchValue,
				copyNewFilter
			)
				.then((res) => {
					const { count, orders } = res.data.response
					updateParams(Math.ceil(count / pageLimit), 'count')
					setOrderHistoryList(orders)
					setLoading(false)
				})
				.catch((err) => {
					const { error } = err.request.response
					const reportError = {
						message: error.message,
						code: error.statusCode,
						error: error.request
					}
					clearParams(true)
					setHasError(reportError)
					setLoading(false)
				})
		}
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
		dispatch(setOrderHistoryFilters(listParams.current))
	}

	const clearParams = (clearAll = false) => {
		listParams.current = clearListParams(listParams.current, clearAll)
		dispatch(setOrderHistoryFilters(listParams.current))
	}

	const onUpdateList = (clientProtheusCode: string) => {
		setClientProtheusCode(clientProtheusCode)
	}

	const onPageChange = (page: number) => {
		updateParams(page, 'page')
		requestHistoryOrders()
	}

	const onLimitChange = (newLimit: number) => {
		updateParams(newLimit, 'limit')
		clearParams()
		requestHistoryOrders()
	}

	const onSearchOrder = (search: string) => {
		updateParams(search, 'search')
		clearParams()
		requestHistoryOrders()
	}

	const onFilterChange = (newFilter: FilterData) => {
		updateParams(newFilter, 'filter')
		clearParams()
		requestHistoryOrders(newFilter)
	}

	const providerValues = () => {
		return {
			orderHistoryList,
			isLoading,
			hasError,
			...listParams.current,
			onUpdateList,
			onPageChange,
			onLimitChange,
			onSearchOrder,
			onFilterChange
		}
	}

	return (
		<OrderHistoryContextElement.Provider value={providerValues()}>
			{props.children}
		</OrderHistoryContextElement.Provider>
	)
}

export default OrderHistoryProvider
