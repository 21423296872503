import { GenericTable, LoadingProgress, Paginator } from '@praticabr/ppsa'
import { HeaderSection } from 'module/adminSystem/components/HeaderSection'
import { ProductsContext } from 'module/productManagement/context/ProductContext'
import React, { useContext, useEffect } from 'react'
import { history } from 'routes/history'
import './styles.scss'

export const ProductsList = () => {
	const {
		isLoading,
		currentPage,
		totalPages,
		handleGetProductsList,
		productsList,
		onPageChange,
		onSearchParams
	} = useContext(ProductsContext)

	useEffect(() => {
		handleGetProductsList()
	}, [currentPage, totalPages])

	return (
		<div className="products-list">
			<HeaderSection.root>
				<HeaderSection.search
					text="Pesquisar produto"
					onChangeValues={(e) => onSearchParams(e)}
				/>
			</HeaderSection.root>
			<div className="products-list-table">
				<GenericTable
					columns={[
						{ key: 'b1Cod', header: 'Código', width: '10%' },
						{ key: 'b1Desc', header: 'Nome', width: '35%' },
						{ key: 'b1Modelo', header: 'Modelo', width: '35%' },
						{ key: 'b1Famprod', header: 'Familia', width: '10%' },
						{ key: 'b1Msblql', header: 'Bloqueado', width: '10%' }
					]}
					data={[
						...productsList.map((product) => ({
							...product,
							b1Msblql: product.b1Msblql === '1' ? 'Sim' : 'Não'
						}))
					]}
					onRowClicked={(e: any) => history.push(`product/${e?.b1Cod}`)}
				/>
			</div>
			<div className="products-list-paginator">
				<Paginator
					currentPage={currentPage}
					onPageChange={(e) => onPageChange(e)}
					totalPages={totalPages}
				/>
			</div>
			{isLoading && <LoadingProgress />}
		</div>
	)
}
