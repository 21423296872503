import { Menu, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Notification } from 'models/Notification'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { MdArrowDownward, MdArrowUpward, MdMoreVert } from 'react-icons/md'
import './styles.css'

interface Props {
	notifications: Notification[]
	notificationsCount: number
	dateFilter: string
	statusFilter: string
	onItemClick: (osNumber: string) => void
	changeStatus: (notifyId: number, isReading?: boolean) => void
	onRemoveClick: (notifyId: number) => void
	setDateFilter: (order: string) => void
	setStatusFilter: (order: string) => void
}

const NotificationsList: React.FC<Props> = ({
	notifications,
	notificationsCount,
	dateFilter,
	statusFilter,
	onRemoveClick,
	changeStatus,
	onItemClick,
	setDateFilter,
	setStatusFilter
}) => {
	const [openMenu, setOpenMenu] = useState<boolean>(false)
	const [selectedNotification, setSelectedNotification] = useState<number>(0)
	const [anchorRefFilter, setAnchorRefFilter] =
		React.useState<null | HTMLElement>(null)

	const handleNotificationClick = (notification: Notification) => {
		changeStatus(notification.id, true)
		onItemClick(notification.osNumber)
	}

	const changeAction = (notifyId: number, readAt?: Date) => {
		const actionName = readAt ? 'Lida' : 'Não lida'
		return (
			<span
				className="mark-as"
				onClick={() => {
					changeStatus(notifyId)
				}}
			>
				{actionName}
			</span>
		)
	}

	const removeNotification = () => {
		if (selectedNotification) {
			onRemoveClick(selectedNotification)
			setSelectedNotification(0)
		}
	}

	const handleOpenMenuItem = (
		event: React.MouseEvent<HTMLElement>,
		notificationId: number
	) => {
		setOpenMenu(true)
		setAnchorRefFilter(event.currentTarget)
		setSelectedNotification(notificationId)
	}

	const handleCloseMenuItem = () => {
		setOpenMenu(false)
		setAnchorRefFilter(null)
	}

	const handleBlurMenuItem = () => {
		setOpenMenu(false)
		setAnchorRefFilter(null)
	}

	const parseDate = (value: Date): string => {
		let mills = value.getTime()
		const zoneTime = value.getTimezoneOffset()
		if (zoneTime) {
			mills = mills - zoneTime * 60000
			const date = new Date(mills)
			return moment(date).format('DD/MM/YYYY')
		}

		return moment(value).format('DD/MM/YYYY')
	}

	const isRead = (readAt: Date) => {
		return readAt ? 'read' : 'new'
	}

	const applyDateFilter = () => {
		const order = dateFilter === 'asc' ? 'desc' : 'asc'
		setDateFilter(order)
	}

	const dateFilterContent = () => {
		return (
			<div
				className="notification-table-header-item-date header-filter"
				onClick={applyDateFilter}
			>
				<span>Data</span>
				{dateFilter === 'asc' ? <MdArrowUpward /> : <MdArrowDownward />}
			</div>
		)
	}

	const applyStatusFilter = () => {
		const order = statusFilter === 'asc' ? 'desc' : 'asc'
		setStatusFilter(order)
	}

	const statusFilterContent = () => {
		return (
			<div
				className="notification-table-header-item-status header-filter"
				onClick={applyStatusFilter}
			>
				<span>Status</span>
				{statusFilter === 'asc' ? <MdArrowUpward /> : <MdArrowDownward />}
			</div>
		)
	}

	const emptyListContent = () => {
		return (
			<div className="empty-list">
				<p>Nenhuma notificação encontrada.</p>
			</div>
		)
	}

	const itemOptionsMenu = (notificationId: number) => {
		return (
			<>
				<Button
					aria-controls="menu"
					aria-haspopup="true"
					size="small"
					onClick={(e) => handleOpenMenuItem(e, notificationId)}
				>
					<MdMoreVert />
				</Button>
			</>
		)
	}

	const notificationList = () => {
		return (
			<>
				{notifications.map((notify, index) => (
					<div
						className={`table-item notification ${isRead(notify.readAt)}`}
						key={index}
					>
						<div
							className="notification-table-item-title"
							onClick={() => {
								handleNotificationClick(notify)
							}}
						>
							{notify.title}
						</div>
						<div
							className="notification-table-item-msg"
							onClick={() => {
								handleNotificationClick(notify)
							}}
						>
							{notify.body}
						</div>
						<div
							className="notification-table-item-os"
							onClick={() => {
								handleNotificationClick(notify)
							}}
						>
							{notify.osNumber ? notify.osNumber : '--'}
						</div>
						<div className="notification-table-item-date">
							{parseDate(new Date(notify.createdAt))}
						</div>
						<div className="notification-table-item-status">
							{changeAction(notify.id, notify.readAt)}
						</div>
						<div className="notification-table-item-options">
							{itemOptionsMenu(notify.id)}
						</div>
					</div>
				))}
				<Menu
					id="menu"
					className="menu-box-item"
					open={openMenu}
					anchorEl={anchorRefFilter}
					keepMounted={false}
					onClose={handleCloseMenuItem}
					onBlur={handleBlurMenuItem}
				>
					<MenuItem className="menu-item" onClick={() => removeNotification()}>
						Excluir Notificação
					</MenuItem>
				</Menu>
			</>
		)
	}

	const notificationCounterContent = () => {
		return (
			<>
				{notificationsCount ? (
					<span className="notifications-count">
						Total de notificações: {notificationsCount}
					</span>
				) : (
					<></>
				)}
			</>
		)
	}

	return (
		<>
			<div className="table notification">
				<div className="table-header notification">
					<div className="notification-table-header-item-title">
						Notificação
					</div>
					<div className="notification-table-header-item-msg">Mensagem</div>
					<div className="notification-table-header-item-os">Protocolo</div>
					{dateFilterContent()}
					{statusFilterContent()}
				</div>
				{notifications?.length ? (
					<div className="table-content">{notificationList()}</div>
				) : (
					emptyListContent()
				)}
			</div>
			{notificationCounterContent()}
			<span className="notifications-warning">
				As notificações são excluidas automaticamente em 60 dias.
			</span>
		</>
	)
}

export default NotificationsList
