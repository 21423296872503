import { handleProtheusDateToDate } from '@praticabr/ppsa'
import EquipHistory from 'models/EquipHistory'
import InfoOS from 'models/InfoOS'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { handleClientType } from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { MdWarning } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { history } from 'routes/history'
import { getSerialInfo } from 'services'
import MobileNavigator from 'shared/components/MobileNavigator'
import AlertIcon from 'shared/components/SvgIcons/AlertIcon'
import { ViewType } from 'utils/Constants'
import EquipmentInfo from '../../EquipmentInfo/index'
import { Protocol } from './styles'
import './styles.css'
import './styles.scss'

const OS_Info: React.FC<InfoOS> = ({
	contato,
	email,
	telephone,
	client,
	type,
	technician,
	equipment,
	serviceOrderNumber,
	addressOS,
	clientType,
	lastAppointmentDate,
	ddd,
	osProtheus,
	generalBlock,
	financialBlock,
	deviation,
	deletedOs
}) => {
	const { viewType } = useContext(DeviceContextElement)
	const [warranty, setWarranty] = useState<string>('')
	const [equipHistory, setEquipHistory] = useState<EquipHistory>()
	const [outWarranty, setOutWarranty] = useState<boolean>(false)
	const [sale, setSale] = useState<string>('')

	useEffect(() => {
		if (
			!!equipment?.serial_number &&
			!!client?.cod_protheus &&
			!!deviation?.id
		) {
			getSerialInfo(
				{
					serialNumber: equipment.serial_number,
					clientCode: client?.cod_protheus,
					equipmentRecno: deviation?.id
				},
				'ADMIN_APP'
			).then((response) => {
				const { data } = response
				if (data?.equipment) {
					setEquipHistory(data.equipment?.equip_history)
					setSale(data.equipment?.sale_date ? data.equipment?.sale_date : '')
					const warrantyDate = handleProtheusDateToDate(
						data.equipment?.date_end_warranty
					)
					if (warrantyDate && warrantyDate < new Date()) {
						setOutWarranty(true)
					}
					setWarranty(
						handleProtheusDateToDate(
							data.equipment?.date_end_warranty
						)?.toLocaleDateString('pt-BR') || ''
					)
				}
			})
		}
	}, [equipment?.serial_number, deviation?.id])

	const equipHistContent = () => {
		return (
			<div className="page-os-history-info">
				<div className="page-os-history-info-item">
					<p>Total de chamados abertos:</p>
					{equipHistory?.all_os_count || equipHistory?.all_os_count === 0 ? (
						<span title={`${equipHistory.all_os_count}` || ''}>
							{equipHistory.all_os_count}
						</span>
					) : (
						<Skeleton count={1} width={60} />
					)}
				</div>
				<div className="page-os-history-info-item">
					<p>Chamados nos últimos 3 meses:</p>
					{equipHistory?.last_os_count || equipHistory?.last_os_count === 0 ? (
						<span title={`${equipHistory.last_os_count}` || ''}>
							{equipHistory.last_os_count}
						</span>
					) : (
						<Skeleton count={1} width={60} />
					)}
				</div>
				<div className="page-os-history-info-item">
					<p>Valor gasto com atendimentos:</p>
					{equipHistory?.total_price.toFixed(2) ? (
						<span title={`R$ ${equipHistory.total_price.toFixed(2)}` || ''}>
							{`R$ ${equipHistory.total_price.toFixed(2)}`}
						</span>
					) : (
						<Skeleton count={1} width={60} />
					)}
				</div>
			</div>
		)
	}

	const clientInfoContent = () => {
		const handleAlertIcon = (clientType: string) => {
			if (clientType !== 'F')
				return <AlertIcon fill="red" height="15" width="15" />

			return ''
		}

		return (
			<div className="page-os-details-info">
				<EquipmentInfo
					equipment={equipment}
					type={type}
					warranty={warranty}
					osProtheus={osProtheus}
				/>
				<div className="page-os-client-info">
					<div className="page-os-client-info-item">
						<p>Data de Venda:</p>
						<span id="clientName">
							{handleProtheusDateToDate(sale)?.toLocaleDateString('pt-BR') ||
								''}
						</span>
					</div>
					<div className="page-os-client-info-item">
						<p>Cliente:</p>
						{clientNameContain()}
					</div>
					<div className="page-os-client-info-item">
						<p>CNPJ:</p>
						{clientCNPJContain()}
					</div>
					<div className="page-os-client-info-item">
						<p>Contato:</p>
						<span title={contato || ''}>
							{contato ? contato : <Skeleton width={250} />}
						</span>
					</div>
					<div className="page-os-client-info-item">
						<p>Telefone:</p>
						{ddd && telephone ? (
							<span title={`(${ddd}) ${telephone}`}>
								({ddd}) {telephone}
							</span>
						) : (
							<Skeleton width={130} />
						)}
					</div>
					<div className="page-os-client-info-item">
						<p>E-mail:</p>
						<span title={email || ''}>
							{email ? email : <Skeleton width={250} />}
						</span>
					</div>
					<div className="page-os-client-info-item">
						<p>Tipo de cliente:</p>
						{clientType ? (
							<span title={handleClientType(clientType) || ''}>
								{handleClientType(clientType)} {handleAlertIcon(clientType)}
							</span>
						) : (
							<Skeleton width={230} />
						)}
					</div>
					<div className="page-os-client-info-item address">
						<p>
							Endereço:
							{addressOS ? (
								<span title={addressOS || ''}>{addressOS}</span>
							) : (
								<Skeleton width={230} />
							)}
						</p>
					</div>
				</div>
			</div>
		)
	}

	const warningContent = () => {
		let buf = ''

		if (deviation?.has_deviation === 'S' && deviation?.deviation_description) {
			buf = Buffer.from(deviation?.deviation_description).toString('latin1')
		}

		const hasWarning =
			generalBlock ||
			financialBlock ||
			outWarranty ||
			client?.preventive ||
			(client?.preventive &&
				equipment?.preventive === 1 &&
				equipment?.next_preventive) ||
			(deviation?.has_deviation === 'S' && deviation?.deviation_description) ||
			deletedOs

		return (
			!!hasWarning && (
				<div className="page-os-warnings">
					<div className="page-os-warning-box">
						<div className="page-os-warning-box-header">
							<p>{`ATENÇÃO`}</p>
						</div>
						<div className="page-os-warning-box-items">
							<>
								{generalBlock && (
									<span key="generalBlock">Cliente com bloqueio geral</span>
								)}
								{financialBlock && (
									<span key="financialBlock">
										Cliente com bloqueio financeiro
									</span>
								)}
								{outWarranty && (
									<span key="outWarranty">Equipamento fora de garantia</span>
								)}
								{client?.preventive && (
									<span key="preventive">Possui contrato preventiva</span>
								)}
								{client?.preventive && equipment?.preventive === 1 && (
									<span key="next_preventive">{`Próxima preventiva: ${handleProtheusDateToDate(
										equipment?.next_preventive
									)?.toLocaleDateString('pt-BR', { timeZone: 'UTC' })}`}</span>
								)}
								{deviation?.has_deviation === 'S' &&
									deviation?.deviation_description && (
										<div className="warning-item-icon">
											<span title={buf}>Equipamento com desvio!</span>
											<MdWarning />
										</div>
									)}
								{deletedOs && <span key="deletedOs">OS Excluída</span>}
							</>
						</div>
					</div>
				</div>
			)
		)
	}

	const clientCNPJContain = () => {
		const CNPJ =
			client?.cpfCnpj?.replace(
				/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
				'$1.$2.$3/$4-$5'
			) || ''
		return CNPJ ? <span title={CNPJ}>{CNPJ}</span> : <Skeleton width={180} />
	}

	const clientNameContain = () => {
		return client?.nome_fantasia && client?.cod_protheus ? (
			<span
				id="clientName"
				title={`${client.cod_protheus} - ${client.nome_fantasia}` || ''}
			>{`${client.cod_protheus} - ${client.nome_fantasia}`}</span>
		) : (
			<Skeleton width={250} />
		)
	}

	return (
		<>
			<div className="page-os-header">
				{viewType === ViewType.MOBILE && (
					<MobileNavigator action={() => history.push('/os')} />
				)}
				<Protocol id="order_number">
					{serviceOrderNumber ? serviceOrderNumber : <Skeleton width={160} />}
				</Protocol>
			</div>
			<div className="page-os-details">
				{clientInfoContent()}
				{warningContent()}
			</div>
			<div className="page-os-history">{equipHistContent()}</div>
		</>
	)
}

export default OS_Info
