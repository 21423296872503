import { Button, Divider, Input, Modal } from '@praticabr/ppsa'
import Filter from 'models/Filter'
import { FederalStatesUF } from 'module/orderManagement/utils/constants'
import { ListFilterOs } from 'module/technicalServices/components/ListFilterOs'
import {
	OsStatusList,
	OsTypesList
} from 'module/technicalServices/utils/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { saveFilter } from 'services/index'
import { store } from 'store'
import { Footer } from './styles'
import './styles.scss'

type FilterSave = {
	id: number | undefined
	name: string
	module: string
	filter_state: string
	filter_status: string
	filter_type_os: string
	filter_type_account: string
	user_id: number
}

interface Props {
	filter?: Filter
	handleCancel: () => void
	handleChanges: (worked: boolean) => void
}

const FilterOs: React.FC<Props> = ({ handleChanges, handleCancel, filter }) => {
	const [typeAccountChecked, setTypeAccountChecked] = useState<string[]>([])
	const [typeOSChecked, setTypeOSChecked] = useState<string[]>([])
	const [statusChecked, setStatusChecked] = useState<string[]>([])
	const [stateChecked, setStateChecked] = useState<string[]>([])

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm<Filter>()

	const user = store.getState().user

	useEffect(() => {
		if (filter !== undefined) {
			setValue('name', filter.name)
			setStateChecked(filter.stateList)
			setStatusChecked(filter.statusList)
			setTypeOSChecked(filter.typeOsList)
			setTypeAccountChecked(filter.accountTypeList)
		}
	}, [filter])

	function handleToggleTypeOS(element: string) {
		const currentIndex = typeOSChecked.indexOf(element)
		const newChecked = [...typeOSChecked]
		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setTypeOSChecked(newChecked)
	}

	function handleToggleState(element: string) {
		const currentIndex = stateChecked.indexOf(element)
		const newChecked = [...stateChecked]
		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setStateChecked(newChecked)
	}

	function handleToggleStatus(element: string) {
		const currentIndex = statusChecked.indexOf(element)
		const newChecked = [...statusChecked]
		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setStatusChecked(newChecked)
	}

	function handleToggleTypeAccount(element: any) {
		const currentIndex = typeAccountChecked.indexOf(element)
		const newChecked = [...typeAccountChecked]
		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setTypeAccountChecked(newChecked)
	}

	const onSubmit = async (data: any) => {
		const filter_state = JSON.stringify(stateChecked)
		const filter_status = JSON.stringify(statusChecked)
		const filter_type_os = JSON.stringify(typeOSChecked)
		const filter_type_account = JSON.stringify(typeAccountChecked)

		const newFilter: FilterSave = {
			name: data.name,
			user_id: user.profile,
			filter_state: filter_state,
			module: 'technical_services',
			filter_status: filter_status,
			filter_type_os: filter_type_os,
			filter_type_account: filter_type_account,
			id: filter !== undefined ? filter.id : undefined
		}

		saveFilter(newFilter)
			.then(() => {
				handleChanges(true)
				handleCancel()
			})
			.catch((error) => {
				toast.error('error: ', error)
			})
	}

	return (
		<Modal.root>
			<Modal.header title="Novo filtro" onClose={handleCancel} />
			<Modal.body>
				<Modal.body.main>
					<div className="new-filter-os-contain">
						<div style={{ height: '50px' }}>
							<Input
								id="name"
								type="text"
								variant="light"
								errors={errors?.name}
								placeholder="Nomeie seu filtro"
								{...register('name', {
									required: {
										value: true,
										message: '*O campo Nome é obrigatório.'
									}
								})}
							/>
						</div>
						<form className="filter-os-list-fields">
							<Divider title="Estados" />
							<div className="filter-os-list-state">
								<ListFilterOs
									title="Estados"
									items={FederalStatesUF()}
									checked={stateChecked}
									onClick={handleToggleState}
								/>
							</div>

							<Divider title="Status" />
							<div className="filter-os-list-status">
								<ListFilterOs
									title="Status"
									items={OsStatusList}
									checked={statusChecked}
									onClick={handleToggleStatus}
								/>
							</div>

							<Divider title="Tipo de OS" />
							<div className="filter-os-list-types">
								<ListFilterOs
									title="Tipo de OS"
									items={OsTypesList}
									checked={typeOSChecked}
									onClick={handleToggleTypeOS}
								/>
							</div>

							<Divider title="Grupo de Clientes" />
							<ListFilterOs
								title="Grupo de Clientes"
								items={['Key Accounts', 'Outros Clientes']}
								checked={typeAccountChecked}
								onClick={handleToggleTypeAccount}
							/>
						</form>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<Footer>
						<Button
							style={{ width: '126px' }}
							size="lg"
							title="Salvar"
							variant="confirmation-solid"
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							style={{ width: '126px' }}
							size="lg"
							title="Cancelar"
							variant="cancellation"
							onClick={handleCancel}
						/>
					</Footer>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}

export default FilterOs
