import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, ListType, Modal, Select } from '@praticabr/ppsa'
import { EmployeesContextElement } from 'module/humanResources/context/EmployeesContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { UpdateEmployeeSchema } from '../../schemas/update-employee-schema'
import './styles.scss'

export default function EmployeeModal({ onClose }: { onClose: () => void }) {
	const {
		departments,
		employee,
		onCleanData,
		onGetSra010,
		onUpdateEmployee,
		sra010
	} = useContext(EmployeesContextElement)
	const [departmentOptions, setDepartmentOptions] = useState<ListType[]>([])
	const [depSelected, setDepSelected] = useState<string>('')
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const statusOptions = [
		{ value: '1', label: 'Ativo' },
		{ value: '0', label: 'Inativo' }
	]

	const form = useForm<UpdateEmployeeSchema>({
		resolver: zodResolver(UpdateEmployeeSchema),
		defaultValues: { ...employee }
	})

	useEffect(() => {
		if (employee?.id) {
			const listDepartments: any = []
			departments.map((department) => {
				listDepartments.push({
					value: department?.code_protheus,
					label: department.description
				})
			})
			setDepartmentOptions(listDepartments)
			setDepSelected(employee?.department?.code_protheus)
			form.setValue('department', employee?.department?.code_protheus)
			form.setValue('is_active', employee?.is_active)
		} else {
			setDepSelected('')
		}
	}, [employee, sra010])

	useEffect(() => {
		if (sra010) {
			const { raCc, raCelular, raEmail, raNome, raTelefon } = sra010
			raNome && form.setValue('name', raNome?.toLowerCase()?.split(' ')[0])
			raNome?.split(' ')[1] &&
				form.setValue('surname', raNome?.toLowerCase()?.split(' ')[1])
			raEmail && form.setValue('email', raEmail.toLowerCase())
			raCelular && form.setValue('cel', raCelular)
			raCc && form.setValue('department', raCc)
			raTelefon && form.setValue('phone', raTelefon)
		}
	}, [sra010])

	const onSubmit = (data: UpdateEmployeeSchema) => {
		const departmentId = departments.find(
			(d) => d?.code_protheus === depSelected
		)?.id
		if (departmentId) {
			onUpdateEmployee({
				userId: employee.id,
				data: { ...data, departmentId },
				callback: handleCloseModal
			})
		}
	}

	const handleCloseModal = () => {
		onCleanData()
		form.reset()
		onClose()
	}

	return (
		<Modal.root>
			<Modal.header onClose={handleCloseModal} title="Detalhes do Usuário" />
			<Modal.body>
				<Modal.body.main>
					<div className="employee-modal">
						<div className="employee-form">
							<div className="employee-form-rows">
								<div className="form-field-info">
									<label>Matrícula</label>
									<Input
										id="register"
										disabled={!isEditing}
										variant="light-normal"
										placeholder="Matrícula"
										errors={form.formState.errors.register}
										{...form.register('register')}
									/>
								</div>
								<div className="form-field-info">
									{isEditing && (
										<div className="search-action">
											<SearchIcon
												height="40"
												width="40"
												onClick={() => onGetSra010(form.getValues('register'))}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="employee-form-rows">
								<div className="form-field-info">
									<label>Nome</label>
									<Input
										id="name"
										placeholder="Nome"
										disabled={!isEditing}
										variant="light-normal"
										errors={form.formState.errors.name}
										{...form.register('name')}
									/>
								</div>
								<div className="form-field-info">
									<label>Sobrenome</label>
									<Input
										id="surname"
										disabled={!isEditing}
										variant="light-normal"
										placeholder="Sobrenome"
										errors={form.formState.errors.surname}
										{...form.register('surname')}
									/>
								</div>
							</div>
							<div className="employee-form-rows">
								<div className="form-field-info">
									<label>E-mail</label>
									<Input
										id="email"
										placeholder="E-mail"
										disabled={!isEditing}
										variant="light-normal"
										errors={form.formState.errors.email}
										{...form.register('email')}
										onChange={(e) => form.setValue('email', e.target.value)}
									/>
								</div>
								<div className="form-field-info">
									<label>Departamento</label>
									{isEditing ? (
										<Select
											id="department"
											variant="light"
											options={departmentOptions}
											onSelectOptions={(option) => {
												setDepSelected(option[0])
												form.setValue('department', option[0])
											}}
											errors={form.formState.errors.department}
											innerRef={{ ...form.register('department') }}
											selectedOption={[form.getValues('department')]}
										/>
									) : (
										<Input
											name="department"
											disabled={!isEditing}
											variant="light-normal"
											placeholder="Departamento"
											value={
												departmentOptions.find(
													(department) => department.value === depSelected
												)?.label
											}
										/>
									)}
								</div>
							</div>
							<div className="employee-form-rows">
								<div className="form-field-info">
									<label>Telefone</label>
									<Input
										id="cel"
										disabled={!isEditing}
										variant="light-normal"
										placeholder="Telefone"
										errors={form.formState.errors.cel}
										{...form.register('cel')}
									/>
								</div>
								<div className="form-field-info">
									<label>Telefone Alternativo</label>
									<Input
										id="phone"
										disabled={!isEditing}
										variant="light-normal"
										placeholder="Telefone"
										errors={form.formState.errors.phone}
										{...form.register('phone')}
									/>
								</div>
								<div className="form-field-info">
									<label>Status</label>
									{isEditing ? (
										<Select
											id="is_active"
											variant="light"
											options={statusOptions}
											onSelectOptions={(option) =>
												form.setValue('is_active', option[0])
											}
											selectedOption={[form.getValues('is_active')]}
											innerRef={{ ...form.register('is_active') }}
										/>
									) : (
										<Input
											name="is_active"
											disabled={!isEditing}
											variant="light-normal"
											placeholder="Telefone"
											value={
												statusOptions.find(
													(status) =>
														status.value === form.getValues('is_active')
												)?.label
											}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="employee-modal-actions">
						{isEditing ? (
							<>
								<Button
									size="lg"
									type="submit"
									title="Salvar"
									variant="confirmation-solid"
									onClick={form.handleSubmit(onSubmit)}
								/>
								<Button
									size="lg"
									title="Cancelar"
									variant="cancellation"
									onClick={() => setIsEditing(false)}
								/>
							</>
						) : (
							<Button
								size="lg"
								type="button"
								title="Editar"
								variant="confirmation-solid"
								onClick={() => setIsEditing(true)}
							/>
						)}
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}
