import {
	applyUserTheme,
	ConfirmModal,
	getDeviceOrientation,
	getDeviceType,
	getViewType
} from '@praticabr/ppsa'
import VersionContext from 'module/entryPoint/context/VersionContext/VersionContext'
import SocketContext from 'module/technicalServices/context/SocketContext/SocketContext'
import React, { createContext, useEffect, useState } from 'react'
import {
	AppThemes,
	DeviceType,
	OrientationType,
	ViewType
} from 'utils/Constants'
import { DeviceContextType } from './DeviceContextType'

export const DeviceContextElement = createContext<DeviceContextType>({
	viewType: {} as ViewType,
	deviceType: {} as DeviceType,
	mainTheme: {} as AppThemes
})

function getSelectedTheme() {
	const theme = AppThemes.LIGHT
	return applyUserTheme(theme)
}

const DeviceProvider = (props: any) => {
	const [viewType, setViewType] = useState<ViewType>(getViewType())
	const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType())
	const [orientationType, setOrientationType] = useState<OrientationType>(
		getDeviceOrientation()
	)
	const [showConfirmOrientation, setShowConfirmOrientation] =
		useState<boolean>(false)
	const [mainTheme, setMainTheme] = useState<string>(getSelectedTheme())

	useEffect(() => {
		checkThemeChanges()
		resizeListener()
	}, [])

	const handleOnConfirmOrientation = () => {
		setShowConfirmOrientation(false)
		window.location.reload()
	}

	window.addEventListener('orientationchange', function () {
		switch (window.orientation) {
			case -90:
			case 90:
				setShowConfirmOrientation(true)
				break
			default:
				window.location.reload()
				break
		}
	})

	const resizeListener = () => {
		const browserOrientation = window.screen?.orientation

		const orientationListener = () => {
			setViewType(getViewType())
			const currentOrientation = getDeviceOrientation()
		}

		if (!browserOrientation) {
			window.addEventListener('resize', orientationListener, false)
		} else {
			browserOrientation.addEventListener('change', orientationListener)
		}
	}

	const checkThemeChanges = () => {
		setMainTheme(getSelectedTheme())
	}

	const providerValues = () => {
		return {
			viewType,
			deviceType,
			mainTheme
		}
	}

	return (
		<>
			<DeviceContextElement.Provider value={providerValues()}>
				<SocketContext>
					<VersionContext>{props.children}</VersionContext>
				</SocketContext>
			</DeviceContextElement.Provider>
			{showConfirmOrientation && (
				<ConfirmModal
					title="AVISO"
					confirmLabel="OK"
					onConfirm={handleOnConfirmOrientation}
					buttonWidth={'68px'}
				>
					<span style={{ textAlign: 'center' }}>
						Para uma melhor experiência, recomendamos que <br />
						utilize a plataforma no modo retrato.
					</span>
				</ConfirmModal>
			)}
		</>
	)
}

export default DeviceProvider
