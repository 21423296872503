import { Button } from '@praticabr/ppsa'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import React, { useContext } from 'react'
import Search from 'shared/components/Search'
import './styles.scss'

interface Props {
	onActionClick: () => void
}

const TransfersHeader: React.FC<Props> = ({ onActionClick }) => {
	const { onSearchEquip } = useContext(TransfersContextElement)

	return (
		<section className="transfers-header-content">
			<div className="options-list-area">
				<div id="joyride-transfer-searchbar">
					<Search
						variant="light"
						placeholder="Procurar equipamento"
						onChangeValues={onSearchEquip}
					/>
				</div>
				<Button
					id="joyride-newtransfer-button"
					size="lg"
					title="NOVA TRANSFERÊNCIA"
					variant="confirmation-solid"
					onClick={onActionClick}
				/>
			</div>
		</section>
	)
}

export default TransfersHeader
