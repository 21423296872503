import { Button } from '@praticabr/ppsa'
import { Order } from 'module/orderManagement/domain/models/order'
import Pendency from 'module/orderManagement/models/Pendency'
import React, { useEffect, useState } from 'react'
import EmailSendConfirm from 'shared/components/EmailSendConfirm'
import { store } from 'store'
import WhatsAppSendConfirm from '../../../../../shared/components/WhatsAppSendConfirm'
import './styles.scss'

type TParams = {
	contacts: {
		email?: string
		phone?: string
	}
	order: Order
	checkedPendency: Pendency[]
	onCancel: (showSendPendencyModal: boolean) => void
}

const SendPendency = ({
	contacts: { email, phone },
	checkedPendency,
	onCancel,
	order
}: TParams) => {
	const [showWhatsAppModal, setShowWhatsAppModal] = useState<boolean>(false)
	const [showEmailModal, setShowEmailModal] = useState<boolean>(false)
	const [textOptional, setTextOptional] = useState<string>('')
	const [textToWhatsapp, setTextToWhatsapp] = useState<string>('')
	const [textToEmail, setTextToEmail] = useState<string>('')
	const { name, surname } = store.getState().user

	useEffect(() => {
		const pluralReturns = (value: 's' | 'ns' | 'em') => {
			if (checkedPendency?.length > 1) return value
			if (value === 'ns') return 'm'
			return ''
		}
		const pendencyDescriptionList = encodeURI(
			checkedPendency
				.map((pendency) => `${pendency.pendency_description}\n`)
				.join('')
		)
		const text =
			`Ol%C3%A1%2C%20%0APara%20seguirmos%20com%20o%20pedido%20${
				order.code
			}%2C%20faz-se%20necess%C3%A1rio%20o%20envio%20do${pluralReturns(
				's'
			)}%20seguinte${pluralReturns('s')}%20` +
			`documento${pluralReturns(
				's'
			)}%2C%20ou%20conclus%C3%A3o%20de%20pend%C3%AAncia${pluralReturns(
				's'
			)}%20ao%20Administra%C3%A7%C3%A3o%20de%20` +
			`Pedidos%20%2F%20Financeiro%3A%0A%0A${pendencyDescriptionList}${
				textOptional && `%0A${encodeURI(textOptional)}%0A`
			}%0AAssim%20que%20o${pluralReturns('s')}%20` +
			`ite${pluralReturns('ns')}%20acima%20for${pluralReturns(
				'em'
			)}%20conclu%C3%ADdo${pluralReturns('s')}%2C%20` +
			`seguiremos%20com%20o%20pedido%20em%20quest%C3%A3o.%20%0AEstamos%20no%20aguardo.%20Abra%C3%A7os%2C%20%0A`
		setTextToWhatsapp(`${text}${encodeURI(`${name} ${surname}`)}`)
		setTextToEmail(text)
	}, [checkedPendency, name, surname, order, textOptional])

	const pendencyDescriptionContain = () => {
		return checkedPendency.map((pendency) => {
			return (
				<div className="pendency-to-send-item" key={pendency.pendency_recno}>
					<span>{pendency.pendency_description}</span>
				</div>
			)
		})
	}

	return (
		<div className="pendency-to-send">
			<span>Pendências selecionadas:</span>
			<div className="selected-pendency-to-send">
				{pendencyDescriptionContain()}
			</div>
			<div className="text-pendency-to-send">
				<span>Digite uma mensagem (opcional)</span>
				<textarea
					name="optional-text-to-send"
					id="optional-text-to-send"
					cols={30}
					rows={10}
					placeholder="Digite aqui sua mensagem opcional"
					onChange={(e) => setTextOptional(e.target.value)}
				/>
			</div>
			<div className="send-pendency-actions">
				<Button
					size="lg"
					title="WHATSAPP"
					variant="whatsApp"
					icon="whatsApp"
					onClick={() => setShowWhatsAppModal(true)}
				/>
				<Button
					size="lg"
					title="E-MAIL"
					variant="outline"
					icon="e-mail"
					onClick={() => setShowEmailModal(true)}
				/>
				<Button
					size="lg"
					title="CANCELAR"
					variant="cancellation"
					onClick={() => onCancel(false)}
				/>
			</div>
			{showWhatsAppModal && (
				<WhatsAppSendConfirm
					onClose={() => setShowWhatsAppModal(false)}
					whatsappParams={{ message: textToWhatsapp, phone: phone }}
				/>
			)}
			<EmailSendConfirm
				show={showEmailModal}
				onClose={() => setShowEmailModal(false)}
				mailParams={{
					body: textToEmail,
					subject: `Prática Klimaquip - Pedido: ${order?.code}`,
					mailto: email
				}}
			/>
		</div>
	)
}

export default SendPendency
