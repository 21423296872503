import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Divider } from '@praticabr/ppsa'
import React, { useState } from 'react'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { history } from 'routes/history'
import smooch from 'smooch'
import { store } from 'store'
import { signOut } from 'store/modules/auth/actions'
import './styles.scss'

interface Params {
	handleConfigModalToggle: () => void
	handleClearTutorials: () => void
	onClose: () => void
}

export const UserSelector: React.FC<Params> = ({
	handleConfigModalToggle,
	handleClearTutorials,
	onClose
}) => {
	const [getHelp, setGetHelp] = useState<boolean>(false)
	const user = store.getState().user
	const userId = user?.profile
	const userName = `${user?.name?.split(' ')[0]} ${user?.surname?.split(
		' '
	)[0]}`
	const dispatch = useDispatch()

	const handleLogout = () => {
		dispatch(signOut(userId))
		history.push('/')
	}

	const handleShowChat = () => {
		smooch.open()
	}

	return (
		<ClickAwayListener onClickAway={onClose}>
			<section className="user-area-selector">
				<Divider title={`${userName}`} />
				{!getHelp && (
					<div
						className="user-area-selector-item"
						onClick={handleConfigModalToggle}
					>
						<span>Editar perfil</span>
					</div>
				)}
				<div
					style={{ justifyContent: getHelp ? 'flex-start' : 'space-between' }}
					className="user-area-selector-item"
					onClick={() => setGetHelp(!getHelp)}
				>
					{getHelp && <MdArrowLeft />}
					<span style={{ fontWeight: getHelp ? 'bold' : '400' }}>Ajuda</span>
					{!getHelp && <MdArrowRight />}
				</div>
				{getHelp && (
					<>
						<div
							className="user-area-selector-item"
							onClick={() => {
								window.open(
									'https://digital-endeavors.zendesk.com/hc/pt-br',
									'_blank'
								)
							}}
						>
							<span>Central de ajuda</span>
						</div>
						<div
							className="user-area-selector-item"
							onClick={handleClearTutorials}
						>
							<span>Rever tutoriais</span>
						</div>
						<div className="user-area-selector-item" onClick={handleShowChat}>
							<span>Chat</span>
						</div>
					</>
				)}
				{!getHelp && (
					<div className="user-area-selector-item" onClick={handleLogout}>
						<span>Sair</span>
					</div>
				)}
			</section>
		</ClickAwayListener>
	)
}
