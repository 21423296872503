import { Checkbox } from '@material-ui/core'
import PrevSchedules from 'models/PrevSchedules'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { ViewType } from 'utils/Constants'

interface Props {
	schedules: PrevSchedules[]
	handleSchedules: (schedule: PrevSchedules[]) => void
	loadingSchedules: boolean
	selectedSchedules: PrevSchedules[]
}

export const AllSchedules: React.FC<Props> = ({
	schedules,
	handleSchedules,
	loadingSchedules,
	selectedSchedules
}) => {
	const { viewType } = useContext(DeviceContextElement)
	const [selected, setSelected] = React.useState<PrevSchedules[]>([])

	useEffect(() => {
		setSelected(selectedSchedules)
	})

	const isSelected = (schedule: PrevSchedules) =>
		selected.find((i) => i.equip_id === schedule.equip_id)

	moment.tz.setDefault('UTC')

	const handleClick = (
		event: React.MouseEvent<unknown>,
		schedule: PrevSchedules
	) => {
		const selectedIndex = selected.findIndex(
			(i) => i.equip_id === schedule.equip_id
		)
		let newSelected: PrevSchedules[] = []

		if (selected?.length === 0 || selected[0]?.loc_id === schedule?.loc_id) {
			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, schedule)
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1))
			} else if (selectedIndex === selected?.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1))
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1)
				)
			}

			setSelected(newSelected)
			handleSchedules(newSelected)
		} else {
			return
		}
	}

	return (
		<div className="table-content">
			{schedules.map((schedule, index) => {
				return viewType !== ViewType.MOBILE ? (
					<div
						className="table-item"
						onClick={(event) => handleClick(event, schedule)}
						key={index}
					>
						<div className="schedules-table-item-client">
							<Checkbox
								checked={isSelected(schedule) ? true : false}
								inputProps={{
									'aria-labelledby': `enhanced-table-checkbox-${index}`
								}}
								disabled={isSelected(schedule) ? false : true}
							/>
							<span>{schedule.nome_fantasia}</span>
						</div>
						<div className="schedules-table-item-equip">
							{schedule.equip_name}
						</div>
						<div className="schedules-table-item-serial">
							{schedule.equip_serial}
						</div>
						<div className="schedules-table-item-prev">
							{schedule?.next_preventive &&
								new Date(schedule.next_preventive).toLocaleDateString('pt-BR')}
						</div>
						<div className="schedules-table-item-location">
							{schedule.loc_street +
								',' +
								schedule.loc_neighborhood +
								', ' +
								schedule.loc_city}
						</div>
					</div>
				) : (
					<div
						className="table-item"
						onClick={(event) => handleClick(event, schedule)}
						key={index}
					>
						<div className="schedules-table-item-client">
							<Checkbox
								checked={isSelected(schedule) ? true : false}
								inputProps={{
									'aria-labelledby': `enhanced-table-checkbox-${index}`
								}}
								disabled={isSelected(schedule) ? false : true}
							/>
							<span>{schedule.nome_fantasia}</span>
						</div>
						<div className="schedules-table-item-equip">
							Equipamento: {schedule.equip_name}
						</div>
						<div className="schedules-table-item-serial">
							N/S: {schedule.equip_serial}
						</div>
						<div className="schedules-table-item-prev">
							Previsão:{' '}
							{schedule?.next_preventive &&
								new Date(schedule.next_preventive).toLocaleDateString('pt-BR')}
						</div>
						<div className="schedules-table-item-location">
							Endereço:{' '}
							{schedule.loc_street +
								',' +
								schedule.loc_neighborhood +
								', ' +
								schedule.loc_city}
						</div>
					</div>
				)
			})}
		</div>
	)
}

AllSchedules.propTypes = {
	schedules: PropTypes.any
}
