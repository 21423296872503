import { CheckBox } from '@praticabr/ppsa'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext } from 'react'
import { normalizeCGC } from 'utils/Pipe'

interface Props {
	isClientChecked: boolean
	onClientCheckedChange: () => void
}

const ClientSelectField: React.FC<Props> = ({
	isClientChecked,
	onClientCheckedChange
}) => {
	const { transferSelected } = useContext(TransfersDetailsContextElement)

	const client = transferSelected?.transferPayloadParsed.prpInfo.newClient
	return (
		<div className="transfer-confirm-action">
			<CheckBox
				format="square"
				variant="blue"
				checked={isClientChecked}
				onChange={onClientCheckedChange}
			/>
			<div className="transfer-confirm-action-text">
				<div>
					<span title={client?.nome_fantasia || ''}>
						<b>Nome Fantasia:</b>
						{client?.nome_fantasia}
					</span>
					<span>
						<b>CPF/CNPJ:</b>
						{normalizeCGC(client?.cpfCnpj)}
					</span>
				</div>
				<div>
					<span title={client?.razao_social || ''}>
						<b>Razão Social:</b>
						{client?.razao_social}
					</span>
					<span>
						<b>Código:</b>
						{client?.cod_protheus}
					</span>
				</div>
			</div>
		</div>
	)
}

export default ClientSelectField
