import { ConfirmModal, LoadingProgress } from '@praticabr/ppsa'
import RequestResponse from 'models/RequestResponse'
import {
	TransferClient,
	TransferContact,
	TransferEquipment,
	TransferInput
} from 'models/Transfer'
import StepProgress from 'module/technicalServices/components/StepProgress'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import TransfersDetailsContext from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { TransferSteps, TransferStepsItemList } from 'utils/Constants'
import TransferClientStep from './CreateTransferSteps/TransferClientStep'
import TransferContactStep from './CreateTransferSteps/TransferContactStep'
import TransferEquipmentStep from './CreateTransferSteps/TransferEquipmentStep'
import TransferSummaryStep from './CreateTransferSteps/TransferSummaryStep'
import './styles.scss'

interface Props {
	onSuccessCreate: () => void
}

const CreateTransfer: React.FC<Props> = ({ onSuccessCreate }) => {
	const { onCreateTransfer } = useContext(TransfersContextElement)

	const [isLoading, setLoading] = useState<boolean>(false)

	const [client, setClient] = useState<TransferClient>()
	const [contact, setContact] = useState<TransferContact>()
	const [equipment, setEquipment] = useState<TransferEquipment>()
	const [actualStep, setActualStep] = useState<number>(TransferSteps.SUMMARY)

	const [showTransferResponseModal, setShowTransferResponseModal] =
		useState<boolean>(false)

	const onSelectClient = (clientSelected: TransferClient) => {
		setClient(clientSelected)
		setActualStep(TransferSteps.CONTACT)
	}

	const onSelectContact = (contactInfo: TransferContact) => {
		const clientUpdated = {
			id: client?.id,
			name: client?.name,
			razaoSocial: client?.razaoSocial,
			contact: contactInfo.name,
			cpfCnpj: client?.cpfCnpj,
			email: contactInfo.email,
			phone: contactInfo.phone,
			isOnDataBase: true
		} as TransferClient
		setContact(contactInfo)
		setClient(clientUpdated)
		setActualStep(TransferSteps.SUMMARY)
	}

	const onSelectEquipment = (equipmentSelected: TransferEquipment) => {
		setEquipment(equipmentSelected)
		setActualStep(TransferSteps.CLIENT)
	}

	const onConfirmTransfer = () => {
		if (client && contact && equipment) {
			setLoading(true)
			const transferInput = {
				name: client.contact,
				cpfCnpj: client.cpfCnpj,
				razaoSocial: client.razaoSocial,
				currentClientCpjCnpj: equipment.cpfCnpj,
				serialNumber: equipment.serialNumber,
				phone: client.phone,
				email: client.email
			} as TransferInput
			onCreateTransfer(transferInput, onCompleted)
		} else {
			toast.error('Verifique os dados da transferência')
		}
	}

	const onCompleted = (request: RequestResponse) => {
		setLoading(false)
		if (request.reportError) {
			toast.error('Erro ao criar solicitação de transferência')
		} else {
			setShowTransferResponseModal(true)
		}
	}

	const onTransferResponseModalClose = () => {
		setShowTransferResponseModal(false)
		onSuccessCreate()
	}

	const onStepChange = (step: number) => {
		switch (step) {
			case TransferSteps.EQUIPMENT:
				setActualStep(step)
				break
			case TransferSteps.CLIENT:
				if (equipment) {
					setActualStep(step)
				}
				break
			case TransferSteps.CONTACT:
				if (client) {
					setActualStep(step)
				}
				break
			default:
				if (contact) {
					setActualStep(step)
				}
		}
	}

	const stepProgressContent = () => {
		const stepList = TransferStepsItemList
		const startStep = TransferSteps.EQUIPMENT
		return (
			<StepProgress {...{ stepList, startStep, actualStep, onStepChange }} />
		)
	}

	const handleStepContent = () => {
		switch (actualStep) {
			case TransferSteps.EQUIPMENT:
				return <TransferEquipmentStep {...{ equipment, onSelectEquipment }} />
			case TransferSteps.CLIENT:
				return <TransferClientStep {...{ client, onSelectClient }} />
			case TransferSteps.CONTACT:
				return <TransferContactStep {...{ client, onSelectContact }} />
			default:
				return (
					<TransferSummaryStep {...{ onConfirmTransfer, client, equipment }} />
				)
		}
	}

	const createResponseModal = () => {
		return (
			showTransferResponseModal && (
				<ConfirmModal
					title="TRANSFERÊNCIA"
					onConfirm={onTransferResponseModalClose}
					confirmLabel="OK"
					text={
						<>
							Solicitação de Transferência de Titularidade criada com sucesso!
						</>
					}
				/>
			)
		)
	}

	const loadingContent = () => {
		return <LoadingProgress text="Carregando..." />
	}

	return (
		<>
			<section className="transfers-create-content">
				<TransfersDetailsContext>
					{stepProgressContent()}
					{handleStepContent()}
				</TransfersDetailsContext>
				<div className="transfers-create-footer"></div>
			</section>
			{isLoading && loadingContent()}
			{createResponseModal()}
		</>
	)
}

export default CreateTransfer
