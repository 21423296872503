import styled from 'styled-components'

export const Header = styled.div`
	font-size: 14px;
	font-weight: 700;
	text-align: left;
`

export const Footer = styled.div`
	width: 100%;
	display: flex;
	column-gap: 20px;
	justify-content: center;
`
export const RequestInfo = styled.div`
	width: 100%;
	display: flex;
	align-self: flex-start;
	flex-direction: column;
`

export const LabelName = styled.div`
	display: flex;
	margin-bottom: 0px;
	padding-right: 5px;
	font-size: 12px;
	line-height: 20px;
	font-weight: 700;
	text-align: left;
`

export const Input = styled.input`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 100%;
	flex-direction: column;
	width: 100%;
	height: 30px;
	padding: 5px 10px;
	margin-bottom: 5px;
	font-size: 12px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const InputArea = styled.textarea`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 100%;
	flex-direction: column;
	width: 100%;
	height: 100px;
	padding: 8px 12px;
	font-size: 12px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const Info = styled.div`
	flex-grow: 1;
	display: flex;
	margin: 0 0 10px;
	flex-direction: row;
	gap: 20px;
`

export const Message = styled.div`
	display: flex;
	margin: 0 0 0px;
	flex-direction: column;
`
export const Characters = styled.div`
	font-size: 9px;
	margin-bottom: 10px;
	align-self: flex-end;
`

export const DtFollowUp = styled.input`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 30%;
	flex-direction: row;
	padding-right: 10px;
	padding-left: 20px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	height: 38px;
`
