import { InputAdornment, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import { Paginator } from '@praticabr/ppsa'
import States from 'assets/js/fs.json'
import $ from 'jquery'
import MyFilter from 'models/Filter'
import TechSpecialty from 'models/TechSpecialty'
import Technician from 'models/Technician'
import Tech from 'module/technicalServices/components/Technician'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { MdClear, MdFilterList, MdSearch } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { getAllTechs, getSpecialties, isTokenValid } from 'services'
import { ListFilter } from 'shared/ListFilter'
import { store } from 'store'
import { signOut } from 'store/modules/auth/actions'
import {
	changeTechSearchTerm,
	getTechChangePage,
	getTechnicians
} from 'store/modules/technician/actions'
import {
	AddressCol,
	CityCol,
	CodPRP,
	Container,
	ContainerTech,
	Content,
	Div1,
	Header,
	Header1,
	Line,
	Link,
	SearchBar,
	StateCol,
	StatusCol,
	Table,
	TechNameCol,
	Toolbar
} from './styles'
import './styles.css'

type TParams = { history: string; clientCode: string }

type PageInfo = {
	pageLimit: number
	offset: number
	stateChecked: string[]
	statusChecked: string[]
	cityChecked: string[]
	searchTerm: string
}

const useStyles = makeStyles({
	root: {
		padding: '0px',
		minWidth: '40px',
		textTransform: 'none'
	},
	menuitem: {
		padding: '5px 10px 5px 0px',
		color: 'rgba(51, 51, 51, 0.84)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.06)',
			width: '100%'
		}
	},
	body: {
		fontSize: '14px',
		lineHeight: '20px',
		color: 'rgba(51, 51, 51, 0.84)',
		paddingBottom: '10px',
		width: '300px'
	}
})

const statusTypes = [
	{ value: '1', label: 'Ativo' },
	{ value: '0', label: 'Inativo' }
]

const AllTechnicians: React.FC<RouteComponentProps<TParams>> = ({
	history,
	match
}: RouteComponentProps<TParams>) => {
	const [technicians, setTechnicians] = useState<Technician[]>()
	const [stateList, setStateList] = useState<string[]>([])
	const [statusList, setStatusList] = useState<string[]>([])
	const [stateChecked, setStateChecked] = useState<string[]>(
		store.getState().techs.pageInfo.stateChecked
	)
	const [cityChecked, setCityChecked] = useState<string[]>([])
	const [statusChecked, setStatusChecked] = useState<string[]>(
		store.getState().techs.pageInfo.statusChecked
	)
	const [openState, setOpenState] = React.useState(false)
	const [openStatus, setOpenStatus] = React.useState(false)
	const anchorRefState = React.useRef<HTMLButtonElement>(null)
	const anchorRefStatus = React.useRef<HTMLButtonElement>(null)
	const [pageNumber, setPageNumber] = React.useState<number>(
		store.getState().techs.pageNumber
	)
	const [pageLimit, setPageLimit] = React.useState<number>(
		store.getState().techs.pageInfo.pageLimit
	)
	const [totalPages, setTotalPages] = React.useState<number>(10)
	const [filterSelected, setFilterSelected] = useState<MyFilter>()
	const [searchTerm, setSearchTerm] = useState<string>(
		store.getState().techs.pageInfo.searchTerm
	)
	const [loading, setLoading] = useState<boolean>(false)
	const [changesTech, setChangesTech] = useState<boolean>(false)
	const [techSelected, setTechSelected] = useState<Technician>()
	const [techSaveEnabled, setTechSaveEnabled] = useState<boolean>(false)
	const [techSpecialtiesList, setTechSpecialtiesList] = useState<
		TechSpecialty[]
	>([])

	const dispatch = useDispatch()
	const classes = useStyles()

	let delay = false
	let searching = false

	useEffect(() => {
		setStateList(States.map((item) => item.initials))
		setStatusList(statusTypes.map((item) => item.label.toString()))

		getSpecialties().then((response) => {
			setTechSpecialtiesList(response.data)
		})

		setTechSelected(undefined)

		const offset = (pageNumber - 1) * pageLimit
		const pageInfo: PageInfo = {
			pageLimit,
			offset,
			stateChecked,
			cityChecked,
			statusChecked,
			searchTerm: encodeURIComponent(searchTerm)
		}

		$(`.searchTechInput`).find('input').val(searchTerm)
		requestTechList(pageInfo)
	}, [])

	useEffect(() => {
		setTechSelected(undefined)

		const offset = (store.getState().techs.pageNumber - 1) * pageLimit
		const pageInfo: PageInfo = {
			pageLimit,
			offset,
			stateChecked,
			cityChecked,
			statusChecked,
			searchTerm: encodeURIComponent(searchTerm)
		}
		requestTechList(pageInfo)
	}, [
		pageNumber,
		stateChecked,
		cityChecked,
		statusChecked,
		searchTerm,
		changesTech
	])

	const requestTechList = (pageInfo: PageInfo) => {
		setLoading(true)
		getAllTechs(pageInfo)
			.then((response) => {
				setLoading(false)
				const { techList, count } = response.data
				setTotalPages(Math.ceil(count / pageLimit))
				saveTechLocalStorage(getTechnicians, techList, pageInfo, pageNumber)
				setTechnicians(store.getState().techs.technicians)
			})
			.catch((error) => {
				console.log(error)
				setLoading(false)
				if (error.message === 'Network Error') {
					history.push('/error/500') // Network Errorr
				} else if (error.message === '_data$ is undefined') {
					history.push('/error/404')
				} else if (error.message === 'Request failed with status code 401') {
					isTokenValid(store.getState().auth.token)
						.then((resp) => {
							if (resp.data.exists === true) {
								history.push('/home')
							} else {
								dispatch(signOut())
								history.push('/')
							}
						})
						.catch((err) => {
							dispatch(signOut())
							history.push('/')
						})
				}
			})
	}

	async function saveTechLocalStorage(
		action: any,
		array: any,
		pageInfo: any,
		page: number
	) {
		dispatch(action(array, pageInfo, pageNumber))
	}

	function handleToggleState(element: string) {
		const currentIndex = stateChecked.indexOf(element)
		const newChecked = [...stateChecked]

		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		changePageByFilterChanges(1)
		setStateChecked(newChecked)
	}

	function handleToggleStatus(element: string) {
		const currentIndex = statusChecked.indexOf(element)
		const newChecked = [...statusChecked]

		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		changePageByFilterChanges(1)
		setStatusChecked(newChecked)
	}

	const handleClick = () => {
		setTechSaveEnabled(true)
		$(`.technician`).toggle()
		$('body').addClass('modal-open')
	}

	const handleCancel = () => {
		$(`.technician`).toggle()
		setTechSaveEnabled(false)
		setTechSelected(undefined)
	}

	const handleCloseState = (event: React.MouseEvent<EventTarget>) => {
		if (
			anchorRefState.current &&
			anchorRefState.current.contains(event.target as HTMLElement)
		) {
			return
		}
		setOpenState(false)
	}

	const handleCloseStatus = (event: React.MouseEvent<EventTarget>) => {
		if (
			anchorRefStatus.current &&
			anchorRefStatus.current.contains(event.target as HTMLElement)
		) {
			return
		}
		setOpenStatus(false)
	}

	const handleToggleStateFilter = () => {
		setOpenState((prevOpen) => !prevOpen)
		setFilterSelected(undefined)
	}

	const handleToggleStatusFilter = () => {
		setOpenStatus((prevOpen) => !prevOpen)
		setFilterSelected(undefined)
	}

	const handleChangePage = async (value: number) => {
		await dispatch(getTechChangePage(value))
		setPageNumber(store.getState().techs.pageNumber)
	}

	const changePageByFilterChanges = async (value: number) => {
		await dispatch(getTechChangePage(value))
		setPageNumber(store.getState().techs.pageNumber)
	}

	const handleTechChanges = () => {
		setTechSaveEnabled(false)
		setChangesTech(!changesTech)
		setTechSelected(undefined)
	}

	function handleSearchTech(term: any) {
		const s = term
		setSearchTerm(s)
		dispatch(changeTechSearchTerm(s))
	}

	const setDel = async () => {
		delay = true

		if (searching == false) {
			searching = true
			setSear()
		}
	}

	const setSear = () => {
		changePageByFilterChanges(1)
		if (delay == false && searching == true) {
			searching = false
			handleSearchTech($(`.searchTechInput`).find('input').val())
		} else if (delay == true) {
			delay = false
			setTimeout(() => {
				delay
				setSear()
			}, 600)
		}
	}

	const handleSelectedTech = (tech: Technician | undefined) => {
		setTechSelected(tech)
		setTechSaveEnabled(true)
	}

	return (
		<>
			<Container>
				<Header>Técnicos</Header>
				<Toolbar>
					<div style={{ display: 'flex', alignItems: 'flex-end' }}>
						<a href={`${history.location.pathname}#`} className="link-block">
							<div className="text-block" onClick={handleClick}>
								Registrar novo técnico
							</div>
						</a>
					</div>
					<SearchBar>
						<div style={{ display: 'flex', alignItems: 'flex-end' }}>
							<div className="link-block">
								<TextField
									id="search"
									placeholder="Encontre um técnico"
									className="searchTechInput"
									InputProps={{
										endAdornment: (
											<InputAdornment position="start">
												{searchTerm ? (
													<MdClear
														style={{ cursor: 'pointer' }}
														onClick={() => {
															$(`.searchTechInput`).find('input').val('')
															setSearchTerm('')
															dispatch(changeTechSearchTerm(''))
														}}
													/>
												) : (
													<MdSearch />
												)}
											</InputAdornment>
										)
									}}
									onChange={() => {
										setDel()
									}}
								/>
							</div>
						</div>
					</SearchBar>
				</Toolbar>

				<Content>
					<Table>
						<Header1>
							<TechNameCol>
								<div>Técnico</div>
							</TechNameCol>

							<CodPRP>
								<div>Código</div>
							</CodPRP>

							<StateCol>
								<div>Status</div>
								<Button
									ref={anchorRefStatus}
									aria-controls={openStatus ? 'menu-list-grow' : undefined}
									aria-haspopup="true"
									onClick={handleToggleStatusFilter}
									classes={{
										root: classes.root
									}}
								>
									<MdFilterList
										fontSize="small"
										style={{
											color:
												statusChecked?.length === 0 ? 'lightgray' : '#333333'
										}}
									/>
								</Button>
								<Popper
									style={{ zIndex: 1 }}
									open={openStatus}
									anchorEl={anchorRefStatus.current}
									role={undefined}
									keepMounted={true}
								>
									<ClickAwayListener onClickAway={handleCloseStatus}>
										<Paper>
											<ListFilter
												items={statusList}
												checked={statusChecked}
												onClick={handleToggleStatus}
											></ListFilter>
										</Paper>
									</ClickAwayListener>
								</Popper>
							</StateCol>

							<StateCol>
								<div>Estado</div>
								<Button
									ref={anchorRefState}
									aria-controls={openState ? 'menu-list-grow' : undefined}
									aria-haspopup="true"
									onClick={handleToggleStateFilter}
									classes={{
										root: classes.root
									}}
								>
									<MdFilterList
										fontSize="small"
										style={{
											color:
												stateChecked?.length === 0 ? 'lightgray' : '#333333'
										}}
									/>
								</Button>
								<Popper
									style={{ zIndex: 1 }}
									open={openState}
									anchorEl={anchorRefState.current}
									role={undefined}
									keepMounted={true}
								>
									<ClickAwayListener onClickAway={handleCloseState}>
										<Paper>
											<ListFilter
												items={stateList}
												checked={stateChecked}
												onClick={handleToggleState}
											></ListFilter>
										</Paper>
									</ClickAwayListener>
								</Popper>
							</StateCol>

							<CityCol>
								<div>Cidade</div>
							</CityCol>

							<AddressCol>
								<div>Endereço</div>
							</AddressCol>
						</Header1>
						{store.getState().techs.technicians ? (
							<>
								<div>
									{store.getState().techs.technicians?.length !== 0 ? (
										store
											.getState()
											.techs.technicians?.map(
												(tech: Technician, index: number) => (
													<Line
														onClick={() => handleSelectedTech(tech)}
														key={index}
													>
														<Link>
															<ContainerTech>
																<TechNameCol>
																	<Div1>
																		{tech.name ? (
																			tech.name
																		) : (
																			<Skeleton width={230} />
																		)}
																	</Div1>
																</TechNameCol>

																<CodPRP>
																	<Div1>
																		{tech?.code_prp ? (
																			tech?.code_prp
																		) : (
																			<Skeleton width={70} />
																		)}
																	</Div1>
																</CodPRP>

																<StatusCol>
																	<Div1>
																		{tech.active ? (
																			tech.active === '1' ? (
																				'Ativo'
																			) : (
																				'Inativo'
																			)
																		) : (
																			<Skeleton width={80} />
																		)}
																	</Div1>
																</StatusCol>

																<StateCol>
																	<Div1>
																		{tech.state ? (
																			tech.state
																		) : (
																			<Skeleton width={70} />
																		)}
																	</Div1>
																</StateCol>

																<CityCol>
																	<Div1>
																		{tech.city ? (
																			tech.city
																		) : (
																			<Skeleton width={120} />
																		)}
																	</Div1>
																</CityCol>

																<AddressCol>
																	<Div1>
																		{tech.address ? (
																			tech.address
																		) : (
																			<Skeleton width={230} />
																		)}
																	</Div1>
																</AddressCol>
															</ContainerTech>
														</Link>
													</Line>
												)
											)
									) : (
										<div style={{ textAlign: 'center' }}> Nenhum técnico</div>
									)}
								</div>
							</>
						) : (
							<div style={{ textAlign: 'center' }}> Carregando técnicos</div>
						)}
					</Table>
				</Content>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						paddingTop: '20px'
					}}
				>
					<Paginator
						onPageChange={(e) => handleChangePage(e)}
						currentPage={pageNumber}
						totalPages={totalPages}
					/>
				</div>
			</Container>

			{techSaveEnabled && (
				<div className={`modal technician`} style={{ display: 'none' }}>
					<Tech
						handleChanges={handleTechChanges}
						handleCancel={handleCancel}
						techEdit={techSelected}
						techSaveEnabled={techSaveEnabled}
						specialties={techSpecialtiesList}
					/>
				</div>
			)}
		</>
	)
}

AllTechnicians.propTypes = {
	history: PropTypes.any
}

export default AllTechnicians
