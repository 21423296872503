import DateFnsUtils from '@date-io/date-fns'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { Input } from '@praticabr/ppsa'
import brLocale from 'date-fns/locale/pt-BR'
import { AddOrder } from 'module/orderManagement/domain/usecases/add-order'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface OrderClientInstallmentProps {
	parcel: any
	index: number
	form: UseFormReturn<AddOrder.InstallmentForm, any, AddOrder.InstallmentForm>
	selectedQtyParcel?: string
	handleSumInstallment: () => void
}

export const OrderClientInstallmentEditingMode = ({
	parcel,
	index,
	form,
	selectedQtyParcel,
	handleSumInstallment
}: OrderClientInstallmentProps) => {
	const [parcelDate, setParcelDate] = useState<any>()

	const {
		register,
		setValue,
		watch,
		getFieldState,
		formState: { errors }
	} = form

	const watchedValueInstallment = watch(`installments.${index}.value`)

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			handleSumInstallment()
		}, 500)
		return () => clearTimeout(timeOutId)
	}, [watchedValueInstallment])

	useEffect(() => {
		if (parcel) {
			setValue(`installments.${index}.value`, parcel.value)
			setValue(`installments.${index}.date`, parcel.date)
		} else {
			setValue(`installments.${index}.value`, 0)
			setValue(`installments.${index}.date`, null)
		}
	}, [selectedQtyParcel, parcel])

	return (
		<div className="installments-details-item">
			<h5>{`Parcela ${index + 1}`}</h5>
			<div className="installments-details-item-values">
				<div className="value-container">
					<span>Valor</span>
					<Input
						type="number"
						placeholder=" 0,00"
						id={`installments.${index}.value`}
						variant="light"
						defaultValue={parcel?.value ? parcel.value : '0,00'}
						{...register(`installments.${index}.value`, {
							required: true,
							valueAsNumber: true
						})}
						errors={getFieldState(`installments.${index}.value`).error}
						maxLength={12}
						money={true}
					/>
				</div>
				<div className="date-container">
					<span>Vencimento</span>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
						<KeyboardDatePicker
							cancelLabel="CANCELAR"
							clearLabel="LIMPAR"
							okLabel="OK"
							emptyLabel="DD/MM/AAAA"
							format="dd/MM/yyyy"
							id="date-initial-picker"
							invalidDateMessage="Data invalida"
							value={parcelDate || parcel?.date || null}
							{...register(`installments.${index}.date`)}
							onChange={(value: any) => {
								setValue(`installments.${index}.date`, value)
								setParcelDate(value)
							}}
							onError={() => {}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
								className: 'period-filter-keyboard-button'
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>
		</div>
	)
}
