import { Input, Select } from '@praticabr/ppsa'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { Order } from 'module/orderManagement/domain/models/order'
import {
	FairOrderTypes,
	OrderCategoryTypes,
	OrderTypes
} from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SearchIcon from 'shared/components/SvgIcons/SearchIcon'
import { store } from 'store'
import OrderClientSearch from './OrderClientSearch'

export type OrderDetailsEditingModeProps = {
	order?: Order
	form: any
	isEditing?: boolean
}

const stateSUFRAMA = ['AC', 'AM', 'AP', 'RO', 'RR']

export const OrderDetailsEditingMode = ({
	order,
	form,
	isEditing = false
}: OrderDetailsEditingModeProps) => {
	const { sc5010 } = useContext(OrderDetailsContextElement)
	const [clientSearchShowModal, setClientSearchShowModal] =
		useState<boolean>(false)
	const [priceTableSelected, setPriceTableSelected] = useState<string[]>([])
	const { salespersonList, priceTableList } = store.getState().baseLists
	const [client, setClient] = useState<any>()

	const {
		register,
		setValue,
		getValues,
		clearErrors,
		formState: { errors }
	} = form

	useEffect(() => {
		if (order?.client) {
			const { state, branch, code, name } = order.client
			const localClient = {
				code: code,
				branch: branch,
				name: name,
				state: state
			}
			onChangeClient(localClient)
		}

		order?.category && setValue('category', order.category)
		order?.price_table && setValue('price_table', order.price_table)
		order?.client && setValue('client_branch', order.client.branch)
		order?.issue_date && setValue('issue_date', order.issue_date)
		order?.fair_order && setValue('fair_order', order.fair_order)
		order?.financial_release &&
			setValue('financial_release', order.financial_release)
		order?.message_invoice && setValue('message_invoice', order.message_invoice)
		order?.order_origin && setValue('order_origin', order.order_origin)
		order?.seller?.code && setValue('salesperson_code', order.seller?.code)
		order?.service_order_code &&
			setValue('service_order_code', order.service_order_code)
		order?.type && setValue('type', order.type)
	}, [
		order?.category,
		order?.price_table,
		order?.client,
		order?.fair_order,
		order?.financial_release,
		order?.message_invoice,
		order?.order_origin,
		order?.seller?.code,
		order?.service_order_code,
		order?.type
	])

	useEffect(() => {
		if (sc5010) {
			setValue('obs_intern', sc5010.c5Follow)
			setValue('obs_memo', sc5010.c5Obsmemo)
			setValue('obs_pcp', sc5010.c5Obspcp)
		}
	}, [sc5010])

	const onChangeClient = (client: any) => {
		setClient(client)
		clearErrors('client_code')
		setValue('client_code', client?.code)
		setValue('client_branch', client.branch)
		setClientSearchShowModal(false)
		if (stateSUFRAMA.includes(client.state)) {
			toast.info(
				'Cliente em região Suframa. Verifique os dados cadastrais antes de prosseguir.'
			)
		}
	}

	return (
		<>
			<div className="new-order-information-contain-information">
				<header>Cliente do pedido</header>
				<div
					onClick={() => setClientSearchShowModal(true)}
					className={`order-customer-container ${
						errors.client_code ? 'error-order-customer-container' : ''
					}`}
				>
					<span
						className={client?.name ? '' : 'fake-placeholder'}
						{...register('client_code', {
							required: {
								value: true,
								message: 'O campo tipo de pedido é obrigatório!'
							}
						})}
					>
						{client?.name ? client?.name : 'Selecione um cliente'}
					</span>
					<small>
						{errors.client_code && (
							<span className="select-error-span">
								{errors.client_code.message}
							</span>
						)}
					</small>
					<div>
						<SearchIcon
							width="40px"
							height="40px"
							fill="#5DAEFF"
							onClick={() => setClientSearchShowModal(true)}
						/>
					</div>
				</div>
			</div>
			<div className="new-order-information-contain-information">
				<header>Tipo de pedido</header>
				<Select
					id={'type'}
					options={OrderTypes}
					selectedOption={[getValues('type')]}
					onSelectOptions={(option) => {
						setValue('type', option[0])
						clearErrors('type')
					}}
					innerRef={{
						...register('type', {
							required: {
								value: true,
								message: 'O campo tipo de pedido é obrigatório!'
							}
						})
					}}
					errors={errors.type}
				/>
			</div>
			<div className="new-order-information-contain-information">
				<header>Categoria</header>
				<Select
					id="category"
					options={OrderCategoryTypes}
					onSelectOptions={(option) => {
						setValue('category', option[0])
						clearErrors('category')
					}}
					selectedOption={[getValues('category')]}
					innerRef={{
						...register('category', {
							required: {
								value: true,
								message: 'O campo categoria é obrigatório!'
							}
						})
					}}
					errors={errors.category}
				/>
			</div>
			<div className="new-order-information-contain-information">
				<header>Emissão</header>
				<div className="order-issue-date">
					<Input
						name="financial_release"
						variant="light"
						disabled
						value={
							isEditing
								? moment(getValues('issue_date')).format('DD/MM/YYYY')
								: moment(new Date()).format('DD/MM/YYYY')
						}
					/>
				</div>
			</div>
			<div className="new-order-information-contain-information">
				<header>Tabela de preço</header>
				<Select
					id="price_table"
					options={priceTableList.filter((item: any) => item.available === '1')}
					selectedOption={[getValues('price_table')]}
					onSelectOptions={(option) => {
						setValue('price_table', option[0])
						setPriceTableSelected(() => {
							if (priceTableSelected?.length) {
								toast.info('Revise os valores unitários dos produtos')
							}
							return option
						})
						clearErrors('price_table')
					}}
					innerRef={{ ...register('price_table') }}
				/>
			</div>
			<div className="new-order-information-contain-information">
				<header>Pedido de feira</header>
				<Select
					id="fair_order"
					options={FairOrderTypes}
					selectedOption={[getValues('fair_order')]}
					innerRef={{ ...register('fair_order') }}
					onSelectOptions={(option) => {
						setValue('fair_order', option[0])
						clearErrors('fair_order')
					}}
				/>
			</div>
			<div className="new-order-information-contain-information">
				<header>Vendedor</header>
				<Select
					id="salesperson_code"
					options={salespersonList}
					selectedOption={[getValues('salesperson_code')]}
					onSelectOptions={(option) => {
						setValue('salesperson_code', option[0])
						clearErrors('salesperson_code')
					}}
					innerRef={{
						...register('salesperson_code', {
							required: {
								value: true,
								message: 'O campo vendedor é obrigatório!'
							}
						})
					}}
					errors={errors.salesperson_code}
					tooltip="right"
				/>
			</div>
			{getValues('category')?.includes('5') && (
				<div className="new-order-information-contain-information">
					<header>Ordem de serviço</header>
					<Input
						name="service_order_code"
						variant="light"
						{...register('service_order_code', {
							required: {
								value: !!getValues('category')?.includes('5'),
								message: 'O campo OS é obrigatório!'
							}
						})}
						errors={errors.service_order_code}
					/>
				</div>
			)}
			<div className="new-order-information-contain-information message-for-invoice">
				<header>Mensagem para nota fiscal</header>
				<Input
					variant="light"
					maxLength={250}
					name="message_invoice"
					{...register('message_invoice')}
				/>
			</div>
			{clientSearchShowModal && (
				<OrderClientSearch
					onChangeClient={onChangeClient}
					onClose={(close) => setClientSearchShowModal(close)}
				/>
			)}
		</>
	)
}
