import SearchIcon from 'assets/image/searchIcon.svg'
import React, { useEffect } from 'react'
import './styles.scss'

export interface SearchProps extends React.ComponentPropsWithoutRef<'input'> {
	variant:
		| 'light'
		| 'dark'
		| 'cancellation'
		| 'confirmation-solid'
		| 'outline-highlight'
		| 'confirmation-solid'
	onChangeValues: (value: string) => void
	innerRef?: any
	autoComplete?: 'on' | 'off'
	removeSpecialCharacters?: boolean
}

const Search = ({
	variant,
	onChangeValues,
	innerRef,
	autoComplete = 'off',
	removeSpecialCharacters = false,
	...rest
}: SearchProps) => {
	const input = document.getElementById('search-input') as HTMLInputElement

	let timer = 0
	const onSearch = async (value: string) => {
		clearTimeout(timer)
		timer = window.setTimeout(() => {
			onChangeValues(value.trim())
			clearTimeout(timer)
		}, 600)
	}

	useEffect(() => {
		if (removeSpecialCharacters) {
			if (input) {
				input.addEventListener('input', () => {
					const cleanValue = input.value.replace(/[^\w\s\d\u00C0-\u017F]/g, '')
					input.value = cleanValue
					onSearch(cleanValue)
				})
			}
		}
	}, [input])

	return (
		<div className={`search-${variant}`}>
			<div className="search-container">
				<img
					id="searchIcon"
					style={{ height: '20px', width: '20px' }}
					src={SearchIcon}
					alt="search icon"
				/>
				<input
					id="search-input"
					autoComplete={autoComplete}
					onChange={(e) => {
						onSearch(e.target.value.toUpperCase())
					}}
					{...rest}
					{...innerRef}
				/>
			</div>
		</div>
	)
}
export default Search
