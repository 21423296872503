import { Radio } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { LoadingProgress } from '@praticabr/ppsa'
import States from 'assets/js/fs.json'
import TechSpecialty from 'models/TechSpecialty'
import Technician from 'models/Technician'
import { ListTechSpecialties } from 'module/technicalServices/components/ListTechSpecialties'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import {
	getGeolocation,
	getZipCodeInfo,
	saveTechnician,
	updateTechnician
} from 'services/index'
import { store } from 'store'
import { stringMask } from 'utils/Pipe'
import { maskOnInput } from 'utils/Pipe/maskOnInput'
import CpfCnpjField from './CpfCnpjField'
import TechnicianFieldControl from './TechnicianFieldControl'
import {
	Abas,
	Characters,
	Code,
	Container,
	Content,
	Div1,
	DivCenter,
	DivContainer,
	DivLeft,
	DivLeft2,
	DivRight,
	DivRight2,
	Footer,
	Header,
	Input,
	InputArea,
	LabelName,
	LabelRadio,
	Link,
	Message,
	Panel,
	PanelInfo,
	PersonTypeSelector,
	Select,
	TechTypeSelector
} from './styles'

export interface IFormInput {
	code_prp: string
	active: number
	name: string
	full_name: string
	state_register: number
	customer_type: string
	channel: number
	zip_code: number
	city: string
	state: string
	address: string
	district: string
	ccusto: number
	region: number
	latitude: number
	longitude: number
	contact: string
	phone: number
	phone1: number
	cel: number
	email: string
	email2: string
	email3: string
	cpf: number
	cnpj: number
	contrib: string
	obs: string
	employee_id_fc: string
}

interface Props {
	techEdit?: Technician
	techSaveEnabled: boolean
	specialties: TechSpecialty[]
	handleChanges: () => void
	handleCancel: () => void
}

const channelTypes = [
	{ value: '', label: 'Selecione' },
	{ value: '1', label: 'Concessionaria' },
	{ value: '2', label: 'Cons. Final Direto' },
	{ value: '3', label: ' Cons. Final Repres' },
	{ value: '5', label: ' KeyAccount' },
	{ value: '7', label: ' Revenda' }
]

const customerTypes = [
	{ value: '', label: 'Selecione' },
	{ value: 'F', label: 'Consumidor final' },
	{ value: 'R', label: 'Revendedor' }
]

const ccustoTypes = [
	{ value: '', label: 'Selecione' },
	{ value: '0010101', label: 'COMERCIAL BRASIL' },
	{ value: '0010301', label: 'FILIAL SAO PAULO' },
	{ value: '0010401', label: 'REGIAO MG' },
	{ value: '0010501', label: 'REGIAO NORDESTE' },
	{ value: '0010601', label: 'REGIAO SUL' },
	{ value: '0010701', label: 'REGIAO INT.SP / TRIANG.MG' },
	{ value: '002020101', label: 'PRATICA DIRETO' },
	{ value: '0010450', label: 'REGIAO RJ/ES' },
	{ value: '0010550', label: 'REGIAO NORTE' },
	{ value: '0010650', label: 'REGIAO CENTRO-OESTE' },
	{ value: '0010102', label: 'ADM DE PEDIDOS' }
]

const regionTypes = [
	{ value: '', label: 'Selecione' },
	{ value: '001', label: 'NORTE' },
	{ value: '002', label: 'SUL' },
	{ value: '003', label: 'INTERIOR SP' },
	{ value: '004', label: 'GRANDE SP' },
	{ value: '005', label: 'ES/RJ' },
	{ value: '006', label: 'EXPORTACAO' },
	{ value: '007', label: 'NORDESTE' },
	{ value: '008', label: 'CENTRO-OESTE' },
	{ value: '009', label: 'MG' }
]

const activeTypes = [
	{ value: '1', label: 'Ativo' },
	{ value: '0', label: 'Inativo' }
]

const contrib = [
	{ value: '', label: '' },
	{ value: '1', label: 'Sim' },
	{ value: '2', label: 'Não' }
]

type Address = {
	cep: string
	city: string
	state: string
	address: string
	number: string
	complement: string
	district: string
}

const PERSON_TYPE = {
	PJ: 'J',
	PF: 'F'
}

const TECH_INTERNAL = {
	EXTERNAL: 0,
	INTERNAL: 1
}

const Tech: React.FC<Props> = ({
	handleChanges,
	handleCancel,
	techEdit,
	techSaveEnabled,
	specialties
}) => {
	const [techSpecialtiesList, setTechSpecialtiesList] = useState<
		TechSpecialty[]
	>([])
	const [techSpecialtiesChecked, setTechSpecialtiesChecked] = useState<
		number[]
	>([])
	const [disableAddress, setDisableAddress] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [personType, setPersonType] = useState<string>(
		techEdit?.person_type || PERSON_TYPE.PJ
	)
	const [addressInfo, setAddressInfo] = useState<Address>({
		cep: '',
		city: '',
		state: '',
		address: '',
		number: '',
		complement: '',
		district: ''
	})
	const [changeName, setChangeName] = useState<string>('')
	const [changeFullName, setChangeFullName] = useState<string>('')
	const [changeAddress, setChangeAddress] = useState<string>('')
	const [changeComplement, setChangeComplement] = useState<string>('')
	const [changeDistrict, setChangeDistrict] = useState<string>('')
	const [changeContact, setChangeContact] = useState<string>('')
	const [changeEmail1, setChangeEmail1] = useState<string>('')
	const [changeEmail2, setChangeEmail2] = useState<string>('')
	const [changeEmail3, setChangeEmail3] = useState<string>('')
	const [stateRegister, setStateRegister] = useState<string>('')
	const [isInternal, setIsInternal] = useState<number>(
		techEdit?.is_internal || TECH_INTERNAL.EXTERNAL
	)
	const [obs, setObs] = useState<string>('')
	const [profileTechnicianFieldControl, setProfileTechnicianFieldControl] =
		useState<string>('')
	const [companyIdFieldControl, setCompanyIdFieldControl] = useState<string>('')
	const [employeeIdFieldControl, setEmployeeIdFieldControl] =
		useState<string>('')
	const [msg, setMsg] = useState<number>(254)

	const techForm = useForm<IFormInput>({
		defaultValues: {
			employee_id_fc: techEdit?.employee_id_fc,
			code_prp: techEdit?.code_prp,
			state: techEdit?.state,
			email: techEdit?.email,
			name: techEdit?.name
		}
	})

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors
	} = techForm

	const { roles } = store.getState().user
	const fieldServiceTechnicianManager = roles.includes(
		'fieldServiceTechnicianManager'
	)
		? true
		: false

	useEffect(() => {
		$(`.technician`).toggle()
		$('.infoAba').css('display', 'block')
		$('.infoAba').css('text-decoration', 'underline')
		$('.info').css('display', 'block')
		$('.info').css('width', '100%')
		$('.address').css('display', 'none')
		$('.contact').css('display', 'none')
		$('.pj-form-content').toggle()

		setTechSpecialtiesList(specialties)

		if (techEdit !== undefined) {
			setChangeName(techEdit?.name)
			setObs(techEdit?.obs)
			setChangeEmail1(techEdit?.email)
			setObs(stringMask(techEdit?.obs))

			if (techEdit?.obs?.trim() !== undefined) {
				setMsg(254 - techEdit.obs.trim()?.length)
			}

			setAddressInfo({
				cep: techEdit?.zip_code,
				city: techEdit?.city,
				state: techEdit?.state,
				address: techEdit?.address,
				number: techEdit?.number,
				complement: techEdit?.complement,
				district: techEdit?.district
			})

			const techSpecialties: number[] = []
			techEdit.technical_specialties.map((item) => {
				techSpecialties.push(item.specialtyId)
			})
			setTechSpecialtiesChecked(techSpecialties)

			if (techEdit?.person_type === PERSON_TYPE.PF) {
				$('.pf-form-content').toggle()
				$('.pj-form-content').toggle()
			}
		}

		return () => {
			setPersonType('')
			setTechSpecialtiesList([])
			setTechSpecialtiesChecked([])
		}
	}, [])

	useEffect(() => {
		if (personType === PERSON_TYPE.PJ) {
			$(`.full_name`).val('')
		}

		if (techEdit?.person_type !== personType) {
			clearPersonFields()
		} else if (techEdit) {
			$(`.full_name`).val(techEdit.full_name)
			$(`.state_register`).val(techEdit.state_register)
		}
	}, [personType])

	function handleToggleSpecialties(element: number) {
		const currentIndex = techSpecialtiesChecked.indexOf(element)
		const newChecked = [...techSpecialtiesChecked]

		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setTechSpecialtiesChecked(newChecked)
	}

	async function getAddressByZipCode(event: React.FormEvent<EventTarget>) {
		const target = event.target as HTMLInputElement
		let zipCode = target.value

		//zipcode somente com dígitos.
		zipCode = zipCode.replace(/\D/g, '')

		if (zipCode !== '') {
			//Expressão regular para validar o CEP.
			const validacep = /^[0-9]{8}$/

			if (validacep.test(zipCode)) {
				// disable address            // disable address
				setDisableAddress(true)

				const { data } = await getZipCodeInfo(zipCode)
				const { code, address } = data
				if (code === 200) {
					setDisableAddress(false)
					setValue(
						'city',
						address.localidade.normalize('NFD').replace(/[^a-z A-Z 0-9]/g, '')
					)
					setValue('state', address.uf)
					setValue(
						'address',
						address.logradouro.normalize('NFD').replace(/[^a-z A-Z 0-9 ,]/g, '')
					)
					setValue(
						'district',
						address.bairro.normalize('NFD').replace(/[^a-z A-Z 0-9]/g, '')
					)
					setAddressInfo({
						...addressInfo,
						city: address.localidade,
						state: address.uf,
						cep: zipCode
					})
				} else {
					setDisableAddress(false)
					clearAddressFields()
				}
			} else {
				clearAddressFields()
			}
		} else {
			clearAddressFields()
		}
	}

	function clearAddressFields() {
		setValue('address', '')
		setValue('city', '')
		setValue('state', '')
	}

	function getOriginCoord() {
		const address =
			addressInfo.cep +
			' ' +
			addressInfo.city.replace(/[#?!@$%^&*'"_><¨+-/|()=§`]+/g, '') +
			' ' +
			addressInfo.state +
			' ' +
			addressInfo.address +
			' ' +
			addressInfo.number +
			' ' +
			addressInfo.district +
			' ' +
			addressInfo.complement
		getGeolocation(address)
			.then((res) => {
				const { latitude, longitude } = res.data.response[0]
				setValue('latitude', latitude)
				setValue('longitude', longitude)
			})
			.catch((error) => console.log(error))
	}

	const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
		setIsLoading(true)
		let specialties: number[] = []

		const cpfCnpj = customValidator(data)

		if (!cpfCnpj) {
			return
		}

		const technician = { ...data, cnpj: cpfCnpj.replace(/[^0-9]+/g, ''), obs }
		technician.person_type = personType
		technician.is_internal = isInternal
		specialties = techSpecialtiesChecked
		technician.technician_profile = profileTechnicianFieldControl
			? profileTechnicianFieldControl
			: null
		technician.company_id_fc = companyIdFieldControl
			? companyIdFieldControl
			: null
		technician.employee_id_fc = employeeIdFieldControl
			? employeeIdFieldControl
			: null
		technician.complement = changeComplement ? changeComplement : null
		if (technician.person_type === 'F') {
			technician.full_name = technician.name
		}
		if (techEdit === undefined) {
			saveTechnician({ technician, specialties })
				.then((res) => {
					if (res.data.statusCode === 203) {
						toast.error(res.data.message)
					} else {
						toast.success('Técnico cadastrado com sucesso.')
						handleChanges()
					}
				})
				.catch(() => {
					toast.error('Erro ao cadastrar o técnico.')
				})
				.finally(() => {
					setIsLoading(false)
				})
		} else {
			technician.id = techEdit.id
			updateTechnician({ technician, specialties })
				.then(() => {
					toast.success('Técnico atualizado com sucesso.')
					handleChanges()
					setIsLoading(false)
				})
				.catch(() => {
					toast.error('Erro ao cadastrar o técnico.')
					setIsLoading(false)
				})
		}
	}

	const customValidator = (data: any) => {
		const cpf = $('.pf-form-content').find('input').val() as string
		const cnpj = $('.pj-form-content').find('input').val() as string

		const validateField = (field: string, name: string) => {
			if (field) {
				return field
			} else {
				return console.log('Field not found')
			}
		}

		switch (personType) {
			case PERSON_TYPE.PJ:
				return validateField(cnpj, 'cnpj')
			case PERSON_TYPE.PF:
				return validateField(cpf, 'cpf')
			default:
				return
		}
	}

	const countMsg = (e: any) => {
		if (obs?.length < 254 || obs?.length === undefined) {
			setObs(stringMask(e.target.value.substring(0, 254)))
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				if (obs?.length == undefined) {
					setMsg(253)
				} else {
					setMsg(254 - (obs?.length + 1))
				}
			} else {
				setMsg(254 - (obs?.length - 1))
			}
		} else {
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				setMsg(0)
			} else {
				setObs(stringMask(e.target.value.substring(0, 254)))
				setMsg(254 - (obs?.length + 1))
			}
		}
	}

	const handlePersonTypeSelected = (type: string) => {
		setPersonType(type)
		clearErrors('cnpj')
		clearErrors('cpf')
	}
	const handleTechTypeSelected = (value: number) => {
		setIsInternal(value)
	}

	const onChangeTechProfile = (tecProfile: string) => {
		const profile = tecProfile
		setProfileTechnicianFieldControl(profile)
	}

	const onChangeTecCompany = (tecCompany: string) => {
		const company = tecCompany
		setCompanyIdFieldControl(company)
	}

	const onChangeTecEmployee = (tecEmployee: string) => {
		const employee = tecEmployee
		setEmployeeIdFieldControl(employee)
	}

	const technicianFieldControl = () => {
		return (
			<TechnicianFieldControl
				onChangeTechnicianProfile={onChangeTechProfile}
				onChangeTechnicianCompany={onChangeTecCompany}
				onChangeTechnicianEmployee={onChangeTecEmployee}
				{...{
					techEdit,
					changeName,
					changeEmail1,
					techForm,
					fieldServiceTechnicianManager
				}}
			/>
		)
	}

	const cpfCnpjField = () => {
		return (
			<>
				<CpfCnpjField {...{ techEdit, register, personType, errors }} />
			</>
		)
	}

	const setList: { [index: string]: any } = {
		address: (value: any) => setChangeAddress(value),
		'name-tech': (value: any) => setChangeName(value),
		'full-name': (value: any) => setChangeFullName(value),
		'state-register': (value: any) => setStateRegister(value),
		district: (value: string) => setChangeDistrict(value),
		complement: (value: string) => setChangeComplement(value),
		contact: (value: string) => setChangeContact(value),
		email: (value: string) => setChangeEmail1(value),
		email2: (value: string) => setChangeEmail2(value),
		email3: (value: string) => setChangeEmail3(value)
	}

	const onChangeTechMask = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputName: any = `${e.target.name}`
		if (e.target.id) {
			;(document.querySelector(`#${e.target.id}`) as HTMLInputElement).value =
				maskOnInput(e.target.id, e.target.value)
			setValue(inputName, maskOnInput(e.target.id, e.target.value))
			setList[e.target.id](maskOnInput(e.target.id, e.target.value))
		} else {
			console.error('Id was not defined in input')
		}
	}

	const rSocialTechField = () => {
		return (
			<>
				<Div1>
					<LabelName>Razão social</LabelName>
					<Input
						id="full-name"
						className="full_name"
						placeholder="Razão Social"
						{...register('full_name', { required: false })}
						defaultValue={
							techEdit?.full_name
								? stringMask(techEdit?.full_name)
								: changeFullName
						}
						onChange={onChangeTechMask}
					/>
				</Div1>
				<small>
					{errors.full_name && (
						<span style={{ color: 'red', display: 'flex', margin: 'auto' }}>
							O campo Razão social é obrigatório
						</span>
					)}
				</small>
			</>
		)
	}

	const handleRSocialField = () => {
		return personType === PERSON_TYPE.PJ ? rSocialTechField() : <></>
	}

	const clearPersonFields = () => {
		setTimeout(() => {
			$(`.full_name`).val('')
			$(`.state_register`).val('')
			$(`.cpf-cnpj`).find('input').val('')
		}, 75)
	}

	return (
		<Container>
			<Panel>
				<Header>
					Técnico -{' '}
					<span defaultValue={stringMask(techEdit?.name)}>{changeName}</span>
				</Header>

				<form>
					<div
						style={{ display: 'flex', flexDirection: 'row', height: '30px' }}
					>
						<PanelInfo>
							<Code>Código Protheus:</Code>
							{techEdit?.code_prp ? techEdit?.code_prp : '-'}
						</PanelInfo>

						<div style={{ width: '110px' }}>
							<Select
								id="active"
								{...register('active', { required: true })}
								defaultValue={techEdit?.active}
							>
								{activeTypes.map((item) => (
									<option key={item.value} value={item.value}>
										{item.label}
									</option>
								))}
							</Select>
						</div>
					</div>
					<Abas>
						<div
							style={{
								padding: '9px 30px 9px 0px',
								fontWeight: 500,
								fontSize: '14px'
							}}
							className="infoAba"
						>
							<a
								onClick={() => {
									$('.infoAba').css('font-weight', 500)
									$('.infoAba').css('text-decoration', 'underline')
									$('.info').css('display', 'block')
									$('.info').css('width', '100%')
									$('.address').css('display', 'none')
									$('.addressAba').css('text-decoration', 'none')
									$('.contact').css('display', 'none')
									$('.contactAba').css('text-decoration', 'none')
								}}
							>
								Informações
							</a>
						</div>
						<div
							style={{ padding: '9px 30px 9px 0px', fontSize: '14px' }}
							className="addressAba"
						>
							<a
								onClick={() => {
									$('.addressAba').css('font-weight', 500)
									$('.addressAba').css('text-decoration', 'underline')
									$('.address').css('display', 'block')
									$('.address').css('width', '100%')
									$('.info').css('display', 'none')
									$('.infoAba').css('text-decoration', 'none')
									$('.contact').css('display', 'none')
									$('.contactAba').css('text-decoration', 'none')
								}}
							>
								Endereço
							</a>
						</div>
						<div
							style={{ padding: '9px 30px 9px 0px', fontSize: '14px' }}
							className="contactAba"
						>
							<a
								onClick={() => {
									$('.contactAba').css('font-weight', 500)
									$('.contactAba').css('text-decoration', 'underline')
									$('.contact').css('display', 'block')
									$('.contact').css('width', '100%')
									$('.info').css('display', 'none')
									$('.infoAba').css('text-decoration', 'none')
									$('.address').css('display', 'none')
									$('.addressAba').css('text-decoration', 'none')
								}}
							>
								Contato
							</a>
						</div>
					</Abas>
					<Content>
						<div className="info">
							<Div1>
								<LabelName>Tipo de Pessoa</LabelName>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										width: '100%',
										margin: '10px 0',
										height: '25px'
									}}
								>
									<PersonTypeSelector
										onClick={() => {
											handlePersonTypeSelected(PERSON_TYPE.PJ)
										}}
									>
										<Radio
											checked={personType === PERSON_TYPE.PJ}
											value={PERSON_TYPE.PJ}
											name="person-type-pj"
										/>
										<LabelRadio>Pessoa Jurídica</LabelRadio>
									</PersonTypeSelector>
									<PersonTypeSelector
										onClick={() => {
											handlePersonTypeSelected(PERSON_TYPE.PF)
										}}
									>
										<Radio
											checked={personType === PERSON_TYPE.PF}
											value={PERSON_TYPE.PF}
											name="person-type-pf"
										/>
										<LabelRadio>Pessoa Física</LabelRadio>
									</PersonTypeSelector>
								</div>
							</Div1>
							<Div1>
								<LabelName>Tipo de Técnico</LabelName>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										width: '100%',
										margin: '10px 0',
										height: '25px'
									}}
								>
									<TechTypeSelector
										onClick={() => {
											handleTechTypeSelected(TECH_INTERNAL.INTERNAL)
										}}
									>
										<Radio
											checked={isInternal === TECH_INTERNAL.INTERNAL}
											value={TECH_INTERNAL.INTERNAL}
											name="tech-type-internal"
										/>
										<LabelRadio>Técnico Interno</LabelRadio>
									</TechTypeSelector>
									<TechTypeSelector
										onClick={() => {
											handleTechTypeSelected(TECH_INTERNAL.EXTERNAL)
										}}
									>
										<Radio
											checked={isInternal === TECH_INTERNAL.EXTERNAL}
											value={TECH_INTERNAL.EXTERNAL}
											name="tech-type-external"
										/>
										<LabelRadio>Técnico Externo</LabelRadio>
									</TechTypeSelector>
								</div>
							</Div1>
							<Div1>
								<LabelName>Nome Fantasia</LabelName>
								<Input
									id="name-tech"
									defaultValue={
										techEdit?.name ? stringMask(techEdit.name) : changeName
									}
									placeholder="Nome Fantasia"
									{...register('name', { required: true })}
									onChange={onChangeTechMask}
								/>
							</Div1>
							<small>
								{errors.name && (
									<span
										style={{ color: 'red', display: 'flex', margin: 'auto' }}
									>
										O campo Nome Fantasia é obrigatório
									</span>
								)}
							</small>
							{handleRSocialField()}
							<DivContainer>
								<div style={{ width: '340px', paddingRight: '20px' }}>
									{cpfCnpjField()}
								</div>
								<DivCenter>
									<Div1>
										<LabelName>Inscrição Estadual</LabelName>
										<Input
											id="state-register"
											className="state_register"
											defaultValue={
												techEdit?.state_register
													? stringMask(techEdit?.state_register)
													: stateRegister
											}
											placeholder="Inscrição Estadual"
											{...register('state_register', { required: true })}
											onChange={onChangeTechMask}
										/>
									</Div1>
									<small>
										{errors.state_register && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Inscrição Estadual social é obrigatório
											</span>
										)}
									</small>
								</DivCenter>
								<DivRight>
									<Div1>
										<LabelName>Contribuinte</LabelName>
										<Select
											id="contrib"
											{...register('contrib', { required: false })}
											defaultValue={
												techEdit?.contrib === undefined
													? ' '
													: parseInt(techEdit?.contrib)
											}
										>
											{contrib.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</Select>
									</Div1>
								</DivRight>
							</DivContainer>

							<DivContainer>
								<DivLeft>
									<Div1>
										<LabelName>Tipo de cliente</LabelName>
										<Select
											id="cliSelect"
											{...register('customer_type', { required: true })}
											defaultValue={techEdit?.customer_type}
										>
											{customerTypes.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</Select>
									</Div1>
									<small>
										{errors.customer_type && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Tipo de Cliente é obrigatório
											</span>
										)}
									</small>
								</DivLeft>
								<DivCenter>
									<Div1>
										<LabelName>Canal</LabelName>
										<Select
											id="channel"
											{...register('channel', { required: true })}
											defaultValue={
												techEdit?.channel === undefined
													? ' '
													: parseInt(techEdit?.channel)
											}
										>
											{channelTypes.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</Select>
									</Div1>
									<small>
										{errors.channel && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Canal é obrigatório
											</span>
										)}
									</small>
								</DivCenter>

								<DivRight>
									<Div1></Div1>
								</DivRight>
							</DivContainer>
							<Div1>
								<LabelName>Especialidades:</LabelName>
								<ListTechSpecialties
									items={techSpecialtiesList}
									checked={techSpecialtiesChecked}
									onClick={handleToggleSpecialties}
								></ListTechSpecialties>
							</Div1>
						</div>

						<div
							className="address"
							style={{ paddingLeft: '0px', paddingRight: '0px' }}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between'
								}}
							>
								<DivLeft>
									<Div1>
										<LabelName>CEP</LabelName>
										<InputMask
											style={{
												width: '100%',
												height: '30px',
												padding: '4px 5px',
												borderStyle: 'solid',
												borderColor: 'rgba(51, 51, 51, 0.65)',
												borderRadius: '4px',
												color: 'rgba(51, 51, 51, 0.7)',
												fontSize: '14px',
												lineHeight: '20px'
											}}
											type="tel"
											mask="99999999"
											maskChar=""
											placeholder="CEP"
											defaultValue={techEdit?.zip_code}
											{...register('zip_code', {
												required: true,
												minLength: 8,
												maxLength: 8
											})}
											onBlur={getAddressByZipCode}
										/>
									</Div1>
									<small>
										{errors.zip_code && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>{`${errors.zip_code.type}`}</span>
										)}
									</small>
								</DivLeft>
								<DivCenter>
									<Div1>
										<LabelName>Cidade</LabelName>
										<Input
											readOnly
											defaultValue={techEdit?.city}
											placeholder="Cidade"
											{...register('city', { required: true })}
										></Input>
									</Div1>
									<small>
										{errors.city && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Cidade é obrigatório
											</span>
										)}
									</small>
								</DivCenter>
								<DivRight>
									<Div1>
										<LabelName>Estado</LabelName>
										<Select
											id="state-select"
											{...register('state', { required: false })}
										>
											{States.map((item) => (
												<option key={item.value} value={item.initials}>
													{item.initials}
												</option>
											))}
										</Select>
									</Div1>
									<small>
										{errors.state && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Estado é obrigatório
											</span>
										)}
									</small>
								</DivRight>
							</div>

							<Div1>
								<LabelName>Endereço</LabelName>
								<Input
									id="address"
									disabled={disableAddress}
									defaultValue={
										techEdit?.address
											? stringMask(techEdit?.address)
											: changeAddress
									}
									placeholder="Endereço"
									{...register('address', { required: true })}
									onChange={onChangeTechMask}
									onBlur={(ev: React.ChangeEvent<HTMLInputElement>): void =>
										setAddressInfo({
											...addressInfo,
											address: ev.target.value
										})
									}
								/>
							</Div1>
							<small>
								{errors.address && (
									<span
										style={{ color: 'red', display: 'flex', margin: 'auto' }}
									>
										O campo Endereço é obrigatório
									</span>
								)}
							</small>

							<DivContainer>
								<DivLeft2>
									<Div1>
										<LabelName>Complemento (opcional)</LabelName>
										<Input
											id="complement"
											disabled={disableAddress}
											defaultValue={
												techEdit?.complement
													? stringMask(techEdit?.complement)
													: changeComplement
											}
											name="complement"
											placeholder="Complemento"
											onChange={onChangeTechMask}
											onBlur={(ev: React.ChangeEvent<HTMLInputElement>): void =>
												setAddressInfo({
													...addressInfo,
													complement: ev.target.value
												})
											}
										/>
									</Div1>
								</DivLeft2>
								<DivRight2>
									<Div1>
										<LabelName>Bairro</LabelName>
										<Input
											id="district"
											disabled={disableAddress}
											defaultValue={
												techEdit?.district
													? stringMask(techEdit?.district)
													: changeDistrict
											}
											placeholder="Bairro"
											{...register('district', { required: true })}
											onChange={onChangeTechMask}
											onBlur={(ev: React.ChangeEvent<HTMLInputElement>): void =>
												setAddressInfo({
													...addressInfo,
													district: ev.target.value
												})
											}
										/>
									</Div1>
									<small>
										{errors.district && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Bairro é obrigatório
											</span>
										)}
									</small>
								</DivRight2>
							</DivContainer>
							<DivContainer>
								<DivLeft2>
									<Div1>
										<LabelName>Centro de custo</LabelName>
										<Select
											id="ccustoSelect"
											{...register('ccusto', { required: true })}
											defaultValue={techEdit?.ccusto}
										>
											{ccustoTypes.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</Select>
									</Div1>
									<small>
										{errors.ccusto && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Centro de custo é obrigatório
											</span>
										)}
									</small>
								</DivLeft2>
								<DivRight2>
									<Div1>
										<LabelName>Região</LabelName>
										<Select
											id="region"
											{...register('region', { required: true })}
											defaultValue={techEdit?.region}
										>
											{regionTypes.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</Select>
									</Div1>
									<small>
										{errors.region && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Região é obrigatório
											</span>
										)}
									</small>
								</DivRight2>
							</DivContainer>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between'
								}}
							>
								<DivLeft>
									<Div1>
										<LabelName>Latitude</LabelName>
										<Input
											defaultValue={techEdit?.latitude}
											placeholder="-"
											{...register('latitude', { required: true })}
										></Input>
									</Div1>
									<small>
										{errors.latitude && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Latitude é obrigatório
											</span>
										)}
									</small>
								</DivLeft>
								<DivCenter>
									<Div1>
										<LabelName>Longitude</LabelName>
										<Input
											defaultValue={techEdit?.longitude}
											placeholder="-"
											{...register('longitude', { required: true })}
										></Input>
									</Div1>
									<small>
										{errors.longitude && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Longitude é obrigatório
											</span>
										)}
									</small>
								</DivCenter>
								<DivRight>
									<Div1>
										<Link onClick={() => getOriginCoord()}>
											Buscar geolocalização
										</Link>
									</Div1>
								</DivRight>
							</div>
						</div>

						<div className="contact">
							<DivContainer>
								<div style={{ width: '100%' }}>
									<Div1>
										<LabelName>Contato</LabelName>
										<Input
											id="contact"
											defaultValue={
												techEdit?.contact
													? stringMask(techEdit?.contact)
													: changeContact
											}
											placeholder="Contato"
											{...register('contact', { required: true })}
											onChange={onChangeTechMask}
										/>
									</Div1>
									<small>
										{errors.contact && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Contato é obrigatório
											</span>
										)}
									</small>
								</div>
							</DivContainer>

							<DivContainer>
								<DivLeft>
									<Div1>
										<LabelName>Telefone</LabelName>
										<InputMask
											style={{
												width: '100%',
												height: '30px',
												padding: '3px 5px',
												borderStyle: 'solid',
												borderWidth: '1px',
												borderColor: 'rgba(51, 51, 51, 0.65)',
												borderRadius: '4px',
												color: 'rgba(51, 51, 51, 0.7)',
												fontSize: '14px',
												lineHeight: '20px'
											}}
											type="tel"
											mask="(99) 999999999"
											maskChar=""
											placeholder="Telefone 1"
											defaultValue={techEdit?.phone}
											{...register('phone', {
												required: true,
												minLength: 13,
												maxLength: 16
											})}
										/>
									</Div1>
									<small>
										{errors.phone && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo Telefone é obrigatório
											</span>
										)}
									</small>
								</DivLeft>
								<DivCenter>
									<Div1>
										<LabelName>Telefone 2 (opcional)</LabelName>
										<InputMask
											style={{
												width: '100%',
												height: '30px',
												padding: '3px 5px',
												borderStyle: 'solid',
												borderWidth: '1px',
												borderColor: 'rgba(51, 51, 51, 0.65)',
												borderRadius: '4px',
												color: 'rgba(51, 51, 51, 0.7)',
												fontSize: '14px',
												lineHeight: '20px'
											}}
											type="tel"
											mask="(99) 999999999"
											maskChar=""
											placeholder="Telefone 3"
											defaultValue={techEdit?.cel}
											{...register('cel', {
												required: false,
												minLength: 13,
												maxLength: 16
											})}
										/>
									</Div1>
								</DivCenter>
								<DivRight>
									<Div1>
										<LabelName>Whats-app (opcional)</LabelName>
										<InputMask
											style={{
												width: '100%',
												height: '30px',
												padding: '3px 5px',
												borderStyle: 'solid',
												borderWidth: '1px',
												borderColor: 'rgba(51, 51, 51, 0.65)',
												borderRadius: '4px',
												color: 'rgba(51, 51, 51, 0.7)',
												fontSize: '14px',
												lineHeight: '20px'
											}}
											type="tel"
											mask="(99) 999999999"
											maskChar=""
											placeholder="Whats-app"
											defaultValue={techEdit?.phone1}
											{...register('phone1', {
												required: false,
												minLength: 13,
												maxLength: 16
											})}
										/>
									</Div1>
								</DivRight>
							</DivContainer>

							<DivContainer>
								<DivLeft>
									<Div1>
										<LabelName>E-mail 1 </LabelName>
										<Input
											id="email"
											type="email"
											defaultValue={
												techEdit?.email ? techEdit?.email : changeEmail1
											}
											placeholder="E-mail 1"
											{...register('email', { required: true })}
											onChange={onChangeTechMask}
										/>
									</Div1>
									<small>
										{errors.email && (
											<span
												style={{
													color: 'red',
													display: 'flex',
													margin: 'auto'
												}}
											>
												O campo E-mail 1 é obrigatório
											</span>
										)}
									</small>
								</DivLeft>
								<DivCenter>
									<Div1>
										<LabelName>E-mail 2 (opcional)</LabelName>
										<Input
											id="email2"
											type="email"
											placeholder="E-mail 2"
											defaultValue={
												techEdit?.email2
													? stringMask(techEdit?.email2)
													: changeEmail2
											}
											{...register('email2', { required: false })}
											onChange={onChangeTechMask}
										/>
									</Div1>
								</DivCenter>
								<DivRight>
									<Div1>
										<LabelName>E-mail 3 (opcional)</LabelName>
										<Input
											id="email3"
											type="email"
											placeholder="E-mail 3"
											defaultValue={
												techEdit?.email3
													? stringMask(techEdit?.email3)
													: changeEmail3
											}
											{...register('email3', { required: false })}
											onChange={onChangeTechMask}
										/>
									</Div1>
								</DivRight>
							</DivContainer>
							{technicianFieldControl()}
							<Message>
								<LabelName>Mensagem</LabelName>
								<InputArea
									{...register('obs', { required: false })}
									onChange={(e: any) => {
										countMsg(e)
									}}
									defaultValue={obs}
								/>
							</Message>
							<Characters>{msg} caracteres restantes</Characters>
						</div>
					</Content>

					<Footer>
						<Button
							style={{
								marginLeft: '20px',
								border: 'solid',
								borderColor: '#004687',
								borderWidth: 'thin'
							}}
							color="default"
							onClick={handleCancel}
						>
							<div style={{ color: 'rgb(30, 72, 131)', textTransform: 'none' }}>
								Cancelar
							</div>
						</Button>
						<Button
							type="submit"
							style={{
								marginLeft: '20px',
								background: !fieldServiceTechnicianManager
									? 'rgb(131 131 131)'
									: '#004687',
								textTransform: 'none',
								cursor: !fieldServiceTechnicianManager
									? 'not-allowed'
									: 'pointer',
								pointerEvents: 'all'
							}}
							variant="contained"
							color="primary"
							onClick={handleSubmit(onSubmit)}
							disabled={!fieldServiceTechnicianManager}
						>
							Salvar
						</Button>
					</Footer>
				</form>
			</Panel>
			{isLoading && <LoadingProgress />}
		</Container>
	)
}

export default Tech
