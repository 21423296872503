import { Divider } from '@praticabr/ppsa'
import { Order } from 'module/orderManagement/domain/models/order'
import { sumOrderTaxes } from 'module/orderManagement/utils/sumOrderTaxes'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './styles.scss'

export const PaymentTaxesContent = ({ order }: { order?: Order }) => (
	<div className="new-order-payment-contain-information">
		<div className="new-order-payment-contain-taxes">
			<Divider style={{ marginBottom: '15px' }} title="IMPOSTOS" />
			{order?.total_with_taxes ? (
				<div className="taxes">
					<label htmlFor="">
						DIFAL:{' '}
						<span>
							{sumOrderTaxes(order).difal.toLocaleString('pt-br', {
								minimumFractionDigits: 2,
								style: 'currency',
								currency: 'BRL'
							})}
						</span>
					</label>
					<label htmlFor="">
						ICM:{' '}
						<span>
							{sumOrderTaxes(order).icm.toLocaleString('pt-br', {
								minimumFractionDigits: 2,
								style: 'currency',
								currency: 'BRL'
							})}
						</span>
					</label>
					<label htmlFor="">
						PIS:{' '}
						<span>
							{sumOrderTaxes(order).pis.toLocaleString('pt-br', {
								minimumFractionDigits: 2,
								style: 'currency',
								currency: 'BRL'
							})}
						</span>
					</label>
					<label htmlFor="">
						CONFINS:{' '}
						<span>
							{sumOrderTaxes(order).confins.toLocaleString('pt-br', {
								minimumFractionDigits: 2,
								style: 'currency',
								currency: 'BRL'
							})}
						</span>
					</label>
					<label htmlFor="">
						IPI:{' '}
						<span>
							{sumOrderTaxes(order).ipi.toLocaleString('pt-br', {
								minimumFractionDigits: 2,
								style: 'currency',
								currency: 'BRL'
							})}
						</span>
					</label>
				</div>
			) : (
				<div className="taxes">
					<Skeleton height={21} />
					<Skeleton height={21} />
					<Skeleton height={21} />
					<Skeleton height={21} />
					<Skeleton height={21} />
				</div>
			)}
		</div>
	</div>
)
