import { LoadingProgress } from '@praticabr/ppsa'
import { debounce } from 'lodash'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { getAccessoriesByValue } from 'module/orderManagement/services'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Search from 'shared/components/Search'
import { ViewType } from 'utils/Constants'
import { ItensDetailsListAccessoriesBodyEditingMode } from './ItensDetailsListAccessoriesBody/ItensDetailsListAccessoriesBodyEditingMode'
import { AccessoriesListHeader } from './ItensDetailsListAccessoriesHeader'
import './styles.scss'

interface Props {
	onClose: () => void
	itemSelect?: any
	onChangeAccessories: (accessories: any) => void
}
export const ItensDetailsListAccessoriesEditingMode = ({
	onClose,
	itemSelect,
	onChangeAccessories
}: Props) => {
	const { viewType } = useContext(DeviceContextElement)
	const [listAccessories, setListAccessories] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const { register } = useForm()

	const getAccessories = (value: string) => {
		setIsLoading(true)
		getAccessoriesByValue(value, 'A77')
			.then((res) => {
				const { accessories } = res.data
				setListAccessories(accessories)
			})
			.catch(() => toast.error('Erro ao buscar a lista de acessórios!'))
			.finally(() => setIsLoading(false))
	}

	useEffect(() => {
		getAccessories('')
	}, [])

	const handleSearchInput = debounce((value: string) => {
		getAccessories(value?.toUpperCase())
	}, 600)

	const onSearch = async (value: string) => {
		handleSearchInput(value)
	}

	return (
		<>
			<div className="new-accessories-container">
				<div className="accessories-search-container">
					<Search
						name="accessories-search"
						placeholder="Buscar Produto"
						variant="light"
						onChangeValues={() => {}}
						innerRef={{
							...register('order_product', {
								onChange(event) {
									onSearch(event.target.value)
								}
							})
						}}
					/>
					<div className="order-item-name">
						<span>Produto: </span>
						<p className="order-item-name-value">
							{String(`${itemSelect?.code} - ${itemSelect?.description}`)}
						</p>
					</div>
				</div>

				{viewType !== ViewType.MOBILE && <AccessoriesListHeader />}
				<ItensDetailsListAccessoriesBodyEditingMode
					{...{ onChangeAccessories, onClose }}
					accessories={listAccessories}
					itemSelect={itemSelect}
				/>
			</div>
			{isLoading && <LoadingProgress />}
		</>
	)
}
