import { Button, LoadingProgress, Modal } from '@praticabr/ppsa'
import Client from 'models/Client'
import Equipment from 'models/Equipment'
import Equipments from 'module/technicalServices/components/Equipments'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { resendEmailTech } from 'services/index'
import { Footer, Header } from './styles'
import './styles.scss'

interface Props {
	onClose: (value: boolean) => void
	equipment?: Equipment
	client?: Client
	techAssistId?: number
}

const ResendEmail: React.FC<Props> = ({
	onClose,
	client,
	equipment,
	techAssistId
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const onResendEmail = () => {
		if (techAssistId) {
			setIsLoading(true)
			resendEmailTech(techAssistId, { email_tech: null })
				.then(() => {
					setIsLoading(true)
					handleOnClose()
					toast.success('Email enviado com sucesso.')
				})
				.catch(() => {
					setIsLoading(true)
					toast.error('Erro ao encerrar ordem de serviço.')
				})
		}
	}

	const handleOnClose = () => {
		onClose(false)
	}

	return (
		<Modal.root>
			<Modal.header onClose={handleOnClose} title="Reenviar e-mail" />
			<Modal.body>
				<Modal.body.main>
					<div className="service-order-re-send-email">
						<Header>
							<Equipments client={client} equipment={equipment} />
						</Header>

						<div style={{ margin: '20px 0px', fontSize: '15px' }}>
							Deseja reenviar o e-mail do atendimento para o técnico?
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<Footer>
						<Button
							style={{ width: '120px' }}
							variant="confirmation-solid"
							size="lg"
							title="Enviar"
							onClick={onResendEmail}
						/>
						<Button
							style={{ width: '120px' }}
							variant="cancellation"
							size="lg"
							title="Cancelar"
							onClick={handleOnClose}
						/>
					</Footer>
				</Modal.body.actions>
			</Modal.body>
			{isLoading && <LoadingProgress text="Carregando..." />}
		</Modal.root>
	)
}

export default ResendEmail
