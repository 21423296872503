import { Button } from '@praticabr/ppsa'
import { VersionContextElement } from 'module/entryPoint/context/VersionContext/VersionContextType'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import newVersionImage from '../../assets/image/new-version.png'
import './style.scss'

export const UpdateNotification: React.FC = () => {
	const { onUpdateClick, version, show } = useContext(VersionContextElement)
	const [showNotification, setShowNotification] = useState<boolean>(show)
	const location = useLocation()

	useEffect(() => {
		setShowNotification(show)
	}, [location.pathname, show])

	const handleOpenRelease = () => {
		window.open(version?.data)
	}

	return (
		<div
			className={`update-notification-container ${
				showNotification ? 'show' : ''
			}`}
		>
			<div className="update-notification-content">
				<div className="update-notification-body">
					<div className="update-notification-image">
						<img src={newVersionImage} alt="new-version-image" />
					</div>
					<label>Temos uma nova versão!</label>
					<p>
						Melhorias e novidades já estão disponíveis nesta nova versão! Para
						saber mais,{' '}
						<span
							className="update-notification-click"
							onClick={handleOpenRelease}
						>
							clique aqui
						</span>
					</p>

					<div className="update-notification-action">
						<Button
							size="sm"
							title="Atualizar Agora"
							variant="confirmation-solid"
							onClick={onUpdateClick}
						/>
						<Button
							size="sm"
							title="Lembrar Depois"
							variant="outline"
							onClick={() => setShowNotification(false)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
