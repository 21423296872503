import { PrpOrderEvents } from 'module/orderManagement/models/PrpOrderModel'
import { handleCommentaryTypes } from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import React from 'react'

type PropsEvent = {
	eventList: PrpOrderEvents[]
}

export const EventList = ({ eventList }: PropsEvent) => {
	const showAttachments = (attachments: any[]) => {
		return (
			<span className="show-attachments">
				{attachments.map((attachment, index) => {
					return (
						<button
							key={`${attachment?.file?.id}${index}`}
							onClick={() => window.open(attachment?.file?.link)}
							disabled={!!attachment.deletedAt || !attachment?.file?.link}
							className={`attachment-button ${
								attachment.deletedAt || !attachment?.file?.link ? 'deleted' : ''
							}`}
						>
							Anexo {index + 1}
						</button>
					)
				})}
			</span>
		)
	}

	return (
		<>
			{eventList
				?.filter((event) => event?.type !== 'attachment')
				?.map((event, index) => (
					<div key={index} className="event">
						<div className="map">
							<div className="line" />
							<div className="ball" />
						</div>
						<div className="info">
							<span className="event-date">
								{new Date(event?.createdAt)?.toLocaleString()}
							</span>
							<span className="event-title">
								{handleCommentaryTypes(event?.type)}
							</span>
							<span className="event-content">{event?.content}</span>

							<span className="event-user">
								{event?.followUpAt
									? `Data para follow up: ${
											event?.followUpAt
												? moment(event?.followUpAt).format('DD/MM/YY')
												: '-'
									  } | ${event?.user?.name} ${
											event?.user?.surname ? event?.user?.surname : ''
									  }`
									: `${event?.user?.name} ${
											event?.user?.surname ? event?.user?.surname : ''
									  }`}
							</span>

							{!!event?.attachments?.length &&
								showAttachments(event?.attachments)}
						</div>
					</div>
				))}
		</>
	)
}
