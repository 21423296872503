import { ConfirmModal, LoadingProgress } from '@praticabr/ppsa'
import { MoreOptionsItem } from 'models/MoreOptionsItem'
import RequestResponse from 'models/RequestResponse'
import { Transfer } from 'models/Transfer'
import MoreOptions from 'module/technicalServices/components/MoreOptions'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import moment from 'moment-timezone'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { store } from 'store'
import { TransferOrigin, TransferStatus } from 'utils/Constants'
import './styles.scss'

interface Params {
	transfer: Transfer
	onItemClick: (transfer: Transfer) => void
}

const TransfersListItem: React.FC<Params> = ({ transfer, onItemClick }) => {
	const { onMeetTransfer, onRemoveTransfer } = useContext(
		TransfersContextElement
	)

	const [isLoading, setLoading] = useState<boolean>(false)
	const [loadingMessage, setLoadingMessage] = useState<string>('')

	const [showMeetModal, setShowMeetModal] = useState<boolean>(false)
	const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)

	const onMeetClick = () => {
		setLoadingMessage('Atualizando Transferência')
		setShowMeetModal(true)
	}

	const onMeetCancel = () => {
		setShowMeetModal(false)
	}

	const meetTransfer = () => {
		setLoading(true)
		const userId = store.getState().user.profile
		onMeetTransfer(transfer.id, userId, onMeetComplete)
	}

	const onMeetComplete = (request: RequestResponse) => {
		setLoading(false)
		onMeetCancel()
		if (request.reportError) {
			toast.error('Erro ao alterada transferência')
		} else {
			toast.success('Transferência alterada com sucesso!')
		}
	}

	const onRemoveClick = () => {
		setLoadingMessage('Excluindo Transferência')
		setShowRemoveModal(true)
	}

	const onRemoveCancel = () => {
		setShowRemoveModal(false)
	}

	const removeTransfer = () => {
		setLoading(true)
		onRemoveTransfer(transfer.id, onRemoveComplete)
	}

	const onRemoveComplete = (request: RequestResponse) => {
		setLoading(false)
		onRemoveCancel()
		if (request.reportError) {
			toast.error('Erro ao remover solicitação de transferência')
		} else {
			toast.success('Transferência removida com sucesso!')
		}
	}

	const transferDateContent = () => {
		return transfer.transferAt ? (
			<span>{moment(transfer.transferAt).format('DD/MM/YYYY')}</span>
		) : (
			<span>--</span>
		)
	}

	const onClick = () => {
		try {
			transfer.transferPayloadParsed = JSON.parse(transfer.transferPayload)
			onItemClick(transfer)
		} catch (error) {
			toast.error('Falha ao carregar detalhes da transferência')
		}
	}

	const meetModalContent = () => {
		return (
			showMeetModal && (
				<ConfirmModal
					title="TRANSFERÊNCIA"
					onCancel={onMeetCancel}
					onConfirm={meetTransfer}
					text={<>Deseja atender essa transferência?</>}
				/>
			)
		)
	}

	const removeModalContent = () => {
		return (
			showRemoveModal && (
				<ConfirmModal
					title="TRANSFERÊNCIA"
					onCancel={onRemoveCancel}
					onConfirm={removeTransfer}
					text={<>Deseja remover essa transferência?</>}
				/>
			)
		)
	}

	const loadingContent = () => {
		return <LoadingProgress text="Carregando..." />
	}

	const menuOptionsContent = () => {
		const menuOptionsList = [
			{ label: 'Atender', onClick: onMeetClick },
			{ label: 'Excluir', onClick: onRemoveClick }
		] as MoreOptionsItem[]
		return <MoreOptions options={menuOptionsList} />
	}

	const originContent = () => {
		switch (transfer.origin) {
			case TransferOrigin.APP:
				return 'Aplicativo'
			default:
				return 'Manual'
		}
	}

	const statusContent = () => {
		switch (transfer.status) {
			case TransferStatus.OPEN:
				return 'Pendente'
			case TransferStatus.IN_PROGRESS:
				return 'Em andamento'
			case TransferStatus.FINISHED:
				return 'Concluída'
			default:
				return 'Removida'
		}
	}

	return (
		<>
			<div className="transfer-list-item" onClick={onClick}>
				<div className="transfer-info">
					<div className="date-solicitation">
						<span>{moment(transfer.createdAt).format('DD/MM/YYYY')}</span>
					</div>
					<div className="serial">
						<span>{transfer.serialNumber}</span>
					</div>
					<div className="origin">
						<span>{originContent()}</span>
					</div>
					<div className="client-name">
						<span>{transfer.currentClientName}</span>
					</div>
					<div className="equip-name">
						<span>{transfer.equipmentName}</span>
					</div>
					<div className="date-transfer">{transferDateContent()}</div>
					<div className="status">
						<span>{statusContent()}</span>
					</div>
					<div className="user">
						<span>{transfer.userName ? transfer.userName : `--`}</span>
					</div>
					<div id="joyride-transfer-options" className="options">
						{menuOptionsContent()}
					</div>
				</div>
			</div>
			{meetModalContent()}
			{removeModalContent()}
			{isLoading && loadingContent()}
		</>
	)
}

export default TransfersListItem
