import { Button } from '@praticabr/ppsa'
import check from 'assets/image/ic_check.png'
import React from 'react'

import { NewModal } from '../../_newModal'
import './styles.scss'

type Props = {
	osNumber?: string
	onClick: () => void
}

export const ModalSuccessOpenOs = ({ osNumber, onClick }: Props) => {
	return (
		<NewModal onClose={onClick} awayClose={true}>
			<div className="msg msg-container container-flex">
				<img src={check} className="icon-confirm" alt="" />
				<section className="container-text-confirm">
					<h2>
						Solicitação <strong>nº {osNumber}</strong> criada com sucesso!
					</h2>
					<p>
						Em até 24 horas um de nossos atendentes entrará em contato para
						agendar o serviço, enviaremos mais informações para seu e-mail.
					</p>
				</section>
			</div>
			<div
				style={{
					display: 'block',
					marginTop: '30px',
					width: 'fit-content'
				}}
			>
				<Button
					title="Novo atendimento"
					variant="confirmation-solid"
					size="lg"
					onClick={onClick}
					style={{
						width: '190px',
						borderRadius: '1px',
						textTransform: 'uppercase',
						fontWeight: '600'
					}}
				/>
			</div>
		</NewModal>
	)
}
