import TextField from '@material-ui/core/TextField'
import { Button, Modal } from '@praticabr/ppsa'
import ServiceOrdersResponse from 'models/ServiceOrderResponse'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { getDeleteOS } from 'services'
import { store } from 'store'
import { KeyedMutator } from 'swr'
import {
	ClientName,
	ClientNameTitle,
	Description,
	Footer,
	FormError,
	Header,
	Info,
	Line,
	SerialNumber,
	Title
} from './styles'
import './styles.scss'

interface Props {
	mutate: KeyedMutator<any>
	serviceOrder: ServiceOrdersResponse
	onClose: () => void
}

const DeleteOS: React.FC<Props> = ({ mutate, serviceOrder, onClose }) => {
	const [obsErrorMessage, setObsErrorMessage] = useState<boolean>(false)

	function DelOS() {
		const obs = $('#obsdelete').val()
		if (obs) {
			const { profile } = store.getState().user

			const json = {
				os_code: serviceOrder.service_order,
				order_id: '#id#',
				user_id: profile,
				origin: 'admin',
				obs
			}

			const resp = getDeleteOS(json)
			resp
				.then((resp) => {
					const { data } = resp

					if (data.statusCode == '200') {
						toast.success('Ordem de serviço excluída com sucesso.')
						mutate()
						onClose()
					}
				})
				.catch(() => {
					toast.error('Erro ao excluir ordem de serviço.')
				})

			$('#obsdelete').val('')
		} else {
			setObsErrorMessage(true)
		}
	}

	const onObsChange = () => {
		if ($('#obsdelete').val()) {
			setObsErrorMessage(false)
		} else {
			setObsErrorMessage(true)
		}
	}

	return (
		<Modal.root>
			<Modal.header title="Excluir atendimento" onClose={onClose} />
			<Modal.body>
				<Modal.body.main>
					<Header>
						<div>Protocolo: {serviceOrder.service_order}</div>
						<Title className="align-header">
							{serviceOrder.equipment.name}
						</Title>

						<SerialNumber>
							<Description>Número de série:</Description>
							<Info>{serviceOrder.equipment.serial_number}</Info>
						</SerialNumber>
						<Line>
							<ClientNameTitle>Cliente:</ClientNameTitle>
							<ClientName>{serviceOrder.client.nome_fantasia}</ClientName>
						</Line>
					</Header>

					<div className="delete-os-warning">
						Ao excluir este atendimento, tenha em mente que o mesmo também será
						excluído no aplicativo do cliente, veja a necessidade de reporta-lo
						antes de seguir.
					</div>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<TextField
							id="obsdelete"
							label="Motivo da Exclusão"
							style={{ width: '650px' }}
							fullWidth={true}
							margin="normal"
							multiline={true}
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							onChange={onObsChange}
						/>

						{obsErrorMessage && (
							<FormError>Motivo da Exclusão é obrigatório!</FormError>
						)}
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<Footer>
						<Button
							style={{ width: '126px' }}
							size="lg"
							title="Confirmar"
							variant="confirmation-solid"
							onClick={() => {
								DelOS()
							}}
						/>
						<Button
							style={{ width: '126px' }}
							size="lg"
							title="Cancelar"
							variant="cancellation"
							onClick={onClose}
						/>
					</Footer>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}

export default DeleteOS
