import axios, { AxiosResponse, CreateAxiosDefaults } from 'axios'
import { ClientPrp } from 'models/ClientPrp'
import Department from 'models/Department'
import Filter from 'models/Filter'
import InstanceOS from 'models/InstanceOS'
import LoginInfo from 'models/LoginInfo'
import { NotificationTypes } from 'models/notificationProfile'
import PageInfo from 'models/PageInfo'
import PageInfoTech from 'models/PageInfoTech'
import { RecognitionCard } from 'models/RecognitionCard'
import { ResponseServer } from 'models/ResponseServer'
import Sa1010Overview from 'models/Sa1010Overview'
import Sc5010 from 'models/Sc5010'
import TechniciansInfo from 'models/TechniciansInfo'
import TechSpecialty from 'models/TechSpecialty'
import { TransferInput, TransferUpdate } from 'models/Transfer'
import User from 'models/User'
import { AppVersionsResponse } from 'module/adminSystem/pages/VersionApps'
import { PrpOrder } from 'module/orderManagement/models/PrpOrderModel'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'
import { RouteList } from 'utils/Constants'
import handleCleanCacheCookies from 'utils/handle-clean-cache-cookies'

export const config: CreateAxiosDefaults = {
	baseURL: process.env.REACT_APP_API_URL,
	headers: { accept: 'application/json', 'content-type': 'application/json' },
	timeout: 40000,
	responseType: 'json',
	withCredentials: true,
	validateStatus: (status: number) => status >= 200 && status < 300,
	transformResponse: (response, headers) => {
		if (headers['content-type']?.includes('application/json')) {
			response = JSON.parse(response)
			if (response?.error) {
				console.error(response.error)
				if (
					response?.error?.message === 'Authorization Required' &&
					response?.error?.statusCode === 401
				) {
					handleCleanCacheCookies()
					location.replace(RouteList.HOME)
				}
			}
		}
		return response
	}
}

export const api = axios.create(config)

export const uploadFile = axios.create({
	baseURL: process.env.REACT_APP_API_URL
})

// appRoleMappings //
export const getPermissions = async (id: number) =>
	api.get(
		`appRoleMappings?filter=%7B%22where%22%3A%20%7B%22principalId%22%3A%20%22${id}%22%7D%7D`
	)

// clients //
export const getClientInfo = async (code: any) =>
	api.get(`clients/getClientInfo?clientCode=${code}`)
export const getClientAddress = async (clientCode: string) =>
	api.get(`clients/address?clientCode=${clientCode}`)
export const createClient = async (client: any) =>
	api.post(`clients/register`, client)
export const getClientLendingByClientId = async (client_id: number) =>
	api.get(`clients/getClientLending?id=${client_id}`)

// departments //
export const getAllDepartments = async (): Promise<
	AxiosResponse<Department[]>
> => api.get(`departments?filter[order]=description%20ASC`)

// equipments //
export const updateEquipPrev = async (
	updated: object,
	serial: string
): Promise<AxiosResponse<ResponseServer>> =>
	api.post(
		`equipments/update?where=%7B%22serial_number%22%3A%20%22${serial}%22%7D`,
		updated
	)
export const getEquipmentsBySerial = async (serial_number?: string) =>
	api.get(`equipments/getEquipmentsBySerial?serial_number=${serial_number}`)
export const updateEquipmentById = async (id: number, family_prod: any) =>
	api.patch(`equipments/${id}`, family_prod)
export const techAssistDeleteById = async (id: number) =>
	api.delete(`technicalAssistances/${id}`)

// errorLogs //
export const registerErrorLog = async (
	origin: string,
	stack: string,
	request: string
) => api.post(`errorLogs`, { origin, stack, request })

// filters //
export const saveFilter = async (data: any) => {
	if (data.id === 0) {
		return api.post(`filters`, data)
	} else {
		return api.put(`filters`, data)
	}
}

export const getFilters = async (
	userId: any,
	module: string
): Promise<AxiosResponse<Filter[]>> =>
	api.get(
		`filters?filter=%7B%22where%22%3A%7B%22user_id%22%3A%22${userId}%22%2C%22module%22%3A%22${module}%22%7D%7D`
	)
export const removeFilter = async (id: number) => api.delete(`filters/${id}`)

// locations //
export const createLocation = async (location: any) =>
	api.post(`locations/register`, location)
export const deleteLocation = async (data: any) =>
	api.delete(`locations/deleteLocation?id=${data}`)
export const getClientLocations = async (
	code: any,
	limit?: number,
	offset?: number
) => {
	let limitQuery = ''
	if (limit?.toString()) {
		limitQuery += `&limit=${limit}`
	}
	let offsetQuery = ''
	if (offset?.toString()) {
		offsetQuery += `&offset=${offset}`
	}
	return api.get(`locations/client/?code=${code}${limitQuery}${offsetQuery}`)
}

// location_field_control //
export const getLocationById = async (locationId: number) =>
	api.get(`LocationsFieldControl/${locationId}`)

// notificationProfiles //
export const getNotificationsProfile = async (userId: number) =>
	api.get(`notificationProfiles/getProfile?userId=${userId}`)
export const setNotificationsProfile = async (data: any) =>
	api.put(`notificationProfiles/setProfile`, data)

// notificationTypes //
export const getNotificationsTypes = async (): Promise<
	AxiosResponse<NotificationTypes[]>
> => api.get(`notificationTypes/`)

// notifications //
export const getAllNotifications = async (
	userId: number,
	limit?: number,
	offset?: number,
	dateOrder?: string,
	statusOrder?: string,
	search?: string
) => {
	let query = ''
	if (limit) {
		query += `&limit=${limit}`
	}
	if (offset) {
		query += `&offset=${offset}`
	}
	if (dateOrder) {
		query += `&dateOrder=${dateOrder}`
	}
	if (statusOrder) {
		query += `&statusOrder=${statusOrder}`
	}
	if (search) {
		query += `&search=${encodeURIComponent(search)}`
	}
	return api.get(`notifications/getAllNotifications?userId=${userId}${query}`)
}

export const updateNotificationStatus = async (
	id: number,
	isReading?: boolean
) => {
	let query = `id=${id}`
	if (isReading) {
		query += `&isReading=${isReading}`
	}
	return api.put(`notifications/updateStatus?${query}`)
}

export const updateAllNotificationStatus = async (userId: number) =>
	api.put(`notifications/updateAllStatus?userId=${userId}`)
export const removeNotification = async (id: number) =>
	api.delete(`notifications/${id}`)

// PartRequests //
export const getWarehouseBalance = async (filial: any, prod: string) =>
	api.get(`Sb2010s/getWarehouseBalance?filial=${filial}&prod=${prod}`)
export const getAverageCost = async (filial: any, prod: string, local: any) =>
	api.get(`Sb2010s/getAverageCost?filial=${filial}&prod=${prod}&local=${local}`)
export const postPartRequest = async (data: any) =>
	api.post(`PartRequests/register`, data)
export const getPartRequest = async (order: any) =>
	api.get(
		`PartRequests?filter=%7B%22where%22%3A%7B%22id%22%3A%22${order}%22%7D%7D`
	)
export const updatePartRequest = async (id: any, data: any) =>
	api.post(`PartRequests/update?where=%7B%22id%22%3A%20${id}%7D`, data)

// preventive_contracts //
export const clientPreventive = async (clientId: number) =>
	api.get(`preventive_contracts/client?id=${clientId}`)
export const getClientPreventiveContract = async (clientId: number) =>
	api.get(
		`preventive_contracts?filter=%7B%22where%22%3A%20%7B%22id_client%22%3A%20%22${clientId}%22%7D%7D`
	)
export const createPreventive = async (preventive: any) =>
	api.post(`preventive_contracts/register`, preventive)
export const updatePreventive = async (preventive: any) =>
	api.put(`preventive_contracts/updateContract`, preventive)
export const removePreventive = async (clientId: any) =>
	api.delete(`preventive_contracts/deleteContract?id=${clientId}`)
export const getPrevContracts = async (
	search: string,
	offset: number,
	limit: number
) =>
	api.get(
		`preventive_contracts/getAll?limit=${limit}&offset=${offset}&search=${encodeURIComponent(
			search
		)}`
	)

// recognition_cards //
export const getCardsFromUser = async (
	id: number
): Promise<AxiosResponse<RecognitionCard[]>> =>
	api.get(`recognition_cards/getCardsFromUser?id=${id}`)
export const getCardsToUser = async (
	id: number
): Promise<AxiosResponse<RecognitionCard[]>> =>
	api.get(`recognition_cards/getCardsToUser?id=${id}`)
export const postRecognitionCards = async (data: any) =>
	api.post(`recognition_cards`, data)

// reported_defects //
export const getDefect = async () => api.get(`reported_defects`)

// sent_cards //
export const getSentCards = async (UserId: any, Date: any) =>
	api.get(
		`sent_cards?filter=%7B%22where%22%3A%7B%22userId%22%3A%22${UserId}%22%2C%20%22date%22%3A%22${Date}%22%7D%7D`
	)
export const postSentCards = async (data: any) => api.post(`sent_cards`, data)
export const updateSentCards = async (id: any, date: any, data: any) =>
	api.post(
		`sent_cards/update?where=%7B%22userId%22%3A${id}%2C%22date%22%3A%22${date}%22%7D`,
		data
	)

// ServiceOrders //
export const getVldDelete = async (osNumber: string) =>
	api.get(`ServiceOrders/getVldDelete?os=${osNumber}`)
export const getDeleteOS = async (data: object) =>
	api.post(`ServiceOrders/deleteOS`, data)
export const getOpenOs = async (equipment: any, osNumber: any) =>
	api.get(
		`ServiceOrders/getOpenOs?serie=${equipment.serialNumber}&clientCode=${equipment.clientCode}&osnumber=${osNumber}`
	)

export const createNewServiceOrder = async (os: any) =>
	api.post(`serviceOrders/register`, os)
export const getServiceOrderId = async (): Promise<AxiosResponse<number>> =>
	api.get(`serviceOrders/getServiceOrderId`)
export const selectAllServicesOrdersByCode = async (
	code: string
): Promise<AxiosResponse<InstanceOS>> =>
	api.get(`serviceOrders/getOSInfo?code=${code}`)
export const getAllOSInfo = async (pageInfo: PageInfo) =>
	api.get(`serviceOrders/getAllOSInfo?pageInfo=${JSON.stringify(pageInfo)}`)
export const updateServiceOrder = async (updated: object) =>
	api.put(`serviceOrders/updateServiceOrder`, updated)
export const takeOs = async (updated: object) =>
	api.post(`serviceOrders/takeOs`, updated)
export const getPrevSchedules = async (pageInfo: any) =>
	api.get(`serviceOrders/getPrevSchedules?pageInfo=${JSON.stringify(pageInfo)}`)
export const getTodayOS = async () => api.get(`serviceOrders/getLatestOS?`)
export const getServiceHistory = async (serial_number: string) =>
	api.get(`serviceOrders/getServiceHistory?serial_number=${serial_number}`)
export const getEquipDeviation = async (serial_number: string) =>
	api.get(`installedBases/getEquipDeviation?serial_number=${serial_number}`)

// specialties //
export const getSpecialties = async (): Promise<
	AxiosResponse<TechSpecialty[]>
> => api.get(`specialties`)

// tech_assistance_files //
export const getBase64 = async (id_img: number) =>
	api.get(
		`tech_assistance_files?filter=%7B%22where%22%3A%20%7B%22id%22%3A%20%22${id_img}%22%7D%7D`
	)

export const onUpdateFile = async (data: {
	id: number
	attachment_category: string
}) => {
	api.post(`Files/customUpdate`, data)
}

// technicalAssistances //
export const techAssist = async (techAssist: any) =>
	api.post(`technicalAssistances/register`, techAssist)
export const integrateWithFieldControl = async () =>
	api.post(`technicalAssistances/integrateWithFCForce`)

export const registerFollowUp = async (techAssist: any) =>
	api.post(`technicalAssistances/registerFollowUp`, techAssist)
export const registerClosingOs = async (techAssist: any) =>
	api.post(`technicalAssistances/registerClosingOs`, techAssist)
export const registerAuthorization = async (techAssist: any) =>
	api.post(`technicalAssistances/registerAuthorization`, techAssist)
export const updateTech = async (updated: object) =>
	api.post('technicalAssistances/changeTech', updated)
export const resendEmailTech = async (id: number, data: object) =>
	api.post(`technicalAssistances/update?where=%7B%22id%22%3A${id}%7D`, data)

// technicians //
export const saveTechnician = async (data: any) =>
	api.post(`technicians/register`, data)
export const updateTechnician = async (data: any) =>
	api.put(`technicians/updateTechnician`, data)
export const requestCompanyListFieldControl = async () =>
	api.get(`technicians/getCompanyListFieldControl`)
export const getAllTechs = async (
	pageInfo: PageInfoTech
): Promise<AxiosResponse<TechniciansInfo>> =>
	api.get(`technicians/getAllTechs?pageInfo=${JSON.stringify(pageInfo)}`)
export const getAllTechsInfo = async (
	pageInfo: PageInfoTech
): Promise<AxiosResponse<TechniciansInfo>> =>
	api.get(`technicians/getAllTechsInfo?pageInfo=${JSON.stringify(pageInfo)}`)
export const getAllTechsInfoCount = async (
	pageInfo: PageInfoTech
): Promise<AxiosResponse<TechniciansInfo>> =>
	api.get(
		`technicians/getAllTechsInfoCount?pageInfo=${JSON.stringify(pageInfo)}`
	)
export const getTechListBySpeciality = async (
	type_code: any,
	state_sigla: any,
	origin: any,
	userId: number,
	search = ''
) =>
	api.get(
		`technicians/getTechListBySpeciality?code=${type_code}&state=${state_sigla}&origin=${origin}&userId=${userId}&search=${encodeURIComponent(
			search
		)}`
	)
export const getTechListForPreventive = async (search = '') =>
	api.get(
		`technicians/getTechListForPreventive?search=${encodeURIComponent(search)}`
	)
export const isTokenValid = async (token: string) =>
	api.get(`technicians/checkForToken?token=${token}`)
export const getLastTechnician = async (osnumber: string) =>
	api.get(`technicians/getLastTechnician?os=${osnumber}`)
export const registerEmployeeFieldControl = async (data: any) =>
	api.post(`technicians/register_employee_field_control`, data)

// transfers //
export const getTransfers = async (
	limit: number,
	offset: number,
	search?: string
) => {
	let searchQuery = ''
	if (search) {
		searchQuery += `&search=${search}`
	}

	return api.get(
		`transfers/getTransfers?limit=${limit}&offset=${offset}${searchQuery}`
	)
}

export const createTransfer = async (transferInput: TransferInput) =>
	api.post(`transfers/register`, transferInput)
export const updateTransfer = async (transferUpdate: TransferUpdate) =>
	api.put(`transfers/updateTransfer`, transferUpdate)
export const meetTransfer = async (transferId: number, userId: number) =>
	api.put(`transfers/meetTransfer`, { transferId, userId })
export const removeTransfer = async (transferId: number) =>
	api.delete(`transfers/removeTransfer?id=${transferId}`)
export const verifyClientTransfer = async (
	cpfCnpj: string,
	transferId: number
) =>
	api.get(
		`transfers/verifyClientTransfer?cpfCnpj=${cpfCnpj}&transferId=${transferId}`
	)
export const getTransferClient = async (search: string) =>
	api.get(`transfers/getTransferClient?search=${search}`)
export const getTransferEquip = async (search: string) =>
	api.get(`transfers/getTransferEquip?search=${search}`)

// two_factors_auths //
export const getTwoFactor = async (code: any) =>
	api.get(
		`two_factors_auths?filter=%7B%22where%22%3A%20%7B%22code_auth%22%3A%20%22${code}%22%7D%20%7D`
	)

// users //
export const login = async (
	email: string,
	password: string
): Promise<AxiosResponse<LoginInfo>> =>
	api.post('users/login', { email, password })
export const userInfo = async (userId: string): Promise<AxiosResponse<User>> =>
	api.get(`users/${userId}`)
export const updateUserToken = async (
	deviceToken: any,
	userId: number
): Promise<AxiosResponse<User>> =>
	api.post(`users/deviceToken`, { deviceToken, userId })
export const updateUser = async (user: any, id: number) => {
	user.id = id
	return api.put(`users/updateUser`, user)
}
export const createNewUser = async (data: any) =>
	api.post(`users/customRegister`, data)
export const updateUserById = async ({
	userId,
	data
}: {
	userId: number
	data: any
}) => api.put(`users/customUpdateById`, data, { params: { userId } })
export const getEmployees = async () => api.get(`users/getAllActiveEmployees`)
export const usersPlatforms = async (data: any) => api.get(`users/${data}`)
export const requestNewPass = async (data: any) =>
	api.post(`users/requestNewPass`, data)
export const RecoveryPass = async (data: any) =>
	api.post(`users/recovery`, data)
export const newPass = async (data: any) => api.post(`users/newPass`, data)
export const requestUserIdFieldControl = async (email: string) =>
	api.get(`users/request_user_id_field_control?email=${email}`)
export const updateTutorialsList = async (data: any) =>
	api.post(`users/updateTutorialsList`, data)
export const clearTutorialsList = async (userId: string) =>
	api.post(`users/clearTutorialsList/${userId}`, userId)

// installedBases //
export const getSerialInfo = async (equipment: any, origin: any) =>
	api.get(
		`installedBases/search?numserie=${equipment.serialNumber}&clientCode=${equipment?.clientCode}&equipmentRecno=${equipment?.equipmentRecno}&origin=${origin}`
	)
export const searchMultipleEquipments = async (serialNumber: string) =>
	api.get(
		`installedBases/searchMultipleEquipments?serialNumber=${serialNumber}`
	)
export const getClientEquips = async (
	code: any,
	limit: number,
	offset: number,
	search: string
) =>
	api.get(
		`installedBases/client?code=${code}&limit=${limit}&offset=${offset}&search=${encodeURIComponent(
			search
		)}`
	)

export const searchClients = async (
	value: string,
	limit: number,
	offset: number
) =>
	api.get(
		`installedBases/searchClients?value=${value}&limit=${limit}&offset=${offset}`
	)

// Da1010s //
export const getPrice = async (id: any, code: any) =>
	api.get(
		`Da1010s?filter=%7B%22where%22%3A%7B%22da1Codtab%22%3A%22${id}%22%2C%20%22da1Codpro%22%3A%22${code}%22%2C%20%22dELET%22%3A%22%22%7D%7D`
	)

// products //
export const getProdDescri = async (code: any) =>
	api.get(
		`Sb1010s?filter=%7B%22fields%22%3A%20%7B%22b1Desc%22%3A%20true%20%7D%2C%22where%22%3A%7B%22b1Cod%22%3A%22${code}%22%7D%7D`
	)

// cardEmails //
export const postEmailCard = async (data: any) =>
	api.post(`cardEmails/send`, data)

// types //
export const registerTypeOnPrp = async (data: any) =>
	api.post(`Types/register`, data)
export const getTypes = async (table: string) =>
	api.get(`types?filter=%7B%22where%22%3A%7B%22table%22%3A%22${table}%22%7D%7D`)
export const getTypeByKey = async (table: string, key: string) =>
	api.get(
		`types?filter=%7B%22where%22%3A%7B%22table%22%3A%22${table}%22%2C%22key%22%3A%22${key}%22%20%7D%7D`
	)

// prp //
export const getZipCodeInfo = async (zipcode: any) =>
	api.get(`prp/address/zipcode?zipcode=${zipcode}`)
export const getGeolocation = async (address: any) =>
	api.get(`prp/address/geoLocation?address=${address}`)

// AppVersions //
export const getLastVersion = async () =>
	api.get(`appsVersions/getLastVersion?app=PRP`)

export const updateOrder = async (branch: string, order: string, data: any) =>
	api.put(`Sc5010s/UpdateSc5010?branch=${branch}&order=${order}`, data)

export const simulateOrder = async (data: any) =>
	api.post(`Sc5010s/SimulateSc5010`, data)

export const createOrder = async (data: any) =>
	api.post(`Sc5010s/CreateSc5010`, data)

export const createClientRest = async (data: updateClientSchema) =>
	api.post(`Sa1010s/CreateSa1010`, data)

export const updateClientRest = async (
	{ a1Cod, a1Loja }: { a1Cod: string; a1Loja: string },
	data: updateClientSchema
) => api.put(`Sa1010s/UpdateSa1010?a1Cod=${a1Cod}&a1Loja=${a1Loja}`, data)

export const getOrderPrpOverview = async (
	protheusOrder: string
): Promise<AxiosResponse<PrpOrder>> =>
	api.get(`Orders/overview`, { params: { protheusOrder } })

export const getClientPrpOverview = async ({
	a1Cod,
	a1Loja
}: {
	a1Cod: string
	a1Loja: string
}): Promise<AxiosResponse<ClientPrp>> =>
	api.get(`NewClients/overview`, { params: { a1Cod, a1Loja } })

export const getSa1010FinancePosition = async ({
	a1Cod,
	a1Loja,
	a1Grpven
}: {
	a1Cod: string
	a1Loja: string
	a1Grpven?: string
}): Promise<AxiosResponse<Sa1010Overview>> =>
	api.get(`Sa1010s/financePosition`, { params: { a1Cod, a1Loja, a1Grpven } })

export const getSa1010Overview = async ({
	a1Cod,
	a1Loja
}: {
	a1Cod: string
	a1Loja: string
}): Promise<AxiosResponse<Sa1010Overview>> =>
	api.get(`Sa1010s/overview`, { params: { a1Cod, a1Loja } })

export const GetByRaMat = async ({ raMat }: { raMat: string }) =>
	api.get(`Sra010s/GetByRaMat`, { params: { raMat } })

export const getSc5010Overview = async ({
	c5Num,
	c5Filial
}: {
	c5Num: string
	c5Filial: string
}): Promise<AxiosResponse<Sc5010>> =>
	api.get(`Sc5010s/overview`, { params: { c5Num, c5Filial } })

export const getPrpUsers = async ({
	limit,
	offset,
	search
}: {
	limit: number
	offset: number
	search?: string
}) => {
	return api.get(`users/getUsersPrp`, {
		params: { search, limit, offset }
	})
}

export const getUserPrpOverview = async (userId: number) =>
	api.get(`users/overview?userId=${userId}`)

export const getLogs = async (params: {
	limit: number
	offset: number
	search?: string
}) => api.get(`AuditLogs/getLogs`, { params })

export const getLogOverview = async (logId: number) =>
	api.get(`AuditLogs/overview`, { params: { logId } })

export const getAppsVersions = async ({
	limit,
	offset,
	search
}: {
	limit: number
	offset: number
	search?: string
}): Promise<AxiosResponse<AppVersionsResponse>> => {
	let searchQuery = ''
	if (search && search != '') {
		searchQuery += `&search=${search}`
	}
	return api.get(
		`AppsVersions/getAppsVersions?limit=${limit}&offset=${offset}${searchQuery}`
	)
}

export const registerAppsVersions = async (data: any) =>
	api.post(`AppsVersions/customRegister`, data)

export const deleteAppsVersion = async (id: number) =>
	api.delete(`AppsVersions/customDelete?id=${id}`)

export const sendBillets = async (params: any) =>
	api.get(`Se1010s/SendPendingBillets`, { params })

//PRODUCTS
export const getListProducts = async (data: {
	limit: number
	offset: number
	term: string
}) => {
	const limit = `?limit=${data?.limit?.toString()}`
	const offset = `&offset=${data?.offset?.toString()}`
	const term = `&term=${data?.term}`
	return api.get(`Sb1010s/getProducts${limit}${term}${offset}`)
}

export const getListFamilies = async (data: {
	limit: number
	offset: number
	term: string
}) => {
	const limit = `?limit=${data?.limit?.toString()}`
	const offset = `&offset=${data?.offset?.toString()}`
	const term = `&term=${data?.term}`
	return api.get(`Szr010s/getFamilies${limit}${term}${offset}`)
}

export const recoveryAccount = async (data: any) =>
	api.post(`users/recoveryAccount`, data)
