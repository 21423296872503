import { Button } from '@praticabr/ppsa'
import Equipment from 'models/Equipment'
import { RequisitionError } from 'models/RequisitionError'
import { TransferEquipment } from 'models/Transfer'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import FormValidator from 'module/technicalServices/components/Shared/Forms/FormValidator'
import LoadingProgress from 'module/technicalServices/components/Shared/Loadings/LoadingProgress'
import TransferEquipmentInfo from 'module/technicalServices/components/Shared/Transfers/TransferEquipmentInfo'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdErrorOutline } from 'react-icons/md'
import { toast } from 'react-toastify'

interface Props {
	equipment?: TransferEquipment
	onSelectEquipment: (client: any) => void
}

const TransferEquipmentStep: React.FC<Props> = ({
	equipment,
	onSelectEquipment
}) => {
	const { onRequestEquipments } = useContext(TransfersDetailsContextElement)

	const [equipmentSelected, setEquipmentSelected] =
		useState<TransferEquipment>()

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()

	useEffect(() => {
		setEquipmentSelected(equipment)
	}, [])

	const onSearch = (data: any) => {
		const serialNumber = data.serialNumber
		setIsLoading(true)
		onRequestEquipments(serialNumber, onCompleted)
	}

	const onCompleted = (equip?: Equipment, hasError?: RequisitionError) => {
		if (hasError) {
			switch (hasError?.code) {
				case 404:
					toast.error('Equipamento não encontrado')
					break
				case 400:
					toast.error('Erro ao buscar equipamento')
					break
			}
		} else {
			const equipData = {
				id: equip?.id,
				name: equip?.name,
				serialNumber: equip?.serial_number,
				saleDate: equip?.sale_date,
				currentClient: equip?.client.nome_fantasia,
				cpfCnpj: equip?.client.cpfCnpj,
				clientCode: equip?.client.cod_protheus
			} as TransferEquipment

			setEquipmentSelected(equipData)
		}
		setIsLoading(false)
	}

	const onSubmit = () => {
		if (equipmentSelected) {
			onSelectEquipment(equipmentSelected)
		} else {
			toast.error('Nenhum equipamento foi selecionado!')
		}
	}

	const formContent = () => {
		return (
			<form className="main-form-content">
				<div className="transfer-equip-form">
					<div className="form-item-content">
						<input
							placeholder="Informe o número de série"
							defaultValue={equipmentSelected?.serialNumber}
							{...register('serialNumber', { required: true })}
						/>
					</div>
					<Button
						size="md"
						title="Buscar"
						variant="confirmation-solid"
						onClick={handleSubmit(onSearch)}
					/>
				</div>
				<FormValidator {...{ errors, formName: 'serialNumber' }} />
			</form>
		)
	}

	const equipContent = () => {
		return <TransferEquipmentInfo {...{ equipment: equipmentSelected }} />
	}

	const equipValidatorWarning = () => {
		return (
			<div className="transfer-equip-info-warning">
				<MdErrorOutline />
				<h5>Equipamento é obrigatório</h5>
			</div>
		)
	}

	const handleEquipContent = () => {
		if (equipmentSelected && !isLoading) {
			return equipContent()
		} else if (isLoading) {
			return loadingContent()
		} else {
			return equipValidatorWarning()
		}
	}

	const loadingContent = () => {
		return <LoadingProgress text="Buscando equipamento..." show={isLoading} />
	}

	const mainContent = () => {
		return (
			<div className="transfer-equip-content">
				<div className="transfer-equip-header">
					<h4>Equipamento</h4>
					<p>Informe o número de série do equipamento que deseja transferir.</p>
				</div>
				{formContent()}
				{handleEquipContent()}
				<div className="transfer-equip-footer">
					<CustomButtom
						type="button"
						label="Avançar"
						context="primary"
						onClick={onSubmit}
						disabled={!equipmentSelected}
					/>
				</div>
			</div>
		)
	}

	return mainContent()
}

export default TransferEquipmentStep
