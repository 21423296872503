import React, { createContext, useEffect, useRef } from 'react'
import * as io from 'socket.io-client'

export interface SocketContextType {
	socket: io.Socket | null
	connect: () => void
	disconnect: () => void
	eventListener: (
		topic: string,
		callback: (error: any, data: any) => void
	) => void
}

export const SocketContextElement = createContext<SocketContextType>({
	socket: null,
	connect: () => {},
	disconnect: () => {},
	eventListener: () => {}
})

const SocketProvider = (props: any) => {
	const SOCKET_URL = process.env.REACT_APP_SOCKET_HOST || ''
	const socketRef = useRef<io.Socket | null>(null)

	const connect = () => {
		if (!SOCKET_URL) {
			console.error('SOCKET_URL não foi configurado. Verifique o arquivo .env')
			return
		}

		if (!socketRef.current || !socketRef.current.connected) {
			socketRef.current = io.connect(SOCKET_URL, {
				reconnection: true,
				reconnectionAttempts: 5,
				reconnectionDelay: 2000,
				transports: ['websocket'],
				secure: true
			})

			socketRef.current.on('connect', () => {
				console.log('Socket conectado:', socketRef.current?.id)
			})

			socketRef.current.on('connect_error', (error) => {
				console.error('Erro ao conectar ao socket:', error)
				console.log('SOCKET_URL:', SOCKET_URL)
				console.log('Environment:', process.env.NODE_ENV)
			})

			socketRef.current.on('disconnect', () => {
				console.warn('Socket desconectado.')
			})
		}
	}

	const disconnect = () => {
		if (socketRef.current) {
			socketRef.current.disconnect()
			console.log('Socket desconectado manualmente.')
		}
	}

	const eventListener = (
		topic: string,
		callback: (error: any, data: any) => void
	) => {
		if (!socketRef.current) {
			console.error('Nenhuma conexão de socket ativa. Chamando `connect`...')
			connect()
		}

		if (socketRef.current) {
			socketRef.current.removeListener(topic)
			socketRef.current.on(topic, (...data: any) => {
				callback(null, data)
			})
		} else {
			callback(
				new Error(
					'Falha ao registrar listener: Conexão de socket inexistente.'
				),
				null
			)
		}
	}

	const providerValues = {
		socket: socketRef.current,
		connect,
		disconnect,
		eventListener
	}

	useEffect(() => {
		connect()
		return () => {
			disconnect()
		}
	}, [])

	return (
		<SocketContextElement.Provider value={providerValues}>
			{props.children}
		</SocketContextElement.Provider>
	)
}

export default SocketProvider
