import { updateUserToken } from 'services'
import { store } from 'store'

export default async function handleCleanCacheCookies() {
	const userId = store.getState().user?.profile
	const isLogged = store.getState().auth?.signed

	if ('caches' in window) {
		caches.keys().then((cacheNames) => {
			cacheNames.forEach((cacheName) => {
				caches.delete(cacheName)
			})
		})
	}

	if ('localStorage' in window) {
		localStorage.clear()
	}

	document.cookie?.split(';')?.forEach((cookie) => {
		document.cookie = cookie
			.replace(/^ +/, '')
			.replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
	})

	if (isLogged) {
		await updateUserToken(null, userId)
	}

	localStorage.clear()
	isLogged && window.location.reload()
}
