import { Order } from 'module/orderManagement/domain/models/order'
import {
	handleOrderBranchTypes,
	handlePaymentFormType
} from 'module/orderManagement/utils/constants'
import React, { ReactNode, useState } from 'react'
import AddIcon from 'shared/components/SvgIcons/AddIcon'
import { OrderClientInstallmentsViewingMode } from './NewOrderClientInstallments/OrderClientInstallmentsViewingMode'
import './styles.scss'

type Props = {
	order: Order
	children: ReactNode
}

export const PaymentDetailsViewingMode = ({ order, children }: Props) => {
	const [installmentsShowModal, setInstallmentsShowModal] =
		useState<boolean>(false)

	const MainContent = () => (
		<div className="new-order-payment-contain-view-mode">
			<div className="new-order-payment-contain-information">
				<label>Filial</label>
				<span>
					{order?.branch ? handleOrderBranchTypes(order?.branch) : '-'}
				</span>
			</div>
			<div className="new-order-payment-contain-information">
				<label>Forma de pagamento</label>
				<span>
					{order?.payment_code
						? handlePaymentFormType(order?.payment_code)
						: '-'}
				</span>
			</div>
			<div className="new-order-payment-contain-information">
				<label>Aut. cartão</label>
				<span>
					{order?.credit_card_authorization
						? order?.credit_card_authorization
						: '-'}
				</span>
			</div>
			{order?.payment_code === '999' && (
				<div className="new-order-payment-contain-information">
					<div className="new-order-payment-contain-action">
						<div
							className="new-order-installments"
							onClick={() => {
								if (order?.total_with_taxes && order?.payment_code === '999') {
									setInstallmentsShowModal(true)
								}
							}}
						>
							<AddIcon
								width=""
								height=""
								fill={
									order?.total_with_taxes && order?.payment_code === '999'
										? '#004992'
										: '#7c7c7c'
								}
							/>
							<label htmlFor="addItemIcon">Visualizar parcelas</label>
						</div>
					</div>
				</div>
			)}
		</div>
	)

	return (
		<>
			<MainContent />
			<div className="new-order-payment-contain">{children}</div>
			{installmentsShowModal && order?.total_with_taxes && (
				<OrderClientInstallmentsViewingMode
					onClose={() => {
						setInstallmentsShowModal(false)
					}}
					order={order}
				/>
			)}
		</>
	)
}
