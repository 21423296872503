import styled from 'styled-components'

export const Header = styled.div`
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
	margin-bottom: 10px;
`

export const Footer = styled.div`
	width: 100%;
	display: flex;
	column-gap: 20px;
	justify-content: center;
`

export const RequestDetails = styled.div`
	margin-top: 10px;
	height: calc(100% - 150px);
	position: relative;
	overflow: auto;
	padding: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
`
