import { GenericTable, LoadingProgress, Paginator } from '@praticabr/ppsa'
import { HeaderSection } from 'module/adminSystem/components/HeaderSection'
import EmployeeModal from 'module/humanResources/components/EmployeeModal'
import NewEmployeeModal from 'module/humanResources/components/NewEmployeeModal'
import { EmployeesContextElement } from 'module/humanResources/context/EmployeesContext'
import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'

export default function Employees() {
	const {
		employee,
		employees,
		onChangeParams,
		onSelectEmployee,
		totalPages,
		currentPage,
		isLoading
	} = useContext(EmployeesContextElement)
	const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false)
	const [showNewEmployeeModal, setShowNewEmployeeModal] =
		useState<boolean>(false)
	const [searchParams, setSearchParams] = useState<string>('')

	useEffect(() => {
		if (employee) {
			setShowEmployeeModal(true)
		}
	}, [employee])

	const onSearch = (search: string) => {
		setSearchParams(search)
		onChangeParams({ search: search, page: 1 })
	}

	const handleSelectEmployee = (employee: any) => {
		onSelectEmployee(employee.id)
	}

	const handleCloseModal = () => {
		showEmployeeModal && setShowEmployeeModal(!showEmployeeModal)
		showNewEmployeeModal && setShowNewEmployeeModal(!showNewEmployeeModal)
	}

	return (
		<>
			<article className="employees-app-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={onSearch}
						text="Buscar por nome, email ou matrícula"
					/>
					<HeaderSection.button
						text="Novo Colaborador"
						onClick={() => setShowNewEmployeeModal(!showEmployeeModal)}
					/>
				</HeaderSection.root>
				<div className="employees-app-table">
					<GenericTable
						onRowClicked={handleSelectEmployee}
						data={[
							...employees.map((employee) => ({
								...employee,
								email: employee?.email?.toLowerCase(),
								departmentDescription:
									employee?.department?.description?.toLowerCase(),
								userName:
									`${employee?.name} ${employee?.surname}`.toLowerCase(),
								status: employee?.is_active === '1' ? 'Ativo' : 'Inativo'
							}))
						]}
						columns={[
							{
								key: 'userName',
								header: 'Nome',
								width: '40%'
							},
							{
								key: 'email',
								header: 'Email',
								width: '25%'
							},
							{
								key: 'departmentDescription',
								header: 'Departamento',
								width: '25%'
							},
							{
								key: 'status',
								header: 'Status',
								width: '10%'
							}
						]}
					/>
				</div>
				<div className="employees-app-list-paginator">
					<Paginator
						totalPages={totalPages || 1}
						currentPage={currentPage}
						onPageChange={(e) =>
							onChangeParams({ page: e, search: searchParams })
						}
					/>
				</div>
			</article>
			{employee && showEmployeeModal && (
				<EmployeeModal onClose={handleCloseModal} />
			)}
			{showNewEmployeeModal && <NewEmployeeModal onClose={handleCloseModal} />}
			{isLoading && <LoadingProgress />}
		</>
	)
}
