import { GenericTable, LoadingProgress, Paginator } from '@praticabr/ppsa'
import { HeaderSection } from 'module/adminSystem/components/HeaderSection'
import { UserModal } from 'module/adminSystem/components/UserModal'
import { UsersPrpContextElement } from 'module/adminSystem/context/UsersPrpContext'
import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'

export const PrpUsers = () => {
	const {
		user,
		users,
		onChangeParams,
		onSelectUser,
		totalPages,
		currentPage,
		isLoading
	} = useContext(UsersPrpContextElement)
	const [showUserModal, setShowUserModal] = useState<boolean>(false)
	const [searchParams, setSearchParams] = useState<string>('')

	useEffect(() => {
		if (user) {
			setShowUserModal(true)
		}
	}, [user])

	const onSearch = (search: string) => {
		setSearchParams(search)
		onChangeParams({ search: search, page: 1 })
	}

	const handleSelectUser = (user: any) => {
		onSelectUser(user.id)
	}

	const handleCloseModal = () => {
		showUserModal && setShowUserModal(!showUserModal)
	}

	return (
		<>
			<article className="prp-users-app-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={onSearch}
						text="Buscar por nome, email ou matrícula"
					/>
				</HeaderSection.root>
				<div className="prp-users-app-table">
					<GenericTable
						onRowClicked={handleSelectUser}
						data={[
							...users.map((user) => ({
								...user,
								userName: `${user.name} ${user.surname}`,
								status: user.is_active === '1' ? 'Ativo' : 'Inativo',
								locked: user.loginAttempts === 0 ? 'Sim' : 'Não'
							}))
						]}
						columns={[
							{
								key: 'userName',
								header: 'Usuário',
								width: '35%'
							},
							{
								key: 'email',
								header: 'Email',
								width: '35%'
							},
							{
								key: 'status',
								header: 'Status',
								width: '15%'
							},
							{
								key: 'locked',
								header: 'Bloqueado?',
								width: '15%'
							}
						]}
					/>
				</div>
				<Paginator
					totalPages={totalPages || 1}
					currentPage={currentPage}
					onPageChange={(e) =>
						onChangeParams({ page: e, search: searchParams })
					}
				/>
			</article>
			{user && showUserModal && <UserModal onClose={handleCloseModal} />}
			{isLoading && <LoadingProgress />}
		</>
	)
}
