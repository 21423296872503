import { Modal } from '@praticabr/ppsa'
import { Order } from 'module/orderManagement/domain/models/order'
import {
	handleFairOrderType,
	handleOrderCategoryType,
	handleOrderType,
	handlePriceTableType,
	handleSalespersonType
} from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { OrderInvoices } from './OrderInvoices'

export const OrderDetailsViewingMode = ({ order }: { order: Order }) => {
	const [nFShowModal, setNFShowModal] = useState<boolean>(false)

	return (
		<>
			<div className="new-order-information-contain-information">
				<header>Cliente do pedido</header>
				<span>{order?.client?.name}</span>
			</div>
			<div className="new-order-information-contain-information">
				<header>Tipo de pedido</header>
				<span>{handleOrderType(order?.type)}</span>
			</div>
			<div className="new-order-information-contain-information">
				<header>Categoria</header>
				<span>{handleOrderCategoryType(order?.category)}</span>
			</div>
			<div className="new-order-information-contain-information">
				<header>Emissão</header>
				<span>
					{moment(order?.issue_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
				</span>
			</div>
			<div className="new-order-information-contain-information">
				<header>Tabela de preço</header>
				<span>{handlePriceTableType(order?.price_table)}</span>
			</div>
			<div className="new-order-information-contain-information">
				<header>Pedido de feira</header>
				<span>{handleFairOrderType(order?.fair_order)}</span>
			</div>
			<div className="new-order-information-contain-information">
				<header>Vendedor</header>
				<span>{handleSalespersonType(order?.seller?.code)}</span>
			</div>
			{order?.installments?.length > 0 && (
				<div className="new-order-information-contain-information">
					<button
						className="invoice-span-button"
						onClick={() => setNFShowModal(true)}
					>
						Notas fiscais
					</button>
				</div>
			)}
			{order?.category?.includes('5') && (
				<div className="new-order-information-contain-information">
					<header>Ordem de serviço</header>
					<span>{order?.service_order_code}</span>
				</div>
			)}
			<div className="new-order-information-contain-information message-for-invoice">
				<header>Mensagem para nota fiscal</header>
				<span>{order?.message_invoice}</span>
			</div>
			{nFShowModal && (
				<Modal.root>
					<Modal.header
						onClose={() => setNFShowModal(false)}
						title="Notas fiscais"
					/>
					<Modal.body>
						<Modal.body.main>
							<OrderInvoices order={order} />
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)}
		</>
	)
}
