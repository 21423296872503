import { Divider, LoadingProgress, Modal } from '@praticabr/ppsa'
import { RecognitionCard } from 'models/RecognitionCard'
import { RecognitionCardContext } from 'module/recognitionCard/context/recognitionCardContext'
import React, { useContext, useEffect, useState } from 'react'
import { IoIosCloseCircle } from 'react-icons/io'
import { IoSend } from 'react-icons/io5'
import { LuArrowDownFromLine, LuArrowUpFromLine } from 'react-icons/lu'
import { MdDownloadForOffline } from 'react-icons/md'
import { Card } from '../Cards/Card'
import './styles.scss'

const RECEIVED_CARDS = 0
const SEND_CARDS = 1

interface AllCardsProps {
	onClose: () => void
}

const AllCards = ({ onClose }: AllCardsProps) => {
	const [cardSelected, setCardSelected] = useState<RecognitionCard>(
		{} as RecognitionCard
	)
	const [tabSelected, setTabSelected] = useState<number>(0)
	const [showCard, setShowCard] = useState<boolean>(false)

	const [orderCreatedDate, setOrderCreatedDate] = useState<boolean>(false)
	const [orderCreatedDateReceived, setOrderCreatedDateReceived] =
		useState<boolean>(false)
	const {
		isLoading,
		targetRef,
		onResendCard,
		onDownloadCard,
		cardsReceived,
		cardsSent,
		onGetCards
	} = useContext(RecognitionCardContext)

	useEffect(() => {
		onGetCards()
	}, [])

	const onClickCardDetail = (card: RecognitionCard) => {
		setCardSelected(card)
		setShowCard(true)
	}

	const handleOnClose = () => {
		setTabSelected(0)
		setCardSelected({} as RecognitionCard)
		onClose()
	}

	const order = (a: any, b: any) => {
		if (a.created_at < b.created_at) return -1
		if (a.created_at > b.created_at) return 1
		return 0
	}

	const disorder = (a: any, b: any) => {
		if (a.created_at > b.created_at) return -1
		if (a.created_at < b.created_at) return 1
		return 0
	}

	const allReceivedRecognitionCard = () => {
		return cardsReceived && cardsReceived?.length ? (
			<div className="all-recognition-table-container">
				<div className="header">
					<span>Remetente</span>
					<span>
						Data
						<a
							onClick={() => {
								setOrderCreatedDateReceived(!orderCreatedDateReceived)
								orderCreatedDateReceived
									? cardsReceived.sort(disorder)
									: cardsReceived.sort(order)
							}}
						>
							{orderCreatedDateReceived ? (
								<LuArrowUpFromLine color="#025CB7" size={14} />
							) : (
								<LuArrowDownFromLine color="#025CB7" size={14} />
							)}
						</a>
					</span>
				</div>
				<div className="table-body">
					{cardsReceived.map((cardsReceived, index) => (
						<div
							key={index}
							className="table-row"
							onClick={() => {
								onClickCardDetail(cardsReceived)
								setTabSelected(RECEIVED_CARDS)
							}}
						>
							<div className="header-column">
								{cardsReceived?.fromUser?.name +
									' ' +
									cardsReceived?.fromUser?.surname}
							</div>
							<div className="header-column">
								{new Date(cardsReceived?.created_at).toLocaleDateString()}
							</div>
						</div>
					))}
				</div>
			</div>
		) : (
			<div className="empty-list">
				<p>Você ainda não recebeu um cartão.</p>
			</div>
		)
	}

	const allSentRecognitionCard = () => {
		return cardsSent && cardsSent?.length ? (
			<div className="all-recognition-table-container">
				<div className="header">
					<span>Remetente</span>
					<span>
						Data
						<a
							onClick={() => {
								setOrderCreatedDate(!orderCreatedDate)
								orderCreatedDate
									? cardsSent.sort(disorder)
									: cardsSent.sort(order)
							}}
						>
							{orderCreatedDate ? (
								<LuArrowUpFromLine color="#025CB7" size={14} />
							) : (
								<LuArrowDownFromLine color="#025CB7" size={14} />
							)}
						</a>
					</span>
				</div>
				<div className="table-body">
					{cardsSent.map((cardsSent, index) => (
						<div
							key={index}
							className="table-row"
							onClick={() => {
								onClickCardDetail(cardsSent)
								setTabSelected(SEND_CARDS)
							}}
						>
							<div className="header-column">
								{cardsSent?.fromUser?.name + ' ' + cardsSent?.fromUser?.surname}
							</div>
							<div className="header-column">
								{new Date(cardsSent?.created_at).toLocaleDateString()}
							</div>
						</div>
					))}
				</div>
			</div>
		) : (
			<div className="empty-list">
				<p>Você ainda não enviou um cartão.</p>
			</div>
		)
	}

	return (
		<Modal.root>
			<Modal.header onClose={handleOnClose} title="CARTÃO DE RECONHECIMENTO" />
			<Modal.body>
				<Modal.body.main>
					{showCard && (
						<>
							<Card dataCard={cardSelected} targetRef={targetRef} />
							{tabSelected === 0 ? (
								<MdDownloadForOffline
									size={45}
									color="#004687"
									style={{
										zIndex: '10',
										position: 'absolute',
										bottom: '65px',
										right: '-45px',
										cursor: 'pointer'
									}}
									onClick={() => onDownloadCard()}
								/>
							) : (
								<IoSend
									size={39}
									color="white"
									style={{
										zIndex: '10',
										position: 'absolute',
										bottom: '65px',
										right: '-41px',
										cursor: 'pointer',
										padding: '10px',
										borderRadius: '50%',
										background: 'green'
									}}
									onClick={() =>
										onResendCard(cardSelected, () => setShowCard(false))
									}
								/>
							)}
							<IoIosCloseCircle
								size={53}
								color="white"
								style={{
									padding: '3px',
									zIndex: '10',
									position: 'absolute',
									bottom: '105px',
									right: '-48px',
									cursor: 'pointer',
									color: 'red'
								}}
								onClick={() => setShowCard(false)}
							/>
						</>
					)}
					<div className="all-recognition-card-contain">
						<span className="all-recognition-card-title">
							Clique no item que deseja visualizar:
						</span>
						<div className="all-recognition-card-main-contain">
							<div className="all-received-recognition-card">
								<Divider title="Recebidos" />
								{allReceivedRecognitionCard()}
							</div>
							<div className="all-sent-received-recognition-card">
								<Divider title="Enviados" />
								{allSentRecognitionCard()}
							</div>
						</div>
					</div>
					{isLoading && <LoadingProgress text="Carregando" />}
				</Modal.body.main>
			</Modal.body>
		</Modal.root>
	)
}

export default AllCards
