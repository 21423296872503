import { CheckBox, Input } from '@praticabr/ppsa'
import axios from 'axios'
import React, { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import { clearNumericField, normalizeCepNumber } from 'utils/Pipe'
import { IChangeAddressForm } from '../../ChangeAddress'
import './styles.scss'

interface Props {
	handleAddressDefault?: (value: boolean) => void
	form: UseFormReturn<IChangeAddressForm, any>
}

const AdsForm = ({ handleAddressDefault, form }: Props) => {
	const {
		register,
		setValue,
		formState: { errors },
		watch,
		clearErrors
	} = form

	const WatchedZipCode = watch('zip_code')

	useEffect(() => {
		setValue('zip_code', normalizeCepNumber(WatchedZipCode))
		if (WatchedZipCode?.length === 9) {
			setTimeout(() => {
				handleChangeCEP(clearNumericField(WatchedZipCode))
			}, 500)
		}
	}, [WatchedZipCode])

	const handleChangeCEP = async (zip_code: any) => {
		axios
			.get('https://viacep.com.br/ws/' + zip_code + '/json/')
			.then((response) => {
				try {
					setValue(
						'city',
						response?.data?.localidade
							?.normalize('NFD')
							.replace(/[^a-z A-Z]/g, '')
					)
					clearErrors('city')
					setValue('state_sigla', response?.data?.uf)
					clearErrors('state_sigla')
					setValue(
						'street',
						response?.data?.logradouro
							?.normalize('NFD')
							.replace(/[^a-z A-Z]/g, '')
					)
					clearErrors('street')
					setValue(
						'neighborhood',
						response?.data?.bairro?.normalize('NFD').replace(/[^a-z A-Z]/g, '')
					)
					clearErrors('neighborhood')
				} catch (error) {
					toast.error('CEP incorreto favor verificar!')
				}
			})
	}

	const defaultAddressCheckbox = () => {
		return (
			handleAddressDefault && (
				<label
					className="radio-button-field w-radio"
					style={{ marginBottom: '20px' }}
				>
					<div
						className="w-form-formradioinput radio-button w-radio-input"
						style={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}
					>
						<CheckBox
							variant="standard"
							format="square"
							onChange={(e) => {
								handleAddressDefault(e.target.checked)
							}}
						/>
						<span className="radio-button-label w-form-label">
							Salvar como endereço padrão
						</span>
					</div>
				</label>
			)
		)
	}

	return (
		<div className="address-form-container">
			<div className="address-content">
				<div className="address-itens-1">
					<div className="address-information-content">
						<header>CEP</header>
						<Input
							id="zip_code"
							variant="light"
							placeholder="00000-000"
							{...register('zip_code', {
								required: {
									value: true,
									message: 'Campo CEP é obrigatório!'
								},
								maxLength: 9,
								minLength: {
									value: 9,
									message: 'Limite de mínimo de caracteres não atingido!'
								}
							})}
							maxLength={9}
							autoComplete={'off'}
							errors={errors.zip_code}
						/>
					</div>
					<div className="address-information-content">
						<header>Cidade</header>
						<Input
							id="city"
							{...register('city', {
								required: {
									value: true,
									message: 'Campo cidade é obrigatório!'
								}
							})}
							placeholder="Informe a cidade"
							variant="light"
							readOnly
							errors={errors.city}
						/>
					</div>
					<div className="address-information-content">
						<header>Estado</header>
						<Input
							id="state"
							{...register('state_sigla', {
								required: {
									value: true,
									message: 'Campo estado é obrigatório!'
								}
							})}
							variant="light"
							placeholder="informe o estado"
							errors={errors.state_sigla}
						/>
					</div>
					<div className="address-information-content">
						<header>Bairro</header>
						<Input
							id="neighborhood"
							{...register('neighborhood', {
								required: {
									value: true,
									message: 'Campo bairro é obrigatório!'
								},
								maxLength: { value: 30, message: 'Máximo de 30 caracteres!' }
							})}
							variant="light"
							placeholder="informe o bairro"
							errors={errors.neighborhood}
						/>
					</div>
				</div>
			</div>
			<div className="address-content">
				<div className="address-itens-2">
					<div className="address-information-content">
						<header>Endereço</header>
						<Input
							id="street"
							{...register('street', {
								required: {
									value: true,
									message: 'Campo endereço é obrigatório!'
								},
								maxLength: { value: 48, message: 'Máximo de 48 caracteres!' }
							})}
							variant="light"
							placeholder="Informe o endereço"
							errors={errors.street}
						/>
					</div>
					<div className="address-information-content">
						<header>Nº</header>
						<Input
							id="number"
							{...register('number', { required: true, maxLength: 10 })}
							variant="light"
							placeholder="00"
							errors={errors.number}
						/>
					</div>
					<div className="address-information-content">
						<header>Complemento</header>
						<Input
							id="complement"
							{...register('complement')}
							variant="light"
							placeholder="Quadra/Bloco"
						/>
					</div>
				</div>
			</div>
			<div className="address-default">{defaultAddressCheckbox()}</div>
		</div>
	)
}

export default AdsForm
