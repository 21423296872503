import { Button } from '@material-ui/core'
import { LoadingProgress } from '@praticabr/ppsa'
import $ from 'jquery'
import Client from 'models/Client'
import ConfirmChanges from 'module/technicalServices/components/ConfirmPrevChange'
import React, { useState } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import { deleteLocation } from 'services/index'
import {
	City,
	Container,
	EditImg,
	Footer,
	LocalName,
	Message,
	ModalCont,
	Neighborhood,
	State,
	Street,
	ZipCode
} from './styles'

interface Props {
	id: number
	name: string
	zip_code: string
	street: string
	neighborhood: string
	city: string
	state: string
	original: number
	client?: Client
	index: number
	updateLocations: (index: number) => void
}

const LocalModalPrev: React.FC<Props> = ({
	name,
	zip_code,
	street,
	neighborhood,
	city,
	state,
	original,
	id,
	client,
	index,
	updateLocations
}) => {
	const [render, setRender] = useState<boolean>(true)
	const [finishedUp, setFinishedUp] = useState<boolean>(false)
	const [status, setStatus] = useState<string>('')

	return (
		<>
			{render ? (
				<Container key={id}>
					<LocalName>{name}</LocalName>

					<ZipCode>{zip_code}</ZipCode>

					<Street>{street}</Street>

					<Neighborhood>{neighborhood}</Neighborhood>

					<City>{city}</City>

					<State>{state}</State>

					{original ? (
						<div></div>
					) : (
						<EditImg>
							<a onClick={() => $(`.confirmDelete${id}`).toggle()}>
								<MdDeleteOutline />
							</a>
						</EditImg>
					)}
				</Container>
			) : (
				''
			)}

			<div className={`modal confirmDelete${id}`}>
				<ModalCont>
					<Message>
						Deseja deletar a localização <strong>{name}</strong>?
					</Message>

					<Footer>
						<Button
							style={{ marginLeft: '15px' }}
							color="primary"
							variant="contained"
							onClick={() => {
								$(`.confirmDelete${id}`).toggle()
								$(`.confirmChange${id}`).toggle()
								deleteLocation(id)
									.then((resp) => {
										setRender(false)
										setStatus('true')
										setFinishedUp(true)
										updateLocations(index)
									})
									.catch((err) => {
										setStatus('false')
										setFinishedUp(true)
									})
							}}
						>
							Confirmar
						</Button>

						<Button
							color="secondary"
							onClick={() => {
								$(`.confirmDelete${id}`).toggle()
							}}
						>
							Cancelar
						</Button>
					</Footer>
				</ModalCont>
			</div>

			<div className={`modal confirmChange${id}`}>
				{finishedUp ? (
					<ConfirmChanges
						changeType="delete"
						status={status}
						id={id}
						client={client?.cpfCnpj}
					/>
				) : (
					<div
						style={{
							backgroundColor: 'white',
							height: 'auto',
							width: 'auto',
							borderRadius: '3px',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							position: 'absolute',
							padding: '0px 40px 40px 40px'
						}}
					>
						<LoadingProgress text="Carregando..." />
					</div>
				)}
			</div>
		</>
	)
}

export default LocalModalPrev
