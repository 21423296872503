import { FilterData } from 'models/FilterData'
import moment from 'moment-timezone'
import { api } from 'services'
import { AddNewFollowUpProps } from '../models/PrpOrderModel'
import { IClientUpdateCloudRequest } from '../models/cloudTotvsRequisitionModels/IClientCloudRequisition'
import {
	IPendencyCreateCloudRequest,
	IPendencyDeleteCloudRequest,
	IPendencyUpdateCloudRequest
} from '../models/cloudTotvsRequisitionModels/IPendencyCloudRequisition'

// order__management_clients
export const getClients = async (
	limit: number,
	offset: number,
	search?: string,
	financialBlockStatus?: string[],
	federatedState?: string[],
	salesPersonCode?: string[],
	finalDate?: Date,
	initialDate?: Date
) => {
	let searchQuery = ''
	if (search && search != '') {
		searchQuery += `&search=${search}`
	}
	if (financialBlockStatus) {
		searchQuery += `&financialStatus=${financialBlockStatus}`
	}
	if (federatedState) {
		searchQuery += `&federatedState=${federatedState}`
	}
	if (salesPersonCode) {
		searchQuery += `&salesPersonCode=${salesPersonCode}`
	}
	if (initialDate) {
		searchQuery += `&initialDate=${moment(initialDate).format('YYYYMMDD')}`
	}
	if (finalDate) {
		searchQuery += `&finalDate=${moment(finalDate).format('YYYYMMDD')}`
	}
	return api.get(
		`order__management_clients/getClients?limit=${limit}&offset=${offset}${searchQuery}`
	)
}

export const getClientByOrder = async (order: string) =>
	api.post(`order__management_clients/getClientByOrder`, { order })
export const getClientByProtheusCode = async (clientProtheusCode: string) =>
	api.get(
		`order__management_clients/getClientByProtheusCode?clientProtheusCode=${clientProtheusCode}`
	)
export const getClientByValue = async (clientProtheusValue: string) =>
	api.get(
		`order__management_clients/getClientByValue?getClientByValue=${clientProtheusValue}`
	)
export const getProductByValue = async (productProtheusValue: string) =>
	api.get(
		`order__management_orders/getProductByValue?getProductByValue=${productProtheusValue}`
	)
export const getItemsByValue = async (
	itemsProtheusValue: string,
	itemsProtheusTable: string
) =>
	api.get(
		`order__management_orders/getItemsByValue?getItemsByValue=${itemsProtheusValue}&getItemsByTable=${itemsProtheusTable}`
	)
export const getAccessoriesByValue = async (
	accessoriesProtheusValue: string,
	table: string
) =>
	api.get(
		`order__management_orders/getAccessoriesByValue?getAccessoriesByValue=${accessoriesProtheusValue}&table=${table}`
	)

// order__management_base_lists
export const getListSalesperson = async () =>
	api.get(`order__management_base_lists/getListSalesperson`)
export const getPendencyTypes = async () =>
	api.get(`order__management_base_lists/getPendencyTypes`)
export const getPaymentFormTypes = async () =>
	api.get(`order__management_base_lists/getPaymentFormTypes`)
export const getPriceTableList = async () =>
	api.get(`order__management_base_lists/getPriceTableList`)
export const getNatureList = async () =>
	api.get(`order__management_base_lists/getNatureList`)
export const getClientGroups = async () =>
	api.get(`order__management_base_lists/getClientGroups`)
export const getClientSegments = async () =>
	api.get(`order__management_base_lists/getClientSegments`)
export const getOperationTypes = async () =>
	api.get(`order__management_base_lists/getOperationTypes`)
export const getCarrierList = async () =>
	api.get(`order__management_base_lists/getCarrierList`)
export const getProtheusUserList = async () =>
	api.get(`order__management_base_lists/getProtheusUserList`)
export const getCountyList = async () =>
	api.get(`order__management_base_lists/getCountyList`)
export const getLedgerAccountList = async () =>
	api.get(`order__management_base_lists/getLedgerAccountList`)
export const getCostCenterList = async () =>
	api.get(`order__management_base_lists/getCostCenterList`)
export const getStorageList = async () =>
	api.get(`order__management_base_lists/getStorageList`)
export const getRegionList = async () =>
	api.get(`order__management_base_lists/getRegionList`)

export const getCustomListStorage = async () => api.get(`Nnr010s/customList`)

// order__management_orders
export const getOrderByCode = async (orderCode: string, filial: string) =>
	api.get(
		`order__management_orders/getOrderByCode?orderCode=${orderCode}&filial=${filial}`
	)

export const getOrderPrpByCode = async (orderCode: string, filial: string) =>
	api.get(
		`Orders?filter=%7B%22where%22%3A%7B%22protheusOrder%22%3A%22${filial}${orderCode}%22%7D%7D`
	)

export const getOrderHistoryByClient = async (
	clientProtheusCode: string,
	limit: number,
	offset: number,
	search?: string,
	newFilter?: FilterData
) => {
	let searchQuery = ''
	if (search) {
		searchQuery += `&search=${search}`
	}
	return api.get(
		`order__management_orders/getOrderHistoryByClient?clientProtheusCode=${clientProtheusCode}&limit=${limit}&offset=${offset}${searchQuery}&filter=${
			newFilter ? JSON.stringify(newFilter) : ''
		}`
	)
}

export const getOrders = async (
	limit: number,
	offset: number,
	search?: string,
	pendencyStatus?: string,
	federatedState?: string[],
	salesPersonCode?: string[],
	orderCategory?: string[],
	orderStatus?: string[],
	orderBranch?: string[],
	finalDate?: Date,
	initialDate?: Date
) => {
	let searchQuery = ''
	if (orderCategory) {
		searchQuery += `&orderCategory=${orderCategory}`
	}
	if (orderStatus) {
		searchQuery += `&orderStatus=${orderStatus}`
	}
	if (federatedState) {
		searchQuery += `&federatedState=${federatedState}`
	}
	if (pendencyStatus) {
		searchQuery += `&pendencyStatus=${pendencyStatus}`
	}
	if (salesPersonCode) {
		searchQuery += `&salesPersonCode=${salesPersonCode}`
	}
	if (orderBranch) {
		searchQuery += `&orderBranch=${orderBranch}`
	}
	if (search && search != '') {
		searchQuery += `&search=${search}`
	}
	if (initialDate) {
		searchQuery += `&initialDate=${moment(initialDate).format('YYYYMMDD')}`
	}
	if (finalDate) {
		searchQuery += `&finalDate=${moment(finalDate).format('YYYYMMDD')}`
	}
	return api.get(
		`order__management_orders/getOrders?limit=${limit}&offset=${offset}${searchQuery}`
	)
}
export const createZZF010 = async (data: IPendencyCreateCloudRequest) =>
	api.post(`order__management_orders/createZZF010`, data)
export const deleteZZF010 = async (data: IPendencyDeleteCloudRequest) =>
	api.put(`order__management_orders/deleteZZF010`, data)
export const updateZZF010 = async (data: IPendencyUpdateCloudRequest) =>
	api.put(`order__management_orders/updateZZF010`, data)
export const updateSA1010 = async (data: IClientUpdateCloudRequest) =>
	api.put(`order__management_clients/updateSA1010`, {
		informationToUpdate: data
	})
export const getOrder = async (branch: string, order: string) =>
	api.get(`protheus/orders?branch=${branch}&order=${order}`)

export const getInvoicePdf = async (
	number: string,
	serie: string,
	branch: string
) =>
	api.get(
		`protheus/orders/invoice?number=${number}&serie=${serie}&branch=${branch}`,
		{ responseType: 'blob' }
	)

export const createPrpOrderEvent = async (data: AddNewFollowUpProps) =>
	api.post(`OrderEvents/register`, { ...data })

export const updateC5Libfin = async (params: {
	protheusOrder: string
	c5Libfin: string
}) =>
	api.post(
		`Sc5010s/updateC5Libfin?protheusOrder=${params.protheusOrder}&c5Libfin=${params.c5Libfin}`
	)

export const orderAttachmentDelete = async (id: number) =>
	api.post(`OrderEventAttachments/customDelete/${id}`)

export const OrderChecklistUpsert = async (data: any) =>
	api.post(`OrderChecklists/customUpsert`, { ...data })

export const OrderChecklistUpdateStatus = async (data: any) =>
	api.put(`Orders/${data.id}`, { ...data })

export const ClientInsertAttachment = async (data: any) =>
	api.post(`ClientAttachments/register`, { attachments: data })

export const ClientDeleteAttachment = async (id: number) =>
	api.post(`ClientAttachments/customDelete/${id}`)

export const orderAttachmentsImport = async (data: any) =>
	api.post(`OrderEvents/importAttachments`, { ...data })
