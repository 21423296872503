import { Button, EmptyList, GenericTable, handleType } from '@praticabr/ppsa'
import { ClientPrp } from 'models/ClientPrp'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { AttachmentCategoryModal } from 'module/orderManagement/components/AttachmentCategoryModal'
import ClientMenuTabs from 'module/orderManagement/components/ClientMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import {
	UploadModal,
	UploadedFilesProps,
	fileAttachmentCategories
} from 'module/orderManagement/components/UploadModal'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import {
	ClientDeleteAttachment,
	ClientInsertAttachment
} from 'module/orderManagement/services'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import MoreOptions from 'shared/components/MoreOptions'
import Search from 'shared/components/Search'
import { store } from 'store'
import { ViewType } from 'utils/Constants'
import './styles.scss'

type ClientAttachmentsProps = RouteComponentProps & {
	match: {
		params: {
			clientCode: string
			clientStoreCode: string
		}
	}
}

const ClientAttachments = ({ match }: ClientAttachmentsProps) => {
	const { viewType } = useContext(DeviceContextElement)
	const { sa1010, clientPrp, onSelectClient } = useContext(
		ClientDetailsContextElement
	)
	const { profile } = store.getState().user

	const { clientCode, clientStoreCode } = match.params
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
	const [attachments, setAttachments] = useState<ClientPrp['attachments']>([])
	const [showAttachmentCategoryModal, setShowAttachmentCategoryModal] =
		useState<boolean>(false)
	const [selectedAttachment, setSelectedAttachment] = useState<any>()

	useEffect(() => {
		if (sa1010?.a1Cod !== clientCode) {
			onSelectClient({ a1Cod: clientCode, a1Loja: clientStoreCode })
		}
		if (clientPrp) {
			setAttachments(clientPrp.attachments)
		}
	}, [clientCode, clientStoreCode, clientPrp])

	const {
		control,
		formState: { errors }
	} = useForm()

	const { fields } = useFieldArray({
		control,
		name: 'attachments'
	})

	const handleImportAttachments = (files: UploadedFilesProps[]) => {
		setShowUploadModal(false)
		if (clientPrp) {
			const newFiles = files.map((file) => {
				return {
					description: file.name,
					clientId: clientPrp?.id,
					role: 'string',
					file: file,
					deletedAt: null,
					attachmentCategory: file.attachmentCategory || null
				}
			})
			ClientInsertAttachment(newFiles)
				.then(() => {
					if (!sa1010) return
					onSelectClient({ a1Cod: sa1010.a1Cod, a1Loja: sa1010.a1Loja })
					toast.success('Anexos inseridos com sucesso')
				})
				.catch(() => toast.error('Erro ao anexar arquivos'))
		}
	}

	const openAttachment = (e: any) => {
		window.open(e.link, '_blank')
	}

	const handleDelete = async (id: number) => {
		const response = await ClientDeleteAttachment(id)
		if (!sa1010) return
		onSelectClient({ a1Cod: sa1010.a1Cod, a1Loja: sa1010.a1Loja })
		if (response?.data?.statusCode === 200) {
			toast.success('Anexo deletado com sucesso.')
		} else {
			toast.error('Erro ao deletar anexo.')
		}
	}

	const handleSearchAttachments = (value: string) => {
		if (!clientPrp?.attachments) return
		if (value) setAttachments(clientPrp?.attachments)
		const filtered = clientPrp?.attachments.filter(
			(attachment) =>
				attachment?.description?.toLowerCase().includes(value?.toLowerCase()) ||
				attachment?.file?.file_type
					?.toLowerCase()
					.includes(value?.toLowerCase()) ||
				attachment?.createdAt?.toLowerCase()?.includes(value?.toLowerCase())
		)
		setAttachments(filtered)
		return attachments
	}

	const handleEditCategory = (attachment: any) => {
		setShowAttachmentCategoryModal(true)
		setSelectedAttachment(attachment)
	}

	const optionsOnMoreOptions = (attachment: any) => {
		if (attachment?.userId === profile) {
			return [
				{
					label: 'Editar',
					onClick: () => handleEditCategory(attachment)
				},
				{
					label: 'Excluir',
					onClick: () => handleDelete(attachment.id)
				}
			]
		}

		return [
			{
				label: 'Excluir',
				onClick: () => handleDelete(attachment.id)
			}
		]
	}

	return sa1010 ? (
		<>
			<ClientMenuTabs path={match.path} />
			<div className="client-attachments-tab">
				<div className="client-attachments-header">
					<TabTitles />
					{viewType !== ViewType.MOBILE && (
						<Button
							title="INSERIR ARQUIVO"
							size="lg"
							variant="confirmation-solid"
							onClick={() => setShowUploadModal(true)}
						/>
					)}
				</div>

				<div className="client-attachment-scope">
					<div className="client-attachments-search">
						<Search
							variant="light"
							placeholder="Procurar Arquivo"
							onChangeValues={(e) => handleSearchAttachments(e)}
						/>
					</div>
					{viewType === ViewType.MOBILE && (
						<Button
							title="INSERIR ARQUIVO"
							size="lg"
							variant="confirmation-solid"
							onClick={() => setShowUploadModal(true)}
						/>
					)}
				</div>

				<div className="client-attachments-table">
					{attachments && attachments?.length > 0 ? (
						<GenericTable
							data={attachments
								.filter((attachment) => attachment.file)
								.filter((attachment) => attachment.deletedAt === null)
								.map((attachment) => ({
									...attachment,
									id: attachment?.id,
									document: attachment?.description,
									category: handleType(
										attachment?.file?.attachment_category,
										fileAttachmentCategories
									),
									type: attachment?.file?.file_type
										?.toUpperCase()
										?.split('/')[1],
									include: new Date(attachment?.createdAt).toLocaleDateString(),
									link: attachment?.file?.link,
									moreOptions: (
										<div className="attachment-more-option">
											<MoreOptions
												id={`${attachment.id}`}
												options={optionsOnMoreOptions(attachment)}
											/>
										</div>
									),
									disabledTable: attachment?.file?.link ? false : true
								}))}
							columns={[
								{
									key: 'description',
									header: 'Documento',
									width:
										viewType !== ViewType.MOBILE ? 'calc(40% - 12px)' : '30%'
								},
								{
									key: 'category',
									header: 'Categoria',
									width: viewType !== ViewType.MOBILE ? '20%' : '20%'
								},
								{
									key: 'include',
									header: 'Data',
									width: viewType !== ViewType.MOBILE ? '20%' : '30%'
								},
								{
									key: 'type',
									header: 'Tipo',
									width: viewType !== ViewType.MOBILE ? '20%' : '20%'
								},
								{
									key: 'moreOptions',
									header: '',
									width: '10px'
								}
							]}
							onRowClicked={openAttachment}
						/>
					) : (
						<EmptyList text="Nenhum anexo encontrado" />
					)}
				</div>
			</div>
			{showUploadModal && (
				<UploadModal
					onClose={() => setShowUploadModal(false)}
					title="Anexos"
					files={fields.map((file: any) => file?.file)}
					onUploadFiles={handleImportAttachments}
					activeCategory
				/>
			)}
			{showAttachmentCategoryModal && (
				<AttachmentCategoryModal
					onClose={() => {
						setShowAttachmentCategoryModal(false)
						onSelectClient({ a1Cod: sa1010.a1Cod, a1Loja: sa1010.a1Loja })
					}}
					file={selectedAttachment.file}
				></AttachmentCategoryModal>
			)}
		</>
	) : (
		<EmptyList text="Nenhum anexo encontrado" />
	)
}
export default ClientAttachments
