import { EmptyList, Paginator } from '@praticabr/ppsa'
import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import React, { useContext } from 'react'
import OrdersActions from './OrdersActions'
import { default as OrdersListBody } from './OrdersListBody'
import './styles.scss'

const OrderListMobile = () => {
	const { isLoading, totalPages, currentPage, onPageChange } =
		useContext(OrderContextElement)

	return (
		<>
			<div className="orders">
				<OrdersActions />
				<div className="orders-table">
					{totalPages ? (
						<OrdersListBody />
					) : (
						<EmptyList text="Pedido não encontrado" />
					)}
				</div>
			</div>
			<div className="orders-paginator">
				<Paginator
					totalPages={totalPages}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			</div>
		</>
	)
}
export default OrderListMobile
