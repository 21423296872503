import { Button, Modal } from '@praticabr/ppsa'
import Filter from 'assets/image/Filter.svg'
import not_pendency from 'assets/image/not_pendency.svg'
import pendency from 'assets/image/pendency.svg'
import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import React, { useContext, useState } from 'react'
import { history } from 'routes/history'
import MobileNavigator from 'shared/components/MobileNavigator'
import Search from 'shared/components/Search'
import OrdersFilter from '../OrdersFilter'
import './styles.scss'

const OrdersActions = () => {
	const { onSearchOrder } = useContext(OrderContextElement)
	const [ordersFilterShowModal, setOrdersFilterShowModal] =
		useState<boolean>(false)

	const ordersActionsContain = () => {
		return (
			<>
				<div className="orders-actions">
					<MobileNavigator action={() => history.push('/home')} />
					<div className="orders-search-and-filter">
						<Search
							variant="light"
							placeholder="Procurar Pedido"
							onChangeValues={onSearchOrder}
						/>
						<img
							src={Filter}
							alt="filter icon"
							onClick={() => setOrdersFilterShowModal(true)}
						/>
					</div>
					<div className="orders-bt-new-order">
						<Button
							title="NOVO PEDIDO"
							variant="confirmation-solid"
							size="lg"
							disabled={false}
							onClick={() => history.push('/orders/new-order')}
						/>
					</div>
					{ordersFilterShowModal && (
						<Modal.root>
							<Modal.header
								title="FILTROS"
								onClose={() => setOrdersFilterShowModal(false)}
							/>
							<Modal.body>
								<Modal.body.main>
									<OrdersFilter
										onClose={() => setOrdersFilterShowModal(false)}
									/>
								</Modal.body.main>
							</Modal.body>
						</Modal.root>
					)}
				</div>
				<div className="orders-legends">
					<span>
						<img src={pendency} /> Com pendência
					</span>
					<span>
						<img src={not_pendency} /> Sem pendência
					</span>
				</div>
			</>
		)
	}

	return ordersActionsContain()
}
export default OrdersActions
