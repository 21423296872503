import { useFetch } from 'hooks/useFetch'
import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'
import { RequisitionError } from 'models/RequisitionError'
import moment from 'moment-timezone'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { history } from 'routes/history'
import { store } from 'store'
import { setOrdersParams } from 'store/modules/orderManagement/actions'
import {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'
import { OrderContextType, OrdersContextValue } from './OrderContextType'

export const OrderContextElement =
	createContext<OrderContextType>(OrdersContextValue)
const defaultParams = defaultListParams(
	store.getState().orderManagement.ordersMenu.listOrderParams,
	false
)

const OrderProvider = (props: any) => {
	const filtersParams: FilterData =
		store.getState().orderManagement.ordersMenu.filterSelected
	const [hasError, setHasError] = useState<RequisitionError>()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const listParams = useRef<ListParams>(defaultParams)
	const dispatch = useDispatch()

	const { currentPage, pageLimit, searchValue } = { ...listParams.current }
	const offset = (currentPage - 1) * pageLimit

	useEffect(() => {
		getOrdersListQuery(searchValue)
	}, [searchValue])

	const getOrdersListQuery = (searchValue?: string) => {
		const itemFilter = store.getState().orderManagement.ordersMenu
			.filterSelected?.id
			? store.getState().orderManagement.ordersMenu.filterSelected
			: listParams.current.itemFilter
		const {
			pendencyStatus,
			pendencyCode,
			pendencyType,
			pendencyIssueResolverTeam,
			pendencyIssueResolver,
			federatedState,
			salesPersonCode,
			orderCategory,
			orderStatus,
			orderBranch,
			finalDate,
			initialDate,
			pendencyDate,
			chosenDate
		} = itemFilter

		let searchQuery = ''
		if (orderCategory) {
			searchQuery += `&orderCategory=${orderCategory}`
		}
		if (orderStatus) {
			searchQuery += `&orderStatus=${orderStatus}`
		}
		if (federatedState) {
			searchQuery += `&federatedState=${federatedState}`
		}
		if (pendencyStatus) {
			searchQuery += `&pendencyStatus=${pendencyStatus}`
		}
		if (pendencyCode) {
			searchQuery += `&pendencyCode=${pendencyCode}`
		}
		if (pendencyType) {
			searchQuery += `&pendencyType=${pendencyType}`
		}
		if (pendencyIssueResolverTeam) {
			searchQuery += `&pendencyIssueResolverTeam=${pendencyIssueResolverTeam}`
		}
		if (pendencyIssueResolver) {
			searchQuery += `&pendencyIssueResolver=${pendencyIssueResolver}`
		}
		if (pendencyDate) {
			searchQuery += `&pendencyDate=${pendencyDate}`
		}
		if (salesPersonCode) {
			searchQuery += `&salesPersonCode=${salesPersonCode}`
		}
		if (orderBranch) {
			searchQuery += `&orderBranch=${orderBranch}`
		}
		if (searchValue && searchValue != '') {
			searchQuery += `&search=${searchValue}`
		}
		if (initialDate) {
			searchQuery += `&initialDate=${moment(initialDate).format('YYYYMMDD')}`
		}
		if (finalDate) {
			searchQuery += `&finalDate=${moment(finalDate).format('YYYYMMDD')}`
		}
		if (chosenDate) {
			searchQuery += `&chosenDate=${moment(chosenDate).format('YYYYMMDD')}`
		}
		setSearchQuery(searchQuery)
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
		dispatch(setOrdersParams(listParams.current))
	}

	const clearParams = (clearAll = false) => {
		listParams.current = clearListParams(listParams.current, clearAll)
		dispatch(setOrdersParams(listParams.current))
	}

	const onPageChange = (page: number) => {
		updateParams(page, 'page')
		getOrdersListQuery(searchValue)
		mutate()
	}

	const onLimitChange = (newLimit: number) => {
		updateParams(newLimit, 'limit')
		getOrdersListQuery(searchValue)
		mutate()
	}

	const onSearchOrder = (search: string) => {
		updateParams(search, 'search')
		getOrdersListQuery(search)
		clearParams()
		mutate()
	}

	const onFilterChange = (newFilter: FilterData) => {
		updateParams(newFilter, 'filter')
		clearParams()
		getOrdersListQuery(searchValue)
		mutate()
	}

	const { data, error, mutate, isValidating } = useFetch(
		`order__management_orders/getOrders?limit=${pageLimit}&offset=${offset}${searchQuery}`
	)
	if (error) {
		const { error: err } = error.request.response
		err?.code === 'AUTHORIZATION_REQUIRED' && history.push('/home')
		const reportError = {
			message: err.message,
			code: err.statusCode,
			error: err.request
		}
		clearParams(true)
		setHasError(reportError)
	}

	if (data) {
		const count = data?.response?.count || 1
		updateParams(Math.ceil((count ? count : 1) / pageLimit), 'count')
	}

	const providerValues = () => {
		return {
			orderList: data?.response?.orders ? data?.response?.orders : [],
			isLoading: isValidating,
			hasError,
			...listParams.current,
			onPageChange,
			onLimitChange,
			onSearchOrder,
			onFilterChange,
			filtersParams
		}
	}

	return (
		<OrderContextElement.Provider value={providerValues()}>
			{props.children}
		</OrderContextElement.Provider>
	)
}

export default OrderProvider
