import { Divider } from '@praticabr/ppsa'
import { Order } from 'module/orderManagement/domain/models/order'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './styles.scss'

export const PaymentTotalsContent = ({ order }: { order?: Order }) => {
	return (
		<div className="new-order-payment-contain-information">
			<div className="new-order-payment-contain-total-and-freight">
				<div className="new-order-payment-contain-freight">
					<Divider style={{ marginBottom: '15px' }} title="FRETE" />
					{order?.total_with_taxes ? (
						<label htmlFor="">
							Total do frete:{' '}
							<span>
								{order?.freight?.cost?.toLocaleString('pt-br', {
									minimumFractionDigits: 2,
									style: 'currency',
									currency: 'BRL'
								})}
							</span>
						</label>
					) : (
						<Skeleton height={20} />
					)}
				</div>
				<div className="new-order-payment-contain-total">
					<label htmlFor="">TOTAL DO PEDIDO</label>
					{order?.total_with_taxes ? (
						<h1>
							{order?.total_with_taxes?.toString()?.split('.')[0]}
							<span>
								,{order?.total_with_taxes?.toString()?.split('.')[1] || '00'}
							</span>
						</h1>
					) : (
						<Skeleton width={140} height={40} />
					)}
					<span>Com descontos, frete e tributos já atribuídos</span>
				</div>
			</div>
		</div>
	)
}
