import {
	Divider,
	handleProtheusDateToDate,
	LoadingProgress,
	Section,
	Tooltip
} from '@praticabr/ppsa'
import TabTitles from 'module/productManagement/components/TabTitles'
import { UploadSingleFile } from 'module/productManagement/components/UploadSingleFile'
import { ProductDetailsContext } from 'module/productManagement/context/ProductDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { RouteComponentProps } from 'react-router-dom'

import { getTypeByKey } from 'services'
import './styles.scss'

type ProductDetailsProps = RouteComponentProps & {
	match: {
		params: {
			codeProtheus: string
		}
	}
}

export const ProductDetails = ({ match }: ProductDetailsProps) => {
	const { isLoading, sb1010, szr010, sx5010, productDetails, processUpload } =
		useContext(ProductDetailsContext)

	const [familyProdName, setFamilyProdName] = useState<string>('')
	const [volumeList, setVolumeList] = useState<any[]>([])

	const filterSX5010 = (tabela: string, chave: string) => {
		if (chave) {
			return sx5010?.filter(
				(x: any) => x?.x5Tabela === tabela && x?.x5Chave === chave.trim()
			)[0]?.x5Descri
		} else {
			return '-'
		}
	}

	useEffect(() => {
		if (sb1010?.b1Fmprod.trim()) {
			getTypeByKey('X2', sb1010?.b1Fmprod).then((response) => {
				setFamilyProdName(response.data[0].description.toLowerCase())
			})
		}
	}, [sb1010?.b1Fmprod])

	useEffect(() => {
		transformList()
	}, [szr010])

	const transformList = () => {
		if (!szr010) return
		const result: any[] = []

		for (let i = 1; i <= szr010?.zrVolumes; i++) {
			const alturKey = i >= 10 ? `zrAltuv${i}` : `zrAlturv${i}`
			const largKey = `zrLargv${i}`
			const comprKey = i >= 10 ? `zrCompv${i}` : `zrComprv${i}`
			const pesoKey = `zrPesov${i}`

			const items = {
				volume: i,
				zrAlturv: szr010[alturKey] ?? null,
				zrLargv: szr010[largKey] ?? null,
				zrComprv: szr010[comprKey] ?? null,
				zrPesov: szr010[pesoKey] ?? null
			}

			result.push(items)
		}
		setVolumeList(result)
	}

	return (
		<>
			<div className="product-details">
				<div className="product-details-main">
					<div className="header">
						<TabTitles
							title={sb1010?.b1Desc}
							subtitle={familyProdName}
							isLoading={isLoading}
						/>
					</div>
					<div className="body">
						<Section.root>
							<Section.title>Informações do produto</Section.title>
							<Section.container>
								<div className="body-container">
									<div className="product-details-items">
										<header className="product-details-items">Código</header>
										<Tooltip info={sb1010?.b1Cod}>
											<span>{sb1010?.b1Cod}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">Modelo</header>
										<Tooltip info={sb1010?.b1Modelo}>
											<span>{sb1010?.b1Modelo || '-'}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">Tipo</header>
										<Tooltip info={filterSX5010('02', sb1010?.b1Tipo)}>
											<span>{filterSX5010('02', sb1010?.b1Tipo)}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">Voltagem</header>
										<Tooltip info={sb1010?.b1Voltag}>
											<span>{sb1010?.b1Voltag || '-'}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">Corrente</header>
										<Tooltip info={sb1010?.b1Corrent}>
											<span>{sb1010?.b1Corrent || '-'}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">Potência</header>
										<Tooltip info={sb1010?.b1Poten}>
											<span>{sb1010?.b1Poten || '-'}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Produto importado
										</header>
										<span>{sb1010?.b1Import === 'S' ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Pos. IPI/NCM
										</header>
										<Tooltip info={sb1010?.b1Posipi}>
											<span>{sb1010?.b1Posipi || '-'}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">Origem</header>
										<Tooltip info={sb1010?.b1Origem}>
											<span>{sb1010?.b1Origem || '-'}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Garantia extendida
										</header>
										<span>{sb1010?.b1Garant === 'S' ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Familia do Produto
										</header>
										<Tooltip info={sb1010?.b1Famprod}>
											<span>{sb1010?.b1Famprod || '-'}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Linha do Produto
										</header>
										<Tooltip info={filterSX5010('X1', sb1010?.b1Lprod)}>
											<span>{filterSX5010('X1', sb1010?.b1Lprod)}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Tipo de Familia
										</header>
										<Tooltip info={filterSX5010('X2', sb1010?.b1Fmprod)}>
											<span>{filterSX5010('X2', sb1010?.b1Fmprod)}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Alíquota de IPI
										</header>
										<Tooltip info={sb1010?.b1Ipi}>
											<span>{sb1010?.b1Ipi}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Calcula IPI?
										</header>
										<span>{sb1010?.b1Xipi === '1' ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Alíquota de ICMS
										</header>
										<Tooltip info={sb1010?.b1Icms}>
											<span>{sb1010?.b1Icms}</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Calcula Redução?
										</header>
										<span>{sb1010?.b1Xreduca === '1' ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Calcula ST?
										</header>
										<span>{sb1010?.b1Xst === '1' ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Bloqueado?
										</header>
										<span>{sb1010?.b1Msblql === '1' ? 'Sim' : 'Não'}</span>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Ultima Revisão
										</header>
										<Tooltip info={sb1010?.b1Urev}>
											<span>
												{handleProtheusDateToDate(
													sb1010?.b1Urev
												)?.toLocaleDateString('pt-BR', {
													timeZone: 'America/Sao_Paulo'
												}) ?? '-'}
											</span>
										</Tooltip>
									</div>
									<div className="product-details-items">
										<header className="product-details-items">
											Tipo Prod AT
										</header>
										<Tooltip info={filterSX5010('Z6', sb1010?.b5Tpprod)}>
											<span>{filterSX5010('Z6', sb1010?.b5Tpprod)}</span>
										</Tooltip>
									</div>
									<Divider title="Anexos do produto" />
									<div className="attachments">
										<div className="picture">
											<label>
												<span>Imagem</span>
											</label>
											<UploadSingleFile
												product={productDetails}
												id="picture"
												{...{ processUpload }}
											/>
										</div>
										<div className="docs">
											<label>
												<span>Documentação</span>
											</label>
											<UploadSingleFile
												product={productDetails}
												id="documentation"
												{...{ processUpload }}
											/>
										</div>
										<div className="technical">
											<label>
												<span>Ficha técnica</span>
											</label>
											<UploadSingleFile
												product={productDetails}
												id="spec-sheet"
												{...{ processUpload }}
											/>
										</div>
									</div>
								</div>
							</Section.container>
						</Section.root>
						{sb1010?.b1Famprod && (
							<Section.root>
								<Section.title>Informações da Familia</Section.title>
								<Section.container>
									<div className="body-container">
										<div className="product-details-items">
											<header className="product-details-items">Linha</header>
											<span title={szr010?.zrLinha}>{szr010?.zrLinha}</span>
										</div>
										<div className="product-details-items">
											<header className="product-details-items">Ativo</header>
											<span>{szr010?.zrAtivo ? 'Sim' : 'Não'}</span>
										</div>
										<div className="product-details-items">
											<header className="product-details-items">
												Site Vendas?
											</header>
											<span>{szr010?.zrVsite ? 'Sim' : 'Não'}</span>
										</div>
										<div className="product-details-items">
											<header className="product-details-items">Volumes</header>
											<span>{szr010?.zrVolumes}</span>
										</div>
										<div className="product-details-items">
											<header className="product-details-items">
												Desc. Acess
											</header>
											<span>{filterSX5010('Z6', sb1010?.b5Tpprod)}</span>
										</div>
										<div className="product-details-items descriptive">
											<header>Descritivo</header>
											<Markdown>{szr010?.zrDescrit}</Markdown>
										</div>
										<Divider title={`Volumes`} />
										<div className="product-details-volumes">
											{volumeList.map((item: any) => (
												<div
													className="product-details-volumes-item"
													key={item.volume}
												>
													<div className="product-details-volumes-items">
														<header>Volume:</header>
														<span>{item?.volume || '-'}</span>
													</div>
													<div className="product-details-volumes-items">
														<header>Altura:</header>
														<span>{item?.zrAlturv || '-'}</span>
													</div>
													<div className="product-details-volumes-items">
														<header>Largura:</header>
														<span>{item?.zrLargv || '-'}</span>
													</div>
													<div className="product-details-volumes-items">
														<header>Comprim.:</header>
														<span>{item?.zrComprv || '-'}</span>
													</div>
													<div className="product-details-volumes-items">
														<header>Peso Br.:</header>
														<span>{item?.zrPesov || '-'}</span>
													</div>
												</div>
											))}
										</div>
									</div>
								</Section.container>
							</Section.root>
						)}
					</div>
				</div>
			</div>
			{isLoading && <LoadingProgress />}
		</>
	)
}
