import ServiceOrdersResponse from 'models/ServiceOrderResponse'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import DeleteOS from 'module/technicalServices/components/modalDeleteOS'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getVldDelete } from 'services'
import MoreOptions from 'shared/components/MoreOptions'
import { KeyedMutator } from 'swr'
import { ViewType } from 'utils/Constants'
import './styles.scss'

type Props = {
	mutate: KeyedMutator<any>
	totalPages: number
	orderList: ServiceOrdersResponse[]
	handleChanges: () => void
}

const ServiceOrderListBody = ({
	mutate,
	orderList,
	totalPages,
	handleChanges
}: Props) => {
	const { viewType } = useContext(DeviceContextElement)
	const [showDeleteOS, setShowDeleteOS] = useState<boolean>(false)
	const [serviceOrderToDelete, setServiceOrderToDelete] =
		useState<ServiceOrdersResponse>()

	function handleDelete(serviceOrder: ServiceOrdersResponse) {
		getVldDelete(serviceOrder.service_order)
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						toast.error('Ordem de serviço não pode ser excluída')
					} else {
						setServiceOrderToDelete(serviceOrder)
						setShowDeleteOS(true)
					}
				}
			})
			.catch(() => {
				toast.error('Erro ao verificar se esta ordem pode ser excluída.')
			})
	}

	const handleCloseDeleteOS = () => {
		handleChanges()
		setShowDeleteOS(!showDeleteOS)
	}

	const serviceOrderItemMobile = (serviceOrder: ServiceOrdersResponse) => {
		return (
			<>
				<div className="service-order-item-third-line">
					<div className="service-order-item-atribute">
						<span className="service-order-item-atribute-info">Cliente:</span>
						<p className="service-order-item-atribute-value">
							{serviceOrder.client.nome_fantasia}
						</p>
					</div>
				</div>
				<div className="service-order-item-third-line">
					<div className="service-order-item-atribute">
						<span className="service-order-item-atribute-info">Equip:</span>
						<p className="service-order-item-atribute-value">
							{serviceOrder.equipment.name}
						</p>
					</div>
				</div>
				<div className="service-order-item-first-line">
					<div className="service-order-item-atribute">
						<span className="service-order-item-atribute-info">OS:</span>
						<p className="service-order-item-atribute-value">
							{serviceOrder.service_order}
						</p>
					</div>
					<div className="service-order-item-atribute">
						<span className="service-order-item-atribute-info">Data:</span>
						{serviceOrder?.created_os &&
							new Date(serviceOrder.created_os).toLocaleDateString('pt-BR')}
					</div>
				</div>
				<div className="service-order-item-first-line">
					<div className="service-order-item-atribute">
						<span className="service-order-item-atribute-info">N/S:</span>
						<p className="service-order-item-atribute-value">
							{serviceOrder.equipment.serial_number}
						</p>
					</div>
					<div className="service-order-item-atribute">
						<span className="service-order-item-atribute-info">UF:</span>
						<p className="service-order-item-atribute-value">
							{serviceOrder.state_sigla}
						</p>
					</div>
				</div>
				<div className="service-order-item-atribute">
					<span className="service-order-item-atribute-info">Tipo:</span>
					<p className="service-order-item-atribute-value">
						{serviceOrder.type}
					</p>
				</div>

				<div className="service-order-item-atribute">
					<span className="service-order-item-atribute-info">Status:</span>
					<p className="service-order-item-atribute-value">
						{serviceOrder.status}
					</p>
				</div>
				<div className="service-order-item-atribute">
					<span className="service-order-item-atribute-info">Follow-up:</span>
					<p className="service-order-item-atribute-value">
						{serviceOrder?.followup_date &&
							new Date(serviceOrder.followup_date).toLocaleDateString('pt-BR')}
					</p>
				</div>
				<div className="service-order-item-atribute">
					<span className="service-order-item-atribute-info">Atendente:</span>
					<p className="service-order-item-atribute-value">
						{serviceOrder.user_name ? serviceOrder.user_name : '-'}
					</p>
				</div>
			</>
		)
	}

	const serviceOrderItemWeb = (serviceOrder: ServiceOrdersResponse) => {
		return (
			<>
				<ul className="service-orders-list-item service-orders-list-created_at">
					{serviceOrder?.created_os &&
						new Date(serviceOrder.created_os).toLocaleDateString('pt-BR')}
				</ul>
				<ul className="service-orders-list-item service-orders-list-order">
					<span>{serviceOrder.service_order}</span>
					<span>{serviceOrder.user_name ? serviceOrder.user_name : '-'}</span>
				</ul>
				<ul className="service-orders-list-item service-orders-list-state">
					{serviceOrder.state_sigla}
				</ul>
				<ul className="service-orders-list-item service-orders-list-company-name">
					{serviceOrder.client.nome_fantasia}
				</ul>
				<ul className="service-orders-list-item service-orders-list-equipment-name">
					<span>{serviceOrder.equipment.name}</span>
				</ul>
				<ul className="service-orders-list-item service-orders-list-equipment-serie">
					{serviceOrder.equipment.serial_number}
				</ul>
				<ul className="service-orders-list-item service-orders-list-status">
					{serviceOrder.status}
				</ul>
				<ul className="service-orders-list-item service-orders-list-type">
					{serviceOrder.type}
				</ul>
				<ul className="service-orders-list-item service-orders-list-follow-up">
					{serviceOrder?.followup_date &&
						new Date(serviceOrder.followup_date).toLocaleDateString('pt-BR')}
				</ul>
			</>
		)
	}

	return (
		<div className="service-orders-list">
			{!!totalPages &&
				orderList?.map((serviceOrder: ServiceOrdersResponse, index) => {
					return (
						<div
							className="service-orders-list-row"
							key={index}
							id={serviceOrder.service_order}
						>
							<NavLink to={`/os/${serviceOrder.service_order}`}>
								{viewType !== ViewType.MOBILE
									? serviceOrderItemWeb(serviceOrder)
									: serviceOrderItemMobile(serviceOrder)}
							</NavLink>
							<ul className="service-orders-list-item service-orders-list-more-options">
								<MoreOptions
									id={serviceOrder.service_order}
									options={[
										{
											label: 'Excluir atendimento',
											onClick: () => handleDelete(serviceOrder)
										}
									]}
								/>
							</ul>
						</div>
					)
				})}
			{!!serviceOrderToDelete && showDeleteOS && (
				<DeleteOS
					serviceOrder={serviceOrderToDelete}
					onClose={handleCloseDeleteOS}
					mutate={mutate}
				/>
			)}
		</div>
	)
}
export default ServiceOrderListBody
