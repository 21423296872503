import { Button, Input, Modal } from '@praticabr/ppsa'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'
import { toast } from 'react-toastify'
import { clearNumericField, normalizePhoneNumber } from 'utils/Pipe'
import './styles.scss'

type TParams = {
	whatsappParams: {
		message: string
		phone?: string
	}
	onClose: (show: boolean) => void
}

interface IFormInput {
	phone: string
}

const WhatsAppSendConfirm = ({
	whatsappParams: { message, phone },
	onClose
}: TParams) => {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors }
	} = useForm<IFormInput>({
		defaultValues: { phone: normalizePhoneNumber(phone) }
	})
	const inputValue = watch('phone')

	useEffect(() => {
		setValue('phone', normalizePhoneNumber(inputValue))
	}, [inputValue])

	const onSubmit = (data: FieldValues) => {
		const contactNumber = clearNumericField(data.phone)
		if (contactNumber?.length != 11) {
			toast.error('Numero de telefone inválido!')
		} else {
			window.open(
				`https://api.whatsapp.com/send?phone=55${clearNumericField(
					data.phone
				)}&text=${message}`,
				'_blank'
			)
			onClose(false)
		}
	}

	return (
		<Modal.root>
			<Modal.header
				title="CONFIRME OS DADOS DO DESTINATÁRIO"
				onClose={() => onClose(false)}
			/>
			<Modal.body>
				<Modal.body.main>
					<div className="whatsapp-contain">
						<div className="whatsapp-contain-form">
							<label htmlFor="phone" className="whatsapp-contain-text">
								Whatsapp
							</label>
							<Input
								id="phone"
								type="text"
								variant="light"
								autoComplete="off"
								errors={errors.phone}
								placeholder="(00) 00000-0000"
								{...register('phone', {
									required: {
										value: true,
										message: 'O campo Whatsapp é obrigatório!'
									},
									minLength: {
										value: 15,
										message: 'O campo Whatsapp está incompleto!'
									}
								})}
							/>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="whatsapp-contain-actions">
						<Button
							size="lg"
							title="CONFIRMAR"
							variant="confirmation-solid"
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							size="lg"
							title="CANCELAR"
							variant="cancellation"
							onClick={() => onClose(false)}
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}

export default WhatsAppSendConfirm
