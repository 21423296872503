import { Input, Select } from '@praticabr/ppsa'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'
import {
	blockOptions,
	ClientChannelTypes,
	ClientSegmentTypes,
	ClientTypes,
	IndicatedClassificationType,
	PersonType,
	ValidatedStateRegistrationTypes
} from 'module/orderManagement/utils/constants'
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { store } from 'store'
import { normalizeCGC } from 'utils/Pipe'
import './styles.scss'

export const ClientInformationEditingMode = () => {
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const {
		register,
		setValue,
		getValues,
		formState: { errors }
	} = useFormContext<updateClientSchema>()
	const { salespersonList, clientGroups, regionList } =
		store.getState().baseLists
	const { roles } = useSelector((state: any) => state.user)
	const permBlockClient = roles?.includes('canBlockClient')

	return (
		<div className="client-information">
			<div className="client-information-contain-information">
				<header>Razão social</header>
				<Input
					id="a1Nome"
					variant="light"
					errors={errors.a1Nome}
					{...register('a1Nome')}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Nome fantasia</header>
				<Input
					id="a1Nreduz"
					variant="light"
					errors={errors.a1Nreduz}
					{...register('a1Nreduz')}
				/>
			</div>
			{sa1010?.a1Cod && (
				<div className="client-information-contain-information">
					<header>Código protheus</header>
					<span>{sa1010?.a1Cod}</span>
				</div>
			)}
			<div className="client-information-contain-information">
				<header>Tipo de pessoa</header>
				<Select
					id="a1Pessoa"
					options={PersonType}
					errors={errors.a1Pessoa}
					innerRef={{ ...register('a1Pessoa') }}
					selectedOption={[getValues('a1Pessoa')]}
					onSelectOptions={(option) => setValue('a1Pessoa', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>CNPJ/CPF</header>
				<Input
					id="a1Cgc"
					variant="light"
					errors={errors.a1Cgc}
					{...register('a1Cgc')}
					onChange={(e) => setValue('a1Cgc', normalizeCGC(e.target.value))}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>IE</header>
				<Input
					id="a1Inscr"
					variant="light"
					errors={errors.a1Inscr}
					{...register('a1Inscr')}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>IE Validada</header>
				<Select
					id="a1Valie"
					autoComplete="off"
					errors={errors.a1Valie}
					innerRef={{ ...register('a1Valie') }}
					selectedOption={[getValues('a1Valie')]}
					options={ValidatedStateRegistrationTypes}
					onSelectOptions={(option) => setValue('a1Valie', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Segmento</header>
				<Select
					id="a1Ytipocl"
					errors={errors.a1Ytipocl}
					options={ClientSegmentTypes}
					innerRef={{ ...register('a1Ytipocl') }}
					selectedOption={[getValues('a1Ytipocl')]}
					onSelectOptions={(option) => setValue('a1Ytipocl', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Tipo de cliente</header>
				<Select
					id="a1Tipo"
					options={ClientTypes}
					errors={errors.a1Tipo}
					innerRef={{ ...register('a1Tipo') }}
					selectedOption={[getValues('a1Tipo')]}
					onSelectOptions={(option) => setValue('a1Tipo', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Canal cliente</header>
				<Select
					id="a1Canal"
					errors={errors.a1Canal}
					options={ClientChannelTypes}
					innerRef={{ ...register('a1Canal') }}
					selectedOption={[getValues('a1Canal')]}
					onSelectOptions={(option) => setValue('a1Canal', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Class. indicada</header>
				<Select
					id="a1Classif"
					options={IndicatedClassificationType}
					innerRef={{ ...register('a1Classif') }}
					selectedOption={[getValues('a1Classif')]}
					onSelectOptions={(option) => setValue('a1Classif', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>SUFRAMA</header>
				<Input
					variant="light"
					id="a1Suframa"
					errors={errors.a1Suframa}
					{...register('a1Suframa')}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Grupo cliente</header>
				<Select
					id="a1Grppra"
					options={clientGroups}
					errors={errors.a1Grppra}
					innerRef={{ ...register('a1Grppra') }}
					selectedOption={[getValues('a1Grppra')]}
					onSelectOptions={(option) => setValue('a1Grppra', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Vendedor</header>
				<Select
					id="a1Repres"
					tooltip="right"
					options={salespersonList}
					innerRef={{ ...register('a1Repres') }}
					selectedOption={[getValues('a1Repres')]}
					onSelectOptions={(option) => setValue('a1Repres', option[0])}
				/>
			</div>
			<div className="client-information-contain-information">
				<header>Região</header>
				<Select
					id="a1Regiao"
					options={regionList}
					errors={errors.a1Regiao}
					innerRef={{ ...register('a1Regiao') }}
					selectedOption={[getValues('a1Regiao')]}
					onSelectOptions={(option) => setValue('a1Regiao', option[0])}
				/>
			</div>
			{permBlockClient && (
				<div className="client-information-contain-information">
					<header>Bloqueio financeiro</header>
					<Select
						id="a1Xblfin"
						options={blockOptions}
						errors={errors.a1Xblfin}
						innerRef={{ ...register('a1Xblfin') }}
						selectedOption={[getValues('a1Xblfin') || '']}
						onSelectOptions={(option) => setValue('a1Xblfin', option[0])}
					/>
				</div>
			)}
		</div>
	)
}
