import { ListType } from '@praticabr/ppsa'
import { ModalUserJacCreate } from './ModalUserJacCreate'
import { ModalUserJacEditing } from './ModalUserJacEditing'

export const optionsStores: ListType[] = [
	{
		label: 'Espirito Santo',
		value: 'ES'
	},
	{
		label: 'Minas Gerais',
		value: 'MG'
	},
	{
		label: 'Rio de Janeiro',
		value: 'RJ'
	},
	{
		label: 'São Paulo',
		value: 'SP'
	},
	{
		label: 'Todas',
		value: '3'
	}
]

export const ModalUsersJac = {
	create: ModalUserJacCreate,
	editing: ModalUserJacEditing
}
