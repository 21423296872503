import { zodResolver } from '@hookform/resolvers/zod'
import { Button, LoadingProgress } from '@praticabr/ppsa'
import { ClientAccountingInformationSection } from 'module/orderManagement/components/ClientAccountingInformationSection'
import { ClientAddressInformationSection } from 'module/orderManagement/components/ClientAddressInformationSection'
import { ClientInformationSection } from 'module/orderManagement/components/ClientInformationSection'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'
import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import './styles.scss'

const progressKeys = [
	[
		'a1Nome',
		'a1Nreduz',
		'a1Pessoa',
		'a1Cgc',
		'a1Inscr',
		'a1Valie',
		'a1Ytipocl',
		'a1Tipo',
		'a1Canal',
		'a1Classif',
		'a1Suframa',
		'a1Grppra',
		'a1Repres',
		'a1Regiao'
	],
	[
		'a1Contato',
		'a1Ddd',
		'a1Tel',
		'a1Telex',
		'a1Email',
		'a1End',
		'a1Bairro',
		'a1Est',
		'a1Cep',
		'a1CodMun'
	],
	['a1Endcob', 'a1Bairroc', 'a1Munc', 'a1Cepc', 'a1Estc'],
	['a1Naturez', 'a1Conta', 'a1Ccusto', 'a1Contrib']
]

export const NewClient = () => {
	const { sa1010IsLoading, onCreateClient } = useContext(
		ClientDetailsContextElement
	)
	const [progress, setProgress] = useState<number>(0)
	const methods = useForm<updateClientSchema>({
		resolver: zodResolver(updateClientSchema),
		defaultValues: {
			a1Naturez: '1.01001',
			a1Conta: '1120100001',
			a1Tipo: 'F',
			a1Canal: '2',
			a1Grppra: '99'
		}
	})

	const onValidateByProgress = (e: any) => {
		const keys = Object.keys(e)

		let requiredFieldsProgress: string[] = []
		if (progress === 0) {
			requiredFieldsProgress = [...progressKeys[0]]

			if (!keys.filter((k) => requiredFieldsProgress.includes(k))?.length) {
				methods.clearErrors([
					...(progressKeys[1] as any),
					...(progressKeys[2] as any),
					...(progressKeys[3] as any)
				])
				return setProgress(1)
			}
		}

		if (progress === 1) {
			requiredFieldsProgress = [...progressKeys[0], ...progressKeys[1]]

			const sameBillingAddress = methods.getValues('sameBillingAddress')
			if (!sameBillingAddress) {
				requiredFieldsProgress.push(...progressKeys[2])
			}

			if (!keys.filter((k) => requiredFieldsProgress.includes(k))?.length) {
				methods.clearErrors(...(progressKeys[3] as any))
				return setProgress(2)
			}
		}
	}

	return (
		<FormProvider {...methods}>
			<form
				autoComplete="off"
				className="new-client-details-tab"
				onSubmit={methods.handleSubmit(onCreateClient, onValidateByProgress)}
			>
				<ClientInformationSection.Root>
					<ClientInformationSection.EditingMode />
				</ClientInformationSection.Root>
				{progress > 0 && (
					<ClientAddressInformationSection.Root>
						<ClientAddressInformationSection.EditingMode />
					</ClientAddressInformationSection.Root>
				)}
				{progress > 1 && (
					<ClientAccountingInformationSection.Root>
						<ClientAccountingInformationSection.EditingMode />
					</ClientAccountingInformationSection.Root>
				)}
				<div className="new-order-details-tab-actions">
					<Button
						size="lg"
						variant="confirmation-solid"
						title={progress < 2 ? 'PROSSEGUIR' : 'CONFIRMA'}
					/>
					<Button
						size="lg"
						type="button"
						title="CANCELAR"
						disabled={sa1010IsLoading}
						variant="cancellation"
						onClick={() => window.location.replace(`/clients`)}
					/>
				</div>
			</form>
			{sa1010IsLoading && <LoadingProgress text="Cadastrando..." />}
		</FormProvider>
	)
}
