import { Button, LoadingProgress } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { OrderItemsSection } from 'module/orderManagement/components/NewItensSection'
import { OrderItemsSearch } from 'module/orderManagement/components/NewItensSection/OrderItemsSearch'
import { OrderDetailsSection } from 'module/orderManagement/components/OrderDetailsSection'
import OrderMenuTabs from 'module/orderManagement/components/OrderMenuTabs'
import { PaymentDetailsSection } from 'module/orderManagement/components/PaymentSection'
import { ShippingDetailsSection } from 'module/orderManagement/components/ShippingDetailsSection'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { Order } from 'module/orderManagement/domain/models/order'
import { AddOrder } from 'module/orderManagement/domain/usecases/add-order'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { FaPencilAlt } from 'react-icons/fa'
import { RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import { simulateOrder, updateOrder } from 'services'
import { ViewType } from 'utils/Constants'
import './styles.scss'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
}

function isObjectEmpty(obj: object): boolean {
	return Object.keys(obj)?.length === 0
}

export const OrderDetails: React.FC<TParams> = ({ match }) => {
	const { viewType } = useContext(DeviceContextElement)
	const { order, onOrderChange, sc5010, sc5IsLoading, orderPrpIsLoading } =
		useContext(OrderDetailsContextElement)
	const { sa1010, sa1010IsLoading } = useContext(ClientDetailsContextElement)

	const [searchProductShowModal, setSearchProductShowModal] =
		useState<boolean>(false)
	const [hasSimulated, setHasSimulated] = useState<boolean>(false)
	const [simulatedOrder, setSimulatedOrder] = useState<Order>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const { orderCode, branch } = match.params

	useEffect(() => {
		setSimulatedOrder(order)
	}, [order])

	useEffect(() => {
		if (order?.code !== orderCode) {
			onOrderChange(orderCode, branch)
		}
	}, [orderCode])

	const detailForm = useForm<AddOrder.OrderDetailsForm>({
		defaultValues: {
			type: 'I',
			category: '',
			price_table: '',
			fair_order: 'NF',
			salesperson_code: '000027',
			message_invoice: '',
			client_code: '',
			client_branch: '',
			service_order_code: '',
			financial_release: '',
			order_origin: 'PRP'
		}
	})

	const watchedPriceTable = detailForm.watch('price_table')

	const { client_code, price_table } = useWatch({ control: detailForm.control })

	const shippingForm = useForm<AddOrder.ShippingForm>({
		defaultValues: {
			freight_type: 'C',
			re_delivery_shipping_company: '',
			shipping_company: '',
			partial_delivery: '',
			release_shipment: '',
			freight_cost: 0,
			embedded_shipping: 0,
			commercial_freight: 0,
			package_type: '',
			package_amount: 0,
			net_weight: 0,
			gross_weight: 0
		}
	})

	const { freight_cost, embedded_shipping, commercial_freight } = useWatch({
		control: shippingForm.control
	})

	const itemsForm = useForm<AddOrder.ItemsForm>()

	const itemsFieldArray = useFieldArray({
		control: itemsForm.control,
		name: 'items'
	})

	const { items } = useWatch({ control: itemsForm.control })

	useEffect(() => {
		if (order?.items) {
			itemsFieldArray.replace(
				order?.items.map((item: any) => {
					const newItemsDefaults = {
						item: item.item,
						code: item?.code,
						description: item.description,
						amount: item.amount,
						delivery_date: item.delivery_date,
						rescheduled_delivery_date: item.rescheduled_delivery_date,
						embedded_freight: item.embedded_freight,
						discount: item.discount || 0,
						table_price: item.table_price,
						unit_price: item.unit_price,
						invoice: item.invoice || '-',
						invoice_series: item.invoice_series || '-',
						net_price_total: item.price_total,
						option: item.option,
						storage: item.storage,
						serial_number: item.serial_number,
						has_accessories: item.accessories?.length ? '1' : '2',
						suframa_value: item.suframa_value,
						purchase_order_item: item.purchase_order_item,
						purchase_order_number: item.purchase_order_number,
						installation_value: item.installation_value,
						accessories: item.accessories,
						deleted: false
					}
					return newItemsDefaults
				})
			)
		}
	}, [order?.items])

	const paymentForm = useForm<AddOrder.PaymentForm>({
		defaultValues: {
			payment_code: '',
			branch: '',
			discount: 0,
			discount_one: 0,
			discount_two: 0,
			discount_three: 0,
			addition: 0,
			price_total: 0,
			credit_card_authorization: ''
		}
	})

	const watchedPayment = useWatch({
		control: paymentForm.control
	})

	const installmentForm = useForm<AddOrder.InstallmentForm>()

	const installmentFieldArray = useFieldArray({
		control: installmentForm.control,
		name: 'installments'
	})

	useEffect(() => {
		if (order?.installments) {
			installmentFieldArray.replace(
				order?.installments.map((item: any) => {
					const newInstallment = {
						value: item.value,
						date: item.date
					}
					return newInstallment
				})
			)
		}
	}, [order?.installments])

	useEffect(() => {
		if (hasSimulated) {
			setHasSimulated(false)
		}
	}, [
		client_code,
		price_table,
		freight_cost,
		embedded_shipping,
		commercial_freight,
		items,
		watchedPayment.branch
	])

	const handleClick = async (isUpdate: boolean) => {
		await detailForm.handleSubmit(() => {})()
		await shippingForm.handleSubmit(() => {})()
		await itemsForm.handleSubmit(() => {})()
		await paymentForm.handleSubmit(() => {})()
		await installmentForm.handleSubmit(() => {})()

		const detailsErrors = isObjectEmpty(detailForm.formState.errors)
		const itemsErrors = isObjectEmpty(itemsForm.formState.errors)
		const shippingErrors = isObjectEmpty(shippingForm.formState.errors)
		const paymentErrors = isObjectEmpty(paymentForm.formState.errors)
		const installmentErrors = isObjectEmpty(installmentForm.formState.errors)

		if (
			detailsErrors &&
			itemsErrors &&
			shippingErrors &&
			paymentErrors &&
			installmentErrors
		) {
			if (itemsFieldArray.fields?.length > 0) {
				const detailsValues = detailForm.getValues()
				const shippingValues = shippingForm.getValues()
				const itemsValues = itemsForm.getValues()
				const paymentValues = paymentForm.getValues()
				const installValues = installmentForm.getValues()

				const newOrder = {
					...detailsValues,
					...shippingValues,
					...itemsValues,
					...paymentValues,
					...installValues,
					message_invoice: detailsValues.message_invoice
						.normalize('NFD')
						.replace(/[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e]/g, '')
				}

				hasSimulated && isUpdate ? onUpdate(newOrder) : onSimulate(newOrder)
			}
		} else if (!itemsErrors) {
			toast.error('Preencha todos os campos obrigatórios dos itens')
			if (
				itemsForm &&
				itemsForm.formState &&
				itemsForm.formState.errors &&
				Array.isArray(itemsForm.formState.errors.items)
			) {
				let isFirstItemProcessed = false
				itemsForm.formState.errors.items.forEach((item, index) => {
					if (item !== undefined && !isFirstItemProcessed) {
						const detailsElement = document.getElementById(
							`items-list-details-${index}`
						) as HTMLDetailsElement
						if (detailsElement) {
							detailsElement.open = true
							detailsElement.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							})
						}
						isFirstItemProcessed = true
					}
				})
			}
		}
	}

	const onSimulate = (newOrder: any) => {
		setIsLoading(true)
		simulateOrder(newOrder)
			.then((res) => {
				const { data } = res.data
				if (data) {
					setSimulatedOrder(data)
				}
				setHasSimulated(true)
				toast.success('Pedido simulado com sucesso!')
			})
			.catch((error) => {
				const toastMessage = error.response.data?.error?.message
				toast.error(
					toastMessage ||
						'Não foi possível simular o pedido, revise os dados e tente novamente'
				)
			})
			.finally(() => setIsLoading(false))
	}

	const onUpdate = (newOrder: any) => {
		setIsLoading(true)
		updateOrder(branch, orderCode, newOrder)
			.then((res) => {
				const { data } = res.data
				setIsEditing(false)
				setSimulatedOrder(data)
				if (!!data?.branch && !!data?.code) {
					toast.success('Pedido atualizado com sucesso!')
					setTimeout(() => {
						window.location.reload()
					}, 500)
				}
			})
			.catch((error) => {
				const toastMessage = error.response.data?.error?.message
				toast.error(
					toastMessage ||
						'Erro ao atualizar o pedido, revise os dados e tente novamente'
				)
			})
			.finally(() => setIsLoading(false))
	}

	const renderConfirm = () => (
		<div
			style={{
				marginTop: '20px',
				width: '100%',
				display: 'grid',
				gridTemplateColumns: '128px 128px',
				justifyContent: 'center',
				gap: '20px'
			}}
		>
			<Button
				size="lg"
				title="CONFIRMAR"
				variant="confirmation-solid"
				disabled={
					sc5IsLoading ||
					!hasSimulated ||
					!!sc5010?.c5Nota ||
					orderPrpIsLoading ||
					(sc5010?.c5Liberok === 'E' && !sc5010?.c5Blq)
				}
				onClick={() => handleClick(true)}
			/>
			<Button
				variant="cancellation"
				title="CANCELAR"
				size="lg"
				disabled={isLoading}
				onClick={() => {
					setIsEditing(false)
					setSimulatedOrder(order)
				}}
			/>
		</div>
	)

	const viewingModeContent = () =>
		simulatedOrder && (
			<>
				<OrderDetailsSection.Root>
					<OrderDetailsSection.ViewingMode order={simulatedOrder} />
				</OrderDetailsSection.Root>
				<ShippingDetailsSection.Root>
					<ShippingDetailsSection.ViewingMode order={simulatedOrder} />
				</ShippingDetailsSection.Root>
				<OrderItemsSection.Root>
					<OrderItemsSection.MainContain>
						{viewType !== ViewType.MOBILE && <OrderItemsSection.Header />}
						<OrderItemsSection.ViewingMode order={simulatedOrder} />
					</OrderItemsSection.MainContain>
				</OrderItemsSection.Root>
				<PaymentDetailsSection.Root>
					<PaymentDetailsSection.ViewingMode order={simulatedOrder}>
						<PaymentDetailsSection.TaxesContent order={simulatedOrder} />
						<PaymentDetailsSection.DiscountsContent order={simulatedOrder} />
						<PaymentDetailsSection.TotalsContent order={simulatedOrder} />
					</PaymentDetailsSection.ViewingMode>
				</PaymentDetailsSection.Root>
			</>
		)

	const editingModeContent = () => (
		<>
			<OrderDetailsSection.Root>
				<OrderDetailsSection.EditingMode
					isEditing
					form={detailForm}
					order={simulatedOrder}
				/>
			</OrderDetailsSection.Root>
			<ShippingDetailsSection.Root>
				<ShippingDetailsSection.EditingMode
					form={shippingForm}
					order={simulatedOrder}
				/>
			</ShippingDetailsSection.Root>
			<OrderItemsSection.Root>
				<OrderItemsSection.MainContain>
					{viewType !== ViewType.MOBILE && <OrderItemsSection.Header />}
					<OrderItemsSection.EditingMode
						isEditing
						form={itemsForm}
						order={simulatedOrder}
						table={watchedPriceTable}
						fieldArray={itemsFieldArray}
						branch={watchedPayment.branch}
					/>
				</OrderItemsSection.MainContain>
				<OrderItemsSection.AddItemButton
					showModal={(e) => setSearchProductShowModal(e)}
				/>
			</OrderItemsSection.Root>
			<PaymentDetailsSection.Root>
				<PaymentDetailsSection.EditingMode
					branch={branch}
					order={simulatedOrder}
					paymentForm={paymentForm}
					installmentForm={installmentForm}
					fieldArray={installmentFieldArray}
					handleSimulateClick={() => handleClick(false)}
				>
					<PaymentDetailsSection.TaxesContent order={simulatedOrder} />
					<PaymentDetailsSection.DiscountsContent order={simulatedOrder} />
					<PaymentDetailsSection.TotalsContent order={simulatedOrder} />
				</PaymentDetailsSection.EditingMode>
			</PaymentDetailsSection.Root>
			{renderConfirm()}
		</>
	)

	return (
		<>
			{<OrderMenuTabs path={match.path} />}
			<TabTitles />
			{simulatedOrder && (
				<div className="order-details-tab">
					<div className="section-container-action-edit">
						<Button
							title={
								sc5010?.c5Liberok === 'E' && !sc5010?.c5Blq ? (
									<span className="edit-button">
										{viewType === ViewType.MOBILE && <FaPencilAlt size={28} />}
										{!(viewType === ViewType.MOBILE) && 'Bloqueado'}
									</span>
								) : sc5010?.c5Nota ? (
									<span className="edit-button">
										{viewType === ViewType.MOBILE && <FaPencilAlt size={28} />}
										{!(viewType === ViewType.MOBILE) && 'Faturado'}
									</span>
								) : (
									<span className="edit-button">
										{viewType === ViewType.MOBILE && <FaPencilAlt size={28} />}
										{!(viewType === ViewType.MOBILE) && 'Editar'}
									</span>
								)
							}
							size="lg"
							disabled={
								isEditing ||
								!!sc5010?.c5Nota ||
								(sc5010?.c5Liberok === 'E' && !sc5010?.c5Blq) ||
								sc5010?.c5Msblql === '1' ||
								sa1010?.a1Msblql === '1'
							}
							variant="confirmation-solid"
							onClick={() => setIsEditing(true)}
						/>
					</div>
					{isEditing ? editingModeContent() : viewingModeContent()}
				</div>
			)}
			{(sc5IsLoading || orderPrpIsLoading || isLoading) && (
				<LoadingProgress text="Carregando dados do pedido..." />
			)}
			{searchProductShowModal && (
				<OrderItemsSearch
					table={watchedPriceTable}
					fieldArray={itemsFieldArray}
					onClose={() => setSearchProductShowModal(false)}
				/>
			)}
		</>
	)
}
