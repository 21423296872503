import styled from 'styled-components'

export const Footer = styled.div`
	display: flex;
	column-gap: 20px;
	justify-content: center;
`

export const Description = styled.div`
	font-size: 13px;
	padding-bottom: 10px !important;
	font-weight: 700;
`
