import { Button } from '@praticabr/ppsa'
import React from 'react'

type Props = {
	text: string
	onClick?: () => void
}

export const ButtonHeader = ({ text, onClick }: Props) => {
	return (
		<Button
			{...{ onClick }}
			title={text}
			variant="confirmation-solid"
			size="lg"
		/>
	)
}
