import { Button, Input, LoadingProgress, Modal } from '@praticabr/ppsa'
import LoginInput from 'models/LoginInput'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { newPass } from 'services'
import { signInRequest } from 'store/modules/auth/actions'
import './styles.scss'

const PASSWORD_REGEX =
	'(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}'

interface Params {
	email: string
	authToken?: string
	handleCancel: () => void
}

const RenewPassForm: React.FC<Params> = ({ email, handleCancel }) => {
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors }
	} = useForm<LoginInput>()

	const onSubmit = (data: LoginInput) => {
		data.email = email
		if (data.newPassword === data.password) {
			setError('newPassword', {
				message: 'A senha atual e a nova senha não podem ser iguais!'
			})
			return
		}
		if (data.newPassword !== data.confirmPassword) {
			setError('confirmPassword', {
				message: 'Confirmar nova senha e Nova senha não são iguais '
			})
			return
		}

		setIsLoading(true)
		const newData = {
			email: email,
			password: data.password,
			newPassword: data.newPassword
		}

		newPass(newData)
			.then((response) => {
				setIsLoading(false)
				if (response.status === 200) {
					toast.success('Senha alterada com sucesso!')
					dispatch(signInRequest(email, data.newPassword))
					handleCancel()
				}
			})
			.catch((error) => {
				toast.error(error.request.response.error.message)
				setIsLoading(false)
			})
	}

	const renewPasswordContain = () => {
		return (
			<form className="renew-password-form">
				<div className="renew-password-form-item">
					<label className="renew-password-form-item-descriptive">
						Senha Atual
					</label>
					<Input
						id="password"
						variant="light"
						type={'password'}
						errors={errors.password}
						placeholder="Digite sua senha atual"
						{...register('password', {
							shouldUnregister: true,
							required: { value: true, message: 'O campo é obrigatório!' },
							maxLength: {
								value: 20,
								message: 'O campo passou seu limite de caracteres!'
							}
						})}
					/>
				</div>
				<div className="renew-password-form-item">
					<label className="renew-password-form-item-descriptive">
						Nova senha
					</label>
					<Input
						id="newPassword"
						variant="light"
						type={'password'}
						errors={errors.newPassword}
						placeholder="Digite sua nova senha"
						{...register('newPassword', {
							shouldUnregister: true,
							required: { value: true, message: 'O campo é obrigatório!' },
							minLength: {
								value: 8,
								message: 'O campo não atingiu o mínimo de caracteres!'
							},
							maxLength: {
								value: 20,
								message: 'O campo passou seu limite de caracteres!'
							},
							pattern: {
								value: RegExp(PASSWORD_REGEX),
								message: 'O campo não atende o formato correto!'
							}
						})}
					/>
				</div>
				<div className="renew-password-form-item">
					<label className="renew-password-form-item-descriptive">
						Confirme a nova senha
					</label>
					<Input
						id="confirmPassword"
						variant="light"
						type={'password'}
						errors={errors.confirmPassword}
						placeholder="Digite sua nova senha"
						{...register('confirmPassword', {
							shouldUnregister: true,
							required: { value: true, message: 'O campo é obrigatório!' },
							minLength: {
								value: 8,
								message: 'O campo não atingiu o mínimo de caracteres!'
							},
							maxLength: {
								value: 20,
								message: 'O campo passou seu limite de caracteres!'
							},
							pattern: {
								value: RegExp(PASSWORD_REGEX),
								message: 'O campo não atende o formato correto!'
							}
						})}
					/>
				</div>
				<span className="renew-password-roles-info">
					Sua nova senha deve estar entre 8 e 20 caracteres, ter uma letra
					maiúscula, uma minúscula, um número e um caractere especial.
				</span>
			</form>
		)
	}

	return (
		<Modal.root>
			<Modal.header title="Alterar Senha" onClose={() => handleCancel()} />
			<Modal.body>
				<Modal.body.main>
					<div className="renew-password-contain">{renewPasswordContain()}</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="renew-password-actions">
						<Button
							size="lg"
							title="SALVAR"
							variant="confirmation-solid"
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							size="lg"
							title="CANCELAR"
							variant="cancellation"
							onClick={() => handleCancel()}
						/>
					</div>
				</Modal.body.actions>
				{isLoading && <LoadingProgress text="Carregando..." />}
			</Modal.body>
		</Modal.root>
	)
}

export default RenewPassForm
