import { IconButton, TextField } from '@material-ui/core'
import { LoadingProgress, Paginator } from '@praticabr/ppsa'
import { Notification } from 'models/Notification'
import {
	NotificationProfile,
	NotificationTypes
} from 'models/notificationProfile'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import NotificationsConfig from 'module/technicalServices/components/NotificationsConfig'
import NotificationsList from 'module/technicalServices/components/NotificationsList'
import React, { useContext, useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	getAllNotifications,
	getNotificationsProfile,
	getNotificationsTypes,
	removeNotification,
	updateNotificationStatus
} from 'services'
import MobileNavigator from 'shared/components/MobileNavigator'
import { store } from 'store'
import { ViewType } from 'utils/Constants'
import './styles.css'

const PAGE_LIMIT = 10

type TParams = { history: string; clientCode: string }

const Notifications: React.FC<RouteComponentProps<TParams>> = ({
	history
}: RouteComponentProps<TParams>) => {
	const { viewType } = useContext(DeviceContextElement)
	const [userProfile, setUserProfile] = useState<NotificationProfile>()
	const [notificationsTypes, setNotificationsTypes] = useState<
		NotificationTypes[]
	>([])
	const [notifications, setNotifications] = useState<Notification[]>([])
	const [searchValue, setSearchValue] = useState<string>('')
	const [dateFilter, setDateFilter] = useState<string>('desc')
	const [statusFilter, setStatusFilter] = useState<string>('asc')
	const [notificationsCount, setNotificationsCount] = useState<number>(0)
	const [totalPages, setTotalPages] = useState<number>(0)
	const [pageNumber, setPageNumber] = useState<number>(1)
	const [showConfig, setShowConfig] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const userId = store.getState().user.profile

	useEffect(() => {
		getNotificationsConfig()
	}, [pageNumber])

	useEffect(() => {
		getNotifications()
	}, [pageNumber, dateFilter, statusFilter])

	const getNotifications = () => {
		const userId = store.getState().user.profile
		const offset = (pageNumber - 1) * PAGE_LIMIT
		const search = searchValue
		const dateOrder = dateFilter
		const statusOrder = statusFilter

		setLoading(true)
		getAllNotifications(
			userId,
			PAGE_LIMIT,
			offset,
			dateOrder,
			statusOrder,
			search
		)
			.then((res) => {
				const notificationList = res.data.response
					.notifications as Notification[]
				const count = res.data.response.count
				setTotalPages(Math.ceil(count / PAGE_LIMIT))
				setNotificationsCount(count)
				setNotifications(notificationList)
				setLoading(false)
			})
			.catch(() => {
				setLoading(false)
				toast.error('Houve um erro ao buscar as últimas notificações!')
			})
	}

	const getNotificationsConfig = () => {
		getNotificationsTypes()
			.then((res) => {
				setNotificationsTypes(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
		getNotificationsProfile(userId)
			.then((res) => {
				setUserProfile(res.data.response)
			})
			.catch((err) => {
				console.log(err)
				toast.error(
					'Houve um erro ao carregar o perfil de notificações do usuário!'
				)
			})
	}

	const changeStatus = (id: number, isReading?: boolean) => {
		updateNotificationStatus(id, isReading)
			.then(() => {
				getNotifications()
			})
			.catch(() => {
				toast.error('Houve um erro ao atualizar status da notificação!')
			})
	}

	const onRemoveNotification = (id: number) => {
		removeNotification(id)
			.then(() => {
				getNotifications()
			})
			.catch(() => {
				toast.error('Houve um erro ao excluir notificação!')
			})
	}

	const onItemClick = (osNumber: string) => {
		if (osNumber) {
			history.push(`/os/${osNumber}`)
		} else {
			history.push(`/transfers`)
		}
	}

	const onSearchChange = (value: string) => {
		setSearchValue(value)
	}

	const onSearchClick = async () => {
		if (searchValue) {
			setTotalPages(0)
			await getNotifications()
		}
	}

	const handleChangePage = (page: number) => {
		setPageNumber(page)
	}

	const applyDateFilter = (order: string) => {
		setDateFilter(order)
	}

	const applyStatusFilter = (order: string) => {
		setStatusFilter(order)
	}

	const notificationsConfigToogle = () => {
		if (showConfig) {
			getNotificationsConfig()
		}
		setShowConfig(!showConfig)
	}

	const searchContent = () => {
		return (
			<div className="header-content">
				<div className="config" onClick={notificationsConfigToogle}>
					Configurações
				</div>
				<div className="search">
					<TextField
						id="search"
						className="search-field"
						placeholder="Pesquisar"
						value={searchValue}
						onChange={(e) => {
							onSearchChange(e.target.value)
						}}
						onKeyPress={(e) => {
							const value = $('.search-field').find('input').val()?.toString()
							if (e.key == 'Enter' && value) {
								onSearchChange(value)
								onSearchClick()
							}
						}}
					/>
					<IconButton
						color="primary"
						component="span"
						className="icon-button"
						onClick={() => {
							onSearchClick()
						}}
					>
						<MdSearch />
					</IconButton>
				</div>
			</div>
		)
	}

	const notifyListContent = () => {
		return (
			<NotificationsList
				notifications={notifications}
				notificationsCount={notificationsCount}
				onItemClick={onItemClick}
				changeStatus={changeStatus}
				onRemoveClick={onRemoveNotification}
				dateFilter={dateFilter}
				statusFilter={statusFilter}
				setDateFilter={applyDateFilter}
				setStatusFilter={applyStatusFilter}
			/>
		)
	}

	const pagListContent = () => {
		return (
			<div className="pagination-content">
				<Paginator
					totalPages={totalPages}
					currentPage={pageNumber}
					onPageChange={handleChangePage}
				/>
			</div>
		)
	}

	const configNotificationsContent = () => {
		return showConfig && userProfile ? (
			<div className="modal notifications-config">
				<NotificationsConfig
					types={notificationsTypes}
					userProfile={userProfile}
					onClose={notificationsConfigToogle}
				/>
			</div>
		) : (
			<></>
		)
	}

	return (
		<>
			<div className="notification-page-container">
				<div className="notification-page-header">
					{viewType === ViewType.MOBILE && (
						<MobileNavigator action={() => history.goBack()} />
					)}
					<h3>Central de Notificações</h3>
				</div>
				<div className="notification-page-content">
					{loading ? (
						<LoadingProgress text="Carregando..." />
					) : (
						<>
							{searchContent()}
							{notifyListContent()}
							{pagListContent()}
						</>
					)}
				</div>
			</div>

			{configNotificationsContent()}
		</>
	)
}

export default Notifications
