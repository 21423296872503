import { EmptyList, Paginator } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import ClientMenuTabs from 'module/orderManagement/components/ClientMenuTabs'
import OrderHistoryListBody from 'module/orderManagement/components/OrderHistoryListBody'
import OrderHistoryListHeader from 'module/orderManagement/components/OrderHistoryListHeader'
import OrdersHistoryActions from 'module/orderManagement/components/OrdersHistoryActions'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderHistoryContextElement } from 'module/orderManagement/context/OrderHistoryContext/OrderHistoryContext'
import React, { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ViewType } from 'utils/Constants'
import './styles.scss'

type ClientOrdersHistoryProps = RouteComponentProps & {
	match: {
		params: {
			clientCode: string
			clientStoreCode: string
		}
	}
}

const ClientOrdersHistory = ({ match }: ClientOrdersHistoryProps) => {
	const { viewType } = useContext(DeviceContextElement)
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const { isLoading, totalPages, currentPage, onPageChange } = useContext(
		OrderHistoryContextElement
	)

	return sa1010 ? (
		<>
			<ClientMenuTabs path={match.path} />
			<TabTitles />
			<div className="client-order-history-tab">
				<OrdersHistoryActions />
				<div className="client-order-history-table">
					{totalPages ? (
						<>
							{viewType !== ViewType.MOBILE && <OrderHistoryListHeader />}
							<OrderHistoryListBody />
						</>
					) : (
						<EmptyList text="Pedido não encontrado" />
					)}
				</div>
			</div>
			<div className="client-order-history-paginator">
				<Paginator
					totalPages={totalPages}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			</div>
		</>
	) : (
		<EmptyList text="Histórico de pedidos do cliente não encontrado" />
	)
}
export default ClientOrdersHistory
