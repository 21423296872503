import { Button, LoadingProgress } from '@praticabr/ppsa'
import { ModalInstall } from 'module/installAndSearch/components/Modals/ModalInstall'
import { ModalNumSerieNotFound } from 'module/installAndSearch/components/Modals/ModalSerialNumberNotFound'
import { ModalSuccessOpenOs } from 'module/installAndSearch/components/Modals/ModalSuccessOpenOs'
import {
	SearchSerial,
	ValuesForm
} from 'module/installAndSearch/components/SearchSerial'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { api } from 'services'
import './styles.scss'

export const OrderInstallation = () => {
	const methods = useForm<ValuesForm>()

	const { handleSubmit } = methods

	const [productInfo, setProductInfo] = useState()
	const [clientInfo, setClientInfo] = useState()
	const [osNumber, setOsNumber] = useState<string>()
	const [isLoading, setIsLoading] = useState(false)
	const [showModalChangeAddress, setShowModalChangeAddress] = useState(false)
	const [showModalNumSerieNotFound, setShowModalNumSerieNotFound] =
		useState(false)
	const [showModalResponse, setShowModalResponse] = useState(false)

	async function onSubmit({ numserie }: any) {
		setIsLoading(true)
		const { data } = await api.get(
			`installedBases/search/?numserie=${numserie}&origin=ADMIN_APP`
		)
		const { client, equipment, code } = data

		if (code === 200) {
			setProductInfo(equipment)
			setClientInfo(client)
			setIsLoading(false)

			setShowModalChangeAddress(true)
			setShowModalNumSerieNotFound(false)
		} else {
			setShowModalChangeAddress(false)
			setShowModalNumSerieNotFound(true)
			setIsLoading(false)
		}
	}

	/**
	 * Method that close all modals
	 */

	const onReturn = () => {
		setShowModalChangeAddress(false)
		setShowModalNumSerieNotFound(false)
	}

	const submitNewOs = (os_info: any) => {
		os_info.origin = 'INSTALAÇÃO'
		api
			.post('serviceOrders/register', os_info)
			.then((resp: any) => {
				setShowModalChangeAddress(false)
				setOsNumber(resp.data.serviceOrderCode)
				setShowModalResponse(true)
			})
			.catch((err: any) => {
				setShowModalChangeAddress(false)
				setOsNumber('error')
				setShowModalResponse(false)
			})
	}

	const handleCloseNewOsResponseModal = () => {
		setShowModalResponse(false)
		window.location.reload()
	}

	return (
		<div className="order-installation">
			{osNumber ? (
				osNumber === 'error' && (
					<div
						style={{
							marginRight: 'auto',
							marginLeft: 'auto',
							paddingTop: '40px',
							paddingBottom: '40px',
							backgroundColor: '#fff',
							textAlign: 'center'
						}}
					>
						<strong>
							Não foi possível criar a sua requisição. Tente novamente mais
							tarde.
						</strong>
						<div
							className="container-address-confirm-actions"
							style={{
								justifyContent: 'center'
							}}
						>
							<Button
								size="lg"
								variant="confirmation-solid"
								title="Tentar Novamente"
								onClick={() => location.reload()}
							/>
						</div>
					</div>
				)
			) : (
				<>
					<div className="msg msg-container msg-default">
						<strong>
							Para solicitar a instalação de um equipamento, informe seu número
							de série no campo abaixo.
						</strong>
					</div>
					<FormProvider {...methods}>
						<SearchSerial>
							<div className="container-address-confirm-actions">
								<Button
									size="lg"
									variant="confirmation-solid"
									title="Enviar"
									onClick={handleSubmit(onSubmit)}
								/>
							</div>
						</SearchSerial>
					</FormProvider>
				</>
			)}
			{showModalChangeAddress && (
				<ModalInstall
					onReturn={onReturn}
					product={productInfo}
					client={clientInfo}
					submitNewOs={submitNewOs}
					title="Solicitação de Instalação"
				/>
			)}
			{showModalNumSerieNotFound && (
				<ModalNumSerieNotFound onReturn={onReturn} requester="install" />
			)}
			{showModalResponse && (
				<ModalSuccessOpenOs
					osNumber={osNumber}
					onClick={handleCloseNewOsResponseModal}
				/>
			)}
			{isLoading && <LoadingProgress />}
		</div>
	)
}
