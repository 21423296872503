import { ListType, Select } from '@praticabr/ppsa'
import Defect from 'models/Defect'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getDefect } from 'services'
import { setDefectList } from 'store/modules/baseLists/actions'

interface Props extends React.ComponentPropsWithRef<'input'> {
	selectedDefect: string
	onSelectDefect: (defectCode: string) => void
	innerRef?: any
}

const DefectSelector: React.FC<Props> = ({
	selectedDefect,
	onSelectDefect,
	innerRef
}) => {
	const [defectOptions, setDefectOptions] = useState<ListType[]>([])

	const dispatch = useDispatch()

	useEffect(() => {
		getDefect()
			.then((response) => {
				const defectOptions: ListType[] = []
				response.data.forEach(
					(element: Defect) =>
						element?.code !== '000000' &&
						defectOptions.push({
							value: element?.code,
							label: `${element?.code} - ${element?.name}`
						})
				)
				setDefectOptions(defectOptions)
				dispatch(setDefectList(defectOptions))
			})
			.catch(() => {
				toast.error('Erro ao buscar lista de defeitos.')
			})
	}, [])

	const onChangeValues = (defect: string[]) => {
		onSelectDefect(defect[0])
	}

	return (
		<Select
			innerRef={innerRef}
			id="defects"
			options={defectOptions}
			onSelectOptions={onChangeValues}
			selectedOption={[selectedDefect]}
		/>
	)
}

export default DefectSelector
