import { LoadingProgress } from '@praticabr/ppsa'
import ClientMenuTabs from 'module/orderManagement/components/ClientMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import React, { useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { FinancePositionDetails } from './FinancePositionDetails'
import { OpenOrdersList } from './OpenOrdersList'
import { OpenTitlesList } from './OpenTitlesList'
import './styles.scss'

type Guides = 'position' | 'title' | 'orders'

type Props = RouteComponentProps & {
	match: {
		params: {
			clientCode: string
			clientStoreCode: string
		}
	}
}

export function ClientFinanceDetails({ match }: Props) {
	const { sa1010IsLoading } = useContext(ClientDetailsContextElement)
	const [guide, setGuide] = useState<Guides>('position')

	return (
		<>
			<ClientMenuTabs path={match.path} />
			<TabTitles />
			<div className="order-follow-up-tab-guide-selector">
				<span
					onClick={() => setGuide('position')}
					style={{
						fontWeight: guide === 'position' ? 'bold' : 'normal',
						textDecoration: guide === 'position' ? 'underline' : 'none'
					}}
				>
					POSIÇÃO FINANCEIRA
				</span>
				<span
					onClick={() => setGuide('title')}
					style={{
						fontWeight: guide === 'title' ? 'bold' : 'normal',
						textDecoration: guide === 'title' ? 'underline' : 'none'
					}}
				>
					TÍTULOS EM ABERTOS
				</span>
				<span
					onClick={() => setGuide('orders')}
					style={{
						fontWeight: guide === 'orders' ? 'bold' : 'normal',
						textDecoration: guide === 'orders' ? 'underline' : 'none'
					}}
				>
					PEDIDOS EM ABERTOS
				</span>
			</div>
			{guide === 'position' && <FinancePositionDetails />}
			{guide === 'title' && <OpenTitlesList />}
			{guide === 'orders' && <OpenOrdersList />}
			{sa1010IsLoading && (
				<LoadingProgress text="Carregando dados do cliente..." />
			)}
		</>
	)
}
