import { Button, CheckBox, EmptyList, Modal } from '@praticabr/ppsa'
import { PrpOrder } from 'module/orderManagement/models/PrpOrderModel'
import React, { useEffect, useState } from 'react'
import { store } from 'store'
import { filterByUniqueDatas } from 'utils/Pipe'
import { OrderLogsList } from './ChangeLogsList'
import { EventList } from './EventList'
import NewFollowUp from './NewFollowUp'
import './styles.scss'

type TimelineGuideProps = {
	prpOrder: PrpOrder
	array: any[]
	sc5010: any
}

export default function TimelineGuide({
	prpOrder,
	array,
	sc5010
}: TimelineGuideProps) {
	const { roles } = store.getState().user
	const [eventList, setEventList] = useState<PrpOrder['events']>(
		filterByUniqueDatas(prpOrder?.events || [], array)
	)
	const [logs, setLogs] = useState<PrpOrder['logs']>([])
	const [filters, setFilters] = useState<string[]>([])
	const [showNewFollowUpModal, setShowNewFollowUpModal] =
		useState<boolean>(false)

	const digitalEndeavourManager = roles.includes('digitalEndeavourManager')
	const orderManager = roles.includes('orderManager')

	useEffect(() => {
		if (filters?.length > 0) {
			const filteredEventsList = filterByUniqueDatas(
				prpOrder?.events || [],
				array
			)?.filter(
				(event: any) => filters?.some((filter) => event.type.includes(filter))
			)
			setEventList([...sortByCreationDateDesc(filteredEventsList)])

			if (digitalEndeavourManager || orderManager) {
				const filteredChangeLogs = prpOrder?.logs?.filter((log) => log?.status)

				digitalEndeavourManager
					? setLogs(prpOrder?.logs)
					: setLogs(filteredChangeLogs)
			}
		} else {
			setEventList([
				...sortByCreationDateDesc(
					filterByUniqueDatas(prpOrder?.events || [], array)
				)
			])
		}
	}, [filters, prpOrder, array])

	const sortByCreationDateDesc = (events: any) => {
		events = events?.sort(
			(a: { createdAt: string }, b: { createdAt: string }) => {
				const dateA = new Date(a.createdAt).getTime()
				const dateB = new Date(b.createdAt).getTime()
				return dateB - dateA
			}
		)

		return events || []
	}

	const handleManageFilters = (filter: string) => {
		if (filters.includes(filter)) {
			setFilters(filters.filter((f) => f !== filter && f !== 'logs'))
		} else {
			setFilters([...filters.filter((f) => f !== 'logs'), filter])
		}
	}

	const handleCloseNewFollowUpModal = () => {
		setFilters([])
		setShowNewFollowUpModal(false)
	}

	const TimeLineGuideHeader = () => {
		return (
			<div className="order-follow-up-tab-timeline-guide-header">
				<div className="order-follow-up-tab-timeline-guide-header-filters">
					<div className="timeline-guide-filter">
						<CheckBox
							id="all"
							format="round"
							variant="blue"
							checked={filters?.length === 0}
							onChange={(e) => e.target.checked && setFilters([])}
						/>
						<label htmlFor="all">Todos os comentários</label>
					</div>
					<div className="timeline-guide-filter">
						<CheckBox
							id="internalComment"
							format="square"
							variant="blue"
							checked={filters.includes('internalComment')}
							onChange={() => handleManageFilters('internalComment')}
						/>
						<label htmlFor="internalComment">Comentários Internos</label>
					</div>
					<div className="timeline-guide-filter">
						<CheckBox
							id="publicComment"
							format="square"
							variant="blue"
							checked={filters.includes('publicComment')}
							onChange={() => handleManageFilters('publicComment')}
						/>
						<label htmlFor="publicComment">Comentários públicos</label>
					</div>
					<div className="timeline-guide-filter">
						<CheckBox
							id="pcp"
							format="square"
							variant="blue"
							checked={filters.includes('pcp')}
							onChange={(e) => handleManageFilters('pcp')}
						/>
						<label htmlFor="pcp">Comentários PCP</label>
					</div>
					<div className="timeline-guide-filter">
						<CheckBox
							id="expedition"
							format="square"
							variant="blue"
							checked={filters.includes('expedition')}
							onChange={() => handleManageFilters('expedition')}
						/>
						<label htmlFor="expedition">Comentários expedição</label>
					</div>
					{(digitalEndeavourManager || orderManager) && (
						<div className="timeline-guide-filter">
							<CheckBox
								id="logs"
								format="square"
								variant="blue"
								checked={filters.includes('logs')}
								onChange={(e) =>
									e.target.checked ? setFilters(['logs']) : setFilters([])
								}
							/>
							<label htmlFor="logs">Registro de logs</label>
						</div>
					)}
				</div>
				<div className="order-follow-up-tab-timeline-guide-header-actions">
					<Button
						size="lg"
						variant="confirmation-solid"
						title="NOVO COMENTÁRIO"
						onClick={() => setShowNewFollowUpModal(true)}
						disabled={
							!!sc5010?.c5Nota || (sc5010?.c5Liberok === 'E' && !sc5010?.c5Blq)
						}
					></Button>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="order-follow-up-tab-timeline-guide">
				<TimeLineGuideHeader />
				<div className="order-follow-up-tab-timeline-guide-main-contain">
					{!filters?.includes('logs') ? (
						eventList?.length ? (
							<EventList eventList={eventList} />
						) : (
							<EmptyList text="Nenhum evento encontrado" />
						)
					) : logs?.length ? (
						<OrderLogsList logs={logs} />
					) : (
						<EmptyList text="Nenhum log encontrado" />
					)}
				</div>
			</div>
			{showNewFollowUpModal && (
				<Modal.root>
					<Modal.header
						title="ADICIONAR COMENTÁRIO"
						onClose={handleCloseNewFollowUpModal}
					/>
					<Modal.body>
						<Modal.body.main>
							{prpOrder && (
								<NewFollowUp
									prpOrder={prpOrder}
									onClose={handleCloseNewFollowUpModal}
								/>
							)}
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)}
		</>
	)
}
