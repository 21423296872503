import { EmptyList, Paginator } from '@praticabr/ppsa'
import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import React, { useContext } from 'react'
import OrdersActions from './OrdersActions'
import OrdersListBody from './OrdersListBody'
import OrdersListHeader from './OrdersListHeader'
import './styles.scss'

const OrderListWeb = () => {
	const { isLoading, totalPages, currentPage, onPageChange } =
		useContext(OrderContextElement)

	return (
		<div className="orders">
			<OrdersActions />
			<div className="orders-table">
				{totalPages ? (
					<>
						<OrdersListHeader />
						<OrdersListBody />
					</>
				) : (
					<EmptyList text="Pedido não encontrado" />
				)}
			</div>
			<div className="orders-paginator">
				<Paginator
					totalPages={totalPages}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			</div>
		</div>
	)
}
export default OrderListWeb
