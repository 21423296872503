import { EmptyList } from '@praticabr/ppsa'
import $ from 'jquery'
import { Transfer } from 'models/Transfer'
import HasError from 'module/technicalServices/components/Shared/Messages/HasError'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TransfersListItemMobile from '../../TransfersListItem/Mobile'
import './styles.scss'

const SCROLL_TOP = 0

interface Params {
	onItemClick: (transfer: Transfer) => void
}

const TransfersListMobile: React.FC<Params> = ({ onItemClick }) => {
	const { transferList, isLoading, hasError } = useContext(
		TransfersContextElement
	)

	$('.mobile-scroll').scrollTop(SCROLL_TOP)
	$('.tranfer-list-items').scrollTop(SCROLL_TOP)

	const listContent = () => {
		return (
			<>
				{transferList?.map((transfer: Transfer, index: number) => {
					return (
						<TransfersListItemMobile
							{...{ transfer, onItemClick }}
							key={index}
						/>
					)
				})}
			</>
		)
	}

	return (
		<section className="transfer-list-content">
			<div className="transfer-list-items">
				{!isLoading ? listContent() : <Skeleton count={10} height={28.9} />}
			</div>
			{!transferList?.length && !isLoading && (
				<EmptyList text="Sem transferências por aqui" />
			)}
			<HasError show={!!hasError && !transferList?.length && !isLoading} />
		</section>
	)
}

export default TransfersListMobile
