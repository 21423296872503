import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from 'react'
import { toast } from 'react-toastify'
import { recoveryAccount, updateUserToken } from 'services'
import { store } from 'store'

export const AuthContext = createContext({
	user: {} as any,
	handleRecoverAccount: (callback: () => void) => {},
	isLoading: false,
	setEmail: (email: string) => {},
	email: ''
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const userRef = useRef(store.getState().user)
	const [recoverAccountEmail, setRecoverAccountEmail] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		const auth = store.getState().auth
		if (auth.token) {
			navigator.serviceWorker
				.register('/service-worker.js')
				.then(async (serviceWorker) => {
					let subscription = await serviceWorker.pushManager.getSubscription()

					if (!subscription) {
						const publicKeyResponse = await fetch(
							`${process.env.REACT_APP_API_SOCKET}/push/public-key`,
							{
								method: 'GET',
								headers: { 'Content-Type': 'application/json' }
							}
						)

						const parsedPublicKeyResponse = await publicKeyResponse.json()
						const { publicKey } = parsedPublicKeyResponse

						subscription = await serviceWorker.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: publicKey
						})
					}
					updateUserToken(subscription, userRef.current.profile).catch((err) =>
						console.log(err)
					)

					return Notification.requestPermission()
				})
				.then(async (permission) => {
					if (permission === 'granted') {
						console.log('Notification permission granted.')
					} else {
						console.log('Unable to get permission to notify.')
					}
				})
				.catch((err) => {
					console.log('Error during service worker registration:', err)
				})
		}
	}, [])

	const setEmail = (email: string) => {
		setRecoverAccountEmail(email)
	}

	const handleRecoverAccount = (callback: () => void) => {
		setIsLoading(true)

		if (!recoverAccountEmail) {
			toast.error('E-mail inválido')
			return
		}

		recoveryAccount({ email: recoverAccountEmail })
			.then(() => {
				toast.success('Verifique seu e-mail!')
				callback()
				setRecoverAccountEmail('')
			})
			.catch((e) => {
				toast.error('Erro ao enviar email de recuperação!')
				console.log(e)
			})
			.finally(() => setIsLoading(false))
	}

	const value = {
		user: userRef.current,
		handleRecoverAccount,
		isLoading,
		setEmail,
		email: recoverAccountEmail
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
