import { EmptyList, Paginator } from '@praticabr/ppsa'
import { useFetch } from 'hooks/useFetch'
import MyFilter from 'models/Filter'
import React, { useEffect, useMemo, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { history } from 'routes/history'
import { getFilters, isTokenValid } from 'services'
import JoyRide from 'shared/components/JoyRide'
import { store } from 'store'
import { signOut } from 'store/modules/auth/actions'
import { getOsChangePage } from 'store/modules/serviceOrders/actions'
import {
	StepsOrderlist,
	handleStepTitles
} from '../../../../utils/Constants/joyrideSteps'
import ServiceOrderListActions from './ServiceOrderListActions'
import ServiceOrderListBody from './ServiceOrderListBody'
import ServiceOrderListHeader from './ServiceOrderListHeader'
import './styles.scss'

type PageInfo = {
	pageLimit: number
	offset: number
	fieldFilter: string
	orderFilter: string
	searchTerm: string
	statusChecked: string[]
	stateChecked: string[]
	typeOSChecked: string[]
	typeAccountChecked: string[]
	filterSelected?: MyFilter
}

const INITIAL_PAGE = 1

const ServiceOrderList = () => {
	const [stateChecked, setStateChecked] = useState<string[]>([
		...(store.getState().serviceOrders?.pageInfo?.stateChecked || []),
		...(store.getState().serviceOrders?.pageInfo?.filterSelected?.stateList ||
			[])
	])
	const [statusChecked, setStatusChecked] = useState<string[]>([
		...(store.getState().serviceOrders?.pageInfo?.statusChecked || []),
		...(store.getState().serviceOrders?.pageInfo?.filterSelected?.statusList ||
			[])
	])
	const [typeOSChecked, setTypeOSChecked] = useState<string[]>([
		...(store.getState().serviceOrders?.pageInfo?.typeOSChecked || []),
		...(store.getState().serviceOrders?.pageInfo?.filterSelected?.typeOsList ||
			[])
	])
	const [typeAccountChecked, setTypeAccountChecked] = useState<string[]>(
		store.getState().serviceOrders?.pageInfo?.filterSelected?.accountTypeList ||
			[]
	)
	const [searchTerm, setSearchTerm] = useState<string>(
		store.getState().serviceOrders?.pageInfo?.searchTerm
	)
	const [pageNumber, setPageNumber] = React.useState<number>(
		store.getState().serviceOrders.pageNumber
			? store.getState().serviceOrders.pageNumber
			: INITIAL_PAGE
	)
	const [fieldFilter, setFieldFilter] = React.useState<string>(
		store.getState().serviceOrders.pageInfo?.fieldFilter
	)
	const [orderFilter, setOrderFilter] = React.useState<string>(
		store.getState().serviceOrders.pageInfo?.orderFilter
	)
	const [filters, setFilters] = useState<MyFilter[]>([])
	const [filterSelected, setFilterSelected] = useState<MyFilter>(
		store.getState().serviceOrders.pageInfo?.filterSelected
	)
	const [changeStatusFilter, setChangeStatusFilter] = useState<boolean>(false)
	const [handleChanges, setHandleChanges] = useState<boolean>(false)
	const user = store.getState().user
	const dispatch = useDispatch()
	const pageLimit = 12
	const offset = (pageNumber - 1) * pageLimit
	let totalPages = 0
	let serviceOrders = []
	const [tutorials, setTutorials] = useState(store.getState().user.tutorials)

	useMemo(() => {
		store.subscribe(() => setTutorials(store.getState().user.tutorials))
	}, [tutorials])

	const pageInfo: PageInfo = {
		pageLimit,
		offset,
		fieldFilter,
		orderFilter,
		searchTerm: encodeURIComponent(searchTerm),
		statusChecked,
		stateChecked,
		typeOSChecked,
		typeAccountChecked,
		filterSelected
	}

	useEffect(() => {
		requestFilters()
	}, [
		pageNumber,
		stateChecked,
		statusChecked,
		typeOSChecked,
		typeAccountChecked,
		fieldFilter,
		orderFilter,
		searchTerm,
		changeStatusFilter,
		handleChanges
	])

	const requestFilters = async () => {
		await getFilters(user.profile, 'technical_services')
			.then((resp) => {
				const filters = resp.data
				filters.map((item) => {
					item.stateList = JSON.parse(item.filter_state)
					item.statusList = JSON.parse(item.filter_status)
					item.typeOsList = JSON.parse(item.filter_type_os)
					item.accountTypeList = JSON.parse(item.filter_type_account)
				})
				setFilters(filters)
			})
			.catch(() => toast.error('Erro ao recuperar filtros'))
	}

	const { data, error, mutate } = useFetch(
		`serviceOrders/getAllOSInfo?pageInfo=${JSON.stringify(pageInfo)}`
	)
	if (error) {
		if (error.message === 'Network Error') {
			history.push('/error/500')
		} else if (error.message === '_data$ is undefined') {
			history.push('/error/404')
		} else if (error.message === 'Request failed with status code 401') {
			isTokenValid(store.getState().auth.token)
				.then((resp) => {
					if (resp.data.exists === true) {
						history.push('/home')
					} else {
						dispatch(signOut())
						history.push('/')
					}
				})
				.catch(() => {
					dispatch(signOut())
					history.push('/')
				})
		}
	}

	if (data) {
		const count = data.response?.count
		totalPages = Math.ceil((count ? count : 1) / pageLimit)
		serviceOrders = data?.response?.osList
	}

	const handleChangePage = async (value: number) => {
		dispatch(getOsChangePage(value))
		setPageNumber(store.getState().serviceOrders.pageNumber)
	}

	return (
		<>
			<div className="all-service-orders">
				<ServiceOrderListActions
					mutate={mutate}
					states={{ searchTerm, filters, filterSelected }}
					setters={{
						setChangeStatusFilter,
						setFilterSelected,
						setSearchTerm,
						setPageNumber,
						setStateChecked,
						setStatusChecked,
						setTypeAccountChecked,
						setTypeOSChecked
					}}
				/>
				<ServiceOrderListHeader
					setters={{
						setFieldFilter,
						setOrderFilter,
						setPageNumber,
						setStateChecked,
						setStatusChecked,
						setTypeOSChecked
					}}
					states={{ stateChecked, statusChecked, typeOSChecked }}
				/>
				<div className="all-service-orders-table">
					{!!serviceOrders && (
						<ServiceOrderListBody
							totalPages={totalPages}
							orderList={serviceOrders}
							handleChanges={() => setHandleChanges(!handleChanges)}
							mutate={mutate}
						/>
					)}
					{!totalPages && (
						<EmptyList text="Nenhuma ordem de serviço encontrada" />
					)}
				</div>
				<div className="all-service-orders-paginator">
					<Paginator
						onPageChange={(e) => handleChangePage(e)}
						currentPage={pageNumber}
						totalPages={totalPages}
					/>
				</div>
			</div>
			{!tutorials?.includes(handleStepTitles(StepsOrderlist)) && (
				<JoyRide steps={StepsOrderlist} />
			)}
		</>
	)
}

export default ServiceOrderList
