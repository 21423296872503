import { ListType } from '@praticabr/ppsa'
import Defect from 'models/Defect'
import { RequisitionError } from 'models/RequisitionError'
import IAvailablePendency, {
	IAvailablePendencyItem
} from 'module/orderManagement/models/IAvailablePendency'
import {
	getCarrierList,
	getClientGroups,
	getClientSegments,
	getCostCenterList,
	getCountyList,
	getCustomListStorage,
	getLedgerAccountList,
	getListSalesperson,
	getNatureList,
	getOperationTypes,
	getPaymentFormTypes,
	getPendencyTypes,
	getPriceTableList,
	getProtheusUserList,
	getRegionList
} from 'module/orderManagement/services'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDefect } from 'services'
import { store } from 'store'
import {
	setDefectList,
	setStoredAvailablePendencyList,
	setStoredCarrierList,
	setStoredClientGroups,
	setStoredClientSegments,
	setStoredCostCenterList,
	setStoredCountyList,
	setStoredLedgerAccountList,
	setStoredNatureList,
	setStoredOperationTypes,
	setStoredPaymentFormList,
	setStoredPriceTableList,
	setStoredProtheusUserList,
	setStoredRegionList,
	setStoredSalespersonList,
	setStoredStorageList
} from 'store/modules/baseLists/actions'
import {
	BaseOrderManagementContextType,
	BaseOrderManagementContextValue
} from './BaseOrderManagementContextType'

export const BaseOrderManagementElement =
	createContext<BaseOrderManagementContextType>(BaseOrderManagementContextValue)

export const BaseOrderManagementProvider = (props: any) => {
	const [availablePendencyList, setAvailablePendencyList] =
		useState<IAvailablePendency>(
			store.getState().baseLists.availablePendencyList
		)
	const [paymentFormList, setPaymentFormList] = useState<
		IAvailablePendencyItem[]
	>(store.getState().baseLists.paymentFormList)
	const [salespersonList, setSalespersonList] = useState<
		IAvailablePendencyItem[]
	>(store.getState().baseLists.salespersonList)
	const [priceTableList, setPriceTableList] = useState<any[]>(
		store.getState().baseLists.priceTableList
	)
	const [clientSegments, setClientSegments] = useState<
		IAvailablePendencyItem[]
	>(store.getState().baseLists.clientSegments)
	const [operationTypes, setOperationTypes] = useState<
		IAvailablePendencyItem[]
	>(store.getState().baseLists.operationTypes)
	const [clientGroups, setClientGroups] = useState<IAvailablePendencyItem[]>(
		store.getState().baseLists.clientGroups
	)
	const [protheusUserList, setProtheusUserList] = useState<
		IAvailablePendencyItem[]
	>(store.getState().baseLists.protheusUserList)
	const [carrierList, setCarrierList] = useState<IAvailablePendencyItem[]>(
		store.getState().baseLists.carrierList
	)
	const [natureList, setNatureList] = useState<IAvailablePendencyItem[]>(
		store.getState().baseLists.natureList
	)
	const [countyList, setCountyList] = useState<IAvailablePendencyItem[]>(
		store.getState().baseLists.countyList
	)
	const [ledgerAccountList, setLedgerAccountList] = useState<
		IAvailablePendencyItem[]
	>(store.getState().baseLists.ledgerAccountList)
	const [costCenterList, setCostCenterList] =
		useState<IAvailablePendencyItem[]>()
	const [storageList, setStorageList] = useState()
	const [regionList, setRegionList] = useState<IAvailablePendencyItem[]>()
	const [baseListsIsLoading, setBaseListsIsLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<RequisitionError>()
	const dispatch = useDispatch()

	useEffect(() => {
		if (
			!store.getState().baseLists?.availablePendencyList?.commercial_pending
				?.length
		) {
			requestAvailablePendencyList()
		}
		if (!store.getState().baseLists?.paymentFormList?.length) {
			requestPaymentFormList()
		}
		if (!store.getState().baseLists?.salespersonList?.length) {
			requestSalespersonList()
		}
		if (!store.getState().baseLists?.priceTableList?.length) {
			requestPriceTableList()
		}
		if (!store.getState().baseLists?.natureList?.length) {
			requestNatureList()
		}
		if (!store.getState().baseLists?.clientGroups?.length) {
			requestClientGroups()
		}
		if (!store.getState().baseLists?.clientSegments?.length) {
			requestClientSegments()
		}
		if (!store.getState().baseLists?.operationTypes?.length) {
			requestOperationTypes()
		}
		if (!store.getState().baseLists?.carrierList?.length) {
			requestCarrierList()
		}
		if (!store.getState().baseLists?.protheusUserList?.length) {
			requestProtheusUserList()
		}
		if (!store.getState().baseLists?.countyList?.length) {
			requestCounty()
		}
		if (!store.getState().baseLists?.ledgerAccountList?.length) {
			requestLedgerAccountList()
		}
		if (!store.getState().baseLists?.costCenterList?.length) {
			requestCostCenter()
		}
		if (!store.getState().baseLists?.storageList?.length) {
			requestStorage()
		}
		if (!store.getState().baseLists?.regionList?.length) {
			requestRegion()
		}
		if (!store.getState().baseLists?.protheusUserList?.length) {
			requestProtheusUserList()
		}
		if (!store.getState().baseLists?.defectList?.length) {
			getDefect().then((response) => {
				const defectOptions: ListType[] = []
				response.data.forEach(
					(element: Defect) =>
						element?.code !== '000000' &&
						defectOptions.push({
							value: element?.code,
							label: `${element?.code} - ${element?.name}`
						})
				)
				dispatch(setDefectList(defectOptions))
			})
		}
	}, [])

	const requestSalespersonList = () => {
		setBaseListsIsLoading(true)
		getListSalesperson()
			.then(async (response) => {
				const { salespersonList } = response.data
				setSalespersonList(salespersonList)
				dispatch(setStoredSalespersonList(salespersonList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestPaymentFormList = () => {
		setBaseListsIsLoading(true)
		getPaymentFormTypes()
			.then(async (response) => {
				const { paymentFormList } = response.data
				setPaymentFormList(paymentFormList)
				dispatch(setStoredPaymentFormList(paymentFormList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestPriceTableList = () => {
		setBaseListsIsLoading(true)
		getPriceTableList()
			.then(async (response) => {
				const { priceTableList } = response.data
				setPriceTableList(priceTableList)
				dispatch(setStoredPriceTableList(priceTableList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestNatureList = () => {
		setBaseListsIsLoading(true)
		getNatureList()
			.then(async (response) => {
				const { natureList } = response.data
				setNatureList(natureList)
				dispatch(setStoredNatureList(natureList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestClientSegments = () => {
		setBaseListsIsLoading(true)
		getClientSegments()
			.then(async (response) => {
				const { clientSegments } = response.data
				setClientSegments(clientSegments)
				dispatch(setStoredClientSegments(clientSegments))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestClientGroups = () => {
		setBaseListsIsLoading(true)
		getClientGroups()
			.then(async (response) => {
				const { clientGroups } = response.data
				setClientGroups(clientGroups)
				dispatch(setStoredClientGroups(clientGroups))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestOperationTypes = () => {
		setBaseListsIsLoading(true)
		getOperationTypes()
			.then(async (response) => {
				const { operationTypes } = response.data
				setOperationTypes(operationTypes)
				dispatch(setStoredOperationTypes(operationTypes))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestCarrierList = () => {
		setBaseListsIsLoading(true)
		getCarrierList()
			.then(async (response) => {
				const { carrierList } = response.data
				setCarrierList(carrierList)
				dispatch(setStoredCarrierList(carrierList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestProtheusUserList = () => {
		setBaseListsIsLoading(true)
		getProtheusUserList()
			.then(async (response) => {
				const { users } = response.data
				const newList = [...users]
				newList.unshift({ value: '', label: '' })
				setProtheusUserList(newList)
				dispatch(setStoredProtheusUserList(newList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestAvailablePendencyList = () => {
		setBaseListsIsLoading(true)
		getPendencyTypes()
			.then(async (response) => {
				const { availablePendencyList } = response.data
				const newList: IAvailablePendency = {
					commercial_pending: [],
					financial_pending: [],
					shipment_pending: [],
					administrative_pending: []
				}
				await availablePendencyList.map((e: any) => {
					if (e?.origin === '1') {
						newList.commercial_pending.push({
							value: e?.code,
							label: e?.description
						})
					}
					if (e?.origin === '2') {
						newList.financial_pending.push({
							value: e?.code,
							label: e?.description
						})
					}
					if (e?.origin === '3') {
						newList.administrative_pending.push({
							value: e?.code,
							label: e?.description
						})
					}
					if (e?.origin === '4') {
						newList.shipment_pending.push({
							value: e?.code,
							label: e?.description
						})
					}
				})

				setAvailablePendencyList(newList)
				dispatch(setStoredAvailablePendencyList(newList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestCounty = () => {
		setBaseListsIsLoading(true)
		getCountyList()
			.then((response) => {
				const { countyList } = response.data
				setCountyList(countyList)
				dispatch(setStoredCountyList(countyList))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestLedgerAccountList = () => {
		setBaseListsIsLoading(true)
		getLedgerAccountList()
			.then((response) => {
				const { ledgerAccount } = response.data
				setLedgerAccountList(ledgerAccount)
				dispatch(setStoredLedgerAccountList(ledgerAccount))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestCostCenter = () => {
		setBaseListsIsLoading(true)
		getCostCenterList()
			.then((response) => {
				const { costCenter } = response.data
				setStorageList(costCenter)
				dispatch(setStoredCostCenterList(costCenter))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestStorage = () => {
		setBaseListsIsLoading(true)
		getCustomListStorage()
			.then((response) => {
				const { list } = response.data
				setStorageList(list)
				dispatch(setStoredStorageList(list))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const requestRegion = () => {
		setBaseListsIsLoading(true)
		getRegionList()
			.then((response) => {
				const { regions } = response.data
				setRegionList(regions)
				dispatch(setStoredRegionList(regions))
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
			})
			.finally(() => setBaseListsIsLoading(false))
	}

	const providerValues = () => {
		return {
			availablePendencyList,
			paymentFormList,
			priceTableList,
			salespersonList,
			natureList,
			clientGroups,
			clientSegments,
			operationTypes,
			carrierList,
			protheusUserList,
			countyList,
			ledgerAccountList,
			regionList,
			costCenterList,
			storageList,
			baseListsIsLoading,
			requestCounty,
			hasError
		}
	}

	return (
		<BaseOrderManagementElement.Provider value={providerValues()}>
			{props.children}
		</BaseOrderManagementElement.Provider>
	)
}
