import { EmptyList, Paginator } from '@praticabr/ppsa'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import OrderHistoryListHeader from 'module/orderManagement/components/OrderHistoryListHeader'
import OrderMenuTabs from 'module/orderManagement/components/OrderMenuTabs'
import OrdersHistoryActions from 'module/orderManagement/components/OrdersHistoryActions'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderHistoryContextElement } from 'module/orderManagement/context/OrderHistoryContext/OrderHistoryContext'
import React, { useContext, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ViewType } from 'utils/Constants'
import OrderHistoryListBody from '../../components/OrderHistoryListBody'
import './styles.scss'

type ClientOrdersHistoryProps = RouteComponentProps & {
	match: {
		params: {
			clientCode: string
			clientStoreCode: string
		}
	}
}

const OrderClientOrdersHistory = ({ match }: ClientOrdersHistoryProps) => {
	const { isLoading, totalPages, currentPage, onPageChange } = useContext(
		OrderHistoryContextElement
	)
	const { sa1010, onSelectClient } = useContext(ClientDetailsContextElement)
	const { viewType } = useContext(DeviceContextElement)
	const { clientCode, clientStoreCode } = match.params

	useEffect(() => {
		if (sa1010?.a1Cod !== clientCode) {
			onSelectClient({ a1Cod: clientCode, a1Loja: clientStoreCode })
		}
	}, [clientCode, clientStoreCode])

	const mainContain = () => {
		return (
			<>
				<div className="order-history-tab">
					<OrdersHistoryActions />
					<div className="order-history-table">
						{totalPages ? (
							<>
								{viewType !== ViewType.MOBILE && <OrderHistoryListHeader />}
								<OrderHistoryListBody />
							</>
						) : (
							<EmptyList text="Pedido não encontrado" />
						)}
					</div>
				</div>
				<div className="order-history-paginator">
					<Paginator
						totalPages={totalPages}
						currentPage={currentPage}
						onPageChange={onPageChange}
					/>
				</div>
			</>
		)
	}

	return sa1010 ? (
		<>
			<OrderMenuTabs path={match.path} />
			<TabTitles />

			{mainContain()}
		</>
	) : (
		<></>
	)
}
export default OrderClientOrdersHistory
