import { EmptyList, Paginator } from '@praticabr/ppsa'
import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import React, { useContext } from 'react'
import ClientsActions from './ClientsActions'
import ClientsListBody from './ClientsListBody'
import ClientsListHeader from './ClientsListHeader'
import './styles.scss'

const ClientListWeb = () => {
	const { isLoading, totalPages, currentPage, onPageChange } =
		useContext(ClientContextElement)

	return (
		<div className="clients">
			<ClientsActions />
			<div className="clients-table">
				{totalPages ? (
					<>
						<ClientsListHeader />
						<ClientsListBody />
					</>
				) : (
					<EmptyList text="Cliente não encontrado" />
				)}
			</div>
			<div className="clients-paginator">
				<Paginator
					totalPages={totalPages}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			</div>
		</div>
	)
}
export default ClientListWeb
