import { Modal } from '@praticabr/ppsa'
import { Order } from 'module/orderManagement/domain/models/order'
import React from 'react'
import { OrderClientInstallmentViewingMode } from './NewOrderClientInstallment/OrderClientInstallmentViewingMode'
import './styles.scss'

interface OrderClientInstallmentsProps {
	onClose: () => void
	order?: Order
}

export const OrderClientInstallmentsViewingMode = ({
	onClose,
	order
}: OrderClientInstallmentsProps) => {
	return (
		<>
			<Modal.root>
				<Modal.header title="PARCELAS" onClose={onClose} />
				<Modal.body>
					<Modal.body.main>
						<div className="installments-container viewing">
							<div className="installments-details-body">
								{order?.installments?.map((item: any, index: number) => {
									return (
										<OrderClientInstallmentViewingMode
											parcel={item}
											key={index}
											index={index}
										/>
									)
								})}
							</div>
						</div>
					</Modal.body.main>
				</Modal.body>
			</Modal.root>
		</>
	)
}
