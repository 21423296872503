import styled from 'styled-components'

export const Container = styled.div`
	max-height: 700px;
	max-width: 975px;
	min-height: 420px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 120px;
	padding-top: 10px;
	padding-right: 20px;
	padding-bottom: 10px;
	padding-left: 20px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	background-color: white;
	overflow: hidden;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.37);
	border-radius: 3px;
`

export const Type = styled.div`
	font-size: 18px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
`

export const Footer = styled.div`
	display: flex;
	column-gap: 20px;
	flex-direction: row;
	justify-content: center;
`
export const RequestInfo = styled.div`
	width: 100%;
	padding-top: 15px;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	display: flex;
`

export const Form = styled.div`
	margin: 0 0 15px;
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const LabelName = styled.div`
	display: flex;
	margin-bottom: 0px;
	padding-right: 5px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-align: left;
`

export const Input = styled.input`
	display: flex;
	margin-top: 5px;
	border-style: solid;
	border-radius: 3px;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	width: 350px;
	flex-direction: column;
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
	margin-right: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const InputSearch = styled.input`
	display: flex;
	margin-top: 5px;
	border-style: solid;
	border-radius: 3px;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	width: 710px;
	flex-direction: column;
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
	margin-right: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const InputArea = styled.textarea`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 100%;
	flex-direction: column;
	width: 100%;
	height: 50px;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const Info = styled.div`
	margin: 0 0 15px;
	display: flex;
	flex-direction: row;
	flex-grow: 1;
`

export const Message = styled.div`
	margin: 0 0 15px;
	padding-right: 20px;
	padding-left: 20px;
	display: flex;
	flex-direction: column;
`
export const Span = styled.div`
	padding-left: 10px;
	text-align: center;
	color: red;
`
export const Search = styled.div`
	display: flex;
	flex-direction: row;
`

export const TechInfo = styled.div`
	display: flex;
	flex-direction: row;
`
export const TechResult = styled.div`
	display: flex;
	margin-right: 10px;
	border-radius: 3px;
	margin-top: 5px;
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	width: 710px;
`

export const Col = styled.div`
	width: 100%;
	min-height: 1px;
	@media screen and (max-width: 767px) {
		width: auto;
		margin-top: 3px;
		margin-bottom: 5px;
		margin-left: 20px;
		padding-right: 40px;
	}
`

export const Col3 = styled.div`
	position: relative;
	justify-content: center;
	display: flex;
	font-size: 13px;
	@media screen and (max-width: 767px) {
		justify-content: left;
	}
	@media screen and (min-width: 768px) {
		top: 50%;
		transform: translate(0%, -50%);
	}
`

export const Col4 = styled.div`
	position: relative;
	justify-content: left;
	display: flex;
	font-size: 13px;
	@media screen and (max-width: 767px) {
		justify-content: left;
	}
	@media screen and (min-width: 768px) {
		top: 50%;
		transform: translate(0%, -50%);
	}
`

export const Line = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 2px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: hsla(0, 0%, 86.7%, 0.3);
	border-style: none solid solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.3);
	border-radius: 3px 3px 0px 0px;
`

export const Div1 = styled.div`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	@media screen and (max-width: 767px) {
		font-weight: 600;
	}
`

export const Table = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	max-height: 700px;
	overflow: auto;
`

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
`

export const Header1 = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 2px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: hsla(0, 0%, 86.7%, 0.3);
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.3);
	border-radius: 3px 3px 0px 0px;
`

export const Header = styled.div`
	line-height: 30px;
	display: flex;
	flex-direction: row;
	width: 100%;
`

export const FilterName = styled.input`
	padding: 5px 0px 5px 10px;
	outline: none;
	font-weight: 700;
	border-width: 1px;
	line-height: 28px;
	border-radius: 3px;
	border-style: solid;
	margin-bottom: 10px;
	border-color: #004992;
`

export const FilterType = styled.div`
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 10px;
`
