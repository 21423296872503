import { LoadingProgress, Modal } from '@praticabr/ppsa'
import Client from 'models/Client'
import RequestResponse from 'models/RequestResponse'
import { Transfer, TransferInfo } from 'models/Transfer'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { clearNumericField } from 'utils/Pipe'
import TransferEdit from '../../TransferEdit'
import TransferFoundClient from './TransferFoundClient'
import TransferNewClient from './TransferNewClient'

interface Props {
	transfer: Transfer
}

function getClient(transfer: Transfer) {
	return transfer.transferPayloadParsed?.prpInfo?.newClient
}

function getTransferInfo(transfer: Transfer) {
	return {
		...transfer.transferPayloadParsed?.transferInfo,
		userId: transfer.userId,
		origin: transfer.origin
	}
}

const TransferClientContent: React.FC<Props> = ({ transfer }) => {
	const { transferSelected, verifyClient } = useContext(
		TransfersDetailsContextElement
	)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [client, setClient] = useState<Client>(getClient(transfer))
	const [transferInfo, setTransferInfo] = useState<TransferInfo>(
		getTransferInfo(transfer)
	)
	const [transferData, setTransferData] = useState<Transfer>(transfer)

	useEffect(() => {
		setClient(getClient(transfer))
		setTransferInfo(getTransferInfo(transfer))
	}, [transferSelected])

	const onEditClick = () => {
		if (transfer.userId) {
			setIsEditing(true)
		}
	}

	const onClose = () => {
		setIsEditing(false)
	}

	const onCloseEdit = (transferUpdated?: Transfer) => {
		onClose()
		updateContentInfo(transferUpdated || transferSelected)
	}

	const updateContentInfo = (transferUpdate?: Transfer) => {
		if (transferUpdate) {
			setTransferData(transferUpdate)
			setClient(getClient(transferUpdate))
			setTransferInfo(getTransferInfo(transferUpdate))
		}
	}

	const onUpdateComplete = (transferUpdated?: Transfer) => {
		onCloseEdit(transferUpdated)
	}

	const onSearchClient = () => {
		if (transferInfo.userId) {
			setIsLoading(true)
			const cpfCnpj = clearNumericField(transferInfo.cpfCnpj)
			const transferId = transfer.id
			verifyClient(cpfCnpj, transferId, onCompleted)
		}
	}

	const onCompleted = (request: RequestResponse) => {
		setIsLoading(false)
		if (request?.reportError) {
			switch (request?.reportError?.code) {
				case 404:
					toast.error('Cliente não encontrado no Protheus')
					break
				default:
					toast.error('Erro ao buscar cliente')
					break
			}
		} else {
			updateContentInfo(request?.response)
			toast.success('Informações de cliente atualizadas')
		}
	}

	const clientContent = () => {
		if (client) {
			return <TransferFoundClient {...{ client, transferInfo, onEditClick }} />
		} else {
			return (
				<TransferNewClient {...{ transferInfo, onEditClick, onSearchClient }} />
			)
		}
	}

	const editClientModalContent = () => {
		const title = 'Alterar Cliente'
		const show = isEditing
		return (
			show && (
				<Modal.root>
					<Modal.header {...{ title, onClose }} />
					<Modal.body>
						<Modal.body.main>
							<TransferEdit
								{...{
									transfer: transferData,
									type: 'Client',
									onUpdateComplete
								}}
							/>
						</Modal.body.main>
					</Modal.body>
				</Modal.root>
			)
		)
	}

	const loadingContent = () => {
		return <LoadingProgress text="Carregando..." />
	}

	return (
		<>
			<section className="transfers-client-info-content">
				<div className="transfers-info-header">
					<h6>Cliente de Destino</h6>
				</div>
				{clientContent()}
			</section>
			{isLoading && loadingContent()}
			{editClientModalContent()}
		</>
	)
}

export default TransferClientContent
