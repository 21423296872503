import useZendeskWidget from 'hooks/useZendeskWidget'
import { UsersJacProvider } from 'module/adminSystem/context/UsersJacContext'
import { InstallAndSearchProvider } from 'module/installAndSearch/context/InstallAndSearchContext'
import { BaseOrderManagementProvider } from 'module/orderManagement/context/BaseOrderManagementContext/BaseOrderManagementContext'
import { ClientProvider } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import OrderDetailsContext from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import OrderHistoryContext from 'module/orderManagement/context/OrderHistoryContext/OrderHistoryContext'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import AuthLayout from 'shared/_layout/authLayout'
import DefaultLayout from 'shared/_layout/defaultLayout'
import Empty from 'shared/_layout/empty'
import { store } from 'store'

export interface RouteParams {
	component: any
	exact?: boolean
	path: string
	isPrivate?: boolean
	isPublic?: boolean
	module?:
		| 'order_management'
		| 'technical_services'
		| 'human_resources'
		| 'home'
		| 'config_user'
		| 'register'
		| 'cs'
		| 'install-and-search'
		| 'products_management'
}

const routePublic = ['/service', '/installation', '/result', '/result-address']

const RouterWrapper: React.FC<RouteParams> = ({
	component: Component,
	isPrivate,
	module,
	...rest
}) => {
	const { signed, token } = store.getState().auth
	const { user } = store.getState()

	useZendeskWidget(
		'3bb7e7aa-9769-4c7b-a2fa-71777601eff6',
		{
			webWidget: {
				color: { theme: '#61dafb' }
			}
		},
		{
			contact: `${user.name} ${user.surname}`,
			email: user.email,
			token: token
		}
	)

	const { pathname } = window.location

	if (!signed && isPrivate && !routePublic.includes(pathname)) {
		return <Redirect to="/" />
	}

	if (signed && !isPrivate && !routePublic.includes(pathname)) {
		return <Redirect to="/home" />
	}

	const moduleContextLayout = (props: any) => {
		switch (module) {
			case 'register':
				return (
					<Empty>
						<Component {...props} />
					</Empty>
				)
			case 'home':
				return <Component {...props} />
			case 'config_user':
				return <Component {...props} />
			case 'human_resources':
				return (
					<DefaultLayout module={module}>
						<Component {...props} />
					</DefaultLayout>
				)
			case 'technical_services':
				return (
					<BaseOrderManagementProvider>
						<DefaultLayout module={module}>
							<Component {...props} />
						</DefaultLayout>
					</BaseOrderManagementProvider>
				)
			case 'order_management':
				return (
					<BaseOrderManagementProvider>
						<OrderHistoryContext>
							<ClientProvider>
								<OrderDetailsContext>
									<DefaultLayout module={module}>
										<Component {...props} />
									</DefaultLayout>
								</OrderDetailsContext>
							</ClientProvider>
						</OrderHistoryContext>
					</BaseOrderManagementProvider>
				)
			case 'cs':
				return (
					<DefaultLayout module={module}>
						<UsersJacProvider>
							<Component {...props} />
						</UsersJacProvider>
					</DefaultLayout>
				)
			case 'install-and-search':
				return (
					<InstallAndSearchProvider>
						<Component {...props} />
					</InstallAndSearchProvider>
				)
			case 'products_management':
				return (
					<DefaultLayout module={module}>
						<Component {...props} />
					</DefaultLayout>
				)
			default:
				return (
					<AuthLayout>
						<Component {...props} />
					</AuthLayout>
				)
		}
	}

	const render = (props: any) => {
		return moduleContextLayout({ ...props })
	}

	return <Route {...rest} {...{ render }} />
}

export default RouterWrapper
