import { Button, LoadingProgress } from '@praticabr/ppsa'
import { AuthContext } from 'module/entryPoint/context/AuthContext/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { history } from 'routes/history'
import './styles.scss'

export const RecoverAccountForm = () => {
	const [send, setSend] = useState<boolean>(false)
	const { handleRecoverAccount, isLoading, email } = useContext(AuthContext)

	useEffect(() => {
		!email &&
			setTimeout(() => {
				history.push('/')
			}, 5000)
	}, [email])

	const sendEmail = () => (
		<>
			<div className="recover-account-form-content">
				<h4>
					POR SEGURANÇA, O ACESSO À CONTA FOI BLOQUEADO DEVIDO A TENTATIVAS DE
					LOGIN INCORRETAS.
				</h4>
				<h4>
					PARA DESBLOQUEAR SUA CONTA, SIGA AS INSTRUÇÕES APÓS CLICAR NO BOTÃO
					ABAIXO:
				</h4>
			</div>

			<div className="recover-account-form-actions">
				<Button
					size="lg"
					title="DESBLOQUEAR CONTA"
					variant="standard"
					onClick={() => handleRecoverAccount(() => setSend(true))}
				/>
			</div>
			{isLoading && <LoadingProgress />}
		</>
	)

	const sendedEmail = () => (
		<div className="recover-account-form-content">
			<h4>VOCÊ RECEBEU UM E-MAIL PARA INICIAR O DESBLOQUEIO DE SUA CONTA.</h4>
			<h4>VERIFIQUE SUA CAIXA DE ENTRADA OU SPAM.</h4>
			{isLoading && <LoadingProgress />}
		</div>
	)

	return (
		<div className="recover-account-form">
			{send ? sendedEmail() : sendEmail()}
		</div>
	)
}
