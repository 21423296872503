export function signInRequest(email, password, hasError) {
	return {
		type: '@auth/SIGN_IN_REQUEST',
		payload: { email, password, hasError }
	}
}

export function signInSuccess(
	token,
	user,
	name,
	email,
	cel,
	surname,
	register,
	department,
	tutorials,
	lastVersion
) {
	return {
		type: '@auth/SIGN_IN_SUCCESS',
		payload: {
			token,
			user,
			name,
			email,
			cel,
			surname,
			register,
			department,
			tutorials,
			lastVersion
		}
	}
}

export function signInFailure() {
	return {
		type: '@auth/SIGN_IN_FAILURE'
	}
}

export function signOut(userId) {
	return {
		type: '@auth/SIGN_OUT',
		payload: { userId }
	}
}
