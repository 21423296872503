import billedIcon from 'assets/image/billed.svg'
import billed_partiallyIcon from 'assets/image/billed_partially.svg'
import to_billingIcon from 'assets/image/to_billing.svg'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderHistoryContextElement } from 'module/orderManagement/context/OrderHistoryContext/OrderHistoryContext'
import IOrderHistoryListItem from 'module/orderManagement/models/IOrderHistoryListItem'
import {
	handleFinancialReleaseTypes,
	handleOrderCategoryType
} from 'module/orderManagement/utils/constants'
import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import LoadingList from 'shared/components/LoadingList'
import './styles.scss'

const OrderHistoryListBodyWeb = () => {
	const { orderHistoryList, isLoading, totalPages } = useContext(
		OrderHistoryContextElement
	)
	const { sa1010IsLoading } = useContext(ClientDetailsContextElement)

	const handleStatus = (billed: boolean, not_billed: boolean) => {
		if (billed && !not_billed) {
			return <img src={billedIcon} style={{ height: '26px', width: '16px' }} />
		}

		if (billed && not_billed) {
			return (
				<img
					src={billed_partiallyIcon}
					style={{ height: '26px', width: '16px' }}
				/>
			)
		}

		if (!billed && not_billed) {
			return (
				<img src={to_billingIcon} style={{ height: '26px', width: '16px' }} />
			)
		}
	}

	const listContent = () => {
		return totalPages ? (
			<>
				{orderHistoryList.map((order: IOrderHistoryListItem, index: number) => {
					return (
						<NavLink
							key={index}
							target="_blank"
							id={order.order_code}
							className="order-history-list"
							to={`/order/${order.branch}/${order.order_code}/details`}
						>
							<ul className="order-history-list-item order-history-list-order-status">
								{handleStatus(order.billed, order.not_billed)}
							</ul>
							<ul className="order-history-list-item order-history-list-order">{`${order.branch}-${order.order_code}`}</ul>
							<ul className="order-history-list-item order-history-list-order-category">
								{handleOrderCategoryType(order.order_category)}
							</ul>
							<ul className="order-history-list-item order-history-list-order-issue-date">
								{order.issue_date
									? moment(order.issue_date).format('DD/MM/YY')
									: ''}
							</ul>
							<ul className="order-history-list-item order-history-list-order-delivery-date">
								{order.delivery_date
									? moment(order.delivery_date).format('DD/MM/YY')
									: ''}
							</ul>
							<ul className="order-history-list-item order-history-list-order-last-billing">
								{order.last_billing
									? moment(order.last_billing).format('DD/MM/YY')
									: ''}
							</ul>
							<ul className="order-history-list-item order-history-list-order-salesperson">
								{order.salesperson}
							</ul>
							<ul className="order-history-list-item order-history-list-order-financial-status">
								{handleFinancialReleaseTypes(order.financial_status)}
							</ul>
							<ul className="order-history-list-item order-history-list-order-more-options"></ul>
						</NavLink>
					)
				})}
			</>
		) : (
			<NavLink className="orders-list" to={`/orders`} id="emptyList" key={0}>
				<ul className="orders-list-item orders-list-empty-list">
					Nenhum pedido encontrado
				</ul>
			</NavLink>
		)
	}

	return (
		<>
			{sa1010IsLoading || isLoading ? (
				<LoadingList show={isLoading} text="Buscando pedido..." />
			) : (
				listContent()
			)}
		</>
	)
}

export default OrderHistoryListBodyWeb
