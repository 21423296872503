import { zodResolver } from '@hookform/resolvers/zod'
import { FormControlLabel } from '@material-ui/core'
import { Button, CheckBox, Divider, Input, Modal } from '@praticabr/ppsa'
import { UsersPrpContextElement } from 'module/adminSystem/context/UsersPrpContext'
import { roles } from 'module/adminSystem/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { UpdateUserPrpSchema } from '../../schemas/update-user-prp-schema'
import './styles.scss'

interface Props {
	onClose: () => void
}

export const UserModal = ({ onClose }: Props) => {
	const { onIntegrateFieldControl, onUpdateUser, user } = useContext(
		UsersPrpContextElement
	)
	const [integratedWithFieldControl, setIntegratedWithFieldControl] =
		useState<boolean>(user?.id_field_control ? true : false)
	const [resetPass, setResetPass] = useState<boolean>(false)
	const [resetToken, setResetToken] = useState<boolean>(false)
	const [selectedRoles, setSelectedRoles] = useState<number[]>([])
	const [blocked, setBlocked] = useState<boolean>(user.loginAttempts === 0)

	const form = useForm<UpdateUserPrpSchema>({
		resolver: zodResolver(UpdateUserPrpSchema),
		defaultValues: {
			id_field_control: user?.id_field_control || ''
		}
	})

	useEffect(() => {
		if (user?.roles) {
			const rolesId = user?.roles?.map((role: { id: any }) => role?.id)
			setSelectedRoles(rolesId)
		}
	}, [user])

	const onChangeUserProfile = (roleSelected: number) => {
		const currentIndex = selectedRoles?.indexOf(roleSelected)
		const newChecked = [...selectedRoles]

		if (currentIndex === -1) {
			newChecked?.push(roleSelected)
		} else {
			newChecked?.splice(currentIndex, 1)
		}
		setSelectedRoles(newChecked)
	}

	const handleIntegrateFieldControl = () => {
		if (user?.email) {
			onIntegrateFieldControl(user?.email?.toLowerCase(), (data) => {
				switch (data?.statusCode) {
					case 201:
						form.setValue('id_field_control', data?.id_field_control)
						setIntegratedWithFieldControl(true)
						toast.success('Sucesso ao integrar usuário com a Field Control.')
						break
					case 406:
						toast.error(
							'Erro ao atualizar o id Field Control do usuário no PRP, por gentileza consulte o Digital Endeavour.'
						)
						break
					case 404:
						toast.error(`Erro ao encontrar o usuário no ${data?.local}.`)
						break
					default:
						toast.error('Erro ao integrar usuário com a Field Control')
						break
				}
			})
		} else {
			toast.error(
				'E-mail não informado, por gentileza preencha o e-mail do usuário.'
			)
		}
	}

	const onSubmit = (data: UpdateUserPrpSchema) => {
		onUpdateUser({
			userId: user?.id,
			data: {
				...data,
				loginAttempts: blocked ? 0 : 3,
				roles: selectedRoles,
				resetToken: resetToken,
				resetPass: resetPass
			},
			callback: handleCloseModal
		})
	}

	const handleCloseModal = () => {
		form.reset()
		setIntegratedWithFieldControl(false)
		setSelectedRoles([])
		setResetPass(false)
		setResetToken(false)
		onClose()
	}

	return (
		<Modal.root>
			<Modal.header onClose={handleCloseModal} title="Detalhes do Usuário" />
			<Modal.body>
				<Modal.body.main>
					<div className="prp-user-modal">
						<div className="prp-user-form">
							<Divider title="Usuário" />
							<div className="prp-user-form-rows">
								<div className="form-field-info">
									<label>Matrícula</label>
									<Input
										name="register"
										disabled={true}
										value={user?.register}
										variant="light-normal"
									/>
								</div>
								<div className="form-field-info" />
							</div>
							<div className="prp-user-form-rows">
								<div className="form-field-info">
									<label>Nome</label>
									<Input
										name="user"
										disabled={true}
										variant="light-normal"
										value={`${user?.name} ${user?.surname}`}
									/>
								</div>
								<div className="form-field-info">
									<label>E-mail</label>
									<Input
										name="email"
										disabled={true}
										value={user?.email?.toLowerCase()}
										variant="light-normal"
									/>
								</div>
							</div>
							<div className="form-field-info">
								<label>Integração do usuário com a Field Control</label>
								<div className="prp-user-field-control-integration">
									<Input
										disabled={true}
										variant="light-normal"
										placeholder="Usuário não integrado com a Field Control."
										{...form.register('id_field_control')}
									/>
									<Button
										size="lg"
										type="button"
										title="Integrar"
										variant="confirmation-solid"
										onClick={handleIntegrateFieldControl}
										disabled={
											user?.id_field_control || integratedWithFieldControl
										}
									/>
								</div>
							</div>
							<Divider title="Permissões" />
							<div className="form-field-info">
								<div className="form-field-checkbox-items">
									{roles
										?.filter((item) => item?.id < 13 || item?.id > 16)
										?.map((item) => (
											<FormControlLabel
												key={item?.id}
												label={item?.label}
												control={
													<CheckBox
														format="square"
														variant="standard"
														value={item?.id}
														color="secondary"
														onClick={() => onChangeUserProfile(item?.id)}
														checked={selectedRoles?.includes(item?.id)}
													/>
												}
											/>
										))}
								</div>
							</div>
							<Divider title="Permissões de anexo" />
							<div className="form-field-info">
								<div className="form-field-checkbox-items">
									{roles
										?.filter((item) => item?.id > 13 && item?.id < 17)
										?.map((item) => (
											<FormControlLabel
												key={item?.id}
												label={item?.label}
												control={
													<CheckBox
														format="square"
														variant="standard"
														value={item?.id}
														color="secondary"
														onClick={() => onChangeUserProfile(item?.id)}
														checked={selectedRoles.includes(item?.id)}
													/>
												}
											/>
										))}
								</div>
							</div>
							<Divider title="Opções" />
							<div className="form-field-info">
								<div className="form-field-checkbox-items">
									<FormControlLabel
										label="Restaurar Device Token"
										control={
											<CheckBox
												format="square"
												variant="standard"
												color="secondary"
												checked={resetToken}
												onClick={() => setResetToken(!resetToken)}
											/>
										}
									/>
									<FormControlLabel
										label="Restaurar senha"
										control={
											<CheckBox
												format="square"
												variant="standard"
												color="secondary"
												checked={resetPass}
												onClick={() => setResetPass(!resetPass)}
											/>
										}
									/>
									<FormControlLabel
										label="Bloqueado por tentativas de login?"
										control={
											<CheckBox
												format="square"
												variant="standard"
												color="secondary"
												defaultChecked={user.loginAttempts === 0}
												onChange={(e) => setBlocked(e.target.checked)}
											/>
										}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal.body.main>
				<Modal.body.actions>
					<div className="prp-user-modal-actions">
						<Button
							size="lg"
							title="Salvar"
							variant="confirmation-solid"
							onClick={form.handleSubmit(onSubmit)}
						/>
						<Button
							size="lg"
							title="Cancelar"
							onClick={handleCloseModal}
							variant="cancellation"
						/>
					</div>
				</Modal.body.actions>
			</Modal.body>
		</Modal.root>
	)
}
